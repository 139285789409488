import React from 'react'
import Skeleton from 'ui/atoms/skeleton/Skeleton'

const HomeCardsShimmer = () => {
  return (
    <>
      {[1, 2, 3].map((item, index) => (
        <div className='flex flex-col space-y-4' key={index}>
          <Skeleton width="50px" height="10px" />
          <Skeleton width="100%" height="10px" />
        </div>
      ))}
    </>
  )
}

export default HomeCardsShimmer