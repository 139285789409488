import React, { useEffect } from "react";
import { routes } from "core/router";
import 'react-photo-view/dist/react-photo-view.css';
import FullStory from 'react-fullstory';
import {
  Routes as RrdRoutes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { AuthGuard } from "modules/auth";
import { BrowserTracing } from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "core/graphql";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate:
      process.env.REACT_APP_ENVIRONMENT === "production" ? 0.2 : 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}


const Routes = Sentry.withSentryReactRouterV6Routing(RrdRoutes);
import 'quill/dist/quill.snow.css';
import usePreventZoom from "hooks/usePreventZoom";
const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID as string;
function App() {
  const location = useLocation();

  useEffect(() => {
    // --- scroll to top of page on url change ---
    window.scrollTo(0, 0);
  }, [location]);

  usePreventZoom();

  return (
    <ApolloProvider client={apolloClient}>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.meta.requiresAuth ? (
                <AuthGuard>
                  <>
                    <FullStory org={ORG_ID} />
                    <route.Component />
                  </>
                </AuthGuard>
              ) : (
                <>
                  <FullStory org={ORG_ID} />
                  <route.Component />
                </>
              )
            }
          ></Route>
        ))}
      </Routes>
    </ApolloProvider>
  );
}

export default App;
