import React from 'react'
import { SvgIonTimeOutline } from 'ui';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { SvgSpliceFaintLogo } from 'ui';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber';
import { convertSalonPackageServicesNamesToString } from './utils';
import { COLORS } from 'constants/colors';
import { limitString } from '../../utils/misc';
import { PackageBookingCardProps } from './types';

const PackageBookingCard = ({ salonPackage, businessInfo, selectBookingPackage }: PackageBookingCardProps) => {
  const price = salonPackage?.usesCustomPrice ? salonPackage?.customPrice : salonPackage?.totalPrice;
  return (
    <div className="bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1722945105/am6lrwkmexijtjyrm3fr.png')] w-full max-w-[360px] bg-cover bg-center bg-no-repeat h-[100px] sm:h-[150px] cursor-pointer rounded-md" onClick={() => selectBookingPackage && selectBookingPackage(salonPackage)}>
      <div className='w-full h-full flex justify-between py-5 px-6'>
        <div className='w-full max-w-[80%] md:max-w-[70%] flex flex-col justify-center sm:justify-between'>
          <div className='flex gap-2'>
            <div className='w-[14px] h-[14px] flex justify-center items-center rounded bg-grey-50'>
              <SvgIonTimeOutline width="10px" height="10px" />
            </div>
            <Paragraph size='b6' weight='normal' color={COLORS.WHITE} className='uppercase'>Valid for {salonPackage?.validityMonths} Month{salonPackage?.validityMonths > 1 ? 's' : ''}</Paragraph>
          </div>
          <div className='flex flex-col justify-start space-y-2'>
            <Paragraph size='b5' weight='normal' color={COLORS.WHITE}>{salonPackage?.name}</Paragraph>
            <Paragraph size='b6' weight='normal' color={COLORS.WHITE}>{limitString(convertSalonPackageServicesNamesToString(salonPackage?.packageServices), 20)}</Paragraph>
          </div>
          <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE}>{businessInfo?.country?.currency?.symbol || DEFAULT_CURRENCY}<FormatNumber value={price} /></Paragraph>
        </div>
        <div className='flex flex-col justify-center items-center w-[50px] md:w-[50px] space-y-4'>
          <SvgSpliceFaintLogo width="25px" height="35px" />
          <div className='hidden sm:flex flex-col gap-1'>
            <Paragraph size='b8' weight='normal' color={COLORS.WHITE} className='text-center'>{businessInfo?.name}</Paragraph>
            <Paragraph size='b9' weight='normal' color={COLORS.GREY[400]} className='text-center'>Powered by Splice</Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageBookingCard