import React, { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';
import {
  Button,
  CalendarPicker,
  Checkbox,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Layout,
  Modal,
  OtpInput,
  Paginator,
  Paragraph,
  Pill,
  SearchTerm,
  SelectInput,
  SvgCopy,
  SvgDatabase,
  Table,
  TableSkeleton,
  Tabs,
  ToastProps,
  ToastWrapper,
} from '../ui';
import {
  CreateExpenseInput,
  CreatePayoutInput,
  Expense,
  Payment,
  User,
  Wallet,
  WalletTransaction,
} from 'core/generated';
import {
  convertFullDateStringToDate,
  copyTextToClipboard,
  createFileNameFromDates,
  formatDateToOriginalDate,
  formatInToNumberString,
  formatInToPrice,
  formatNumber,
  formatSnakeCaseText,
  getCurrencySign,
  getDayMonthAndNumberAndTimeFromDate,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  getTodayQueryDates,
  handleDownload,
  handleExcelDownload,
} from '../components/utils/misc';
import { CurrencyCode } from '../components/utils/types';
import { useModal, useToast } from 'hooks';
import { COLORS } from 'constants/colors';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { ERRORS } from 'constants/errors';
import { RECEIPT_UPLOAD_PATTERN, REGEX_PATTERNS } from 'constants/pattern';
import { useSalonCache } from 'hooks/useSalonCache';
import { useUserCache } from 'hooks/useUserCache';
import {
  SvgCalendar,
  SvgClose,
  SvgCreditCard,
  SvgDownGrowthIndicator,
  SvgFilter,
  SvgGrowthIndicator,
  SvgInfo,
  SvgLocationPin,
  SvgSale,
} from 'ui';
import { PaymenntsFilterInput } from '../components/uicomponents/salesComponents/types';
import { standardizePaymentMethod } from '../components/uicomponents/salesComponents/utils';
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader';
import {
  createExpensesLineGraphDataGroup,
  expensesPieGraphData,
} from '../components/uicomponents/reportGraphUtils';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { canPerformAction } from '../components/utils/permission';
import { PERMISSION_CONSTANTS } from 'constants/permission';
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx';
import { DEFAULT_CURRENCY } from 'constants/currency';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from 'constants/routes';
import { useAddExpense, useCompleteBvnVerification, useCreatePayout, useCreateVirtualAccount, useDeleteExpense, useGetExpenses, useGetExpenseSummary, useGetPayments, useGetWalletTransactions } from 'api/useMoney';
import { useSalon } from 'api/useSalon';
import { useUploadFile, useUser } from 'api/useAccount';
import { Menu, Transition } from '@headlessui/react';
import { OTP_COUNTER } from 'constants/otpCounter';

const MONEY_TAB_NAME = {
  WALLET: 'Splice wallet',
  PAYMENTS: 'Payments',
  EXPENSE: 'Expense',
  FINANCE: 'Finance',
  POS: 'POS',
  BANK_TRANSFERS: 'Transfers',
};

const Wrapper = () => {
  return (
    <Money />
  );
};

const countryString = localStorage.getItem('country');
const country = countryString ? JSON.parse(countryString) : null

const Money = () => {
  const MONEY_TABS = [
    {
      key: MONEY_TAB_NAME.WALLET,
      title: MONEY_TAB_NAME.WALLET,
      show:
        canPerformAction(`Money::${PERMISSION_CONSTANTS.money.viewWallet}`) &&
        country?.payoutsSupported === true &&
        country?.collectionsSupported === true,
    },
    {
      key: MONEY_TAB_NAME.PAYMENTS,
      title: MONEY_TAB_NAME.PAYMENTS,
      show: canPerformAction(
        `Money::${PERMISSION_CONSTANTS.money.viewPayment}`
      ),
    },
    {
      key: MONEY_TAB_NAME.EXPENSE,
      title: MONEY_TAB_NAME.EXPENSE,
      show: canPerformAction(
        `Money::${PERMISSION_CONSTANTS.money.viewExpense}`
      ),
    },
    {
      key: MONEY_TAB_NAME.FINANCE,
      title: MONEY_TAB_NAME.FINANCE,
      show: false,
    },
  ];
  const [activeTab, setActiveTab] = useState(
    MONEY_TABS.filter((tab) => tab?.show)?.[0].key as string
  );
  const getCurrentMoneyTabView = () => {
    switch (activeTab) {
      case MONEY_TAB_NAME.PAYMENTS:
        return <Payments />;
      case MONEY_TAB_NAME.EXPENSE:
        return <Expenses hideButton={false} />;
      case MONEY_TAB_NAME.WALLET:
        return <Wallets />;
    }
  };

  return (
    <Layout pageTitle="Money" pageLevel={2}>
      <Tabs
        tabs={MONEY_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {getCurrentMoneyTabView()}
    </Layout>
  );
};

export const Expenses = ({ hideButton }: { hideButton?: boolean }) => {
  const { toast, addToast } = useToast()
  const [expense, setExpense] = useState<Expense | null>(null);
  const { getSalonFieldValue } = useSalonCache();
  const [selectedSummaryDates, setSelectedSummaryDates] = useState<
    [Date, Date] | null
  >(getDefaultQueryDates());
  const [before, setBefore] = useState<string | null>(null);
  const [after, setAfter] = useState<string | null>(null);

  const {
    data: expensesData,
    loading,
    refetch: refetchExpenses,
  } = useGetExpenses({
    salonId: getSalonFieldValue("id"),
    startDate: formatDateToOriginalDate(selectedSummaryDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedSummaryDates[1], "end"),
    before,
    after,
  })
  const expenses = useMemo(() => expensesData?.expenses, [expensesData]);
  const salonId = getSalonFieldValue("id");
  const {
    data: expenseSummaryData,
    loading: expenseSummaryLoading,
    refetch: refetchExpenseSummary,
  } = useGetExpenseSummary({
    salonId: getSalonFieldValue("id"),
    startDate: formatDateToOriginalDate(selectedSummaryDates[0], "start"),
    endDate: formatDateToOriginalDate(selectedSummaryDates[1], "end"),
  })
  const expensesSummary = useMemo(() => expenseSummaryData?.expenseSummaryReport, [expenseSummaryData]);
  const EXPENSE_HEADINGS = [
    { label: 'Paid to', value: 'paid_to' },
    { label: 'Description', value: 'description' },
    { label: 'Category', value: 'category' },
    { label: 'Date of Expense', value: 'date' },
    { label: 'Amount', value: 'amount' },
  ];

  const MOBILE_EXPENSE_HEADINGS = [
    {
      key: 'paid_to',
      title: 'Paid to',
    },
    {
      key: 'category',
      title: 'Category',
    },
    {
      key: 'date',
      title: 'Date',
    },
    {
      key: 'amount',
      title: 'Amount',
    },
  ];

  useEffect(() => {
    refetchExpenses();
    refetchExpenseSummary();
  }, [selectedSummaryDates, before, after, salonId]);

  const {
    isVisible: isAddExpenseModalVisible,
    closeModal: closeAddExpenseModal,
    openModal: openAddExpenseModal,
  } = useModal();

  const _closeAddExpenseModal = () => {
    setExpense(null);
    closeAddExpenseModal();
    refetchExpenses();
    refetchExpenseSummary();
  };

  const openExpenseModal = (id: number) => {
    if (hideButton) return;
    setExpense(expenses[id]);

    openAddExpenseModal();
  };

  const generateExpensesTableData = (expenses: Expense[]) => {
    return expenses?.map?.((expense) => ({
      paid_to: expense.recipient,
      description: expense?.description,
      category: formatSnakeCaseText(expense?.expenseCategory.name),
      date: getNumberMonthAndYearFromDate(expense?.date),
      amount: () => {
        return expense ? (
          <div className="flex items-center">
            <Paragraph size="b5">{formatInToPrice(expense?.amount)}</Paragraph>
          </div>
        ) : null;
      },
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getExpensesTotal = (expenses: Expense[]) => {
    return expenses.reduce((total, expense) => {
      return total + expense.amount;
    }, 0);
  };

  const formatExpenseCsvDownloadData = (expenses: Expense[]) => {
    return expenses?.map?.((expense) => ({
      'Paid to': expense?.recipient,
      Description: expense?.description,
      Category: formatSnakeCaseText(expense?.expenseCategory.name),
      Date: getNumberMonthAndYearFromDate(expense?.date),
      Amount: expense?.amount,
    }));
  };

  const filename = `${createFileNameFromDates(
    selectedSummaryDates || getDefaultQueryDates()
  )}_expense_report`;
  const _handleDownload = () => {
    if (expenses) {
      handleDownload(formatExpenseCsvDownloadData(expenses?.nodes), filename);
    }
  };

  const downloadExcel = () => {
    if (expenses) {
      handleExcelDownload(
        formatExpenseCsvDownloadData(expenses?.nodes),
        filename,
        'Expenses'
      );
    }
  };

  const expensesPieData = expensesPieGraphData(
    expensesSummary?.expensesGraphData || []
  );
  const expensesLineGraphData = createExpensesLineGraphDataGroup(
    expensesSummary?.expensesTrendGraphData || []
  );

  const getExpenseContent = () => {
    if (Array.isArray(expenses?.nodes) && expenses?.nodes?.length) {
      const tableData = generateExpensesTableData(expenses?.nodes);
      return (
        <>
          {/* <div className='flex flex-col md:flex-row w-full justify-center items-center gap-4'>
            <div className='flex flex-col'>
              <Heading size='h8' variant='h2' weight='bold'>
                {formatInToPrice(getExpensesTotal(expenses))}
              </Heading>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold'>
                {calculateDaysBetweenDates(selectedDates as [Date, Date])}
              </Paragraph>
            </div>
            <div className='w-full max-w-[400px]'>
              <Chart
                chartType='PieChart'
                width='100%'
                height='100%'
                data={chartData}
                options={chartOptions}
              />
            </div>
          </div> */}
          <Table
            headers={EXPENSE_HEADINGS}
            mobileHeaders={MOBILE_EXPENSE_HEADINGS}
            rows={tableData}
            onClick={openExpenseModal}
          />
        </>
      );
    }

    return (
      <>
        {/* <div className='w-full flex flex-col md:flex-row py-4 px-4 md:px-12 gap-4'>
          <div className='w-full md:w-2/4 flex items-center'>
            <SearchTerm setDebouncedSearchQuery={() => {}} />
          </div>
          <div className='w-full md:w-2/4 flex items-center gap-2'>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            <Button
              variant='secondary'
              size='sm'
              type='button'
              rounded='md'
              fontSize='b5'
              disabled={!expenses}
              className='hidden md:flex'
              onClick={_handleDownload}
            >
              Export Report
            </Button>
            {!hideButton && (
              <Button
                variant='primary'
                size='sm'
                disabled={false}
                loading={false}
                type='button'
                rounded='md'
                fontSize='b5'
                onClick={openAddExpenseModal}
              >
                Add Expense
              </Button>
            )}
          </div>
        </div> */}
        <div className="w-full flex h-full justify-center items-center">
          <div className="flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12">
            <Heading variant="h2" size="h3">
              Expense
            </Heading>
            <Paragraph size="b5" className="mt-4">
              You have not recorded any expense yet.
            </Paragraph>
            <div className="w-full flex space-x-2">
              {!hideButton ? (
                <Button
                  variant="primary"
                  size="lg"
                  disabled={false}
                  loading={false}
                  type="button"
                  rounded="lg"
                  onClick={openAddExpenseModal}
                >
                  Add Expense
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  };

  const goToNext = () => {
    if (expenses?.pageInfo?.hasNextPage) {
      setBefore(expenses?.pageInfo?.endCursor)
      setAfter(null)
    }
  };

  const goToPrev = () => {
    if (expenses?.pageInfo?.hasPreviousPage) {
      setBefore(null)
      setAfter(expenses?.pageInfo?.startCursor)
    }
  };

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      {loading ? <ContentLoader /> : null}
      <div className="w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2">
        <div className="flex flex-col md:flex-row xl:flex-col gap-3">
          <Heading size="h9" variant="h1" weight="bold">
            Expenses
          </Heading>
          <div className="flex space-x-1 items-center">
            <Paragraph
              size="b4"
              color={COLORS.GREY[300]}
              className="flex space-x-1 items-center"
            >
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(
                selectedSummaryDates[0]
              )} - ${getNumberMonthAndYearFromDate(
                selectedSummaryDates[1]
              )}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph
              size="b4"
              color={COLORS.GREY[300]}
              className="flex space-x-1 items-center"
            >
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-2">
          <Paragraph size="b4" className="hidden md:flex">
            Showing:
          </Paragraph>
          <CalendarPicker
            {...{
              selectedDates: selectedSummaryDates,
              setSelectedDates: setSelectedSummaryDates,
            }}
          />
          {hideButton ? (
            <ExportDataToCsvOrXlsx
              downloadCsv={_handleDownload}
              downloadXlsx={downloadExcel}
            />
          ) : canPerformAction(
            `Money::${PERMISSION_CONSTANTS.money.addExpense}`
          ) ? (
            <Button
              variant="primary"
              size="sm"
              disabled={false}
              loading={false}
              type="button"
              rounded="md"
              fontSize="b5"
              onClick={openAddExpenseModal}
            >
              Add Expense
            </Button>
          ) : null}
        </div>
      </div>
      <div className="w-full flex flex-col xl:flex-row gap-4 p-3 md:p-6 border-t border-grey-100">
        <div className="w-full flex flex-col min-h-[290px] xl:w-[19.57%] justify-between space-y-3">
          <div className="w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md">
            <span className="w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50">
              <SvgSale width="18px" height="18px" />
            </span>

            <div className="w-full flex flex-col">
              <Paragraph
                size="b4"
                color={COLORS.GREY[400]}
                weight="bold"
                className=""
              >
                Total expense ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size="b1" weight="semiBold" className="">
                {expensesSummary?.totalExpense}
              </Paragraph>
            </div>
          </div>
          <div className="w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md">
            <span className="w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50">
              <SvgSale width="18px" height="18px" />
            </span>

            <div className="w-full flex flex-col">
              <Paragraph
                size="b4"
                color={COLORS.GREY[400]}
                weight="bold"
                className=""
              >
                Highest category
              </Paragraph>
              <Paragraph size="b1" weight="semiBold" className="">
                {expensesSummary?.categoryWithMostExpenses}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-[40.04%] h-[290px] flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md">
          <div className="w-full flex justify-between items-center">
            <Paragraph
              size="b4"
              color={COLORS.GREY[300]}
              weight="semiBold"
              className=""
            >
              Expenses
            </Paragraph>

            <span className="bg-grey-50/80 border border-grey-100 text-b6 text-grey-300 font-semibold py-1 px-2">
              Total: {expensesSummary?.totalExpense}
            </span>
          </div>
          <Pie
            data={expensesPieData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'right', // Hide the legend
                },
                tooltip: {
                  enabled: true,
                },
              },
            }}
          />
        </div>
        <div className="w-full xl:w-[40.39%] h-[290px] flex flex-col p-4 space-y-4 rounded-md border border-grey-100 bg-grey-50/20">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col space-y-2 w-[60%]">
              <Paragraph
                size="b4"
                color={COLORS.GREY[300]}
                weight="semiBold"
                className=""
              >
                Trends and Highlights
              </Paragraph>
              <Paragraph size="b5" color={COLORS.GREY[400]} className="">
                Find your reviews highlights here
              </Paragraph>
            </div>
          </div>

          <div className="w-full flex justify-between">
            <div className="flex flex-col justify-end space-y-2">
              <Paragraph
                size="b6"
                color={COLORS.GREY[300]}
                weight="semiBold"
                className="uppercase"
              >
                TOTAL EXPENSES
              </Paragraph>
              <Paragraph
                size="b4"
                color={COLORS.GREY[300]}
                weight="semiBold"
                className=""
              >
                {expensesSummary?.totalExpense}
              </Paragraph>
            </div>

            <div className="flex flex-col space-y-2">
              <Paragraph
                size="b4"
                color={COLORS.GREY[300]}
                weight="semiBold"
                className="flex space-x-1 items-center justify-end"
              >
                <span>{expensesSummary?.expenseTrend}%</span>
                {expensesSummary?.expenseTrend?.toString().includes('-') ? (
                  <SvgDownGrowthIndicator width="16px" height="16px" />
                ) : (
                  <SvgGrowthIndicator width="16px" height="16px" />
                )}
              </Paragraph>
              <Paragraph
                size="b6"
                color={COLORS.GREY[300]}
                weight="light"
                className=""
              >
                VS LAST MONTH%
              </Paragraph>
            </div>
          </div>
          <div className="w-full h-fit">
            <Bar
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                    text: 'Expenses',
                  },
                },
                scales: {
                  x: {
                    type: 'category',
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      callback: function (value, index, values) {
                        return DEFAULT_CURRENCY + value.toLocaleString('en-US');
                      },
                    },
                  },
                },
              }}
              data={expensesLineGraphData}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col xl:flex-row items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6 gap-3">
        <Paragraph size="b4" weight="semiBold" className="">
          Overview
        </Paragraph>
        <div className="w-full xl:w-8/12 flex items-center justify-end space-x-4">
          <Paginator
            pageInfo={expenses?.pageInfo}
            {...{ goToNext, goToPrev }}
          />
        </div>
      </div>
      {getExpenseContent()}
      <AddExpenseModal
        isVisible={isAddExpenseModalVisible}
        closeModal={_closeAddExpenseModal}
        expense={expense}
        addToast={addToast}
      />
    </>
  );
};

type ExpenseModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  expense?: Expense;
  addToast: (toast: ToastProps) => void;
};

const AddExpenseModal = ({
  isVisible,
  closeModal,
  expense,
  addToast
}: ExpenseModalProps) => {
  const [imageUrl, setImageUrl] = useState('');
  const { getSalonFieldValue } = useSalonCache();
  const {
    loading: isCreating,
    createExpense,
  } = useAddExpense()

  const {
    loading: isDeleting,
    deleteExpense
  } = useDeleteExpense()
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateExpenseInput>();

  useEffect(() => {
    if (expense) {
      setValue('amount', expense.amount);
      setValue('description', expense.description);
      setValue('category', expense.expenseCategory.name);
      setValue('recipient', expense.recipient);
      setValue('isRecurring', expense.isRecurring || false);
      setValue('date', convertFullDateStringToDate(expense.date));
      setValue('receiptUrl', expense.receiptUrl);
      setValue('interval', expense.interval);
    } else {
      resetForm();
    }
  }, [expense]);

  const saveExpense = async (input: CreateExpenseInput) => {
    let receiptUrl = imageUrl || '';
    if (expense && !imageUrl && expense?.receiptUrl) {
      receiptUrl = expense?.receiptUrl;
    }
    const payload = {
      ...input,
      salonId: getSalonFieldValue('id'),
      receiptUrl,
      amount: Number(input.amount.toString().replace(/,/g, '')),
      isRecurring: input.isRecurring ? input.isRecurring : false,
      interval: input.isRecurring ? input.interval : null,
      id: expense ? expense.id : null,
    };
    createExpense({
      variables: {
        input: {
          ...payload,
        },
      }
    }).then(({ data }) => {
      const { createExpense } = data
      if (createExpense?.status === 201 || createExpense?.status === 200) {
        addToast && addToast({
          message: 'Expense saved successfully',
          variant: 'success',
        });
        closeModal()
      }

      if (createExpense?.errors?.length) {
        addToast({ message: createExpense.errors[0].message, variant: 'error' })
      }
    })
  };

  const handleClose = () => {
    closeModal();
    resetForm();
  };

  const resetForm = () => {
    setValue('amount', 0);
    setValue('description', '');
    setValue('category', '');
    setValue('recipient', '');
    setValue('isRecurring', false);
    setValue('date', '');
    setValue('receiptUrl', '');
    setValue('interval', '');
    setImageUrl('');
  };

  const handleDeleteOrCancel = async () => {
    if (expense) {
      deleteExpense({
        variables: {
          input: {
            expenseId: expense.id,
          },
        }
      }).then(({ data }) => {
        const { deleteExpense } = data
        if (deleteExpense?.status === 200) {
          addToast && addToast({
            message: 'Expense deleted successfully',
            variant: 'success',
          });
        } else {
          addToast({ message: deleteExpense.errors[0].message, variant: 'error' })
        }
      })
    } else {
      handleClose();
    }
  };

  const EXPENSE_CATEGORIES = [
    {
      value: 'refunds',
      label: 'Refunds',
    },
    {
      value: 'rent',
      label: 'Rent',
    },
    {
      value: 'electricity',
      label: 'Electricity',
    },
    {
      value: 'transportation',
      label: 'Transportation',
    },
    {
      value: 'internet',
      label: 'Internet',
    },
    {
      value: 'marketing',
      label: 'Marketing',
    },
    {
      value: 'software_and_services',
      label: 'Software & Services',
    },
    {
      value: 'diesel',
      label: 'Diesel',
    },
    {
      value: 'inventory',
      label: 'Inventory (supplies for the business)',
    },
    {
      value: 'miscellaneous',
      label: 'Miscellaneous',
    },
    {
      value: 'water',
      label: 'Water',
    },
  ];
  const EXPENSE_INTERVAL = [
    {
      value: 'daily',
      label: 'Daily',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
    {
      label: 'Annually',
      value: 'annually',
    },
  ];

  const {
    loading: isFileUploading,
    uploadFile
  } = useUploadFile();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target) {
          const base64DataUri = event.target.result;
          uploadFile({
            variables: { input: { data: base64DataUri } }
          }).then(({ data }) => {
            const { uploadFile } = data;
            if (uploadFile?.status === 200) {
              setImageUrl(uploadFile?.url);
            }

            if (uploadFile?.errors?.length) {
              addToast({
                message: uploadFile?.errors[0].message,
                variant: 'error'
              })
            }
          }).catch((error) => {
            addToast({
              message: error?.message,
              variant: 'error'
            })
          })
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      title={expense ? 'Edit Expense' : 'Add Expense'}
      show={isVisible}
      closeModal={closeModal}
    >
      <form
        onSubmit={handleSubmit(saveExpense)}
        className="w-full mt-6 space-y-6"
        autoComplete="off"
      >
        <Input
          name="recipient"
          label="Recipient"
          id="recipient"
          type="text"
          placeholder="Enter recipient"
          control={control}
          rules={{
            required: ERRORS.RECIPIENT_REQUIRED,
          }}
          error={errors.recipient}
        />

        <Input
          name="description"
          label="Description is required"
          id="description"
          type="text"
          placeholder="Description"
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_PUNCTUATION_MARKS,
          }}
          error={errors.description}
        />

        <SelectInput
          name="category"
          label="Category"
          id="category"
          control={control}
          rules={{
            required: 'Category is required',
          }}
          error={errors.category}
          options={EXPENSE_CATEGORIES}
          placeholder="Select Category"
        />

        <Controller
          control={control}
          name="amount"
          rules={{ required: 'Please enter a price' }}
          render={({ field, formState: { errors } }) => (
            <div className="w-full flex flex-col space-y-2">
              <FormLabel htmlFor="price">Amount</FormLabel>
              <input
                className="w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400"
                type="text"
                {...field}
                value={formatNumber(field.value)}
                onChange={(e) => field.onChange(formatNumber(e.target.value))}
                placeholder="Enter price"
              />
              {errors?.amount?.message && (
                <FormHelperText variant="error">
                  {errors.amount.message}
                </FormHelperText>
              )}
            </div>
          )}
        />

        <Input
          name="date"
          label="Date"
          id="date"
          type="date"
          placeholder="DD/MM/YY"
          control={control}
          rules={{
            required: ERRORS.DATE_REQUIRED,
          }}
          error={errors.date as FieldError}
        />

        <Controller
          control={control}
          name="receiptUrl"
          render={({ formState: { errors } }) => (
            <div className="w-full flex flex-col space-y-2">
              <FormLabel htmlFor="price">Upload receipt (required)</FormLabel>
              <div className="w-full relative rounded-md cursor-pointer border border-grey-20">
                <div className="w-full flex justify-between items-center px-4 py-2 cursor-pointer">
                  {isFileUploading ? (
                    <div className="flex justify-center gap-x-2 cursor-pointer">
                      {[1, 2, 3].map((item, index) => (
                        <div
                          key={index}
                          className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse"
                        />
                      ))}
                    </div>
                  ) : expense?.receiptUrl ? (
                    <Paragraph size="b5" className="">
                      {imageUrl ? imageUrl : 'Change receipt'}
                    </Paragraph>
                  ) : (
                    <Paragraph size="b5" className="">
                      {imageUrl ? imageUrl : 'Upload a receipt'}
                    </Paragraph>
                  )}
                  <Button
                    variant="light"
                    className=""
                    disabled={isFileUploading}
                    loading={isFileUploading}
                    size="sm"
                    rounded="sm"
                  >
                    Select file
                  </Button>
                  <input
                    className="w-full absolute left-0 h-full opacity-0 top-0"
                    type="file"
                    disabled={isFileUploading}
                    accept={RECEIPT_UPLOAD_PATTERN}
                    onChange={handleFileUpload}
                  />
                </div>
              </div>
              {errors?.receiptUrl?.message && (
                <FormHelperText variant="error">
                  {errors.receiptUrl.message}
                </FormHelperText>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name="isRecurring"
          render={({ field: { onChange, value } }) => {
            return (
              <div className="w-full flex space-y-4">
                <div className="w-full flex justify-start flex-wrap space-x-2 xl:space-x-4">
                  <div className="flex" onClick={() => onChange(!value)}>
                    <div className="flex justify-center items-center cursor-pointer text-b5 text-grey-900 space-x-2">
                      <Checkbox isChecked={value} />
                      <span className="text-grey-900 xl:whitespace-nowrap">
                        Is this a recurring expense?
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />

        {watch('isRecurring') && (
          <SelectInput
            name="interval"
            label="Interval"
            id="interval"
            control={control}
            rules={{
              required: watch('isRecurring') ? ERRORS.INTERVAL_REQUIRED : false,
            }}
            error={errors.interval}
            options={EXPENSE_INTERVAL}
            placeholder="Select Interval"
          />
        )}
        <Button
          variant="primary"
          className=""
          disabled={false}
          loading={isCreating}
          size="lg"
          rounded="lg"
        >
          Save
        </Button>
        <Button
          variant="text"
          className={`mx-auto ${expense && 'text-red-500'}`}
          disabled={false}
          loading={isDeleting}
          size="none"
          type="button"
          onClick={handleDeleteOrCancel}
        >
          {expense ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  );
};

const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState<Payment | null>(null);
  const { getSalonData } = useSalonCache();
  const [filteredPaymentData, setFilteredPaymentData] = useState<Payment[]>([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const salon = getSalonData();
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getTodayQueryDates()
  );
  const {
    data: paymentsData,
    loading: paymentsLoading,
    refetch: refetchPayments,
  } = useGetPayments({
    salonId: salon?.id,
    startDate: formatDateToOriginalDate(selectedDates?.[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates?.[1], 'end'),
    q: debouncedSearchQuery,
  })

  useEffect(() => {
    if (salon?.id) {
      refetchPayments()
    }
  }, [salon?.id, refetchPayments])

  const payments = useMemo(() => {
    setFilteredPaymentData(paymentsData?.payments);

    return paymentsData?.payments
  }, [paymentsData])

  const PAYMENT_HEADINGS = [
    { label: 'Customer', value: 'customer' },
    { label: 'Service and Product', value: 'service' },
    { label: 'Payment Method', value: 'paymentMethod' },
    { label: 'Date', value: 'date' },
    { label: 'Amount', value: 'amount' },
  ];
  const { control, handleSubmit } = useForm<PaymenntsFilterInput>({});

  const {
    openModal: openFilterDialogModal,
    closeModal: closeFilterDialogModal,
    isVisible: isFilterDialogModalVisible,
  } = useModal();

  const MOBILE_PAYMENT_HEADINGS = [
    {
      key: 'customer',
      title: 'Customer',
    },
    {
      key: 'service',
      title: 'Service',
    },
    {
      key: 'paymentMethod',
      title: 'Payment Mtd',
    },
    {
      key: 'amount',
      title: 'Amount',
    },
  ];

  const {
    openModal: openViewPaymentModal,
    closeModal: closeViewPaymentModal,
    isVisible: isViewPaymentModalVisible,
  } = useModal();

  const openPayment = (id: number) => {
    const p = payments[id];
    setPayment(p);
    openViewPaymentModal();
  };

  const generatePaymentsTableData = (
    payments: Payment[],
    countryCode?: string
  ) => {
    return payments?.map?.((payment) => ({
      customer: payment.client?.firstName + ' ' + payment?.client?.lastName,
      service: payment.description,
      paymentMethod: standardizePaymentMethod(payment?.mode || ''),
      date: getNumberMonthAndYearFromDate(
        payment?.createdAt
      ),
      amount: formatInToPrice(payment.amount),
    }));
  };

  useEffect(() => {
    refetchPayments();
  }, [debouncedSearchQuery, selectedDates]);

  const paymentMethodFIlterOptions = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'bank_transfer',
      label: 'Bank Transfer',
    },
    {
      value: 'pos',
      label: 'POS',
    },
    {
      value: 'cash',
      label: 'Cash',
    },
    {
      value: 'giftcard',
      label: 'Gift Card',
    },
    {
      value: 'voucher',
      label: 'Voucher',
    },
  ];

  const onSubmitData = (input: PaymenntsFilterInput) => {
    closeFilterDialogModal();
    if (input.paymentMethod === 'all') {
      setFilteredPaymentData(payments);
      return;
    } else if (input.paymentMethod) {
      const filteredData = payments?.filter(
        (payment) => payment.mode === input.paymentMethod
      );
      setFilteredPaymentData(filteredData);
      return;
    }
  };

  const getPaymentContent = () => {
    if (Array.isArray(filteredPaymentData) || debouncedSearchQuery) {
      const tableData = generatePaymentsTableData(filteredPaymentData, 'NG');
      return (
        <>
          <div className="flex flex-col xl:flex-row justify-between items-center py-4 px-8 space-x-4">
            <div className="w-full xl:w-6/12 flex items-center space-x-4">
              <SearchTerm
                placeholder="Search"
                setDebouncedSearchQuery={setDebouncedSearchQuery}
              />
              <Button
                variant="light"
                rounded="md"
                size="md"
                onClick={openFilterDialogModal}
              >
                <SvgFilter width="20px" height="20px" />
                <span className="hidden xl:inline">Filter</span>
              </Button>
            </div>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          </div>
          <Table
            headers={PAYMENT_HEADINGS}
            mobileHeaders={MOBILE_PAYMENT_HEADINGS}
            rows={tableData}
            onClick={openPayment}
          />
        </>
      );
    }
  };

  return (
    <>
      {paymentsLoading ? <ContentLoader /> : null}
      {getPaymentContent()}
      <ViewPaymentModal
        isVisible={isViewPaymentModalVisible}
        closeModal={closeViewPaymentModal}
        payment={payment}
      />

      <Modal
        show={isFilterDialogModalVisible}
        closeModal={closeFilterDialogModal}
      >
        <div className="w-full flex flex-col space-y-6">
          <div className="w-full flex justify-between">
            <Paragraph size="b1" color={COLORS.GREY[900]}>
              Filter
            </Paragraph>
            <Button
              variant="icon"
              className="border-0"
              size="xs"
              onClick={closeFilterDialogModal}
            >
              <SvgClose width="24px" height="24px" />
            </Button>
          </div>

          <form
            onSubmit={handleSubmit(onSubmitData)}
            className="w-full flex flex-col space-y-6 pt-6"
          >
            <SelectInput
              name="paymentMethod"
              id="payment-method"
              label="Payment Method"
              control={control}
              options={paymentMethodFIlterOptions}
              placeholder="Select Payment Method"
            />
            <Button variant="primary" className="" size="lg" rounded="lg">
              Apply filter
            </Button>
            <Button
              variant="text"
              size="none"
              type="button"
              fontWeight="semiBold"
              className="text-red-500"
              onClick={closeFilterDialogModal}
            >
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    </>
  );
};

type ViewPaymentModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  payment: Payment;
};
const ViewPaymentModal = ({
  isVisible,
  closeModal,
  payment,
}: ViewPaymentModalProps) => {
  return (
    <Modal title="Transaction details" show={isVisible} closeModal={closeModal}>
      <>
        {payment && (
          <div className="w-full flex flex-col my-12 space-y-6">
            <div className="w-full flex space-x-4">
              <div className="flex flex-col space-y-2">
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  Client
                </Paragraph>
                <Paragraph size="b4" color={COLORS.BLACK}>
                  {payment?.client?.firstName} {payment?.client?.lastName}
                </Paragraph>
              </div>
              <div className="flex flex-col space-y-2">
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  Amount
                </Paragraph>
                <Paragraph size="b4" color={COLORS.BLACK}>
                  {formatInToPrice(payment?.amount)}
                </Paragraph>
              </div>
            </div>
            <div className="w-full flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Services
              </Paragraph>
              <Paragraph size="b4" color={COLORS.BLACK}>
                {payment?.description}
              </Paragraph>
            </div>
            <div className="flex flex-row space-x-4">
              <div className="flex flex-col space-y-2 w-1/2">
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  Payment method
                </Paragraph>
                <Paragraph
                  size="b4"
                  color={COLORS.BLACK}
                  className="capitalize"
                >
                  {payment?.mode}
                </Paragraph>
              </div>
              <div className="flex flex-col space-y-2 w-1/2">
                <Paragraph size="b5" color={COLORS.GREY[300]}>
                  Time
                </Paragraph>
                <Paragraph size="b4" color={COLORS.BLACK}>
                  {getDayMonthAndNumberAndTimeFromDate(
                    payment?.createdAt
                  ) || '-'}
                </Paragraph>
              </div>
            </div>
            <div className="w-full flex flex-col">
              <Paragraph size="b5" color={COLORS.GREY[300]}>
                Payment reference
              </Paragraph>
              <Paragraph size="b4" color={COLORS.BLACK}>
                {payment.paymentReference || '-'}
              </Paragraph>
            </div>
          </div>
        )}
      </>
      <Button
        variant="primary"
        className=""
        size="lg"
        rounded="lg"
        onClick={closeModal}
      >
        Done
      </Button>
    </Modal>
  );
};

const Wallets = () => {
  const { getUserData } = useUserCache()
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const { toast, addToast } = useToast();
  const user = getUserData();
  const business = user?.business;
  const [showBvnForm, setShowBvnForm] = useState(false);
  const [showRcForm, setShowRcForm] = useState(false);

  const {
    data,
    refetch
  } = useUser()

  const {
    data: walletData,
    loading: walletLoading,
    refetch: refetchWallet,
  } = useGetWalletTransactions(salonId)

  const walletTransactions = useMemo(() => {
    return walletData?.walletTransactions
  }, [walletData])

  useEffect(() => {
    refetchWallet()
    refetch()
  }, [refetchWallet])

  const {
    data: salonData,
    loading: salonLoading,
    refetch: refetchSalon,
  } = useSalon(salonId)

  const salon = useMemo(() => {
    return salonData?.salon
  }, [salonData])

  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      if (!currentUser?.business?.bvn && canPerformAction(
        `Money::${PERMISSION_CONSTANTS.money.verifyNin}`
      )) {
        setShowBvnForm(true)
      } else {
        setShowBvnForm(false)
      }

      if (!currentUser?.business?.rcNumber && canPerformAction(
        `Money::${PERMISSION_CONSTANTS.money.verifyNin}`
      )) {
        setShowRcForm(true)
      } else {
        setShowRcForm(false)
      }
    }

    return currentUser;
  }, [data]);

  const WALLET_TRANSACTION_HEADERS = [
    { label: 'Transaction', value: 'title' },
    { label: 'Description', value: 'description' },
    { label: 'Transaction Type', value: 'transaction' },
    { label: 'Payment Mode', value: 'paymentMode' },
    { label: 'Date', value: 'date' },
    { label: 'Amount', value: 'amount' },
    { label: 'Status', value: 'status' },
  ];

  const WALLET_TRANSACTION_MOBILE_HEADERS = [
    {
      title: 'Transaction',
      key: 'title',
    },
    {
      title: 'Type',
      key: 'transaction',
    },
    {
      title: 'Payment',
      key: 'paymentMode',
    },
    {
      title: 'Date',
      key: 'date',
    },
    {
      title: 'Amount',
      key: 'amount',
    },
    {
      title: 'Status',
      key: 'status',
    },
  ];
  const { isVisible, openModal, closeModal: _closeModal } = useModal();

  const closeModal = () => {
    _closeModal();
    refetchWallet();
    refetch()
  }
  const {
    isVisible: isVisibleVirtualErrorNote,
    openModal: openModalVirtualErrorNote,
    closeModal: closeModalVirtualErrorNote,
  } = useModal();

  const generateWalletTransactionsTableData = (
    transactions: WalletTransaction[],
    countryCode?: string
  ) => {
    return transactions?.map?.((transaction) => ({
      title: transaction?.title,
      description: transaction?.description,
      transaction:
        transaction?.transactionType.charAt(0)?.toUpperCase() +
        transaction?.transactionType.slice(1),
      paymentMode: formatSnakeCaseText(transaction?.paymentMode),
      date: getNumberMonthAndYearFromDate(
        transaction?.createdAt
      ),
      amount: `${getCurrencySign(
        transaction?.currency as CurrencyCode
      )}${formatInToNumberString(transaction?.amount)}`,
      status: <Pill
        variant={
          transaction.status === 'Successful'
            ? 'success'
            : transaction.status === 'Pending'
              ? 'warning'
              : transaction.status === 'Queued'
                ? 'light'
                : 'light'
        }
      >
        {transaction.status}
      </Pill>,
    }));
  };
  const rows = generateWalletTransactionsTableData(walletTransactions, 'NG');

  const {
    createVirtualAccount,
    loading: createVirtualAccountLoading,
  } = useCreateVirtualAccount()

  const generateVirtualAccount = async () => {
    createVirtualAccount({
      variables: {
        input: {
          salonId,
        },
      },
    }).then(({ data }) => {
      if (data?.createVirtualAccount?.virtualAccount) {
        // get user data
        addToast({
          message: 'Virtual account has been successfully created',
          variant: 'success',
        });
      } else if (data?.createVirtualAccount?.errors) {
        const message =
          data?.createVirtualAccount?.errors[0]?.message;
        if (message === 'You need to set your RC number first') {
          openModalVirtualErrorNote();
          return;
        }
        addToast({
          message,
          variant: 'error',
        });
      } else {
        openModalVirtualErrorNote();
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error',
      });
    });
  };

  const copyAccountNumber = () => {
    if (!business?.virtualAccount) return;
    copyTextToClipboard(business?.virtualAccount?.accountNumber);
    addToast({
      variant: 'success',
      message: 'Account number copied to clipboard',
    });
  };

  return (
    <div>
      <ToastWrapper toast={toast as ToastProps} />
      {salon?.wallet && (
        <>
          <div className="w-full flex flex-col xl:flex-row gap-6 p-6 bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1718292895/b6nwrxgmc68rgbxgjf8y.png')] bg-contain bg-repeat">
            <div className="flex space-x-4 w-full max-w-[500px] p-6 bg-white/20 backdrop-blur-[15px] rounded-md">
              <div className="text-white p-2 rounded-full bg-grey-400 w-fit h-fit">
                <SvgCreditCard width="24px" height="24px" />
              </div>
              <div className="flex flex-col space-y-2">
                <div className="w-full flex space-x-4 items-center">
                  <Heading size="h11" variant="h2" color={COLORS.GREY['400']}>
                    Available Balance
                  </Heading>
                  <div className="group flex relative text-grey-300">
                    <SvgInfo width="15px" height="15px" />
                    <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                      This balance is the amount you can use right now,
                      considering pending transactions and holds
                    </span>
                  </div>
                </div>
                <Paragraph size="b1" weight="bold" color={COLORS.WHITE}>
                  {formatInToPrice(salon?.wallet?.availableBalance || 0)}
                </Paragraph>
                <div className="flex items-center space-x-2">
                  <Heading size="h11" variant="h2" color={COLORS.GREY['400']}>
                    Total Balance
                  </Heading>
                  <Paragraph size="b4" weight="bold" color={COLORS.WHITE}>
                    {formatInToPrice(salon?.wallet?.balance || 0)}
                  </Paragraph>
                  <div className="group flex relative text-grey-300">
                    <SvgInfo width="15px" height="15px" />
                    <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30">
                      This balance overall amount of money in your Splice
                      Wallet, including both available funds and any pending
                      transactions or hold. Our payment processor settles funds
                      T+1
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  {canPerformAction(
                    `Money::${PERMISSION_CONSTANTS.money.withdraw}`
                  ) ? (
                    <Button
                      variant={'secondary'}
                      size={'sm'}
                      type="button"
                      rounded="md"
                      onClick={openModal}
                      className="max-w-[150px]"
                    >
                      Withdraw
                    </Button>
                  ) : null}
                  {salon?.virtualAccount ? (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md border border-white px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                          <span>Get paid</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-[334px]">
                          <div className="flex flex-col w-full space-y-4 bg-white shadow-md rounded-md p-3 max-w-[334px]">
                            <div className="flex flex-col justify-start items-start space-y-2">
                              <Paragraph
                                size="b4"
                                color={COLORS.GREY[900]}
                                weight="semiBold"
                                className="text-center"
                              >
                                Get paid into your wallet
                              </Paragraph>
                              <Paragraph
                                size="b5"
                                color={COLORS.GREY[300]}
                                className=""
                              >
                                Receive money into your virtual account using
                                the details below
                              </Paragraph>
                            </div>
                            <div className="w-full flex flex-col space-y-2">
                              <div className="flex justify-between items-center w-full">
                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[300]}
                                >
                                  Account number
                                </Paragraph>

                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[900]}
                                  className="w-1/2 flex justify-end items-center space-x-2"
                                >
                                  <span>
                                    {salon?.virtualAccount
                                      ? salon?.virtualAccount?.accountNumber
                                      : ''}
                                  </span>
                                  <span
                                    className="cursor-pointer"
                                    onClick={copyAccountNumber}
                                  >
                                    <SvgCopy width="15px" height="15px" />
                                  </span>
                                </Paragraph>
                              </div>
                              <div className="flex justify-between items-center w-full">
                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[300]}
                                >
                                  Receiving bank
                                </Paragraph>

                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[900]}
                                  className="w-1/2 flex justify-end"
                                >
                                  {salon?.virtualAccount
                                    ? salon?.virtualAccount?.bankName
                                    : ''}
                                </Paragraph>
                              </div>
                              <div className="flex justify-between items-start w-full">
                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[300]}
                                >
                                  Account name
                                </Paragraph>

                                <Paragraph
                                  size="b5"
                                  weight="normal"
                                  color={COLORS.GREY[900]}
                                  className="w-1/2 flex justify-end capitalize"
                                >
                                  {salon?.virtualAccount
                                    ? salon?.virtualAccount?.accountName?.toLowerCase()
                                    : ''}
                                </Paragraph>
                              </div>
                            </div>
                            <Button
                              variant={'primary'}
                              size={'lg'}
                              type="button"
                              rounded="md"
                              onClick={copyAccountNumber}
                              className=""
                            >
                              Copy details
                            </Button>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : null}
                </div>
              </div>
            </div>

            {canPerformAction(
              `Money::${PERMISSION_CONSTANTS.money.addAccount}`
            ) && !salon?.virtualAccount ? (
              <div
                className="flex flex-col items-center justify-center text-center gap-4 w-full max-w-[500px] p-6 rounded-md"
                style={{
                  background: 'rgba(255, 255, 255, 0.2)',
                  backdropFilter: 'blur(15px)',
                }}
              >
                <Paragraph size="b4" weight="normal" color={COLORS.GREY[400]}>
                  Your virtual account details will show here
                </Paragraph>
                <Heading
                  size="h8"
                  variant="h2"
                  weight="bold"
                  className="flex gap-4 items-center"
                  color={COLORS.WHITE}
                >
                  {salon?.virtualAccount
                    ? salon?.virtualAccount?.accountNumber
                    : 'XXXXXXXXXX'}
                  {salon?.virtualAccount ? (
                    <span
                      id="account-number-copy-to-clipboard-button"
                      className="cursor-pointer"
                      onClick={copyAccountNumber}
                    >
                      <SvgCopy width="20px" height="20px" />
                    </span>
                  ) : null}
                </Heading>
                <Button
                  variant="secondary"
                  size="sm"
                  type="button"
                  rounded="md"
                  onClick={generateVirtualAccount}
                >
                  Generate virtual account number
                </Button>
              </div>
            ) : (
              canPerformAction(
                `Money::${PERMISSION_CONSTANTS.money.addAccount}`
              ) &&
              salon?.virtualAccount && ( 
                <div
                  className="flex space-x-4 w-full max-w-[500px] p-6 rounded-md"
                  style={{
                    background: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(15px)',
                  }}
                >
                  <div className="text-white p-2 rounded-full bg-grey-400 w-fit h-fit">
                    <SvgDatabase width="24px" height="24px" />
                  </div>
                  <div className="flex flex-col space-y-3">
                    <div className="w-full flex space-x-4 items-center">
                      <Paragraph
                        size="b4"
                        weight="normal"
                        color={COLORS.GREY[400]}
                      >
                        Virtual account details
                      </Paragraph>
                      <div className="group flex relative text-grey-300">
                        <SvgInfo width="15px" height="15px" />
                        <span className="w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30"></span>
                      </div>
                    </div>

                    <div className="w-full flex flex-col space-y-2">
                      <Heading
                        size="h8"
                        variant="h2"
                        weight="bold"
                        className="flex gap-4 items-center"
                        color={COLORS.WHITE}
                      >
                        {salon?.virtualAccount
                          ? salon?.virtualAccount?.accountNumber
                          : ''}
                      </Heading>
                      <Paragraph
                        size="b4"
                        weight="normal"
                        color={COLORS.GREY[400]}
                        className="capitalize"
                      >
                        {salon?.virtualAccount
                          ? salon?.virtualAccount?.accountName?.toLowerCase()
                          : ''}{' '}
                        <span className="semiBold text-white">
                          {salon?.virtualAccount
                            ? salon?.virtualAccount?.bankName
                            : ''}
                        </span>
                      </Paragraph>
                    </div>
                    <Button
                      variant={'transparent'}
                      size={'sm'}
                      type="button"
                      rounded="md"
                      onClick={copyAccountNumber}
                      className="border border-white text-white w-fit"
                    >
                      Copy details
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-full flex flex-col space-y-4 h-fit">
            <Table
              headers={WALLET_TRANSACTION_HEADERS}
              mobileHeaders={WALLET_TRANSACTION_MOBILE_HEADERS}
              rows={rows}
            />
          </div>
          <Withdraw
            isVisible={isVisible}
            closeModal={closeModal}
            wallet={salon?.wallet}
            addToast={addToast}
            showBvnForm={showBvnForm}
            showRcForm={showRcForm}
          // salonId={props.salonId}
          />
          <VirtualAccountErrorNote
            isVisible={isVisibleVirtualErrorNote}
            closeModal={closeModalVirtualErrorNote}
          />
        </>
      )}
    </div>
  );
};

type WithdrawModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  wallet?: Wallet;
  addToast?: (toast: ToastProps) => void;
  showBvnForm?: boolean;
  showRcForm?: boolean;
};

const Withdraw = ({ isVisible, closeModal: _closeModal, wallet, addToast, showBvnForm, showRcForm }: WithdrawModalProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(OTP_COUNTER.TIMER);
  const [disabled, setDisabled] = useState(true);
  const { getSalonFieldValue } = useSalonCache();
  const { getBusinessData } = useUserCache();
  const {
    createPayout,
    loading
  } = useCreatePayout()

  const {
    completeBvnVerification,
    loading: completeBvnIsLoading
  } = useCompleteBvnVerification()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<CreatePayoutInput>();

  const closeModal = () => {
    setErrorMessage('');
    setOtp('');
    setSeconds(OTP_COUNTER.TIMER);
    setDisabled(true);
    setValue('amount', null);
    setValue('rcNumber', '');
    setValue('bvn', '');
    _closeModal();
  }

  const initiateWithdrawal = (input: CreatePayoutInput) => {
    if (!input?.amount) {
      addToast({
        variant: 'error',
        message: 'Amount is required'
      })
      return;
    }
    const amount = parseFloat(input?.amount?.toString()?.replace(/,/g, ''));

    if (amount < 2000) {
      addToast({
        variant: 'error',
        message: 'Amount must be greater than 2000'
      })
      return;
    }
    createPayout({
      variables: {
        input: {
          salonId: getSalonFieldValue('id'),
          amount,
          rcNumber: input?.rcNumber,
          bvn: input?.bvn,
        },
      },
    }).then(({ data }) => {
      const { createPayout } = data
      if (createPayout?.status === 200) {
        if (createPayout?.showOtpForm) {
          setShowOtpPage(true);
        } else {
          closeModal();
          if (createPayout?.message) {
            addToast({
              variant: 'success',
              message: createPayout?.message
            })
          } else {
            addToast({
              variant: 'success',
              message: 'Withdrawal request submitted successfully'
            })
          }
        }
      }

      if (createPayout?.errors?.length) {
        addToast({
          variant: 'error',
          message: createPayout?.errors[0]?.message
        })
      }
    }).catch((err) => {
      addToast({
        variant: 'error',
        message: err.message
      })
    })
  };

  const rcNumber = getBusinessData()?.rcNumber;
  const bvn = getBusinessData()?.bvn;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [seconds]);

  const handleChange = (otp: string) => {
    setOtp(otp);
    if (otp.length === OTP_COUNTER.INPUTS) {
      setDisabled(false);
    }
  };

  const verifyBvnOtp = (otp: string) => {
    completeBvnVerification({
      variables: {
        input: {
          otpCode: otp,
        },
      },
    }).then(({ data }) => {
      const { completeBvnVerification } = data
      if (completeBvnVerification?.status === 200) {
        setShowOtpPage(false);
        addToast({
          variant: 'success',
          message: completeBvnVerification?.message
        })
        closeModal();
      }
      if (completeBvnVerification?.errors?.length) {
        addToast({
          variant: 'error',
          message: completeBvnVerification?.errors[0]?.message
        })
      }
    })
    // reset values on form
    control._formValues.amount = '';
    control._formValues.rcNumber = '';
    control._formValues.bvn = '';
  };

  return (
    <Modal title="Initiate Withdrawal" show={isVisible} closeModal={closeModal}>
      <>
        {!showOtpPage ? (
          <form
            onSubmit={handleSubmit(initiateWithdrawal)}
            className="w-full space-y-8"
            autoComplete="off"
          >
            <div className='flex flex-col space-y-2'>
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: ERRORS.AMOUNT_REQUIRED,
                }}
                render={({ field }) => (
                  <div className="w-full flex space-x-2 justify-center items-center mt-8">
                    <Paragraph size="h6" weight="bold" color={COLORS.GREY[200]}>
                      {`${DEFAULT_CURRENCY}`}
                    </Paragraph>
                    <input
                      className="w-full border-b border-grey-400 font-semibold text-b1 xl:text-h6 text-grey-200 focus:outline-none placeholder:text-grey-100"
                      type="text"
                      {...field}
                      value={formatNumber(field.value)}
                      onChange={(e) =>
                        field.onChange(formatNumber(e.target.value))
                      }
                      placeholder="20,000"
                    />
                    {errors?.amount?.message && (
                      <FormHelperText variant="error">
                        {errors?.amount.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
              <Paragraph size="b6" weight="bold" color={COLORS.GREY[200]}>
                The minimum withdrawal amount is {DEFAULT_CURRENCY} 2,000.
              </Paragraph>
            </div>
            {showRcForm ? (
              <Controller
                control={control}
                name="rcNumber"
                render={({ field }) => (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="kycId">Business RC Number</FormLabel>
                    <input
                      className="w-full border-b border-grey-400 font-semibold text-b1 xl:text-h6 text-grey-200 focus:outline-none placeholder:text-grey-100"
                      type="text"
                      {...field}
                      placeholder="RC Number"
                    />
                    {errors?.rcNumber?.message && (
                      <FormHelperText variant="error">
                        {errors?.rcNumber.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            ) : null}
            {showBvnForm ? (
              <Controller
                control={control}
                name="bvn"
                render={({ field }) => (
                  <div className="w-full flex flex-col space-y-2">
                    <FormLabel htmlFor="bvn">Business Owner NIN</FormLabel>
                    <input
                      className="w-full border-b border-grey-400 font-semibold text-b1 xl:text-h6 text-grey-200 focus:outline-none placeholder:text-grey-100"
                      type="text"
                      {...field}
                      placeholder="NIN"
                    />
                    {errors?.bvn?.message && (
                      <FormHelperText variant="error">
                        {errors?.bvn.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            ) : null}

            {(showBvnForm || showRcForm) && (
              <Paragraph
                size="b5"
                color={COLORS.GREY[400]}
                className="text-center"
              >
                <br />
                Enjoy uncapped withdrawals by providing us with your RC number
                and NIN. This is in order to protect your account and abide by
                regulations. These details will be used only to verify your
                account information and won’t be shared with your clients or any
                third parties.
              </Paragraph>
            )}

            <Button
              variant="primary"
              className=""
              disabled={loading}
              loading={loading}
              size="lg"
              rounded="lg"
            >
              Withdraw
            </Button>
            <Button variant="text" size="lg" onClick={closeModal}>
              Cancel
            </Button>
          </form>
        ) : (
          <div className="w-full flex flex-col items-center space-y-9">
            <OtpInput
              value={otp}
              onChange={handleChange}
              id="otp-code-input"
              numInputs={OTP_COUNTER.INPUTS}
              separator={<span></span>}
              isInputNum={true}
              className={`custom-otp-input`}
            />
            {errorMessage ? (
              <Paragraph size="b5" color={COLORS.RED[600]}>
                {errorMessage}
              </Paragraph>
            ) : null}
            <div className="w-full flex justify-center space-x-2">
              <Paragraph size="b5" className="" color={COLORS.GREY[400]}>
                Haven’t received an OTP? Contact <a href="/support">Support</a>
              </Paragraph>
            </div>
            <Button
              variant="primary"
              className=""
              disabled={completeBvnIsLoading || disabled}
              loading={completeBvnIsLoading}
              size="lg"
              rounded="lg"
              onClick={() => verifyBvnOtp(otp)}
            >
              Confirm
            </Button>
            <Button
              variant="text"
              className=""
              onClick={() => setShowOtpPage(false)}
              size="none"
            >
              Back
            </Button>
          </div>
        )}
        <Paragraph
          size="b5"
          color={COLORS.GREY[400]}
          className="pt-4 text-center"
        >
          If you have not created a payout account, kindly visit the{' '}
          <span className="underline">
            <a href="/settings">settings</a>
          </span>{' '}
          page
        </Paragraph>
      </>
    </Modal>
  );
};

const VirtualAccountErrorNote = ({
  isVisible,
  closeModal,
}: WithdrawModalProps) => {
  const navigate = useNavigate();
  const goToSettings = () => {
    closeModal();
    navigate(`${PAGE_ROUTES.SETTINGS}?action=rcnumber`)
  };

  return (
    <Modal title="" show={isVisible} closeModal={closeModal}>
      <div className="w-full flex flex-col justify-center space-y-4 items-center">
        <Heading size="h8" variant="h2" weight="bold" color={COLORS.GREY[900]}>
          Generate virtual account number
        </Heading>
        <Paragraph
          size="b5"
          color={COLORS.GREY[400]}
          className="pt-4 text-center"
        >
          Please provide your RC number to generate a unique virtual account
          number for your business. These details will be used only to verify
          your account information and won’t be shared with your clients or any
          third parties.
        </Paragraph>
        <Button
          variant="primary"
          className=""
          size="lg"
          rounded="lg"
          type="button"
          onClick={goToSettings}
        >
          Go to settings
        </Button>
      </div>
    </Modal>
  );
};

export default Wrapper;
