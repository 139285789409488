import * as React from "react";
import { ToastBaseProps } from "./types";
import { TOAST_BG_COLORS } from "constants/colors";

const Base = ({ children, bgColor, onClick }: ToastBaseProps) => {
  return (
    <div
      onClick={onClick}
      className="px-4 py-2 rounded-md flex justify-center items-center space-x-2.5 cursor-pointer z-50 transition ease-in-out delay-150 max-w-[300px]"
      style={{ backgroundColor: bgColor || TOAST_BG_COLORS.INFO }}
      role="alert"
    >
      {children}
    </div>
  );
};

export default Base;
