import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import { API_ERRORS, ERRORS } from 'constants/errors';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { REGEX_PATTERNS } from 'constants/pattern';
import Input from 'ui/molecules/input/Input';
import SelectInput from 'ui/molecules/input/SelectInput';
import Button from 'ui/atoms/button/Button';
import { BankFormProps, BankInput } from './types';
import { useUserCache } from 'hooks/useUserCache';
import { useCreatePayoutAccount, useGetBanks } from 'api/useMoney';

const BankForm = (props: BankFormProps) => {
  const { getBusinessData, setBusinessData } = useUserCache();
  const _business = getBusinessData();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BankInput>({});

  const {
    data: banksData,
  } = useGetBanks()
  const banks = banksData?.banks || []
  const bankListOptions = useMemo(() => {
    return banks.map((bank) => ({
      value: bank.code,
      label: bank.name,
    }))
  }, [banks])

  const { addToast, toast } = useToast();
  const {
    loading: isLoading,
    createPayoutAccount
  } = useCreatePayoutAccount()

  const onSubmitData = async (input: BankInput) => {
    createPayoutAccount({
      variables: { input },
    }).then(({ data }) => {
      if (data?.createPayoutAccount?.payoutAccount) {
        addToast({
          variant: "success",
          message: "Account added successfully",
        });
        setBusinessData({
          ..._business,
          payoutAccount: data?.createPayoutAccount?.payoutAccount,
        })
        props?.refetch && props?.refetch();
        props?.onNextPage && props?.onNextPage();
      }
      if (data?.createPayoutAccount?.errors) {
        const message = data?.createPayoutAccount?.errors[0]?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
        addToast({
          variant: 'error',
          message,
        })
      }
    }).catch(createBusinessPayoutError => {
      const message = createBusinessPayoutError?.message || API_ERRORS.BANK_ACCOUNT_FAILED;
      addToast({
        variant: "error",
        message,
      });
    })
  };
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form onSubmit={handleSubmit(onSubmitData)} className="w-full space-y-6 pt-6">
        <Input name="accountNumber" id="account-number" label="Account Number" type="text" placeholder='Enter account number here' control={control} rules={{
          required: ERRORS.ACCOUNT_NUMBER_REQUIRED,
          pattern: REGEX_PATTERNS.ACCOUNT_NUMBER
        }} error={errors.accountNumber} />
        <SelectInput name="bankCode" id="bank-code" label="Bank Name " control={control} rules={{
          required: ERRORS.BANK_NAME_REQUIRED,
        }} error={errors.bankCode} options={bankListOptions} placeholder="Select Bank" />
        <div className='w-full flex space-x-2'>
          {props?.onPrevPage && (
            <Button
              variant='secondary'
              className=''
              disabled={false}
              onClick={props.onPrevPage}
              size='lg'
              rounded='lg'
              type='button'
            >
              Prev
            </Button>
          )}
          <Button
            variant='primary'
            className=''
            disabled={isLoading}
            loading={isLoading}
            size='lg'
            rounded='lg'
          >
            {props?.buttonText || 'Proceed'}
          </Button>
        </div>
      </form>
    </>
  )
}

export default BankForm