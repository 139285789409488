/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatInToPrice, formatSnakeCaseText, getNumberMonthAndYearFromDate, limitString } from "../../utils/misc";
import Pill from "ui/atoms/pill/Pill";
import React from "react";
import { convertVoucherServicesNamesToString } from "../bookingSales/utils";
import { MilestoneActivity, PackageVoucher, VoucherService } from "core/generated";

export const generateVoucherAllTableData = (vouchers: PackageVoucher[], countryCode?: string) => {
  return vouchers?.map?.((voucher) => {
    return {
      name: voucher?.package?.name,
      service: limitString(convertVoucherServicesNamesToString(voucher?.voucherServices)),
      validFor: getNumberMonthAndYearFromDate(voucher?.expiresAt as string),
      amount: formatInToPrice(voucher?.price),
      amountRedeemed: formatInToPrice(voucher?.amountRedeemed),
      code: voucher?.code,
      status: () => {
        const status = voucher?.status;
        return status ? (
          <Pill
            variant={
              status === 'active'
                ? 'success'
                : status === 'redeemed'
                  ? 'primary'
                  : status === 'expired'
                    ? 'light'
                    : 'light'
            }
          >{formatSnakeCaseText(status)}</Pill>
        ) : null
      },
    };
  });
}

const getRemainingServicesOrAmount = (voucher: PackageVoucher) => {
  if (voucher?.redemptionMode) {
    if (voucher?.redemptionMode === 'services') {
      return convertVoucherServicesNamesToStringToShowNonRedeemed(voucher?.voucherServices);
    } else {
      return formatInToPrice(voucher?.price - voucher?.amountRedeemed);
    }
  } else {
    return "-"
  }
}

export const generateVoucherRedeemedTableData = (vouchers: PackageVoucher[], countryCode?: string) => {
  return (
    vouchers
      ?.filter((voucher) => voucher?.amountRedeemed > 0)
      .map((voucher) => ({
        name: voucher?.package?.name,
        service: limitString(convertVoucherServicesNamesToString(voucher?.voucherServices)),
        validFor: getNumberMonthAndYearFromDate(voucher?.expiresAt as string),
        code: voucher?.code,
        remaining: getRemainingServicesOrAmount(voucher),
      })) || []
  );
};

export const generateVoucherUnusedTableData = (vouchers: PackageVoucher[], countryCode?: string) => {
  return (
    vouchers
      ?.filter((voucher) => voucher?.amountRedeemed === 0)
      .map((voucher) => ({
        service: limitString(convertVoucherServicesNamesToString(voucher?.voucherServices)),
        name: voucher?.package?.name,
        code: voucher?.code,
        validFor: getNumberMonthAndYearFromDate(voucher?.expiresAt as string),
      })) || []
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const convertVoucherServicesNamesToStringToShowNonRedeemed = (services?: VoucherService[]) => {
  const title = services?.filter(({ isRedeemed }) => !isRedeemed).map(({ name, quantity }) => `${name} x${quantity}`).join(', ') || '';
  return title;
}

export const generateActivityLoyaltyTableData = (activities: MilestoneActivity[], countryCode?: string) => {
  return activities?.map?.((activity) => {
    return {
      date: getNumberMonthAndYearFromDate(activity?.date),
      activityDone: activity?.activityDone,
      pointsEarned: () => {
        if (activity?.pointsEarned.toString().includes('-')) {
          return (
            <span className="text-red-500 text-b4">{activity?.pointsEarned}</span>
          )
        }
        return (
          <span className="text-green-500 text-b4">+{activity?.pointsEarned}</span>
        )
      },
      pointsBalance: activity?.pointsBalance,
    };
  });
}