import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentMethodVoucher = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M3.033 9.867H2.5a.533.533 0 0 0 .533.533v-.533Zm0 4.266V13.6a.533.533 0 0 0-.533.533h.533Zm19.2-4.266v.533a.534.534 0 0 0 .534-.533h-.534Zm0 4.266h.534a.533.533 0 0 0-.534-.533v.533ZM5.167 5.067H10.5V4H5.167v1.067Zm5.333 0h9.6V4h-9.6v1.067Zm9.6 13.866h-9.6V20h9.6v-1.067Zm-9.6 0H5.167V20H10.5v-1.067Zm-.533-14.4v14.934h1.066V4.533H9.967ZM4.164 10.87a1.6 1.6 0 0 1 0 2.262l.755.755a2.668 2.668 0 0 0 0-3.772l-.755.755Zm16.939 2.262a1.6 1.6 0 0 1 0-2.262l-.756-.755a2.669 2.669 0 0 0 0 3.772l.756-.755ZM3.033 10.4c.411 0 .82.156 1.131.47l.755-.756a2.66 2.66 0 0 0-1.886-.78V10.4Zm.534-.533v-3.2H2.5v3.2h1.067Zm0 7.466v-3.2H2.5v3.2h1.067Zm.597-4.202c-.311.313-.72.469-1.13.469v1.067a2.66 2.66 0 0 0 1.885-.781l-.755-.755Zm16.939-2.262c.311-.313.72-.469 1.13-.469V9.333a2.66 2.66 0 0 0-1.886.781l.756.755Zm.597-4.202v3.2h1.067v-3.2H21.7Zm0 7.466v3.2h1.067v-3.2H21.7Zm.533-.533c-.41 0-.819-.156-1.13-.47l-.756.756a2.66 2.66 0 0 0 1.886.78V13.6ZM5.167 18.933a1.6 1.6 0 0 1-1.6-1.6H2.5A2.667 2.667 0 0 0 5.167 20v-1.067ZM20.1 20a2.667 2.667 0 0 0 2.667-2.667H21.7a1.6 1.6 0 0 1-1.6 1.6V20Zm0-14.933a1.6 1.6 0 0 1 1.6 1.6h1.067A2.667 2.667 0 0 0 20.1 4v1.067ZM5.167 4A2.667 2.667 0 0 0 2.5 6.667h1.067a1.6 1.6 0 0 1 1.6-1.6V4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPaymentMethodVoucher;
