import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  CalendarPicker,
  Heading,
  HeadlessDropDown,
  Layout,
  Logo,
  MonthPicker,
  Paginator,
  Paragraph,
  Rating,
  ReportTable,
  Tabs,
  ToastWrapper,
} from 'ui'
import {
  createFileNameFromDates,
  formatDateToOriginalDate,
  formatInToPrice,
  formatSnakeCaseText,
  getDefaultQueryDates,
  getNumberMonthAndYearFromDate,
  handleDownload,
  handleExcelDownload
} from '../components/utils/misc'
import { COLORS } from 'constants/colors'
import {
  CustomerSpend,
  Review,
} from 'core/generated'
import { useSalonCache } from 'hooks/useSalonCache'
import { Expenses } from './Money'
import axios from 'axios'
import { useToast } from 'hooks'
import { SvgCalendar, SvgCartBag, SvgCreditCard, SvgDownGrowthIndicator, SvgFreePikGraph, SvgGrowthIndicator, SvgLocationPin, SvgPercent, SvgSale, SvgUser, SvgUsers } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import { IHeadlessOption } from 'ui/organism/headlessDropDown/types'
import { usePDF } from 'react-to-pdf';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { businessPerfomanceAppointmentPieGraphData, businessPerformanceAppointmentTrendGraphData, businessPerformanceCustomersGraphData, businessPerformanceSaleGraphData, businessPerformanceSaleTrendGraphData, calculateWidthPercentages, createServiceSpendGraphData, customerSpendGraphData, getCategorySum, getSumTotal, monthlySpendGraphData } from '../components/uicomponents/reportGraphUtils'
import { Chart, registerables } from 'chart.js';
import EmptyAppointment from 'ui/molecules/empty/EmptyAppointment'
import ExportDataToCsvOrXlsx from '../components/uicomponents/ExportDataToCsvOrXlsx'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { useUserCache } from 'hooks'
import SvgNeutralIcon from 'assets/icons/NeutralIcon'
import SvgPositiveIcon from 'assets/icons/PositiveIcon'
import SvgNegativeIcon from 'assets/icons/NegativeIcon'
import { useBusinessPerformanceSummary, useCustomerSpend, useCustomerSpendSummary, useExecutiveSummary, useLocationPerformanceSummary, useReviewsReport, useReviewSummary } from 'api/useReports'
import { useGetStaffList } from 'api/useStaff'
Chart.register(...registerables);

const BUSINESS_PERFORMANCE_TAB_NAME = {
  EXECUTIVE_SUMMARY: 'Executive Summary',
  MONTHLY_SUMMARY: 'Monthly Summary',
  CUSTOMER_SPEND: 'Customer Spend',
  REVIEWS: 'Reviews',
  EXPENSE_REPORT: 'Expense Report'
}

const Reports = () => {
  const BUSINESS_PERFORMANCE_TABS = [
    {
      key: BUSINESS_PERFORMANCE_TAB_NAME.EXECUTIVE_SUMMARY,
      title: BUSINESS_PERFORMANCE_TAB_NAME.EXECUTIVE_SUMMARY,
      show: true
    },
    {
      key: BUSINESS_PERFORMANCE_TAB_NAME.MONTHLY_SUMMARY,
      title: BUSINESS_PERFORMANCE_TAB_NAME.MONTHLY_SUMMARY,
      show: true
    },
    {
      key: BUSINESS_PERFORMANCE_TAB_NAME.CUSTOMER_SPEND,
      title: BUSINESS_PERFORMANCE_TAB_NAME.CUSTOMER_SPEND,
      show: true
    },
    {
      key: BUSINESS_PERFORMANCE_TAB_NAME.REVIEWS,
      title: BUSINESS_PERFORMANCE_TAB_NAME.REVIEWS,
      show: true
    },
    {
      key: BUSINESS_PERFORMANCE_TAB_NAME.EXPENSE_REPORT,
      title: BUSINESS_PERFORMANCE_TAB_NAME.EXPENSE_REPORT,
      show: true
    }
  ]
  const [activeTab, setActiveTab] = useState<string>(
    BUSINESS_PERFORMANCE_TABS.filter((tab) => tab.show)[0].key
  )

  const getTabView = () => {
    switch (activeTab) {
      case BUSINESS_PERFORMANCE_TAB_NAME.EXECUTIVE_SUMMARY:
        return <BusinessPerformanceExecutiveSummary />
      case BUSINESS_PERFORMANCE_TAB_NAME.MONTHLY_SUMMARY:
        return <MonthlySummary />
      case BUSINESS_PERFORMANCE_TAB_NAME.CUSTOMER_SPEND:
        return <CustomerSpendReport />
      case BUSINESS_PERFORMANCE_TAB_NAME.REVIEWS:
        return <Reviews />
      case BUSINESS_PERFORMANCE_TAB_NAME.EXPENSE_REPORT:
        return <ExpenseReport />
    }
  }

  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={BUSINESS_PERFORMANCE_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const BusinessPerformanceExecutiveSummary = () => {
  const { getUserData } = useUserCache()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const { toPDF, targetRef } = usePDF({ filename: `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_business_performance.pdf` });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { toast, addToast } = useToast()
  const [start, end] = selectedDates || getDefaultQueryDates()
  const user = getUserData()
  const business = user?.business;
  const salons = business?.salons;
  const [staffDropDownSelectedValue, setStaffDropDownSelectedValue] = useState('')
  const [salonDropDownSelectedValue, setSalonDropDownSelectedValue] = useState("")
  const [exportingToPDF, setExportingToPDF] = useState(false);

  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")

  const {
    data: executiveSummaryData,
    loading: executiveSummaryLoading,
    refetch: refetchExecutiveSummaryData
  } = useExecutiveSummary(salonId, startDate, endDate)
  const executiveSummary = useMemo(() => {
    return executiveSummaryData?.executiveSummaryReport
  }, [executiveSummaryData])

  const {
    data: locationPerformanceSummaryData,
    loading: locationPerformanceSummaryLoading,
    refetch: refetchLocationPerformanceSummaryData
  } = useLocationPerformanceSummary(salonDropDownSelectedValue, startDate, endDate)
  const locationSummary = useMemo(() => {
    return locationPerformanceSummaryData?.locationPerformanceSummaryReport
  }, [locationPerformanceSummaryData])

  useEffect(() => {
    if (staffDropDownSelectedValue) {
      refetchExecutiveSummaryData()
    }
  }, [selectedDates, staffDropDownSelectedValue])

  useEffect(() => {
    refetchLocationPerformanceSummaryData()
  }, [salonDropDownSelectedValue, selectedDates])

  const {
    data: staffListData,
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staffMembers = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])

  useEffect(() => {
    if (Array?.isArray(staffMembers) && staffMembers?.length) {
      const firstId = staffMembers[0]?.id
      setStaffDropDownSelectedValue(firstId)
    }
  }, [staffMembers])

  const staffList =
    staffMembers?.length ? staffMembers?.map((staff) => {
      return {
        value: staff?.id,
        label: staff?.user?.firstName + ' ' + staff?.user?.lastName
      }
    }) as IHeadlessOption[] : []

  const salonList = useMemo(() => {
    return salons?.length
      ? salons.map((salon) => ({
        value: salon?.id,
        label: salon?.branchName
      }))
      : [];
  }, [salons]);

  useEffect(() => {
    if (Array?.isArray(salonList) && salonList?.length) {
      setSalonDropDownSelectedValue(salonList[0]?.value)
    }
  }, [salonList])

  const exportToPDF = () => {
    // loading this function after 5 seconds
    setExportingToPDF(true)
    setTimeout(() => {
      toPDF()
      setExportingToPDF(false)
    }, 500)
  }

  const loading = executiveSummaryLoading || locationPerformanceSummaryLoading;
  const [executiveSummaryTrendType, setExecutiveSummaryTrendType] = useState('appointment')

  const chartData = businessPerformanceCustomersGraphData(executiveSummary?.newClientsGraphData, executiveSummary?.returningClientsGraphData)
  const appointmentPieData = businessPerfomanceAppointmentPieGraphData(executiveSummary?.appointmentsPieChartData);
  const graphSummaryData = businessPerformanceAppointmentTrendGraphData(executiveSummary?.appointmentTrendGraphData || []);
  const saleGraphSummaryData = businessPerformanceSaleTrendGraphData(executiveSummary?.saleProductTrendGraphData || [], executiveSummary?.saleServiceTrendGraphData || []);
  const salesChartData = businessPerformanceSaleGraphData(executiveSummary?.productsSummary, executiveSummary?.servicesSummary, executiveSummary?.vouchersSummary);
  const locationChartData = businessPerformanceSaleGraphData(locationSummary?.productsSummary, locationSummary?.servicesSummary, locationSummary?.vouchersSummary);
  return (
    <div className='flex flex-col w-full' ref={targetRef}>
      {loading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Executive summary
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        {!exportingToPDF ?
          <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
            <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
            <CalendarPicker {...{ selectedDates, setSelectedDates }} />
            <Button
              variant='primary'
              size='sm'
              loading={false}
              type='button'
              rounded='md'
              className='flex'
              onClick={exportToPDF}
            >
              Export report
            </Button>
          </div> : null}
      </div>
      <div className='w-full grid grid-col-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgCreditCard width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total revenue ({DEFAULT_CURRENCY})
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {executiveSummary?.totalRevenue}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgSale width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total sales
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {executiveSummary?.totalSales}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUsers width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total clients
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {executiveSummary?.totalClients}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgUser width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Total staff
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {staffList?.length}
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Average rating
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {executiveSummary?.averageRating}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100 p-3 md:p-6 space-y-4'>
        <div className='w-full flex justify-between'>
          <div className='flex flex-col space-y-2'>
            <Paragraph size='b4' weight='semiBold' className=''>
              General Overview
            </Paragraph>
            <Paragraph size='b5' className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>All branches</span>
            </Paragraph>
          </div>
        </div>
        <div className='w-full flex flex-col lg:flex-row gap-4'>
          <div className='w-full xl:w-1/2 border border-grey-100 bg-grey-50/20 rounded-md'>
            <div className='w-full flex justify-between p-6'>
              <Paragraph size='b5' weight='semiBold'>
                Customers
              </Paragraph>

              <div className='flex gap-3 items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-2'></span>
                  new
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                  returning
                </Paragraph>
              </div>
            </div>
            <div className='w-full h-[300px] p-6'>
              <Bar options={
                {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: 'Customers'
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                    },
                  },
                }
              } data={chartData} />
            </div>
          </div>
          <div className='w-full xl:w-1/2 p-4 flex flex-col space-y-3 rounded-md border border-grey-100 bg-grey-50/20'>
            <div className='w-full flex justify-between items-center'>
              <div className='flex flex-col space-y-2 w-[60%]'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                  Trends and Highlights
                </Paragraph>
                <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                  Find your trends and highlights across staff appointment reports here
                </Paragraph>
              </div>

              <SvgFreePikGraph width="50px" height="20px" />
            </div>
            {executiveSummaryTrendType === 'appointment' ? (
              <div className='w-full flex flex-col space-y-3'>
                <div className='w-full flex justify-between'>
                  <div className='flex flex-col space-y-2'>
                    <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                      Average appointments
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                      {executiveSummary?.averageRating}%
                    </Paragraph>
                  </div>

                  <div className='flex flex-col space-y-2'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center'>
                      <span>{executiveSummary?.appointmentTrend}%</span>
                      {executiveSummary?.appointmentTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
                    </Paragraph>
                    <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                      VS LAST MONTH
                    </Paragraph>
                  </div>
                </div>
                <Line options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Value of Appointments by date',
                    },
                    tooltip: {
                      enabled: true,
                      mode: 'index',
                      intersect: false,
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      ticks: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        callback: function (value, index, values) {
                          return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                        }
                      }
                    },
                  },
                }} data={graphSummaryData} />
              </div>
            ) : (
              <div className='w-full flex flex-col space-y-3'>
                <div className='w-full flex justify-between'>
                  <div className='flex flex-col space-y-2'>
                    <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                      Total sales
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                      {executiveSummary?.totalSales}%
                    </Paragraph>
                  </div>

                  <div className='flex flex-col space-y-2'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center'>
                      <span>{executiveSummary?.saleTrend}%</span>
                      {executiveSummary?.saleTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
                    </Paragraph>
                    <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                      VS LAST MONTH
                    </Paragraph>
                  </div>
                </div>
                <Line options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Revenue from Sales',
                    },
                    tooltip: {
                      enabled: true,
                      mode: 'index',
                      intersect: false,
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      ticks: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        callback: function (value, index, values) {
                          return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                        }
                      }
                    },
                  },
                }} data={saleGraphSummaryData} />
                <div className='w-full flex gap-3 justify-center items-center'>
                  <Paragraph size='b5' className='flex items-center capitalize'>
                    <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                    Services
                  </Paragraph>
                  <Paragraph size='b5' className='flex items-center capitalize'>
                    <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
                    Products
                  </Paragraph>
                </div>
              </div>
            )}
            <div className='w-full flex gap-3 justify-center items-center'>
              <span className={`w-[12px] h-[12px] ${executiveSummaryTrendType === 'appointment' ? "bg-[#7EA05C]" : "bg-[#CFE6C1]"} rounded-full cursor-pointer`} onClick={() => setExecutiveSummaryTrendType('appointment')}></span>
              <span className={`w-[12px] h-[12px] ${executiveSummaryTrendType === 'appointment' ? "bg-[#CFE6C1]" : "bg-[#7EA05C]"} rounded-full cursor-pointer`} onClick={() => setExecutiveSummaryTrendType('sale')}></span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col lg:flex-row gap-4 border-t border-grey-100 p-3 md:p-6'>
        <div className='w-full flex flex-col items-start p-5 border border-grey-100 bg-grey-50/20 rounded-md space-y-3'>
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
              Appointments
            </Paragraph>
            <div className='flex px-3 py-1 bg-grey-50 border border-grey-100'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>Total: {getSumTotal(executiveSummary?.appointmentsPieChartData)}</Paragraph>
            </div>
          </div>
          {Array?.isArray(executiveSummary?.appointmentsPieChartData) && executiveSummary?.appointmentsPieChartData?.length ? (
            <div className='w-full flex flex-col space-y-3'>
              <div className='w-full max-w-[200px]'>
                <Pie data={appointmentPieData} options={
                  {
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false, // Hide the legend
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                  }
                } />
              </div>
              <div className='w-full flex flex-wrap gap-3 items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#A8DC96] mr-2'></span>
                  Online
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-2'></span>
                  Walk-ins
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-2'></span>
                  WhatsApp/Phone
                </Paragraph>
                {/* <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                  retained
                </Paragraph> */}
              </div>
            </div>
          ) : <EmptyAppointment />}
        </div>
        <div className='w-full flex flex-col border border-grey-100 bg-grey-50/20 rounded-md'>
          <div className='w-full flex justify-between p-6'>
            <Paragraph size='b5' weight='semiBold'>
              Sales
            </Paragraph>

            <div className='flex gap-3 items-center'>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-2'></span>
                gross sales
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-2'></span>
                taxes
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                net sales
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-[300px] p-6'>
            <Bar options={
              {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    position: "bottom",
                    text: 'Gross Sales, Taxes, and Net Sales Overview'
                  }
                },
                scales: {
                  x: {
                    type: 'category',
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      callback: function (value, index, values) {
                        return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                      }
                    }
                  },
                },
              }
            } data={salesChartData} />
          </div>
        </div>
        <div className='w-full flex flex-col px-4 py-5 space-y-4 border border-grey-100 bg-grey-50/20 rounded-md'>
          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Services
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgCalendar width="18px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.servicesSold}
              </Paragraph>
            </div>
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Products
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgCartBag width="20px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.productsSold}
              </Paragraph>
            </div>
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='w-full flex items-center justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Vouchers
              </Paragraph>

              <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                <SvgPercent width="18px" height="18px" />
              </span>
            </div>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                Quantity sold
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                {executiveSummary?.vouchersSold}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100 p-3 md:p-6 space-y-4'>
        <div className="w-full flex items-center justify-between">
          <div className='flex flex-col space-y-2'>
            <Paragraph size='b4' weight='semiBold' className=''>
              Location performance
            </Paragraph>
            <Paragraph size='b5' className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{
                Array?.isArray(salons) && salons?.length && salonDropDownSelectedValue
                  ? salons?.filter((salon) => salon?.id === salonDropDownSelectedValue)[0]?.branchName
                  : ""
              }
              </span>
            </Paragraph>
          </div>
          <div className='flex flex-col lg:flex-row gap-3'>
            <div className='w-[170px]'>
              <HeadlessDropDown
                options={salonList}
                selectedValue={salonDropDownSelectedValue}
                setDropDownSelectedValue={setSalonDropDownSelectedValue}
              />
            </div>
          </div>
        </div>

        <div className='w-full flex flex-col xl:flex-row gap-4'>
          <div className='w-full flex flex-col p-4 space-y-4 border border-grey-100 bg-grey-50/20 rounded-md'>
            <div className='w-full flex justify-between'>
              <Paragraph size='b5' weight='semiBold'>
                Sales
              </Paragraph>

              <div className='flex gap-1 items-center'>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-2'></span>
                  gross sales
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-2'></span>
                  taxes
                </Paragraph>
                <Paragraph size='b5' className='flex items-center capitalize'>
                  <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
                  net sales
                </Paragraph>
              </div>
            </div>
            <div className='w-full h-[300px] p-6'>
              <Bar options={
                {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      position: "bottom",
                      text: 'Gross Sales, and Net Location Overview'
                    }
                  },
                  scales: {
                    x: {
                      type: 'category',
                    },
                    y: {
                      beginAtZero: true,
                      ticks: {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        callback: function (value, index, values) {
                          return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                        }
                      }
                    },
                  },
                }
              } data={locationChartData} />
            </div>
          </div>
          <div className='w-full flex flex-col xl:max-w-[420px] px-4 py-5 space-y-4 border border-grey-100 bg-grey-50/20 rounded-md'>
            <div className='flex flex-col space-y-3'>
              <div className='w-full flex items-center justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                  Services
                </Paragraph>

                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgCalendar width="18px" height="18px" />
                </span>
              </div>
              <div className='w-full flex justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  Quantity sold
                </Paragraph>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  {locationSummary?.servicesSold}
                </Paragraph>
              </div>
            </div>

            <div className='flex flex-col space-y-3'>
              <div className='w-full flex items-center justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                  Products
                </Paragraph>

                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgCartBag width="20px" height="18px" />
                </span>
              </div>
              <div className='w-full flex justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  Quantity sold
                </Paragraph>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  {locationSummary?.productsSold}
                </Paragraph>
              </div>
            </div>

            <div className='flex flex-col space-y-3'>
              <div className='w-full flex items-center justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[300]} weight='semiBold' className=''>
                  Vouchers
                </Paragraph>

                <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
                  <SvgPercent width="18px" height="18px" />
                </span>
              </div>
              <div className='w-full flex justify-between'>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  Quantity sold
                </Paragraph>
                <Paragraph size='b5' color={COLORS.GREY[400]} weight='semiBold' className=''>
                  {locationSummary?.vouchersSold}
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exportingToPDF ?
        <div className='w-full flex items-center justify-center '>
          <Logo />
        </div> : null}
    </div>
  )
}

const MonthlySummary = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id');
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const {
    data: summaryData,
    loading: loadingData,
    refetch
  } = useBusinessPerformanceSummary(salonId, formatDateToOriginalDate(selectedDates[0], "start"), formatDateToOriginalDate(selectedDates[1], "end"))
  const monthlySummary = useMemo(() => {
    return summaryData?.businessPerformanceMonthlySummaryReport?.monthlySummaryGraphData
  }, [summaryData])

  useEffect(() => {
    refetch()
  }, [selectedDates])

  const chartData = monthlySpendGraphData(monthlySummary || [])

  return (
    <>
      {loadingData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col md:flex-row md:justify-between md:items-end gap-4 md:gap-0 p-3 md:p-6'>
        <div className='flex flex-col space-y-2'>
          <Heading size='h11' variant='h1' weight='bold'>
            Appointment Monthly Summary
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size='b4' className='hidden md:flex'>
            Showing:
          </Paragraph>
          <Paragraph size='b5' className='flex md:hidden'>
            Export
          </Paragraph>
          <MonthPicker {...{ selectedDates, setSelectedDates }} />
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100'>
        <div className='w-full flex justify-between p-6'>
          <Heading size='h10' variant='h2' weight='bold'>
            Bookings
          </Heading>

          <div className='flex gap-2 items-center'>
            <Paragraph size='b5' className='flex items-center'>
              <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-1'></span>
              Online
            </Paragraph>
            <Paragraph size='b5' className='flex items-center'>
              <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-1'></span>
              Walk-ins
            </Paragraph>
            <Paragraph size='b5' className='flex items-center'>
              <span className='w-[12px] h-[12px] bg-[#A8DC96] mr-1'></span>
              WhatsApp/Phone/IG
            </Paragraph>
            <Paragraph size='b5' className='flex items-center'>
              <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-1'></span>
              Promos
            </Paragraph>
          </div>
        </div>
        <div className='flex h-[300px] p-6'>
          <Bar options={
            {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                  text: 'Monthly spend'
                }
              },
              scales: {
                x: {
                  type: 'category',
                },
                y: {
                  beginAtZero: false,
                  min: 0,
                },
              },
            }
          } data={chartData} />
        </div>
        <div className='w-full flex flex-wrap justify-center items-center'>
          <div className='flex gap-2 items-center'>
            <Paragraph size='b5' className='flex items-center' weight="semiBold">
              <span className='w-[12px] h-[12px] bg-[#CFE6C1] mr-1'></span>
              Online: {getCategorySum('online', monthlySummary)}
            </Paragraph>
            <Paragraph size='b5' className='flex items-center' weight="semiBold">
              <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-1'></span>
              Walk-ins: {getCategorySum('walk_in', monthlySummary)}
            </Paragraph>
            <Paragraph size='b5' className='flex items-center' weight="semiBold">
              <span className='w-[12px] h-[12px] bg-[#A8DC96] mr-1'></span>
              WhatsApp/Phone/IG: {getCategorySum('phone', monthlySummary)}
            </Paragraph>
            <Paragraph size='b5' className='flex items-center' weight="semiBold">
              <span className='w-[12px] h-[12px] bg-[#E2E8E9] mr-1'></span>
              Promos: {getCategorySum('promos', monthlySummary)}
            </Paragraph>
          </div>
        </div>
      </div>
    </>
  )
}

const CustomerSpendReport = () => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const { addToast, toast } = useToast()
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { name: 'Customer' },
    { name: 'Phone' },
    { name: 'Service/Product/Voucher' },
    { name: 'Date' },
    { name: 'Payment Mthd.' },
    { name: 'Promo' },
    { name: 'Amount' }
  ]

  const {
    data: customerSpendData,
    loading: loadingCustomerSpendData,
    refetch
  } = useCustomerSpend({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end'),
    before, after
  })
  const appointments = useMemo(() => {
    return customerSpendData?.customerSpendReport
  }, [customerSpendData])

  const {
    data: customerSpendSummaryData,
    loading: loadingCustomerSpendSummaryData,
    refetch: refetchCustomerSpendSummaryData
  } = useCustomerSpendSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end'),
  })
  const appointmentSummary = useMemo(() => {
    return customerSpendSummaryData?.customerSpendSummaryReport
  }, [customerSpendSummaryData])

  useEffect(() => {
    refetch()
    refetchCustomerSpendSummaryData()
  }, [selectedDates])

  const formatCustomerAppointmentsSpendingCsvDownloadData = (
    appointments: CustomerSpend[]
  ) => {
    return appointments?.map?.((appointment) => ({
      Customer: appointment?.customer,
      Phone: appointment?.phone,
      Service: appointment?.service,
      Date: getNumberMonthAndYearFromDate(appointment?.date),
      'Payment Method': formatSnakeCaseText(appointment?.paymentMethod),
      Promo: appointment?.promo,
      Price: formatInToPrice(appointment?.amount)
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_customer_appointments_spending`
  const _handleDownload = () => {
    if (appointments) {
      handleDownload(
        formatCustomerAppointmentsSpendingCsvDownloadData(appointments?.nodes),
        filename
      )
    }
  }

  const downloadExcel = () => {
    if (appointments) {
      handleExcelDownload(
        formatCustomerAppointmentsSpendingCsvDownloadData(appointments?.nodes),
        filename,
        'Customer Appointments Spending'
      )
    }
  }

  const generateCustomerAppointmentsSpendingTableData = (
    appointments: CustomerSpend[]
  ) => {
    return appointments?.map?.((appointment) => ({
      customer: appointment?.customer,
      phone: appointment?.phone,
      service: appointment?.service,
      date: getNumberMonthAndYearFromDate(appointment?.date),
      paymentMethod: formatSnakeCaseText(appointment?.paymentMethod),
      promo: appointment?.promo,
      price: formatInToPrice(appointment?.amount)
    }))
  }

  const rows = generateCustomerAppointmentsSpendingTableData(appointments?.nodes)

  const goToNext = () => {
    if (appointments?.pageInfo?.hasNextPage) {
      setAfter(null)
      setBefore(appointments?.pageInfo?.endCursor)
      refetch()
    }
  }

  const goToPrev = () => {
    if (appointments?.pageInfo?.hasPreviousPage) {
      setAfter(appointments?.pageInfo?.startCursor)
      setBefore(null)
      refetch()
    }
  }

  const spendPieGraphData = createServiceSpendGraphData(appointmentSummary?.spendGraphData || [])
  const trendData = customerSpendGraphData(appointmentSummary?.productsSpendGraphData || [], appointmentSummary?.servicesSpendGraphData || []);


  return (
    <>
      <ToastWrapper toast={toast} />
      {loadingCustomerSpendData || loadingCustomerSpendSummaryData ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Customer spend
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex flex-col xl:flex-row gap-4 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col xl:w-[23.22%] gap-2'>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Total spend ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.totalSpend}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgCreditCard width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Highest spend ({DEFAULT_CURRENCY})
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.highestSpend}
              </Paragraph>
            </div>
          </div>
          <div className='w-full flex flex-col justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
            <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
              <SvgUsers width="18px" height="18px" />
            </span>

            <div className='w-full flex flex-col'>
              <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
                Client with highest spend
              </Paragraph>
              <Paragraph size='b1' weight='semiBold' className=''>
                {appointmentSummary?.clientWithHighestSpend}
              </Paragraph>
            </div>
          </div>
        </div>
        <div className='w-full xl:w-[36.20%] flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
              Spend based on services
            </Paragraph>

            <span className='bg-grey-50/80 border border-grey-100 text-b6 text-grey-300 font-semibold py-1 px-2'>
              Total: {appointmentSummary?.highestSpend}
            </span>
          </div>
          <div className='w-full max-w-[400px]'>
            <Pie data={spendPieGraphData} options={
              {
                responsive: true,
                plugins: {
                  legend: {
                    position: 'bottom', // Hide the legend
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
              }
            } />
          </div>
        </div>
        <div className='w-full xl:w-[40.57%] flex flex-col p-4 space-y-4 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your trends and highlights across staff appointment reports here
              </Paragraph>
            </div>

          </div>

          <div className='w-full flex justify-between'>
            <div className='flex flex-col justify-end space-y-2'>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                TOTAL SPEND
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                {appointmentSummary?.totalSpend}
              </Paragraph>
            </div>

            <div className='flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                <span>{appointmentSummary?.spendTrend}%</span>
                {appointmentSummary?.spendTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
              </Paragraph>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                VS LAST MONTH%
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-[235px]'>
            {/* <Chart
              chartType="LineChart"
              width="100%"
              height="247px"
              data={graphSummaryData}
              options={graphSummaryDataOptions}
            /> */}
            <Bar options={
              {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: true,
                    position: "bottom",
                    text: 'Customer spend by date'
                  }
                },
                scales: {
                  x: {
                    type: 'category',
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      callback: function (value, index, values) {
                        return DEFAULT_CURRENCY + value.toLocaleString("en-US");
                      }
                    }
                  },
                },
              }
            } data={trendData} />
          </div>
          <div className='w-full flex gap-3 justify-center items-center'>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#7EA05C] mr-2'></span>
              Services
            </Paragraph>
            <Paragraph size='b5' className='flex items-center capitalize'>
              <span className='w-[12px] h-[12px] bg-[#E4B91F] mr-2'></span>
              Products
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <Paginator pageInfo={appointments?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100'>
        <ReportTable
          rows={rows}
          headers={Headers}
        />
      </div>
    </>
  )
}

const Reviews = () => {
  const { getSalonFieldValue } = useSalonCache();
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const { toast, addToast } = useToast()
  const salonId = getSalonFieldValue('id')
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)
  const Headers = [
    { name: 'Customer' },
    { name: 'Phone' },
    { name: 'Message' },
    { name: 'Date' },
    { name: 'Rating' },
    { name: '' }
  ]

  const {
    data: reviewsData,
    loading: reviewsLoading,
    refetch: refetchReviews
  } = useReviewsReport({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end'),
    before, after
  })
  const reviews = useMemo(() => reviewsData?.reviewsReport, [reviewsData])

  const {
    data: reviewSummaryData,
    loading: reviewSummaryLoading,
    refetch: refetchReviewSummary
  } = useReviewSummary({
    salonId,
    startDate: formatDateToOriginalDate(selectedDates[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates[1], 'end')
  })
  const reviewSummary = useMemo(() => reviewSummaryData?.reviewsSummaryReport, [reviewSummaryData])

  useEffect(() => {
    refetchReviews()
    refetchReviewSummary()
  }, [selectedDates])

  const generateSalonAppointmentReviewsTableData = (
    reviews: Review[],
    countryCode = 'NG'
  ) => {
    return reviews?.map?.((review) => ({
      customer: review?.client?.firstName + " " + review?.client?.lastName,
      phone: review?.client?.phone,
      message: review?.message,
      date: getNumberMonthAndYearFromDate(
        review?.createdAt
      ),
      rating: review?.stars,
      status: () => {
        const rate = review?.stars || 0
        return rate ? <Rating initialRating={parseInt(rate)} /> : null
      }
    }))
  }
  const rows = generateSalonAppointmentReviewsTableData(reviews?.nodes)

  const formatSalonAppointmentReviewsCsvDownloadData = (
    reviews: Review[],
    countryCode?: string
  ) => {
    return reviews?.map?.((review) => ({
      Customer: review?.client?.firstName + " " + review?.client?.lastName,
      Phone: review?.client?.phone,
      Message: review?.message,
      Date: getNumberMonthAndYearFromDate(
        review?.createdAt
      ),
      Rating: review?.stars
    }))
  }

  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_reviews`
  const _handleDownload = () => {
    if (reviews) {
      handleDownload(
        formatSalonAppointmentReviewsCsvDownloadData(reviews?.nodes, 'NG'),
        filename
      )
    }
  }

  const downloadExcel = () => {
    if (reviews) {
      handleExcelDownload(
        formatSalonAppointmentReviewsCsvDownloadData(reviews?.nodes, 'NG'),
        filename,
        'Reviews'
      )
    }
  }

  const reviewChartData = {
    labels: reviewSummary?.ratingsGraphData.map((item) => item.label),
    datasets: [
      {
        label: 'Value',
        data: reviewSummary?.ratingsGraphData.map((item) => +item.value),
        backgroundColor: '#7EA05C',
      },
    ],
  };

  const goToNext = () => {
    if (reviews?.pageInfo?.hasNextPage) {
      setAfter(null)
      setBefore(reviews?.pageInfo?.endCursor)
      refetchReviews()
    }
  }

  const goToPrev = () => {
    if (reviews?.pageInfo?.hasPreviousPage) {
      setAfter(reviews?.pageInfo?.startCursor)
      setBefore(null)
      refetchReviews()
    }
  }

  const getReviewBgColor = (reviewType: string) => {
    switch (reviewType?.toLowerCase()) {
      case 'positive':
        return "bg-[#10B10F]"
      case 'negative':
        return "bg-[#E62347]"
      default:
        return "bg-[#E1CC12]"
    }
  }

  const reviewBars = calculateWidthPercentages(reviewSummary?.ratingsTrendGraphData)

  return (
    <>
      <ToastWrapper toast={toast} />
      {reviewSummaryLoading || reviewsLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Reviews
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full flex flex-col xl:flex-row gap-4 p-3 md:p-6 border-t border-grey-100'>
        <div className='w-full flex flex-col h-[220px] xl:w-[19.57%] justify-between space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgPercent width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='h1' color={COLORS.GREY[400]} weight='semiBold' className=''>
              {reviewSummary?.averageRating}
            </Paragraph>
            <Rating initialRating={reviewSummary?.averageRating || 0} />
            <Paragraph size='b6' className=''>
              {reviewSummary?.totalReviews} reviews
            </Paragraph>
          </div>
        </div>
        <div className='w-full xl:w-[40.04%] h-[300px] flex flex-col space-y-3 border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <Bar data={reviewChartData} options={
            {
              indexAxis: 'y', // This sets the chart to be horizontal
              responsive: true,
              plugins: {
                legend: {
                  display: false, // Hide the legend if you want
                },
              },
              scales: {
                x: {
                  beginAtZero: true, // Ensures the x-axis starts at zero
                  title: {
                    display: true,
                    text: 'Customers', // Your description for the x-axis
                  },
                  ticks: {
                    callback: function (value: number) {
                      return Math.round(value); // Ignore decimal figures
                    }
                  },
                },
                y: {
                  beginAtZero: true, // Ensures the y-axis starts at zero
                  title: {
                    display: true,
                    text: 'Star ratings', // Your description for the y-axis
                  },
                },
              },
            }
          } />;
        </div>
        <div className='w-full xl:w-[40.39%] h-[220px] flex flex-col p-4 space-y-4 rounded-md border border-grey-100 bg-grey-50/20'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-[60%]'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                Trends and Highlights
              </Paragraph>
              <Paragraph size='b5' color={COLORS.GREY[400]} className=''>
                Find your reviews highlights here
              </Paragraph>
            </div>

          </div>

          <div className='w-full flex justify-between'>
            <div className='flex flex-col justify-end space-y-2'>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='semiBold' className='uppercase'>
                TOTAL REVIEws
              </Paragraph>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className=''>
                {reviewSummary?.totalReviews}
              </Paragraph>
            </div>

            <div className='flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold' className='flex space-x-1 items-center justify-end'>
                <span>{reviewSummary?.reviewTrend}%</span>
                {reviewSummary?.reviewTrend?.toString().includes("-") ? <SvgDownGrowthIndicator width="16px" height="16px" /> : <SvgGrowthIndicator width="16px" height="16px" />}
              </Paragraph>
              <Paragraph size='b6' color={COLORS.GREY[300]} weight='light' className=''>
                VS LAST MONTH
              </Paragraph>
            </div>
          </div>
          <div className='w-full h-fit space-y-6'>
            <div className='w-full flex gap-3'>
              {reviewBars?.map((bar) => {
                return (
                  <div className={`w-full h-[16px] max-w-[${bar?.value}] rounded-sm ${getReviewBgColor(bar?.label)}`}></div>
                )
              })}
            </div>
            <div className='w-full flex justify-center items-center gap-4'>
              <Paragraph size='b5' className='flex items-center capitalize'>
                neutral
                <span className='mx-2'>
                  <SvgNeutralIcon width="16px" height="16px" />
                </span>
                {reviewSummary?.ratingsTrendGraphData?.filter((review) => review?.label === 'neutral')[0]?.value || "0"}
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                positive
                <span className='mx-2'>
                  <SvgPositiveIcon width="16px" height="16px" />
                </span>
                {reviewSummary?.ratingsTrendGraphData?.filter((review) => review?.label === 'positive')[0]?.value || "0"}
              </Paragraph>
              <Paragraph size='b5' className='flex items-center capitalize'>
                negative
                <span className='mx-2'>
                  <SvgNegativeIcon width="16px" height="16px" />
                </span>
                {reviewSummary?.ratingsTrendGraphData?.filter((review) => review?.label === 'negative')[0]?.value || "0"}
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 px-3 py-2 lg:py-3 lg:px-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        <div className='flex space-x-2'>
          <Paginator pageInfo={reviews?.pageInfo} {...{ goToNext, goToPrev }} />
        </div>
      </div>
      <div className='w-full flex flex-col h-fit border-t border-grey-100'>
        <ReportTable
          rows={rows}
          headers={Headers}
        />
      </div>
    </>
  )
}

const ExpenseReport = () => {
  return (
    <Expenses hideButton={true} />
  )
}

export default Reports
