import { gql, useQuery, useMutation } from '@apollo/client'
import { OutstandingPayments, PaymentCollectedItem, Sale, SalesOverviewByService, TransactionSummaryItem } from 'core/generated';

const TRANSACTION_SUMMARY = gql`
  query TransactionSummary(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $salonId: ID!
  ) {
    transactionSummary(
      startDate: $startDate
      endDate: $endDate
      salonId: $salonId
    ) {
      grossValue
      itemType
      salesValue
    }
  }
`;

export const useTransactionSummary = (startDate: string, endDate: string, salonId: string) => {
  return useQuery<{
    transactionSummary: TransactionSummaryItem[]
  }>(TRANSACTION_SUMMARY, {
    variables: {
      startDate,
      endDate,
      salonId,
    },
    skip: !salonId || !startDate || !endDate,
  });
};

const PAYMENT_COLLECTED = gql`
  query PaymentCollected(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $salonId: ID!
  ) {
    paymentCollected(
      startDate: $startDate
      endDate: $endDate
      salonId: $salonId
    ) {
      amount
      paymentType
      quantity
    }
  }
`;

export const usePaymentCollected = (startDate: string, endDate: string, salonId: string) => {
  return useQuery<{
    paymentCollected: PaymentCollectedItem[];
  }>(PAYMENT_COLLECTED, {
    variables: {
      startDate,
      endDate,
      salonId,
    },
    skip: !salonId || !startDate || !endDate,
  });
};

const OUTSTANDING_PAYMNTS = gql`
  query OutstandingPayments(
    $salonId: ID!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    outstandingPayments(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
      amountOutstanding
      amountPaid
      itemType
      quantity
    }
  }
`;

export const useOutstandingPayments = (startDate: string, endDate: string, salonId: string) => {
  return useQuery<{
    outstandingPayments: OutstandingPayments[];
  }>(OUTSTANDING_PAYMNTS, {
    variables: {
      startDate,
      endDate,
      salonId,
    },
    skip: !salonId || !startDate || !endDate,
  });
};

const SALES_REPORT = gql`
  query SalesReport(
    $startDate: ISO8601Date
    $endDate: ISO8601Date
    $salonId: ID!
  ) {
    salesReport(startDate: $startDate, endDate: $endDate, salonId: $salonId) {
      discount
      grossSales
      itemSold
      netSales
      refunds
      service
      tax
      totalSales
      client
      saleStatus
      saleId
      appointmentId
      saleType
      paymentMethod
      paymentDate
      product
      amountClientPaid
    }
  }
`;

export const useSalesReports = (startDate: string, endDate: string, salonId: string) => {
  return useQuery<{
    salesReport: SalesOverviewByService[];
  }>(SALES_REPORT, {
    variables: {
      startDate,
      endDate,
      salonId,
    },
    skip: !salonId || !startDate || !endDate,
  });
};

const EMAIL_SALE_RECEIPT = gql`
  mutation EmailSaleReceipt($input: EmailSaleReceiptInput!) {
    emailSaleReceipt(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useEmailSaleReceipt = () => {
  const [emailSaleReceipt, options] = useMutation<{
    emailSaleReceipt: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(EMAIL_SALE_RECEIPT);

  return {
    emailSaleReceipt,
    ...options,
  };
}

const VOID_SALE_OR_APPOINTMENT = gql`
  mutation VoidAppointmentOrSale($input: VoidAppointmentOrSaleInput!) {
    voidAppointmentOrSale(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useVoidAppointmentOrSale = () => {
  const [voidAppointmentOrSale, options] = useMutation<{
    voidAppointmentOrSale: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(VOID_SALE_OR_APPOINTMENT);

  return {
    voidAppointmentOrSale,
    ...options,
  };
}

const CREATE_SALE = gql`
  mutation CreateSale($input: CreateSaleInput!) {
    createSale(input: $input) {
      clientMutationId
      status
      sale {
        addOnAmount
        amountClientPaid
        addOnReason
        saleDiscount
        clientId
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        createdAt
        discountAmount
        endAt
        id
        note
        paymentMethod
        paymentMethodId
        salon {
          id
        }
        startAt
        status
        taxAmount
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        saleProducts {
          createdAt
          id
          name
          price
          quantity
          productId
          saleId
          updatedAt
        }
        saleStaff {
          createdAt
          id
          saleId
          salonStaffId
          updatedAt
        }
        saleServices {
          createdAt
          duration
          endAt
          id
          name
          price
          quantity
          saleId
          serviceId
          startAt
          status
          updatedAt
        }
        salePromo {
          amount
          createdAt
          id
          promoId
          saleId
          title
          updatedAt
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateSale = () => {
  const [createSale, options] = useMutation<{
    createSale: {
      status: number;
      sale: Sale;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_SALE);

  return {
    createSale,
    ...options,
  };
}

const UPDATE_SALE = gql`
  mutation UpdateSale($input: UpdateSaleInput!) {
    updateSale(input: $input) {
      clientMutationId
      status
      sale {
        addOnAmount
        amountClientPaid
        addOnReason
        saleDiscount
        clientId
        client {
          addedById
          address
          amountSpent
          businessId
          createdAt
          deletedAt
          dob
          id
          lastVisit
          pointsEarned
          salonId
          updatedAt
          altCallingCode
          altCountryCode
          altPhone
          callingCode
          countryCode
          email
          firstName
          lastName
          phone
        }
        createdAt
        discountAmount
        endAt
        id
        note
        paymentMethod
        paymentMethodId
        salon {
          id
        }
        startAt
        status
        taxAmount
        totalDuration
        totalPaid
        totalPoints
        totalPrice
        totalProductsAmount
        totalServicesAmount
        updatedAt
        saleProducts {
          createdAt
          id
          name
          price
          quantity
          productId
          saleId
          updatedAt
        }
        saleStaff {
          createdAt
          id
          saleId
          salonStaffId
          updatedAt
        }
        saleServices {
          createdAt
          duration
          endAt
          id
          quantity
          name
          price
          saleId
          serviceId
          startAt
          status
          updatedAt
        }
        salePromo {
          amount
          createdAt
          id
          promoId
          saleId
          title
          updatedAt
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateSale = () => {
  const [updateSale, options] = useMutation<{
    updateSale: {
      status: number;
      sale: Sale;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPDATE_SALE);

  return {
    updateSale,
    ...options,
  };
}

const GET_SALE = gql`
  query Sale($id: ID!) {
    sale(id: $id) {
      addOnAmount
      amountClientPaid
      addOnReason
      clientId
      createdAt
      discountAmount
      endAt
      id
      note
      paymentMethod
      paymentMethodId
      salon {
        address
        branchName
        callingCode
        city
        countryCode
        depositLinkValidityHours
        depositType
        depositValue
        isTaxVisible
        phone
        state
        updatedAt
        business {
          about
          callingCode
          cancellationPolicy
          countryCode
          logoUrl
          name
          country {
            currency {
              code
              id
              name
              symbol
            }
          }
        }
      }
      startAt
      status
      saleStatus
      taxAmount
      totalDuration
      totalPaid
      totalPoints
      totalPrice
      totalProductsAmount
      totalServicesAmount
      updatedAt
      client {
        addedById
        address
        amountSpent
        createdAt
        deletedAt
        dob
        id
        lastVisit
        pointsEarned
        salonId
        updatedAt
        altCallingCode
        altCountryCode
        altPhone
        callingCode
        countryCode
        email
        firstName
        lastName
        phone
      }
      saleProducts {
        createdAt
        id
        name
        price
        productId
        saleId
        updatedAt
        quantity
      }
      saleServices {
        createdAt
        duration
        endAt
        id
        quantity
        name
        price
        saleId
        serviceId
        startAt
        status
        updatedAt
      }
      saleStaff {
        createdAt
        id
        saleId
        salonStaffId
        salonStaff {
          user {
            fullName
            id
            lastLoggedInAt
            lastName
          }
        }
        updatedAt
      }
      saleDiscount
      salePromo {
        amount
        createdAt
        id
        promoId
        saleId
        title
        updatedAt
      }
    }
  }
`;

export const useGetSale = (id: string) => {
  return useQuery<{
    sale: Sale;
  }>(GET_SALE, { variables: { id }, skip: !id });
}

const CREATE_VOUCHER_SALE = gql`
  mutation CreateVoucherSale($input: CreateVoucherSaleInput!) {
    createVoucherSale(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      voucherSale {
        id
      }
    }
  }
`;

export const useCreateVoucherSale = () => {
  const [createVoucherSale, options] = useMutation<{
    createVoucherSale: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      voucherSale: {
        id: string;
      };
    };
  }>(CREATE_VOUCHER_SALE);

  return {
    createVoucherSale,
    ...options,
  };
}

const PROJECTED_SALES = gql`
  query SalonProjectedSales($salonId: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    salonProjectedSales(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const useProjectedSales = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string;
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    salonProjectedSales: number;
  }>(PROJECTED_SALES, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId || !startDate || !endDate,
  });
}

const TOP_SELLERS = gql`
  query TopSellers(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    topSellers(salonId: $salonId, startDate: $startDate, endDate: $endDate) {
      count
      name
    }
  }
`;

export const useTopSellers = ({
  salonId,
  startDate,
  endDate,
}: {
  salonId: string;
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    topSellers: {
      count: number;
      name: string;
    }[];
  }>(TOP_SELLERS, {
    variables: {
      salonId,
      startDate,
      endDate,
    },
    skip: !salonId || !startDate || !endDate,
  });
}