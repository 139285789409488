import { useMutation, useQuery, gql } from "@apollo/client";
import { AutomatedMessage, Message, MessageRecipient } from "core/generated";

const AUTO_MESSAGING = gql`
  query AutomatedMessages {
    automatedMessages {
      title
      body
      tag
      preference {
        email
        sms
      }
    }
  }
`;

export const useAutomatedMessages = () => {
  return useQuery<{
    automatedMessages: AutomatedMessage[];
  }>(AUTO_MESSAGING);
};

const MESSAGES_RECEIPTS = gql`
  query MessageRecipients(
    $messageId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    messageRecipients(
      messageId: $messageId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        clickCount
        clickStatus
        createdAt
        deliveryStatus
        id
        mailtrapMessageId
        messageId
        openCount
        openStatus
        updatedAt
        client {
          firstName
          lastName
          email
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useMessagesReceipts = ({
  messageId,
  after,
  before,
  first,
  last,
}: {
  messageId: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}) => {
  return useQuery<{
    messageRecipients: {
      nodes: MessageRecipient[];
      pageInfo: {
        endCursor: string;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string;
      };
    };
  }>(MESSAGES_RECEIPTS, {
    variables: {
      messageId,
      after,
      before,
      first,
      last,
    },
  });
};

const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      status
      errors {
        message
        property
      }
      message {
        id
        title
      }
    }
  }
`

export const useCreateMessage = () => {
  const [createMessage, options] = useMutation<{
    createMessage: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      message: {
        id: string;
        title: string;
      };
    };
  }>(CREATE_MESSAGE);

  return {
    createMessage,
    ...options
  }
}

const DELETE_MESSAGE = gql`
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteMessage = () => {
  const [deleteMessage, options] = useMutation<{
    deleteMessage: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_MESSAGE);

  return {
    deleteMessage,
    ...options,
  };
}; 

const CUSTOM_MESSAGES = gql`
query CustomMessages($salonId: ID!) {
  customMessages(salonId: $salonId) {
    id
    title
    body
    messageType
    emailType
    mailCategory
    sendGroup
    status
    createdAt
    headerImageUrl
    recipientCount
    insights {
      clickRate
      deliveryRate
      openRate
    }
  }
}
`
export const useCustomMessages = (salonId: string) => {
  return useQuery<{
    customMessages: Message[]
  }>(CUSTOM_MESSAGES, {
    variables: {
      salonId
    }
  })
}