import React from 'react';
import AccountSetupTemplate from '../AccountSetupTemplate';
import { PageProps } from '../types';
import { useSalonCache } from 'hooks/useSalonCache';
import BusinessHoursForm from '../../BusinessHoursForm';

const Hours = ({ onNextPage, onPrevPage, business }: PageProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  const businessId = business?.businessId;

  return (
    <AccountSetupTemplate
      title='Business hours'
      subtitle='Choose days and set times your weekly working hours'
      skip={{
        text: 'Skip Business Setup',
      }}
    >
      <BusinessHoursForm
        salonId={salonId}
        businessId={businessId}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        accountSetup={true}
      />
    </AccountSetupTemplate>
  )
}

export default Hours