import { ERRORS } from "./errors";


export const REGEX_VALIDATION = {
  NUMBER: /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  LETTERS: /^[a-zA-Z]*$/,
  ALPHANUMERIC: /[a-z]/gi,
  ALPHANUMERIC_WITH_SPACE_OR_DASH: /^[a-zA-Z0-9- ]*$/,
  ALPHANUMERIC_WITH_PUNCTUATION_MARKS: /^[a-zA-Z0-9\s\-.':;&%$#*,!?()[\]]*$/,
  ACCOUNT_NUMBER: /^[0-9]{10}$/,
  BVN_NUMBER: /^[0-9]{11}$/,
  ALPHA_WITH_SPACE: /^[a-zA-Z\s]*$/,
  SOCIAL_MEDIA_URL: /^https?:\/\/(www\.)?/,
};

export const REGEX_PATTERNS = {
  EMAIL: {
    value: REGEX_VALIDATION.EMAIL,
    message: ERRORS.EMAIL_INVALID,
  },
  NUMBER: {
    value: REGEX_VALIDATION.NUMBER,
    message: ERRORS.NUMBER_INVALID,
  },
  ADDRESS: {
    value: REGEX_VALIDATION.ALPHANUMERIC_WITH_PUNCTUATION_MARKS,
    message: ERRORS.ADDRESS_REQUIRED,
  },
  LETTERS: {
    value: REGEX_VALIDATION.ALPHANUMERIC_WITH_PUNCTUATION_MARKS,
    message: ERRORS.ONLY_LETTERS,
  },
  ALPHANUMERIC: {
    value: REGEX_VALIDATION.ALPHANUMERIC,
    message: ERRORS.ONLY_LETTERS,
  },
  ALPHANUMERIC_WITH_SPACE_OR_DASH: {
    value: REGEX_VALIDATION.ALPHANUMERIC_WITH_PUNCTUATION_MARKS,
    message: ERRORS.ONLY_LETTERS,
  },
  ALPHANUMERIC_WITH_PUNCTUATION_MARKS: {
    value: REGEX_VALIDATION.ALPHANUMERIC_WITH_PUNCTUATION_MARKS,
    message: ERRORS.ONLY_LETTERS,
  },
  ACCOUNT_NUMBER: {
    value: REGEX_VALIDATION.ACCOUNT_NUMBER,
    message: ERRORS.ACCOUNT_NUMBER_INVALID,
  },
  BVN_NUMBER: {
    value: REGEX_VALIDATION.BVN_NUMBER,
    message: ERRORS.BVN_NUMBER_INVALID,
  },
  ALPHA_WITH_SPACE: {
    value: REGEX_VALIDATION.ALPHA_WITH_SPACE,
    message: ERRORS.ONLY_LETTERS,
  },
  PASSWORD_STRENGTH: {
    value: new RegExp([
      '^',                // Start of string
      '(?=.*[a-z])',      // Must contain at least one lowercase letter
      '(?=.*[A-Z])',      // Must contain at least one uppercase letter
      '(?=.*\\d)',        // Must contain at least one digit
      '(?=.*[@$!%*?&\\-£€.#^_=+;/,(){}\\[\\]\\\\"])', // Must contain at least one special character
      '[A-Za-z\\d@$!%*?&\\-£€.#^_=+;/,(){}\\[\\]\\\\"]', // Allowed characters
      '{8,}',             // Must be at least 8 characters long
      '$'                 // End of string
    ].join('')),
    message: ERRORS.PASSWORD_STRENGTH,
  },
  TIME: {
    value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    message: ERRORS.WRONG_TIME_FORMAT,
  },
  PRICE: {
    value: REGEX_VALIDATION.NUMBER,
    message: ERRORS.PRICE_INVALID,
  },
  SOCIAL_URL: {
    value: REGEX_VALIDATION.SOCIAL_MEDIA_URL,
    message: ERRORS.SOCIAL_URL_INVALID,
  },
};

export const IMAGE_UPLOAD_PATTERN = ".png,.jpeg,.jpg";
export const RECEIPT_UPLOAD_PATTERN = ".png,.jpeg,.jpg,.pdf";
export const CSV_UPLOAD_PATTERN = ".csv";
