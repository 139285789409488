import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPhQuestion = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M8.75 11.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM8 4.5c-1.379 0-2.5 1.01-2.5 2.25V7a.5.5 0 1 0 1 0v-.25c0-.688.673-1.25 1.5-1.25s1.5.563 1.5 1.25C9.5 7.438 8.827 8 8 8a.5.5 0 0 0-.5.5V9a.5.5 0 1 0 1 0v-.045c1.14-.21 2-1.119 2-2.205C10.5 5.51 9.379 4.5 8 4.5ZM14.5 8A6.5 6.5 0 1 1 8 1.5 6.507 6.507 0 0 1 14.5 8Zm-1 0A5.5 5.5 0 1 0 8 13.5 5.506 5.506 0 0 0 13.5 8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPhQuestion;
