import { useCreditClientWallet, useRecordRefund, useRecordSaleRefund } from 'api/useClient';
import { convertServicesNamesToString } from 'components/uicomponents/bookingSales/utils';
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form';
import { limitString, getDayMonthAndNumberAndTimeFromDate, formatNumber, formatInToPrice, cleanUpText } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { RecordRefundInput, Appointment, Sale, Service } from 'core/generated';
import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Button, FormHelperText, FormLabel, FormTextarea, Modal, MultiSelectOption, Paragraph } from 'ui';
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect';

const RecordRefundModal = ({ isVisible, closeModal, client, addToast }) => {
  const { handleSubmit, setValue, control, watch } = useForm<RecordRefundInput>()

  const {
    loading: clientWalletLoading,
    error: clientWalletError,
    creditClientWallet
  } = useCreditClientWallet()
  const {
    loading: saving,
    recordRefund
  } = useRecordRefund();
  const {
    loading: saving_,
    recordSaleRefund
  } = useRecordSaleRefund()

  const creditWalletAsync = ({
    amount,
    description,
    purposeId,
    purposeType
  }: {
    amount: number,
    description: string,
    purposeId: string,
    purposeType: string
  }) => {
    creditClientWallet({
      variables: {
        input: {
          clientId: client?.id,
          amount,
          description,
          purposeId,
          purposeType
        }
      }
    }).then(({ data }) => {
      if (data?.creditClientWallet?.status === 200) {
        addToast({
          message: 'Refund recorded successfully',
          variant: 'success',
        })
        handleClose()
      }

      if (data?.creditClientWallet?.errors?.length) {
        addToast({
          message: data?.creditClientWallet?.errors[0]?.message,
          variant: 'error',
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error',
      })
    })
  }
  const submitRefund = async (input: RecordRefundInput) => {
    // @ts-expect-error Definition is correct
    const inputId = input?.appointmentId?.value || ''
    const id = inputId?.split(':')[0]
    const type = inputId?.split(':')[1]
    const payload = {
      refundReason: input.refundReason,
      totalAmountRefunded: Number(
        input.totalAmountRefunded.toString().replace(/,/g, '')
      ),
      ...(type === 'appointment' ? { appointmentId: id } : { saleId: id })
    }
    if (type === 'appointment') {
      recordRefund({
        variables: {
          input: {
            ...payload,
            appointmentId: id
          }
        }
      }).then(({ data }) => {
        const recordRefund_ = data?.recordRefund
        if (recordRefund_.status === 200) {
          creditWalletAsync({
            amount: payload.totalAmountRefunded,
            description: input.refundReason,
            purposeId: id,
            purposeType: 'appointment'
          })
        } else {
          addToast({
            message: recordRefund_.errors[0].message,
            variant: 'error'
          })
        }
      })
    } else {
      recordSaleRefund({
        variables: {
          input: {
            ...payload,
            saleId: id
          }
        }
      }).then(({ data }) => {
        const recordSaleRefund_ = data?.recordSaleRefund
        if (recordSaleRefund_.status === 200) {
          creditWalletAsync({
            amount: payload.totalAmountRefunded,
            description: input.refundReason,
            purposeId: id,
            purposeType: 'sale'
          })
        } else {
          addToast({
            message: recordSaleRefund_.errors[0].message,
            variant: 'error'
          })
        }
      })
    }
  }

  const handleClose = () => {
    setValue('appointmentId', '')
    setValue('totalAmountRefunded', 0)
    setValue('refundReason', '')
    closeModal()
  }

  const formatBookingsToSelectField = (bookings: Appointment[]) => {
    const completedBookings = bookings?.filter(
      (booking) => booking.appointmentStatus === 'completed'
    )

    return completedBookings?.map((booking) => ({
      label:
        limitString(convertServicesNamesToString(booking.services), 15) +
        ' - ' +
        getDayMonthAndNumberAndTimeFromDate(booking.startAt),
      value: booking.id + ':appointment'
    }))
  }
  const getSaleDetails = () => {
    const appointmentOrSale = watch('appointmentId') as unknown as MultiSelectOption;
    if (!appointmentOrSale) return;
    const id = appointmentOrSale?.value?.split(':')[0];
    const type = appointmentOrSale?.value?.split(':')[1];
    let details;
    if (type === 'appointment') {
      details = client?.appointments?.find((appointmentDetails) => appointmentDetails?.id === id)
    } else {
      details = client?.sales?.find((saleDetails) => saleDetails?.id === id)
    }
    if (!details) return;
    const services = details?.services?.length ? details?.services?.map(item => item.name).join(", ") : ''

    return (
      <div className='w-full h-fit p-2 rounded-md flex flex-col space-y-2 border border-grey-125 bg-grey-125/45'>
        <div className='grid grid-cols-3'>
          <div className='flex flex-col'>
            <Paragraph size='b6'>Total amount</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{formatInToPrice(details?.totalPrice)}</Paragraph>
          </div>
          <div className='flex flex-col'>
            <Paragraph size='b6'>Payment status</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{cleanUpText(details?.paymentMethod)}</Paragraph>
          </div>
          <div className='flex flex-col'>
            <Paragraph size='b6'>Status</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{details?.saleStatus ? cleanUpText(details?.saleStatus) : cleanUpText(details?.appointmentStatus)}</Paragraph>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='flex flex-col col-span-2'>
            <Paragraph size='b6'>Services</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{services}</Paragraph>
          </div>
          <div className='flex flex-col'>
            <Paragraph size='b6'>Date</Paragraph>
            <Paragraph size='b5' color={COLORS.GREY[300]}>{getDayMonthAndNumberAndTimeFromDate(details?.startAt)}</Paragraph>
          </div>
        </div>
      </div>
    )
  }
  const formatSaleToSelectField = (bookings: Sale[]) => {
    return Array.isArray(bookings)
      ? bookings
        .filter((booking) => booking.saleStatus !== 'unpaid')
        .map((booking) => ({
          label:
            limitString(
              convertServicesNamesToString(
                booking.services as unknown as Service[]
              ),
              15
            ) +
            ' - ' +
            getDayMonthAndNumberAndTimeFromDate(booking.startAt),
          value: booking.id + ':sale'
        }))
      : []
  }
  const appointmentOptions = formatBookingsToSelectField(
    client?.appointments ?? []
  )
  const saleOptions = formatSaleToSelectField(client?.sales ?? [])
  const options = [...appointmentOptions, ...saleOptions]
  return (
    <Modal title='Record a refund' show={isVisible} closeModal={handleClose}>
      <form
        onSubmit={handleSubmit(submitRefund)}
        className='w-full my-6 space-y-6'
        autoComplete='off'
      >
        <div className='flex flex-col space-y-2'>
          <Controller
            control={control}
            name='appointmentId'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage: string = errors?.appointmentId?.message
              return (
                <>
                  <FormLabel htmlFor='service'>
                    Select sale/appointment
                  </FormLabel>
                  <SingleSelect
                    // @ts-expect-error Value is correct
                    selected={value || []}
                    options={options}
                    setSelected={onChange}
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
            rules={{
              required: 'Select sale or appointment'
            }}
          />
          {watch('appointmentId') ? getSaleDetails() : null}
        </div>
        <Controller
          control={control}
          name='totalAmountRefunded'
          rules={{ required: 'Please enter amount' }}
          render={({ field, formState: { errors } }) => (
            <div className='w-full flex flex-col space-y-2'>
              <FormLabel htmlFor='price'>Amount</FormLabel>
              <input
                className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                type='text'
                {...field}
                value={formatNumber(field.value)}
                onChange={(e) => field.onChange(formatNumber(e.target.value))}
                placeholder='Enter price'
              />
              {errors?.totalAmountRefunded?.message && (
                <FormHelperText variant='error'>
                  {errors.totalAmountRefunded.message}
                </FormHelperText>
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name='refundReason'
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors }
          }) => {
            const maxLength = 400
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.refundReason?.message as string
            )
            const helperTexts = []

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

            return (
              <FormTextarea
                type='text'
                id='about'
                label='Write a reason for refund'
                placeholder='Add a note'
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength
                }}
                helperText={helperTexts}
              />
            )
          }}
        />
        <Button
          variant='primary'
          className=''
          disabled={saving || saving_ || clientWalletLoading}
          loading={saving || saving_ || clientWalletLoading}
          size='lg'
          rounded='lg'
        >
          Submit
        </Button>
      </form>
      <Button
        variant='text'
        className='mx-auto text-red-500'
        size='none'
        onClick={handleClose}
      >
        Cancel
      </Button>
    </Modal>
  )
}

export default RecordRefundModal
