/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { MOBILE_PAYMENT_TRANSACTIONS_HEADINGS, PAYMENT_TRANSACTIONS_HEADINGS } from './constants';
import { Menu, Transition } from '@headlessui/react';
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton';
import Label from 'ui/atoms/formLabel/Label';
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm';
import Table from 'ui/organism/table/Table';
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import CalendarPicker from 'ui/molecules/calendarPicker/CalendarPicker';
import { SvgClose, SvgFilter } from 'ui';
import { useSalonCache } from 'hooks/useSalonCache';
import { PaymenntsFilterInput, SalesPageProps } from './types';
import { generatePaymentTransactionsTableData } from './utils'
import { useModal } from 'hooks/useModal';
import ViewPaymentTransactionModal from './ViewPaymentTransactionModal';
import { Payment } from 'core/generated';
import { Button, Modal, Paragraph, SelectInput } from '../../../ui';
import { COLORS } from 'constants/colors';
import { useForm } from 'react-hook-form';
import { useGetPayments } from 'api/useMoney';

const PaymentTransactions = (props: SalesPageProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [paymentHeadings, setPaymentHeadings] = useState(PAYMENT_TRANSACTIONS_HEADINGS);

  const [filteredPaymentData, setFilteredPaymentData] = useState<Payment[]>([]);
  const [start, end] = selectedDates || getTodayQueryDates();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PaymenntsFilterInput>({});

  const {
    openModal: openViewPaymentModal,
    closeModal: closeViewPaymentModal,
    isVisible: isViewPaymentModalVisible
  } = useModal()

  const {
    openModal: openFilterDialogModal,
    closeModal: closeFilterDialogModal,
    isVisible: isFilterDialogModalVisible
  } = useModal()

  const {
    data: paymentsData,
    loading: isLoading,
    refetch: refetchPayments,
  } = useGetPayments({
    salonId: salon?.id,
    startDate: formatDateToOriginalDate(selectedDates?.[0], 'start'),
    endDate: formatDateToOriginalDate(selectedDates?.[1], 'end'),
    q: debouncedSearchQuery,
  })
  const payments = useMemo(() => {
    if (Array?.isArray(paymentsData?.payments) && paymentsData?.payments?.length) {
      setFilteredPaymentData(paymentsData?.payments);

      return paymentsData?.payments
    }
  }, [paymentsData])
  const [payment, setPayment] = useState<Payment | null>(null);
  const showInitialLoadingShimmer = isLoading && !payments && !debouncedSearchQuery;

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in Headings then update the show
    const updatedHeadings = paymentHeadings.map(heading => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setPaymentHeadings(updatedHeadings)
  }

  const openPayment = (id: number) => {
    const report = Array.isArray(payments) ? payments[id] : undefined;

    if (!report) return;
    setPayment(report)
    openViewPaymentModal()
  }

  const initiateCloseViewPaymentModal = () => {
    closeViewPaymentModal()
    setPayment(null)
  }

  const getContent = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array?.isArray(filteredPaymentData) && filteredPaymentData?.length || debouncedSearchQuery) {
      const tableClientsData = generatePaymentTransactionsTableData(filteredPaymentData || [], paymentHeadings, salon?.countryCode)
      return (
        <>
          <Table
            headers={formatTableHeadersFilterArray(paymentHeadings)}
            mobileHeaders={MOBILE_PAYMENT_TRANSACTIONS_HEADINGS}
            rows={tableClientsData}
            onClick={openPayment}
          />
        </>
      )
    }
  }

  useEffect(() => {
    if (props.salonBranchName) {
      refetchPayments()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      refetchPayments()
      props?.setRefreshData(false)
    }
  }, [props.refreshData])

  useEffect(() => {
    refetchPayments()
  }, [debouncedSearchQuery, selectedDates])

  const paymentMethodFIlterOptions = [
    {
      value: "all",
      label: "All"
    },
    {
      value: "bank_transfer",
      label: "Bank Transfer"
    },
    {
      value: "pos",
      label: "POS"
    },
    {
      value: "cash",
      label: "Cash"
    },
    {
      value: "giftcard",
      label: "Gift Card"
    },
    {
      value: "voucher",
      label: "Voucher"
    },
  ]

  const onSubmitData = (input: PaymenntsFilterInput) => {
    closeFilterDialogModal()
    if (input.paymentMethod === "all") {
      setFilteredPaymentData(payments)
      return
    } else if (input.paymentMethod) {
      const filteredData = payments?.filter(payment => payment.mode === input.paymentMethod)
      setFilteredPaymentData(filteredData)
      return
    }
  }

  return (
    <>
      <div className='flex flex-col xl:flex-row justify-between items-center py-4 px-8 space-x-4'>
        <div className='w-full xl:w-6/12 flex items-center space-x-4'>
          <SearchTerm placeholder='Search' setDebouncedSearchQuery={setDebouncedSearchQuery} />
          <Button
            variant='light'
            rounded='md'
            size='md'
            onClick={openFilterDialogModal}
          >
            <SvgFilter width="20px" height="20px" />
            <span className='hidden xl:inline'>Filter</span>
          </Button>
        </div>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getContent()}
      <ViewPaymentTransactionModal
        isVisible={isViewPaymentModalVisible}
        closeModal={initiateCloseViewPaymentModal}
        payment={payment}
      />
      <Modal show={isFilterDialogModalVisible} closeModal={closeFilterDialogModal}>
        <div className='w-full flex flex-col space-y-6'>
          <div className='w-full flex justify-between'>
            <Paragraph size='b1' color={COLORS.GREY[900]}>
              Filter
            </Paragraph>
            <Button
              variant='icon'
              className='border-0'
              size='xs'
              onClick={closeFilterDialogModal}
            >
              <SvgClose width="24px" height="24px" />
            </Button>
          </div>

          <form onSubmit={handleSubmit(onSubmitData)} className="w-full flex flex-col space-y-6 pt-6">
            <SelectInput name="paymentMethod" id="payment-method" label="Payment Method" control={control} options={paymentMethodFIlterOptions} placeholder="Select Payment Method" />
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
            >
              Apply filter
            </Button>
            <Button
              variant='text'
              size='none'
              type='button'
              fontWeight='semiBold'
              className="text-red-500"
              onClick={closeFilterDialogModal}
            >
              Cancel
            </Button>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default PaymentTransactions