import * as React from 'react';
import { SvgEdit, SvgPlus, UploadImage, Paragraph } from 'ui';

export const getImageUploadContent = ({
  imageUrl, uploadIsLoading, placeHolder, size
}: {
  imageUrl: string | null, uploadIsLoading: boolean, placeHolder?: string, size?: string
}) => {
  if (uploadIsLoading) {
    return (
      <div className="flex justify-center gap-x-2 cursor-pointer">
        {[1, 2, 3].map((item, index) => (
          <div key={index} className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse" />
        ))}
      </div>
    )
  }
  if (imageUrl) {
    return (
      <div className="flex justify-center items-center relative">
        <img
          src={imageUrl}
          alt="image upload icon"
          className="w-full max-w-[200px] h-[100px]"
        />
        <span className='absolute p-2 bg-black/70 backdrop-blur-[2px] rounded-full text-white'>
          <SvgEdit width="24px" height="24px" />
        </span>
      </div>
    )
  }

  return (
    <div className="flex justify-center gap-x-2 cursor-pointer">
      {size ? <SvgPlus width="24px" height="24px" /> : <UploadImage width="24px" height="24px" />}
      {!size && (
        <Paragraph size="b5" weight="semiBold" className="border-b border-black line-height-[20px]">
          {placeHolder || "Upload image"}
        </Paragraph>
      )}
    </div>
  )
}