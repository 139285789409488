import { gql, useMutation } from "@apollo/client";
import { ForgotPasswordApiResponseData } from "./types/auth";

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      clientMutationId
      status
      token
      errors {
        message
        property
      }
    }
  }
`;

export const useForgotPassword = () => {
  const [forgotPassword, options] = useMutation<{
    forgotPassword: ForgotPasswordApiResponseData;
  }>(FORGOT_PASSWORD);

  return {
    forgotPassword,
    ...options
  };
}