import Papa from 'papaparse';
import React, { useState } from 'react'
import { BatchUploadProps } from './types';
import axios from 'axios';
// import { useUserCache } from 'hooks/useUserCache';
// import { useSalonCache } from 'hooks/useSalonCache';
import { API_ERRORS } from 'constants/errors';
import Button from 'ui/atoms/button/Button';
import { CSV_UPLOAD_PATTERN } from 'constants/pattern';
import { useSalonCache } from 'hooks/useSalonCache';
import { formatClientsData } from './utils';
import { UploadClientDocument } from 'core/generated';
import { print } from 'graphql'
import { useUploadClient } from 'api/useClient';
import { useUploadFile } from 'api/useAccount';

const ImportClients = (props: BatchUploadProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');
  
  const {
    loading: isLoading,
    uploadClient
  } = useUploadClient()
  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const importClientsSubmit = async (uploadUrl: string) => {
    const payload = {
      salonId,
      url: uploadUrl
    }
    uploadClient({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { uploadClient: uc } = data
      if (uc.status === 200) {
        props?.addToast({ message: 'Clients saved successfully', variant: 'success' })
        props?.refetch()
      } else {
        props?.addToast({ message: uc.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          importClientsSubmit(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          props?.addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        props?.addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Button
      variant='success'
      className='relative'
      // disabled={uploadSalonClients.isLoading}
      size={props.size || 'sm'}
      rounded={props.rounded || 'md'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ?
        (
          <>
            Import Clients
            <input
              className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full"
              type="file"
              accept={CSV_UPLOAD_PATTERN}
              onChange={handleCsvUpload}
            />
          </>
        ) : (
          <div className="flex justify-center gap-x-2 cursor-pointer">
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse" />
            ))}
          </div>
        )}
    </Button>
  )
}

export default ImportClients