import * as React from "react";
import { ReportTableProps } from './types';
import { TableCell, TableCellContent, TableHeader, TableRow, TableSkeleton } from "../..";
import EmptyState from "./EmptyState";

const ReportTable: React.FC<ReportTableProps> = ({ headers, subHeaders, rows, loading, onClick, hasFooterBg }) => {
  return (
    <>
      <table className="w-full table-auto">
        {headers && Array.isArray(headers) && headers?.length && (
          <thead className='table-row-group'>
            <tr className='bg-grey-50 py-2'>
              {headers.map((header, index) => (
                <TableHeader colSpan={header?.span} weight='bold' key={index}>{header?.name}</TableHeader>
              ))}
            </tr>
            {subHeaders && Array.isArray(subHeaders) && subHeaders?.length && (
              <tr className='border-b border-grey-100'>
                {subHeaders.map((subHeader, index) => (
                  <TableHeader key={index} size="b6" weight='bold'>{subHeader}</TableHeader>
                ))}
              </tr>
            )}
          </thead>
        )}
        {!loading && (
          <tbody className="hidden md:table-row-group">
            {Array.isArray(rows) &&
              rows.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  hasBorder={true}
                  onClick={onClick ? () => onClick(rowIndex) : null}
                  hasFooterBg={hasFooterBg && rowIndex === rows?.length - 1}
                >
                  {/* convert object row to an array */}
                  {Object.keys(row).map((cell, index) => (
                    <TableCell key={index}>
                      <TableCellContent size="b6" content={row[cell]} weight={hasFooterBg && rowIndex === rows?.length - 1 ? 'semiBold' : 'normal'} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </tbody>
        )}
      </table>
      {loading && (
        <TableSkeleton />
      )}
      {rows && !loading && !rows?.length && (
        <EmptyState />
      )}
    </>
  );
};

export default ReportTable