import { useBlacklistClient, useUnblacklistClient } from 'api/useClient'
import { COLORS } from 'constants/colors'
import { Client } from 'core/generated'
import React from 'react'
import { Button, Heading, Modal, Paragraph, ToastProps } from 'ui'

const BlacklistOrUnblacklistClientModal = ({
  client,
  isOpen,
  onClose,
  addToast
}: {
  client: Client,
  isOpen: boolean,
  onClose: () => void,
  addToast: (toast: ToastProps) => void
}) => {

  const {
    loading: blacklistClientIsLoading,
    blacklistClient
  } = useBlacklistClient()

  const {
    loading: unblacklistClientIsLoading,
    unblacklistClient
  } = useUnblacklistClient()

  const blackListClientAsync = () => {
    blacklistClient({
      variables: {
        input: {
          clientId: client?.id
        }
      }
    }).then(({ data }) => {
      const { blacklistClient } = data;

      if (blacklistClient?.status === 200) {
        addToast({
          message: blacklistClient?.message,
          variant: 'success'
        })
        onClose()
      }

      if (blacklistClient?.errors?.length) {
        addToast({
          message: blacklistClient?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  }

  const unblackListClientAsync = () => {
    unblacklistClient({
      variables: {
        input: {
          clientId: client?.id
        }
      }
    }).then(({ data }) => {
      const { unblacklistClient } = data;

      if (unblacklistClient?.status === 200) {
        addToast({
          message: unblacklistClient?.message,
          variant: 'success'
        })
        onClose()
      }

      if (unblacklistClient?.errors?.length) {
        addToast({
          message: unblacklistClient?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  }
  return (
    <Modal
      show={isOpen}
      closeModal={onClose}
    >
      <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 max-w-[400px] xl:max-w-[500px]">
        <div className="w-full flex flex-col items-center space-y-6">
          <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>{client?.blacklisted ? 'Reinstate this client?' : 'Blacklist this client?'}</Heading>
          <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
            {!client?.blacklisted ? `Are you sure you want to blacklist this ${client?.firstName}? The client will no longer be able to make appointments?` : `Are you sure you want to remove ${client?.firstName || 'this client'} from the blacklist? The client will be able to make appointments again.`}
          </Paragraph>

          <Button
            variant="primary"
            size='lg'
            rounded='lg'
            fontSize='b5'
            className='capitalize'
            disabled={blacklistClientIsLoading || unblacklistClientIsLoading}
            loading={blacklistClientIsLoading || unblacklistClientIsLoading}
            onClick={client?.blacklisted ? unblackListClientAsync : blackListClientAsync}
          >
            Yes, {client?.blacklisted ? 'reinstate' : 'blacklist'}
          </Button>
          <Button
            variant="text"
            size='none'
            rounded='none'
            fontSize='b5'
            className='mx-0'
            disabled={blacklistClientIsLoading || unblacklistClientIsLoading}
            onClick={onClose}
          >
            No, cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BlacklistOrUnblacklistClientModal
