/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import RedeemVoucherCard from './RedeemVoucherCard';
import { RedeemVoucherPageProps } from './types';
import RedeemVoucherModal from './modals/RedeemVoucherModal';
import CustomerDashboardShimmer from './DashboardShimmer';
import { useModal } from 'hooks/useModal';
import { useSalonCache } from 'hooks/useSalonCache';
import Heading from 'ui/atoms/heading/Heading';
import Button from 'ui/atoms/button/Button';
import { SvgPlusBox } from 'ui';
import { PackageVoucher } from 'core/generated';
import ReactCardFlip from 'react-card-flip';
import PackageCustomerCard from './components/PackageCustomerCard';
import PackageCustomerBackCard from './components/PackageCustomerBackCard';
import { useClientVouchers } from 'api/usePublic';

const RedeemVoucherTablePage = ({
  customerInfo,
  addToast,
}: RedeemVoucherPageProps) => {
  const { getSalonData } = useSalonCache();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const salon = getSalonData();
  const {
    isVisible: isRedeemVoucherViewModalVisible,
    openModal: openRedeemVoucherViewModal,
    closeModal: closeRedeemVoucherViewModal,
  } = useModal();

  const [totalRedeemed, setTotalRedeemed] = useState(0)
  const [totalOutstanding, setTotalOutstanding] = useState(0)
  const [packageVouchers, setPackageVouchers] = useState([]);
  const {
    loading: isLoading,
    data,
    refetch
  } = useClientVouchers(customerInfo?.id)

  useEffect(() => {
    if (customerInfo) {
      refetch();
    }
  }, [customerInfo])

  useMemo(() => {
    const res = data?.clientVouchers;
    if (!Array?.isArray(res)) return [];
    const sortedVouchers = [...res]?.sort((a, b) => {
      // Assuming "active" status should come first, otherwise adjust the condition
      if (a.status === 'active' && b.status !== 'active') return -1;
      if (a.status !== 'active' && b.status === 'active') return 1;
      return 0;
    });
    const vouchers = sortedVouchers?.map((voucher) => {
      return {
        ...voucher,
        isFlipped: false
      }
    });
    // count how many has been redeemed if status is redeemed
    const redeemedCount = [...res]?.reduce((acc, curr) => {
      if (curr.status === 'redeemed') {
        return acc + 1
      }
      return acc
    }, 0)
    setTotalRedeemed(redeemedCount || 0)
    // count how many has been outstanding if status is outstanding
    const outstandingCount = [...res]?.reduce((acc, curr) => {
      if (curr.status === 'active') {
        return acc + 1
      }
      return acc
    }, 0)
    setTotalOutstanding(outstandingCount || 0)

    setPackageVouchers(vouchers);
  }, [data])

  const showInitialLoadingShimmer = isLoading && !packageVouchers;

  // const allTableData = generateVoucherAllTableData(packageVouchers || [], salon?.countryCode)
  // const unusedTableData = generateVoucherUnusedTableData(packageVouchers || [], salon?.countryCode)
  // const redeemedTableData = generateVoucherRedeemedTableData(packageVouchers || [], salon?.countryCode)

  const flipCard = (index: number) => {
    const updatedPackageVouchers = [...packageVouchers];
    updatedPackageVouchers[index].isFlipped = !updatedPackageVouchers[index].isFlipped;
    setPackageVouchers(updatedPackageVouchers);
  }

  const getContent = () => {
    if (showInitialLoadingShimmer) {
      return <CustomerDashboardShimmer />
    }

    return (
      <div className='w-full flex flex-wrap gap-4 p-4 lg:p-0'>
        {Array?.isArray(packageVouchers) && packageVouchers ? packageVouchers?.map((voucher, index) => {
          return (
            <div className='w-full max-w-[400px]' key={index}>
              <ReactCardFlip isFlipped={voucher?.isFlipped} flipDirection="vertical" flipSpeedFrontToBack={0.5}>
                <PackageCustomerCard voucher={voucher} onClick={() => flipCard(index)} />
                <PackageCustomerBackCard voucher={voucher} onClick={() => flipCard(index)} />
              </ReactCardFlip>
            </div>
          )
        }) : null}
      </div>
    )
  }

  return (
    <>
      <div className='w-full p-0 md:p-4 xl:p-10'>
        <div className='w-full flex flex-col py-8 px-0 md:px-3 xl:px-6 md:border border-grey-50 rounded-lg space-y-12'>
          <div className='w-full flex flex-col px-4 md:px-0 space-y-6'>
            <div className='w-full flex justify-between border-b border-grey-50 md:border-0 pb-6'>
              <div className='flex flex-col space-y-6'>
                <Heading variant='h1' size='h8' weight='bold'>Hello, {customerInfo?.firstName}</Heading>
              </div>
              <div className='hidden md:flex gap-4'>
                <Button
                  variant='primary'
                  className=''
                  size='md'
                  rounded='lg'
                  onClick={openRedeemVoucherViewModal}
                >
                  Redeem voucher
                </Button>
              </div>
              <Button
                variant='icon'
                size="none"
                type="button"
                className="block md:hidden border-0"
                onClick={openRedeemVoucherViewModal}
              >
                <SvgPlusBox width="36px" height="36px" />
              </Button>
            </div>
            {showInitialLoadingShimmer ? (
              <CustomerDashboardShimmer />
            ) : (
              <div className='w-full grid grid-cols-1 xl:grid-cols-4 gap-2 md:gap-8 border border-grey-50 md:border-0 rounded-md'>
                <RedeemVoucherCard title="Total vouchers" subTitle="Total number of vouchers" digits={packageVouchers?.length || 0} />
                <RedeemVoucherCard title="Total redeemed" subTitle="Number of vouchers redeemed" digits={totalRedeemed} />
                <RedeemVoucherCard title="Total unused" subTitle="Number of vouchers unused" digits={totalOutstanding} />
              </div>
            )}
          </div>
          {!showInitialLoadingShimmer ? (
            <div className='w-full flex flex-col'>
              {getContent()}
            </div>
          ) : null}
        </div>
      </div >
      <RedeemVoucherModal addToast={addToast} vouchers={packageVouchers || []} closeModal={closeRedeemVoucherViewModal} isVisible={isRedeemVoucherViewModalVisible} refetch={refetch} customerInfo={customerInfo} />
    </>
  )
}

export default RedeemVoucherTablePage

