import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgExpiredVouchers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={8} fill="#F8F2F2" />
    <path
      d="M16 13.375V17.5m0 2.435v-.375m-4.515-4.488C13.455 11.19 14.44 9.25 16 9.25c1.56 0 2.545 1.94 4.515 5.822l.245.483c1.637 3.224 2.456 4.837 1.715 6.016-.74 1.179-2.57 1.179-6.23 1.179h-.49c-3.66 0-5.491 0-6.23-1.179-.74-1.179.078-2.792 1.715-6.017l.245-.482Z"
      stroke="#7A0202"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgExpiredVouchers;
