import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HeadlessDropdownProps, IHeadlessOption } from './types'
import { SvgDropDown } from 'ui'

const HeadlessDropDown = ({ options, selectedValue, setDropDownSelectedValue }: HeadlessDropdownProps) => {

  const getOption = (selected?: string) => {
    if (selected === null) return null;
    return options?.find((option) => option.value === selected)
  }
  const [selected, setSelected] = useState<IHeadlessOption | null>(null);

  useEffect(() => {
    if (selected) {
      setDropDownSelectedValue(selected?.value)
    }
  }, [selected])

  useEffect(() => {
    if (selectedValue) {
      setSelected(getOption(selectedValue))
    }
  }, [selectedValue])

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative mb-1 w-full">
        <Listbox.Button
          className="relative w-full cursor-pointer rounded-md bg-white border border-grey-100 py-2 px-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span className="block truncate">{
            selected?.label || 'Select...'
          }</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SvgDropDown width='24px' height='24px' aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute max-h-60 w-full z-[99999] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            style={{
              top: 50,
              bottom: 'auto',
            }}
          >
            {Array.isArray(options) && options.map((option, optionIdx) => (
              <Listbox.Option
                key={optionIdx}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                        }`}
                    >
                      {option?.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default HeadlessDropDown