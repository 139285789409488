import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ProtectedRouteProps } from "../types";
import { PAGE_ROUTES } from "constants/routes";
import { useManageAuth } from "../hooks/useManageAuth";

export const AuthGuard = ({ children }: ProtectedRouteProps) => {
  const { getAppAuthToken } = useManageAuth();
  const appAuthToken = getAppAuthToken();
  const location = useLocation();

  const forgotPasswordData = JSON.parse(localStorage.getItem('forgotEmailData') || '{}')?.token;

  // First check forgot password flow
  if (forgotPasswordData) {
    if (forgotPasswordData.isVerified) {
      return (
        <Navigate
          to={PAGE_ROUTES.GENERATE_PASSWORD}
          replace
          state={{ path: location.pathname }}
        />
      );
    } else {
      return (
        <Navigate
          to={PAGE_ROUTES.FORGOT_PASSWORD_OTP}
          replace
          state={{ path: location.pathname }}
        />
      );
    }
  }

  // Then check auth token
  return appAuthToken ? (
    children
  ) : (
    <Navigate
      to={PAGE_ROUTES.LOGIN}
      replace
      state={{ path: location.pathname }}
    />
  );
};
