import { AppLoader } from './pages';

import { PAGE_ROUTES } from "constants/routes";
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Register from './pages/Register';
import GeneratePassword from './pages/Password';
import Otp from './pages/Otp';
import VerifyOtpCodePage from './pages/VerifyOtpCodePage';
import ApplyDemo from './pages/ApplyDemo';

export const routes = [
    {
      path: PAGE_ROUTES.APP_LOADER,
      name: "App Loader",
      Component: AppLoader,
      meta: {
        requiresAuth: false,
    },
    },
    {
        path: PAGE_ROUTES.LOGIN,
        Component: Login,
        name: 'Login',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.FORGOT_PASSWORD,
        Component: Forgot,
        name: 'Forgot',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.REGISTER,
        Component: Register,
        name: 'Register',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.OTP,
        Component: Otp,
        name: 'Otp',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.FORGOT_PASSWORD_OTP,
        Component: VerifyOtpCodePage,
        name: 'Forgot Password Otp',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.APPLY,
        Component: ApplyDemo,
        name: 'Join waitlist',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: PAGE_ROUTES.GENERATE_PASSWORD,
        Component: GeneratePassword,
        name: 'GeneratePassword',
        meta: {
            requiresAuth: false,
        },
    },
];