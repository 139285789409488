import * as Sentry from "@sentry/browser";
const isProduction = process.env.environment === 'production'
const business = localStorage.getItem('splice:business');
const parsedBusiness = business ? JSON.parse(business) : null;

export const logger = {
  debug: (message: string) => {
    if (!isProduction) {
      console.log(message);
    }
  },
  info: (message: string) => {
    if (isProduction) {
      Sentry.captureMessage(message, "info");
    } else {
      console.log(message);
    }
  },
  error: (message: string, context = {}) => {
    const errorMessage = parsedBusiness?.name ? `${parsedBusiness?.name}: ${message}` : message;
    if (isProduction) {
      Sentry.withScope((scope) => {
        // Add the entire context object to the scope
        scope.setContext("data", context);
        Sentry.captureMessage(errorMessage, "error");
      });
    } else {
      console.error(errorMessage, context);
    }
  },
  exception: (error?: Error, context = {}) => {
    if (isProduction) {
      Sentry.withScope((scope) => {
        // Add business name as a tag
        if (parsedBusiness?.name) {
          scope.setTag("business", parsedBusiness?.name);
        }

        // Add the entire context object to the scope
        scope.setContext("data", context);

        Sentry.captureException(error);
      });
    } else {
      console.error(error, context);
    }
  },
};
