import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { AddClientModalProps } from '../types'
import { Appointment, ClientKeyPreference, ClientMilestone, ClientRewardHistory, ClientWalletTransaction, Country, CreateKeyPreferenceInput, PackageVoucher, Sale } from 'core/generated'
import { IAppointmentAndSaleProp } from 'components/uicomponents/types'
import { useModal, useSalonCache } from 'hooks'
import { useAddClient, useCreateClientKeyPreferences, useCreditClientWallet, useGetClient, useGetPreferencesCategories, useUpdateClientKeyPreference, useDeleteClientKeyPreference } from 'api/useClient'
import { formatInToPrice, formatNumber, formatSnakeCaseText, formatTableHeadersFilterArray, getDayMonthAndNumberAndTimeFromDate, getNumberMonthAndYearFromDate, limitString } from 'components/utils/misc'
import { ClientBookingRedDot } from '../pages/Clients'
import { formatRewardStatusPill, formatSaleStatusPill } from 'components/uicomponents/salesComponents/StatusUtils'
import { Menu, Transition } from '@headlessui/react'
import BookingDetailsModal from 'components/uicomponents/BookingDetails'
import ClientPackageDetails from 'components/uicomponents/ClientPackageDetails'
import { ACTVITY_LOYALTY_TABLE_HEADERS, MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS } from 'components/uicomponents/customerDasboard/constants'
import { getClientVoucherFromTable, getClientAppointmentFromTable, getClientSaleFromTable, getClientRewardFromTable } from 'components/uicomponents/utils'
import { COLORS } from 'constants/colors'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { Button, SvgArrowBack, SvgChevronLeft, Paragraph, NameAvatar, SvgCharmMenuKebab, ContactLink, SvgPassed, SvgNoShow, SvgCancelled, SvgCartBag, SvgShopping, FormatNumber, SvgPercentFrame, SvgFileTextFrame, SvgPlus, SvgRoundEye, Heading, Table, Tabs, Modal, SvgUnusedVouchers, SvgVoucherUsed, SvgExpiredVouchers, Pill, SvgCreditCard, FormLabel, FormHelperText, FormTextarea, MultiSelectOption, ToggleOnly, SvgArrowRight, SvgChevronRight, SearchTerm, SvgFilter, Checkbox, SvgEmptyPreferences, SvgBookingHistory } from 'ui'
import ClientCommunicationLog from '../components/CoummunicationLogs'
import ClientRewardDetails from '../components/ClientRewardDetails'
import ViewNotesModal from '../components/ViewNotesModal'
import AddNoteModal from './AddNoteModal'
import RecordRefundModal from './RecordRefundModal'
import AddClientModal from './AddClientModal'
import { BOOKING_HISTORY_HEADERS, BOOKING_HISTORY_MOBILE_HEADERS, CLIENT_PACKAGES_HISTORY_HEADERS, CLIENT_PACKAGES_HISTORY_MOBILE_HEADERS, CLIENT_TAB_NAME, CLIENT_TABS, MOBILE_PREFERENCES_HEADINGS, MOBILE_WALLET_HISTORY_HEADERS, MODAL_VIEW, PREFERENCES_HEADINGS, REWARDS_HISTORY_HEADERS, REWARDS_HISTORY_MOBILE_HEADERS, SALES_HISTORY_HEADERS, WALLET_HISTORY_HEADERS } from '../constants'
import BlacklistOrUnblacklistClientModal from './BlacklistOrUnblacklistClientModal'
import { Controller, useForm } from 'react-hook-form'
import { MAXIMUM_NOTE_LENGTH } from 'constants/form'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form'
import ClientPreferenceModal from './ClientPreferenceModal'
import DeleteClientDialog from './DeleteClientDialog'
import Label from 'ui/atoms/formLabel/Label'
import { truncate } from 'node:fs/promises'
import UpdateNotificationSettings from './UpdateNotificationSettings'
import ViewTransactionModal from './ViewTransactionModal'
import DeleteClientKeyPreference from './DeleteKeyPreferenceModal'

const ViewClient = ({
  isVisible,
  closeModal,
  client: c,
  setClient,
  actions,
  addToast
}: AddClientModalProps<
  | 'addClient'
  | 'seeInfo'
  | 'viewClient'
  | 'deleteClient'
  | 'editClient'
  | 'recordRefund'
  | 'viewHistory'
  | 'addNotes'
  | 'editNote'
  | 'viewNotes'
  | 'deleteNote'
>) => {
  const [activeTab, setActiveTab] = useState(CLIENT_TAB_NAME.OVERVIEW as string)
  const [voucher, setVoucher] = useState<PackageVoucher | null>(null)
  const [booking, setBooking] = useState<IAppointmentAndSaleProp | null>(null)
  const [reward, setReward] = useState<ClientMilestone>(null)
  const { getSalonData } = useSalonCache()
  const salon = getSalonData();
  const salonId = salon?.id;
  const businessCountry: Country = JSON.parse(localStorage.getItem('country'));
  const [selectedKeyPreference, setSelectedKeyPreference] = useState<ClientKeyPreference | null>(null)
  const [filteredKeyPreferences, setFilteredKeyPreferences] = useState<ClientKeyPreference[] | null>(null)
  const [filteredWalletTransactions, setFilteredWalletTransactions] = useState<ClientWalletTransaction[] | null>(null)
  const [currentWalletTransaction, setCurrentWalletTransaction] = useState<ClientWalletTransaction | null>(null)
  const [searchKeyPreference, setSearchKeyPreference] = useState<string>('');
  const [searchWalletTransactionPreference, setSearchWalletTransactionPreference] = useState('')
  const {
    data: clientData,
    loading: clientLoading,
    refetch
  } = useGetClient({
    clientId: c?.id,
    salonId
  })

  const {
    createClient
  } = useAddClient()

  const {
    control,
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
  } = useForm<CreateKeyPreferenceInput>();

  useEffect(() => {
    if (c?.id) {
      refetch()
    }
  }, [c?.id, refetch])
  const [topUpWalletAmount, setTopUpWalletAmount] = useState<string | null>(null)
  const [topUpWalletDescription, setTopUpWalletDescription] = useState<string | null>(null)
  const [emailOrSmsNotificationSettingsType, setEmailOrSmsNotificationSettingsType] = useState<string | null>(null)
  const [modalView, setModalView] = useState(MODAL_VIEW?.client)
  const client = useMemo(() => clientData?.salonClient, [clientData])
  const clientListPreferences = useMemo(() => {
    setFilteredKeyPreferences(client?.keyPreferences)
    setFilteredWalletTransactions(client?.clientWalletTransactions)
    // show highlighted ones first then the rest show come later
    const highlightedPreferences = Array?.isArray(client?.keyPreferences) ? client?.keyPreferences?.filter((preference) => preference?.highlighted) : [];
    const nonHighlightedPreferences = Array?.isArray(client?.keyPreferences) ? client?.keyPreferences?.filter((preference) => !preference?.highlighted) : [];
    return [...highlightedPreferences, ...nonHighlightedPreferences];
  }, [client])
  const {
    openModal: openEditClientModal,
    closeModal: closeEditClientModal,
    isVisible: isEditClientModalVisible
  } = useModal()

  const {
    openModal: openAddNoteModal,
    closeModal: closeAddNoteModal,
    isVisible: isNoteModalVisible
  } = useModal()

  const {
    isVisible: notesModalIsVisible,
    openModal: openNotesModal,
    closeModal: closeNotesModal
  } = useModal()

  const {
    isVisible: clientPackageDetailsModalIsVisible,
    openModal: openClientPackageDetailsModal,
    closeModal: closeClientPackageDetailsModal
  } = useModal()

  const {
    isVisible: isRecordRefundModalVisible,
    openModal: openRecordRefundModal,
    closeModal: closeRecordRefundModal
  } = useModal()

  const {
    isVisible: bookingDetailsModalIsVisible,
    openModal: openBookingDetailsModal,
    closeModal: closeBookingDetailsModal
  } = useModal()

  const {
    isVisible: blacklistClientModalIsVisible,
    openModal: openBlacklistClientModal,
    closeModal: closeBlacklistClientModal
  } = useModal()

  const {
    isVisible: clientRewardDetailsModalIsVisible,
    openModal: openClientRewardDetailsModal,
    closeModal: closeClientRewardDetailsModal
  } = useModal()

  const {
    isVisible: clientKeyPreferenceModalIsVisible,
    openModal: openClientKeyPreferenceModal,
    closeModal: closeClientKeyPreferenceModal
  } = useModal()

  const {
    isVisible: isNotificationModalVisible,
    openModal: openNotificationModal,
    closeModal: closeNotificationModal
  } = useModal()

  const initiateCloseAddNotwModal = (action?: 'added') => {
    closeAddNoteModal()
    refetch()
    if (action === 'added') {
      openNotesModal()
    }
  }

  const _closeModal = () => {
    closeModal()
    setClient && setClient(null)
  }

  const initiateCloseRewardModal = () => {
    closeClientRewardDetailsModal()
    setReward(null)
  }

  const initiateCloseBlacklistClient = () => {
    closeBlacklistClientModal()
    refetch()
  }

  const generateBookingsTableData = (appointments: Appointment[]) => {
    return appointments
      ?.filter((appointment) => appointment?.isActive)
      .map((appointment) => {
        const serviceList = limitString(
          appointment.services.map((x) => x.name).join(', '),
          15
        )
        const paid = appointment?.paymentMethod !== 'unpaid'
        return {
          service: ClientBookingRedDot(
            serviceList,
            paid,
            appointment?.appointmentMilestone
          ),
          date: getNumberMonthAndYearFromDate(appointment?.startAt),
          paymentMethod: formatSnakeCaseText(appointment?.paymentMethod),
          amount: formatInToPrice(appointment?.totalPrice),
          status: formatSnakeCaseText(appointment?.appointmentStatus),
          points: appointment?.totalPoints
        }
      })
  }

  const generatePackagesTableDataForClient = (
    vouchers: PackageVoucher[],
    countryCode?: 'NG'
  ) => {
    return vouchers?.map?.((voucher) => {
      return {
        name: voucher?.package?.name,
        purchasedAt: getNumberMonthAndYearFromDate(
          voucher?.createdAt as string
        ),
        validityMonths: getNumberMonthAndYearFromDate(
          voucher?.expiresAt as string
        ),
        status: formatSnakeCaseText(voucher?.status),
        price: formatInToPrice(voucher.price),
        redeemed: formatInToPrice(voucher?.amountRedeemed)
      }
    })
  }

  const generateSalesTableData = (sales: Sale[]) => {
    return sales?.map?.((sale) => {
      return {
        service: limitString(sale.services.map((x) => x.name).join(', '), 15),
        date: getNumberMonthAndYearFromDate(sale?.startAt),
        paymentMethod: formatSnakeCaseText(sale?.paymentMethod),
        discount: formatInToPrice(sale?.discountAmount),
        amount: formatInToPrice(sale?.totalPrice),
        points: sale?.totalPoints,
        status: formatSaleStatusPill(sale?.saleStatus)
      }
    })
  }

  const generateWalletTransactions = (walletTransactions: ClientWalletTransaction[]) => {
    return walletTransactions?.map?.((walletTransaction) => {
      return {
        date: getNumberMonthAndYearFromDate(walletTransaction?.createdAt),
        transactionType: walletTransaction?.transactionType,
        amount: () => {
          if (walletTransaction?.transactionType === 'debit') {
            return (
              <span className='text-red-500 text-b4'>
                -{formatInToPrice(walletTransaction?.amount)}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              +{formatInToPrice(walletTransaction?.amount)}
            </span>
          )
        },
      }
    })
  }
  const walletTransactions = generateWalletTransactions(filteredWalletTransactions)
  const appointments = generateBookingsTableData(client?.appointments)
  const packageVouchers = generatePackagesTableDataForClient(
    client?.packageVouchers
  )
  const sales = generateSalesTableData(client?.sales)

  const generateRewardsTableData = (clientMiles: ClientMilestone[]) => {
    return clientMiles?.map?.((clientMile) => {
      return {
        date: getNumberMonthAndYearFromDate(clientMile?.milestone?.createdAt),
        points:
          clientMile?.milestone?.customerReceivesType === 'percentage'
            ? `${clientMile?.milestone?.customerReceives}%`
            : formatInToPrice(clientMile?.milestone?.customerReceives),
        status: formatRewardStatusPill(clientMile?.status)
      }
    })
  }

  const rewards = generateRewardsTableData(client?.clientMilestones)

  const openSalonPackage = (id: number) => {
    const voucher = getClientVoucherFromTable(client?.packageVouchers || [], id)

    if (!voucher) {
      return
    }
    setVoucher(voucher)
    openClientPackageDetailsModal()
  }

  const openBooking = (id: number) => {
    const booking = getClientAppointmentFromTable(
      client?.appointments || [],
      id
    )

    if (!booking) {
      return
    }
    setBooking({
      id: booking.id,
      services: booking.services,
      type: 'appointment',
      startAt: booking.startAt,
      endAt: booking.endAt,
      paymentMethod: booking.paymentMethod,
      amount: booking.totalPrice,
      points: booking.totalPoints,
      paymentReference: '-',
      status: booking.appointmentStatus,
      note: booking.clientNote?.note,
      packageName: booking?.appointmentVoucher?.packageVoucher?.package?.name,
      appointmentMilestone: booking.appointmentMilestone
    })
    openBookingDetailsModal()
  }

  const openSale = (id: number) => {
    const booking = getClientSaleFromTable(client?.sales || [], id)

    if (!booking) {
      return
    }
    setBooking({
      id: booking.id,
      services: booking.services,
      type: 'sale',
      startAt: booking.startAt,
      endAt: booking.endAt,
      paymentMethod: booking.paymentMethod,
      amount: booking.totalPrice,
      points: booking.totalPoints,
      paymentReference: '-',
      status: 'completed',
      note: booking.note
    })
    openBookingDetailsModal()
  }

  const openReward = (id: number) => {
    const reward = getClientRewardFromTable(client?.clientMilestones || [], id)

    if (!reward) {
      return
    }
    setReward(reward)
    openClientRewardDetailsModal()
  }

  const generateActivityLoyaltyTableData = (
    activities: ClientRewardHistory[],
    countryCode?: string
  ) => {
    return activities?.map?.((activity) => {
      return {
        date: getNumberMonthAndYearFromDate(
          activity?.transactionDate,
        ),
        activityDone: activity?.description,
        pointsEarned: () => {
          if (activity?.pointsEarned.toString().includes('-')) {
            return (
              <span className='text-red-500 text-b4'>
                {activity?.pointsEarned}
              </span>
            )
          }
          return (
            <span className='text-green-500 text-b4'>
              +{activity?.pointsEarned}
            </span>
          )
        },
        pointsBalance: activity?.cumulativePointBalance
      }
    })
  }

  const activityLoyaltyTableData = generateActivityLoyaltyTableData(
    client?.clientRewardHistories || [],
    'NG'
  )

  const generateClientPreferenceTableData = (listPreferences: ClientKeyPreference[]) => {
    return Array?.isArray(listPreferences) ? listPreferences?.map?.((preference) => {
      return {
        preference: () => {
          return (
            <div className='flex items-center gap-2'>
              <img
                src={preference?.keyPreferenceCategory?.iconSvg}
                alt="key icon"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
              <span className='text-b4 text-grey-300'>
                {preference?.keyPreferenceCategory?.label}
              </span>
            </div>
          )
        },
        details: preference?.details,
        date: getDayMonthAndNumberAndTimeFromDate(preference?.createdAt),
      }
    }) : []
  }

  const clientPreferenceTableData = generateClientPreferenceTableData(filteredKeyPreferences || [])


  const initiateCloseEditModal = () => {
    refetch()
    closeEditClientModal()
  }

  const {
    openModal: openDeleteClientModal,
    closeModal: closeDeleteClientModal,
    isVisible: isDeleteClientModalVisible
  } = useModal()

  const {
    openModal: openViewWalletTransactionModal,
    closeModal: closeViewWalletTransactionModal,
    isVisible: isViewWalletTransactionModalVisible
  } = useModal()

  const {
    loading: clientWalletLoading,
    error: clientWalletError,
    creditClientWallet
  } = useCreditClientWallet()

  const {
    loading: addClientKeyPreferenceLoading,
    createKeyPreference
  } = useCreateClientKeyPreferences()

  const {
    loading: updateClientKeyPreferenceLoading,
    updateKeyPreference
  } = useUpdateClientKeyPreference()

  const {
    loading: deleteClientKeyPreferenceLoading,
    deleteKeyPreference
  } = useDeleteClientKeyPreference()

  const topUpWalletAsync = () => {
    if (!topUpWalletAmount) {
      addToast({
        message: 'Please enter an amount',
        variant: 'error',
      })
      return
    }

    const amount = Number(topUpWalletAmount?.replaceAll(",", ""));
    if (amount === 0) {
      addToast({
        message: 'Please enter a valid amount',
        variant: 'error',
      })
      return
    }
    creditClientWallet({
      variables: {
        input: {
          clientId: client?.id,
          amount,
          description: topUpWalletDescription
        }
      }
    }).then(({ data }) => {
      if (data?.creditClientWallet?.status === 200) {
        addToast({
          message: 'Wallet topped up successfully',
          variant: 'success',
        })
        processCloseModal()
        refetch()
      }

      if (data?.creditClientWallet?.errors?.length) {
        addToast({
          message: data?.creditClientWallet?.errors[0]?.message,
          variant: 'error',
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error',
      })
    })
  }

  const addClientKeyPreference = (input: CreateKeyPreferenceInput) => {
    const keyValue = input?.keyPreferenceCategoryId as unknown as MultiSelectOption

    if (selectedKeyPreference) {
      updateKeyPreference({
        variables: {
          input: {
            clientId: client?.id,
            keyPreferenceCategoryId: keyValue?.value,
            details: input?.details,
            highlighted: input?.highlighted,
            keyPreferenceId: selectedKeyPreference?.id
          }
        }
      }).then(({ data }) => {
        if (data?.updateKeyPreference?.status === 200) {
          addToast({
            message: 'Key preference updated successfully',
            variant: 'success',
          })
          processCloseModal()
          refetch()
        }

        if (data?.updateKeyPreference?.errors?.length) {
          addToast({
            message: data?.updateKeyPreference?.errors[0]?.message,
            variant: 'error',
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error',
        })
      })
    } else {
      createKeyPreference({
        variables: {
          input: {
            clientId: client?.id,
            keyPreferenceCategoryId: keyValue?.value,
            details: input?.details,
            highlighted: input?.highlighted
          }
        }
      }).then(({ data }) => {
        if (data?.createKeyPreference?.status === 200) {
          addToast({
            message: 'Key preference added successfully',
            variant: 'success',
          })
          processCloseModal()
          refetch()
        }

        if (data?.createKeyPreference?.errors?.length) {
          addToast({
            message: data?.createKeyPreference?.errors[0]?.message,
            variant: 'error',
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error',
        })
      })
    }
  }


  const {
    isVisible: isDeleteClientPreferenceModalIsVisible,
    openModal: openDeleteClientPreferenceModal,
    closeModal: closeDeleteClientPreferenceModal
  } = useModal()

  const deleteKeyPreferenceAsyc = () => {
    deleteKeyPreference({
      variables: {
        input: {
          keyPreferenceId: selectedKeyPreference?.id
        }
      }
    }).then(({ data }) => {
      const { deleteKeyPreference } = data;

      if (deleteKeyPreference?.status === 200) {
        addToast({
          message: 'Key preference deleted successfully',
          variant: 'success'
        })
        closeDeleteClientPreferenceModal()
        refetch()
      }
    }).catch((error) => {
      addToast({
        message: error.message,
        variant: 'error'
      })
    })
  }

  const _closeModalKeyPreferenceDelete = (action?: string) => {
    if (action === 'delete') {
      deleteKeyPreferenceAsyc()
    }
    closeDeleteClientPreferenceModal()
  }

  const processCloseModal = () => {
    if (modalView === MODAL_VIEW?.topUpWallet) {
      setTopUpWalletAmount(null)
      setTopUpWalletDescription(null)
      setModalView(MODAL_VIEW?.client)
      return;
    }

    if (modalView === MODAL_VIEW?.addPreferences) {
      setSelectedKeyPreference(null)
      reset({
        keyPreferenceCategoryId: null,
        details: null,
        highlighted: false
      })
      setModalView(MODAL_VIEW?.client)
      return;
    }

    setActiveTab(CLIENT_TAB_NAME.OVERVIEW)
    _closeModal()
  }

  const openClientPreference = (preference: ClientKeyPreference) => {
    setSelectedKeyPreference(preference)
    openClientKeyPreferenceModal()
  }

  const {
    data: keyPreferenceCategories,
    refetch: refetchPreferences,
  } = useGetPreferencesCategories()
  useEffect(() => {
    if (c?.id) {
      refetchPreferences()
    }
  }, [c?.id, refetchPreferences])

  const keyOptions = useMemo(() => {
    return keyPreferenceCategories?.keyPreferenceCategories?.map?.((category) => {
      return {
        label: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={category?.iconSvg}
              alt=""
              style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
            <span>{category?.label}</span>
          </div>
        ),
        value: category?.id
      }
    }) as unknown as MultiSelectOption[]
  }, [keyPreferenceCategories])

  const openClientPreferenceFromTable = (index: number, action?: string) => {
    const preference = filteredKeyPreferences?.[index]
    if (!preference) {
      return
    }
    setSelectedKeyPreference(preference)
    if (action === 'edit') {
      setModalView(MODAL_VIEW?.addPreferences)
      return
    }

    if (action === 'delete') {
      openDeleteClientPreferenceModal()
      return
    }

    openClientKeyPreferenceModal()
  }

  useEffect(() => {
    if (modalView === MODAL_VIEW?.addPreferences) {
      console.log({
        selectedKeyPreference
      })
      const keyValue = Array?.isArray(keyOptions) && keyOptions?.length ? keyOptions?.find((option) => {
        return option?.value === selectedKeyPreference?.keyPreferenceCategory?.id
      }) : [] as []
      // @ts-expect-error - just ignore it
      setValue('keyPreferenceCategoryId', keyValue)
      setValue('details', selectedKeyPreference?.details)
      setValue('highlighted', selectedKeyPreference?.highlighted)
    }
  }, [modalView, selectedKeyPreference, setValue])

  const initiateCloseKeyPreferenceModal = () => {
    closeClientKeyPreferenceModal()
    setSelectedKeyPreference(null)
    refetch()
  }

  const changeEmailOrSmsSettings = () => {
    const type = emailOrSmsNotificationSettingsType
    if (!type) return;
    const payload = {
      firstName: client?.firstName,
      lastName: client?.lastName || "",
      dob: client?.dob,
      phone: client?.phone,
      email: client?.email,
      salonId,
      altCallingCode: client?.altCallingCode,
      callingCode: client?.callingCode,
      countryCode: client?.countryCode,
      altCountryCode: client?.altCountryCode,
      optOutEmail: type === 'email' ? !client?.optOutEmail : client?.optOutEmail || false,
      optOutSms: type === 'sms' ? !client?.optOutSms : client?.optOutSms || false,
      id: client.id
    }

    createClient({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { createClient: cc } = data
      if (cc.status === 200 || cc.status === 201) {
        closeNotificationModal()
        refetch()
      } else {
        addToast({ message: cc.errors[0].message, variant: 'error' })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  }

  const closeDeleteClientModalAsync = (action?: string) => {
    closeDeleteClientModal()
    if (action === 'deleted') {
      _closeModal()
    }
  }

  const closeWalletTransactionModal = () => {
    setCurrentWalletTransaction(null)
    closeViewWalletTransactionModal()
  }

  useEffect(() => {
    if (searchKeyPreference && searchKeyPreference !== '') {
      const filtered = client?.keyPreferences?.filter?.((preference) => {
        return preference?.keyPreferenceCategory?.label?.toLowerCase?.()?.includes?.(searchKeyPreference?.toLowerCase?.())
      })
      setFilteredKeyPreferences(filtered)
    } else {
      setFilteredKeyPreferences(client?.keyPreferences)
    }
  }, [searchKeyPreference])

  useEffect(() => {
    if (searchWalletTransactionPreference && searchWalletTransactionPreference !== '') {
      // check if searchWalletTransactionPreference is like transactionType or amount
      const filtered = client?.clientWalletTransactions?.filter?.((transaction) => {
        return transaction?.transactionType?.toLowerCase?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.()) || transaction?.amount?.toString?.()?.includes?.(searchWalletTransactionPreference?.toLowerCase?.())
      })

      setFilteredWalletTransactions(filtered)
    } else {
      setFilteredWalletTransactions(client?.clientWalletTransactions)
    }
  }, [searchWalletTransactionPreference])

  const [keyPreferencesFilter, setKeyPreferencesFilter] = useState(Array?.isArray(keyOptions) && keyOptions?.length ? keyOptions?.map((option) => {
    return {
      label: option?.label,
      value: option?.value,
      show: true
    }
  }) : [])

  const handleKeyPreferenceCheckboxChange = (value: string) => {
    // modify keyPreferencesFilter show
    const index = keyPreferencesFilter?.findIndex?.((filter) => filter?.value === value)
    if (index === -1) return
    const newKeyPreferencesFilter = [...keyPreferencesFilter];
    newKeyPreferencesFilter[index].show = !newKeyPreferencesFilter[index].show;
    setKeyPreferencesFilter(newKeyPreferencesFilter)
  }

  useEffect(() => {
    if (Array?.isArray(keyPreferencesFilter) && keyPreferencesFilter?.length) {
      const filtered = keyPreferencesFilter?.filter?.((filter) => filter?.show)?.map?.((filter) => filter?.value)
      const filteredPreferences = client?.keyPreferences?.filter?.((preference) => {
        return filtered?.includes?.(preference?.keyPreferenceCategory?.id)
      })
      setFilteredKeyPreferences(filteredPreferences)
    }
  }, [keyPreferencesFilter])

  const [walletTransactionsFilter, setWalletTransactionsFilter] = useState([
    {
      label: 'Credit',
      value: 'credit',
      show: true
    },
    {
      label: 'Debit',
      value: 'debit',
      show: true
    }
  ])

  const handleKeyWalletTransactionCheckboxChange = (value: string) => {
    // modify keyPreferencesFilter show
    const index = walletTransactionsFilter?.findIndex?.((filter) => filter?.value === value)
    if (index === -1) return
    const newWalletTransactionsFilter = [...walletTransactionsFilter];
    newWalletTransactionsFilter[index].show = !newWalletTransactionsFilter[index].show;
    setWalletTransactionsFilter(newWalletTransactionsFilter)
  }

  useEffect(() => {
    if (Array?.isArray(walletTransactionsFilter) && walletTransactionsFilter?.length) {
      const filtered = walletTransactionsFilter?.filter?.((filter) => filter?.show)?.map?.((filter) => filter?.value)
      const filteredTransactions = client?.clientWalletTransactions?.filter?.((transaction) => {
        return filtered?.includes?.(transaction?.transactionType)
      })
      setFilteredWalletTransactions(filteredTransactions)
    }
  }, [walletTransactionsFilter])

  const closeNotificationModalAsync = (action?: string) => {
    if (action === 'continue') {
      changeEmailOrSmsSettings()
      return;
    }
    closeNotificationModal()
  }

  const openWalletTransaction = (id?: number) => {
    if (!filteredWalletTransactions?.length) return;
    const walletTransaction = filteredWalletTransactions[id];
    if (!walletTransaction) return;

    setCurrentWalletTransaction(walletTransaction)
    openViewWalletTransactionModal()
  }

  return (
    <>
      <Modal show={isVisible} closeModal={processCloseModal} variant='right'>
        <div className='relative'>
          <div className='fixed bg-white w-full flex border-b border-grey-100 cursor-pointer'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={processCloseModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={processCloseModal}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                {client?.firstName} {client?.lastName}
              </Paragraph>
            </div>
          </div>

          {modalView === MODAL_VIEW?.client ?
            <>
              <div
                style={{ marginTop: '70px' }}
                className='w-full max-w-5xl flex flex-col space-y-6 h-fit py-6'
              >
                {client ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                      className='flex flex-col xl:flex-row xl:justify-between xl:items-center px-6 space-x-4'
                    >
                      <div className='flex space-x-4 items-center'>
                        <NameAvatar
                          name={
                            client?.firstName?.charAt(0) +
                            client?.lastName?.charAt(0)
                          }
                          size='lg'
                        />
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b2' weight='semiBold'>
                            {client?.firstName} {client?.lastName}
                          </Paragraph>
                          <Paragraph size='b4' weight='normal'>
                            {client?.email}
                          </Paragraph>
                        </div>
                        {client?.blacklisted ? <Pill variant="danger">Blacklisted</Pill> : null}
                      </div>
                      <div className='flex flex-row space-x-2'>
                        <Menu as='div' className='relative inline-block text-left'>
                          <div>
                            <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md border border-grey-50 bg-grey-10 px-3 py-2.5 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0'>
                              <SvgCharmMenuKebab width='24px' height='24px' />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                          >
                            <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                              <div className='flex flex-col p-4 space-y-4'>
                                {actions?.editClient ? (
                                  <Menu.Item>
                                    <span
                                      className='text-b4 cursor-pointer flex items-center space-x-2'
                                      onClick={openEditClientModal}
                                    >
                                      Edit
                                    </span>
                                  </Menu.Item>
                                ) : null}
                                {actions?.recordRefund ? (
                                  <Menu.Item>
                                    <span
                                      className='text-b4 cursor-pointer flex items-center space-x-2'
                                      onClick={openRecordRefundModal}
                                    >
                                      Record refund
                                    </span>
                                  </Menu.Item>
                                ) : null}{actions?.editClient ? (
                                  <Menu.Item>
                                    <span
                                      className='text-b4 cursor-pointer flex items-center space-x-2'
                                      onClick={openBlacklistClientModal}
                                    >
                                      {client?.blacklisted ? 'Reinstate' : 'Blacklist'}
                                    </span>
                                  </Menu.Item>
                                ) : null}
                                {actions?.deleteClient ? (
                                  <Menu.Item>
                                    <span
                                      className='text-b4 cursor-pointer flex items-center space-x-2'
                                      onClick={openDeleteClientModal}
                                    >
                                      Delete
                                    </span>
                                  </Menu.Item>
                                ) : null}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className='border-t border-b border-grey-100 pt-1'>
                  <Tabs
                    tabs={CLIENT_TABS}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    variant='underline'
                  />
                </div>
                {activeTab === CLIENT_TAB_NAME.OVERVIEW ?
                  <div className='flex flex-col gap-6 px-6'>
                    <div className='flex flex-col justify-between border border-grey-100 rounded-md p-4 space-y-4'>
                      <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                        Personal information
                      </Paragraph>
                      <div className='grid grid-cols-1 lg:grid-cols-2 justify-between gap-4'>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            First name
                          </Paragraph>
                          <Paragraph size='b4' weight='normal'>
                            {client?.firstName}
                          </Paragraph>
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Last name
                          </Paragraph>
                          <Paragraph size='b4' weight='normal'>
                            {client?.lastName}
                          </Paragraph>
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Phone number
                          </Paragraph>
                          {client?.phone && actions?.seeInfo ? (
                            <ContactLink
                              variant='tel'
                              value={`${client?.callingCode}${client?.phone}`}
                              label={`${client?.callingCode}${client?.phone}`}
                            />
                          ) : (
                            <Paragraph size='b4' weight='normal'>
                              -
                            </Paragraph>
                          )}
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Secondary phone number
                          </Paragraph>
                          {client?.altPhone && actions?.seeInfo ? (
                            <ContactLink
                              variant='tel'
                              value={`${client?.altCallingCode}${client?.altPhone}`}
                              label={`${client?.altCallingCode}${client?.altPhone}`}
                            />
                          ) : (
                            <Paragraph size='b4' weight='normal'>
                              -
                            </Paragraph>
                          )}
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Email Address
                          </Paragraph>
                          {client?.email && actions?.seeInfo ? (
                            <ContactLink
                              variant='mailto'
                              value={client?.email}
                              label={client?.email}
                            />
                          ) : (
                            <Paragraph size='b4' weight='normal'>
                              -
                            </Paragraph>
                          )}
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Birthday
                          </Paragraph>
                          {actions?.seeInfo ? <Paragraph size='b5' weight='normal'>
                            {client?.dob
                              ? getNumberMonthAndYearFromDate(client?.dob)
                              : '-'}
                          </Paragraph> : "-"}
                        </div>
                        <div className='flex items-center justify-between p-2 bg-grey-100/10 col-span-2'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Home Address
                          </Paragraph>
                          <Paragraph size='b4' weight='normal'>
                            {client?.address || '-'}
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col items-center justify-between border border-grey-100 rounded-md p-4 space-y-4'>
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          weight='bold'
                          className=''
                        >
                          Key preferences
                        </Paragraph>
                        {clientListPreferences?.length ?
                          <div className='flex items-center space-x-2'>
                            {actions?.editClient ? <Button
                              variant='light'
                              size='xs'
                              type='button'
                              rounded='lg'
                              className='border border-grey-100'
                              fontSize='b6'
                              onClick={() => setModalView(MODAL_VIEW?.addPreferences)}
                            >
                              <SvgPlus width='14px' height='14px' />
                              Add new
                            </Button> : null}
                            <Button
                              variant='light'
                              size='xs'
                              type='button'
                              className='border border-grey-100'
                              rounded='lg'
                              fontSize='b6'
                              onClick={() => setActiveTab(CLIENT_TAB_NAME?.PREFERENCES)}
                            >
                              <SvgRoundEye width='14px' height='14px' />
                              View all
                            </Button> </div> : null}
                      </div>
                      <div className='w-full flex flex-wrap gap-3'>
                        {Array?.isArray(client?.keyPreferences) && client?.keyPreferences.slice(0, 3)?.map((keyPreference) => (
                          <div key={keyPreference?.id} className='border border-grey-100 cursor-pointer rounded-md flex space-x-2 min-w-[210px] p-2' onClick={() => openClientPreference(keyPreference)}>
                            <img
                              src={keyPreference?.keyPreferenceCategory?.iconSvg}
                              alt="key icon"
                              style={{ width: '24px', height: '24px', marginRight: '8px' }}
                            />
                            <div className='flex flex-col space-y-2 w-full'>
                              <div className='flex items-center justify-between'>
                                <Paragraph
                                  size='b5'
                                  color={COLORS.GREY[400]}
                                  weight='semiBold'
                                  className=''
                                >
                                  {keyPreference?.keyPreferenceCategory?.label}
                                </Paragraph>

                                <SvgChevronRight width="20px" height="20px" />
                              </div>
                              <Paragraph
                                size='b5'
                                color={COLORS.GREY[900]}
                                weight='normal'
                                className=''
                              >
                                {limitString(keyPreference?.details, 10, true)}
                              </Paragraph>
                            </div>
                          </div>
                        ))}
                      </div>
                      {!client?.keyPreferences || Array?.isArray(client?.keyPreferences) && !client?.keyPreferences?.length ?
                        <div className='w-full py-3 flex flex-col justify-center items-center space-y-3'>
                          <SvgEmptyPreferences width="100px" height="100%" />
                          <Heading variant='h2' size='h9'>
                            Preferences
                          </Heading>
                          <p className='mt-4'>This client has no preferences added yet</p>
                          {actions?.editClient ? <Button
                            variant="primary"
                            size='xs'
                            rounded='lg'
                            fontWeight='bold'
                            fontSize='b4'
                            className='px-4 py-2'
                            onClick={() => setModalView(MODAL_VIEW?.addPreferences)}
                          >
                            Add new
                          </Button> : null}
                        </div> : null}
                    </div>
                    <div className='flex flex-col items-center justify-between border border-grey-100 rounded-md p-4 space-y-4'>
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          weight='bold'
                          className=''
                        >
                          Communication
                        </Paragraph>
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph
                          size='b5'
                          color={COLORS.GREY[400]}
                          weight='semiBold'
                          className=''
                        >
                          Receives sms reminders
                        </Paragraph>
                        {actions?.editClient ? <span role="button" onClick={() => {
                          setEmailOrSmsNotificationSettingsType('sms')
                          openNotificationModal()
                        }}>
                          <ToggleOnly isChecked={!client?.optOutSms} />
                        </span> : <span>
                          <ToggleOnly isChecked={!client?.optOutSms} />
                        </span>}
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph
                          size='b5'
                          color={COLORS.GREY[400]}
                          className=''
                        >
                          Receives emails
                        </Paragraph>
                        {actions?.editClient ? <span role="button" onClick={() => {
                          setEmailOrSmsNotificationSettingsType('email')
                          openNotificationModal()
                        }}>
                          <ToggleOnly isChecked={!client?.optOutEmail} />
                        </span> : <span role="button">
                          <ToggleOnly isChecked={!client?.optOutEmail} />
                        </span>}
                      </div>
                    </div>
                    <div className='flex flex-col justify-between border border-grey-100 rounded-md p-4 space-y-4'>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[300]}
                        weight='bold'
                        className=''
                      >
                        Appointment statistics
                      </Paragraph>
                      <div className='flex flex-col xl:flex-row gap-4'>
                        <div className='flex-1 flex space-x-2'>
                          <SvgPassed width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              Completed appointments
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.completedAppointments}
                            </Paragraph>
                          </div>
                        </div>
                        <div className='flex-1 flex space-x-2'>
                          <SvgNoShow width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              No-show appointments
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.noShowAppointments}
                            </Paragraph>
                          </div>
                        </div>
                        <div className='flex-1 flex space-x-2'>
                          <SvgCancelled width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              Cancelled appointments
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.cancelledAppointments}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-between border border-grey-100 rounded-md p-4 space-y-4'>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[300]}
                        weight='bold'
                        className=''
                      >
                        Voucher statistics
                      </Paragraph>
                      <div className='flex flex-col xl:flex-row gap-4'>
                        <div className='flex-1 flex space-x-2'>
                          <SvgVoucherUsed width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              Total used vouchers
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.packageVouchers?.filter(
                                (voucher) => voucher?.status === 'redeemed'
                              )?.length || 0}
                            </Paragraph>
                          </div>
                        </div>
                        <div className='flex-1 flex space-x-2'>
                          <SvgUnusedVouchers width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              Unused vouchers
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.packageVouchers?.filter(
                                (voucher) => voucher?.status === 'active'
                              )?.length || 0}
                            </Paragraph>
                          </div>
                        </div>
                        <div className='flex-1 flex space-x-2'>
                          <SvgExpiredVouchers width='24px' height='24px' />
                          <div className='flex flex-col space-y-2'>
                            <Paragraph
                              size='b5'
                              weight='normal'
                              color={COLORS.GREY[400]}
                            >
                              Expired vouchers
                            </Paragraph>
                            <Paragraph size='b4' weight='normal'>
                              {client?.packageVouchers?.filter(
                                (voucher) => voucher?.status === 'expired'
                              )?.length || 0}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col gap-4 justify-between xl:flex-row'>
                      <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                        <SvgShopping width='40px' height='40px' />
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Total transactions
                          </Paragraph>
                          <Paragraph size='b5' weight='bold'>
                            {DEFAULT_CURRENCY}
                            <FormatNumber value={client?.totalTransactions || '0'} />
                          </Paragraph>
                        </div>
                      </div>
                      <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                        <SvgPercentFrame width='40px' height='40px' />
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Total points
                          </Paragraph>
                          <Paragraph size='b5' weight='bold'>
                            <FormatNumber value={client?.pointsEarned || '0'} />
                          </Paragraph>
                        </div>
                      </div>
                      <div className='w-full xl:max-w-[300px] flex flex-col border border-grey-100 rounded-md p-6 space-y-6'>
                        <SvgFileTextFrame width='40px' height='40px' />
                        <div className='flex flex-col space-y-2'>
                          <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]}>
                            Notes
                          </Paragraph>
                          <div className='flex space-x-4'>
                            {actions?.addNotes ? (
                              <Button
                                variant='light'
                                size='xs'
                                type='button'
                                rounded='lg'
                                className='border border-grey-100'
                                fontSize='b7'
                                onClick={openAddNoteModal}
                              >
                                Add
                                <SvgPlus width='14px' height='14px' />
                              </Button>
                            ) : null}
                            {actions?.viewNotes ? (
                              <Button
                                variant='light'
                                size='xs'
                                type='button'
                                className='border border-grey-100'
                                rounded='lg'
                                fontSize='b7'
                                onClick={openNotesModal}
                              >
                                View <SvgRoundEye width='24px' height='24px' />
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : null}
              </div>
              {activeTab === CLIENT_TAB_NAME.WALLET ?
                <div className='w-full flex flex-col space-y-4'>
                  <div className='w-full flex flex-col space-y-4 px-8 py-3'>
                    <div className="text-white rounded-lg bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1730451853/h4wa7vegk2qijhvvptaz.png')] bg-cover bg-no-repeat w-full h-fit">
                      <div className="flex w-full px-4 py-6 justify-between items-center rounded-lg">
                        <div className='flex items-center space-x-3'>
                          <div className="text-white p-2 rounded-full bg-grey-400 w-fit h-fit">
                            <SvgCreditCard width="24px" height="24px" />
                          </div>
                          <div className="flex flex-col">
                            <Paragraph size="b4" weight="semiBold" color={COLORS.GREY[300]}>
                              Wallet balance
                            </Paragraph>
                            <Paragraph size="b4" weight="bold" color={COLORS.WHITE}>
                              {formatInToPrice(client?.walletBalance || 0)}
                            </Paragraph>
                          </div>
                        </div>

                        {actions?.editClient ? <Button
                          variant="light"
                          size="md"
                          rounded='md'
                          fontWeight='semiBold'
                          className='font-semibold'
                          onClick={() => setModalView(MODAL_VIEW?.topUpWallet)}
                        >
                          Top up
                        </Button> : null}
                      </div>
                    </div>
                  </div>
                  <div className='w-full py-4 px-6 bg-grey-50 border-grey-150/45 border-t border-b'>
                    <Heading size='b4' weight='semiBold' color={COLORS.GREY[900]} variant='h2'>
                      Transaction history
                    </Heading>
                  </div>
                  <div className='flex items-center px-4 space-x-3 w-1/2'>
                    <SearchTerm
                      setDebouncedSearchQuery={setSearchWalletTransactionPreference}
                      placeholder="Search..."
                      size="md"
                    />
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                          <SvgFilter width="20px" height="20px" />
                          <span className='hidden xl:inline'>Filter</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                          <div className="flex flex-col p-4 space-y-4">
                            {Array.isArray(walletTransactionsFilter) && walletTransactionsFilter.length && walletTransactionsFilter.map((heading, index) => (
                              <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleKeyWalletTransactionCheckboxChange(heading.value)}>
                                <Checkbox
                                  isChecked={heading.show}
                                  borderType='Black'
                                  size='Sm'
                                />
                                <span>{heading.label}</span>
                              </Label>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <Table
                    headers={WALLET_HISTORY_HEADERS}
                    mobileHeaders={MOBILE_WALLET_HISTORY_HEADERS}
                    rows={walletTransactions}
                    onClick={openWalletTransaction}
                    tableOptions={{
                      view: true,
                      edit: false,
                      delete: false,
                      duplicate: false
                    }}
                  />
                </div> : null}
              {actions?.viewHistory ? (
                <div className='w-full flex flex-col space-y-4 h-fit'>
                  {activeTab === CLIENT_TAB_NAME.BOOKING ? (
                    <>
                      {Array?.isArray(client?.appointments) && client?.appointments?.length ?
                        <Table
                          headers={BOOKING_HISTORY_HEADERS}
                          mobileHeaders={BOOKING_HISTORY_MOBILE_HEADERS}
                          rows={appointments}
                          onClick={openBooking}
                        /> : <div className='w-full flex items-center justify-center'>
                          <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
                            <SvgBookingHistory width="100%" height="100%" />
                            <Heading variant='h2' size='h7'>
                              Booking history
                            </Heading>
                            <p className='mt-4'>This client has no booking history yet</p>
                          </div></div>}
                    </>
                  ) : activeTab === CLIENT_TAB_NAME.PACKAGES ? (
                    <Table
                      headers={CLIENT_PACKAGES_HISTORY_HEADERS}
                      mobileHeaders={CLIENT_PACKAGES_HISTORY_MOBILE_HEADERS}
                      rows={packageVouchers}
                      onClick={openSalonPackage}
                    />
                  ) : activeTab === CLIENT_TAB_NAME.SALES ? (
                    <Table
                      headers={SALES_HISTORY_HEADERS}
                      mobileHeaders={BOOKING_HISTORY_MOBILE_HEADERS}
                      rows={sales}
                      onClick={openSale}
                    />
                  ) : activeTab === CLIENT_TAB_NAME.REWARDS ? (
                    <Table
                      headers={REWARDS_HISTORY_HEADERS}
                      mobileHeaders={REWARDS_HISTORY_MOBILE_HEADERS}
                      rows={rewards}
                      onClick={openReward}
                    />
                  ) : activeTab === CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY && actions.viewNotes ? (
                    <ClientCommunicationLog client={client} />
                  ) : activeTab === CLIENT_TAB_NAME.ACTIVITY ? (
                    <Table
                      headers={formatTableHeadersFilterArray(
                        ACTVITY_LOYALTY_TABLE_HEADERS
                      )}
                      mobileHeaders={MOBILE_ACTIVITY_LOYALTY_TABLE_HEADERS}
                      rows={activityLoyaltyTableData}
                      hasBorder={true}
                    />
                  ) : activeTab === CLIENT_TAB_NAME.PREFERENCES ? (
                    <div className='w-full flex flex-col space-y-2'>
                      <div className='w-full flex items-center justify-between px-4 pb-4'>
                        <div className='flex items-center space-x-3 w-1/2'>
                          <SearchTerm
                            setDebouncedSearchQuery={setSearchKeyPreference}
                            placeholder="Search..."
                            size='md'
                          />
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                                <SvgFilter width="20px" height="20px" />
                                <span className='hidden xl:inline'>Filter</span>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                                <div className="flex flex-col p-4 space-y-4">
                                  {Array.isArray(keyPreferencesFilter) && keyPreferencesFilter.length && keyPreferencesFilter.map((heading, index) => (
                                    <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleKeyPreferenceCheckboxChange(heading.value)}>
                                      <Checkbox
                                        isChecked={heading.show}
                                        borderType='Black'
                                        size='Sm'
                                      />
                                      <span>{heading.label}</span>
                                    </Label>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                        {actions?.editClient ? <Button
                          variant="primary"
                          size='xs'
                          rounded='lg'
                          fontWeight='extraBold'
                          fontSize='b4'
                          className='px-4 py-2'
                          onClick={() => setModalView(MODAL_VIEW?.addPreferences)}
                        >
                          Add new
                        </Button> : null}
                      </div>
                      {Array?.isArray(client?.keyPreferences) && client?.keyPreferences?.length ?
                        <Table
                          headers={PREFERENCES_HEADINGS}
                          mobileHeaders={MOBILE_PREFERENCES_HEADINGS}
                          rows={clientPreferenceTableData}
                          onClick={openClientPreferenceFromTable}
                          tableOptions={
                            {
                              view: true,
                              edit: true,
                              delete: true,
                              duplicate: false
                            }
                          }
                        /> :
                        <div className='w-full flex items-center justify-center border-t border-grey-150/45 py-6'>
                          <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
                            <SvgEmptyPreferences width="100%" height="100%" />
                            <Heading variant='h2' size='h7'>
                              Preferences
                            </Heading>
                            <p className='mt-4'>This client has no preferences added yet</p>
                            {actions?.editClient ? <Button
                              variant='primary'
                              size='sm'
                              disabled={false}
                              loading={false}
                              type='button'
                              rounded='lg'
                              fontSize='b5'
                              className='px-4 py-2'
                              onClick={() => setModalView(MODAL_VIEW?.addPreferences)}
                            >
                              Add new preference
                            </Button> : null}
                          </div></div>}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </> : null}
          {modalView === MODAL_VIEW?.topUpWallet ?
            <>
              <div className='w-full flex flex-col space-y-6 p-6 mt-[70px]'>
                <div className="w-full flex justify-between items-start py-4">
                  <div className="w-full xl:w-1/2 flex flex-col space-y-6">
                    <Heading variant="h1" size="h9" weight="semiBold">
                      Top up {client?.firstName}’s account
                    </Heading>
                    <Paragraph size="b4">
                      Enter amount to top up {client?.firstName}’s account
                    </Paragraph>
                  </div>
                </div>

                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='amount'>
                    Enter amount to top-up
                  </FormLabel>
                  <div className='w-full flex space-x-3 p-3 rounded-md border border-grey-100'>
                    <Paragraph size='b3' weight='bold'>
                      {businessCountry?.currency?.symbol}
                    </Paragraph>
                    <input
                      className="w-full background-none font-semibold text-b4 text-grey-300 focus:outline-none placeholder:text-grey-300"
                      type="text"
                      value={formatNumber(topUpWalletAmount)}
                      onChange={(e) => {
                        setTopUpWalletAmount(e.target.value);
                      }}
                      placeholder="20,000"
                    />
                  </div>
                </div>
                {/* description */}
                <div className='w-full flex flex-col space-y-2'>
                  <FormLabel htmlFor='description'>
                    Description
                  </FormLabel>
                  <textarea
                    className="w-full h-24 p-3 rounded-md border border-grey-100 focus:outline-none placeholder:text-grey-300"
                    value={topUpWalletDescription}
                    onChange={(e) => {
                      setTopUpWalletDescription(e.target.value);
                    }}
                    placeholder="Enter description"
                  />
                </div>
              </div>
              <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
                <div className="w-full flex justify-end py-4 px-8">
                  <div className="flex space-x-2">
                    <Button
                      variant="text"
                      className="text-red-500"
                      size="sm"
                      fontWeight="semiBold"
                      onClick={() => {
                        setTopUpWalletAmount(null)
                        setTopUpWalletDescription(null)
                        setModalView(MODAL_VIEW?.client)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      className=""
                      disabled={clientWalletLoading}
                      loading={clientWalletLoading}
                      size="md"
                      rounded="lg"
                      onClick={topUpWalletAsync}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </div>
            </> : null}
          {modalView === MODAL_VIEW?.addPreferences ?
            <form onSubmit={handleSubmit(addClientKeyPreference)}
              className="w-full relative my-[80px]"
              autoComplete="off">
              <div className='w-full flex flex-col space-y-6 p-6 mt-[70px]'>
                <div className="w-full flex justify-between items-start py-4">
                  <div className="w-full xl:w-1/2 flex flex-col space-y-6">
                    <Heading variant="h1" size="h9" weight="semiBold">
                      Add new preference
                    </Heading>
                    <Paragraph size="b4">
                      Enter the details to add new preference
                    </Paragraph>
                  </div>
                </div>

                <div className='w-full flex flex-col space-y-3'>
                  <div className="w-full flex flex-col space-y-2">
                    <Controller
                      control={control}
                      name="keyPreferenceCategoryId"
                      render={({
                        field: { onChange, value },
                        formState: { errors },
                      }) => {
                        const errorMessage: string = errors?.keyPreferenceCategoryId?.message;
                        return (
                          <>
                            <FormLabel htmlFor="client">Preference category</FormLabel>
                            <SingleSelect
                              selected={value as unknown as MultiSelectOption[] || []}
                              options={keyOptions}
                              placeholder={'Select key preference'}
                              setSelected={onChange}
                            />
                            {errorMessage && (
                              <FormHelperText variant="error">
                                {errorMessage}
                              </FormHelperText>
                            )}
                          </>
                        );
                      }}
                      rules={{
                        required: 'Select a preference'
                      }}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="details"
                    render={({
                      field: { onChange, onBlur, name, ref, value },
                      formState: { errors },
                    }) => {
                      const maxLength = MAXIMUM_NOTE_LENGTH;
                      const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                        errors?.details?.message as string
                      );
                      const helperTexts = [];

                      helperTexts.push(getHelpTextForCharacterLeft(maxLength, value));
                      if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts);

                      return (
                        <FormTextarea
                          type="text"
                          id="details"
                          label="Details"
                          placeholder="Add details"
                          {...{
                            ref,
                            name,
                            value,
                            onChange,
                            onBlur,
                            maxLength,
                          }}
                          helperText={helperTexts}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name='highlighted'
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className='w-full flex space-x-2'>
                          <div className='w-fit flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                            <div
                              className='flex'
                              onClick={() => onChange(!value)}
                            >
                              <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                <ToggleOnly isChecked={value} />
                              </div>
                            </div>
                          </div>
                          <FormLabel htmlFor='optOutSms'>
                            Highlight as a key preference
                          </FormLabel>
                        </div>
                      )
                    }}
                  />
                </div>
                <div className="w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer">
                  <div className="w-full flex justify-end py-4 px-8">
                    <div className="flex space-x-2">
                      <Button
                        variant="text"
                        className="text-red-500"
                        disabled={addClientKeyPreferenceLoading}
                        size="sm"
                        fontWeight="semiBold"
                        onClick={() => {
                          reset({
                            keyPreferenceCategoryId: null,
                            details: null
                          })
                          setModalView(MODAL_VIEW?.client)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className=""
                        disabled={addClientKeyPreferenceLoading || updateClientKeyPreferenceLoading}
                        loading={addClientKeyPreferenceLoading || updateClientKeyPreferenceLoading}
                        size="md"
                        rounded="lg"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            : null}
        </div>
      </Modal>
      <AddClientModal
        client={client}
        isVisible={isEditClientModalVisible}
        closeModal={initiateCloseEditModal}
        actions={actions}
        addToast={addToast}
      />
      <AddNoteModal
        isVisible={isNoteModalVisible}
        closeModal={initiateCloseAddNotwModal}
        client={client}
        addToast={addToast}
      />
      <ViewNotesModal
        isVisible={notesModalIsVisible}
        closeModal={closeNotesModal}
        actions={actions}
        clientId={client?.id}
        addToast={addToast}
      />
      <BookingDetailsModal
        isOpen={bookingDetailsModalIsVisible}
        onClose={closeBookingDetailsModal}
        bookingOrSaleData={booking}
      />
      <BlacklistOrUnblacklistClientModal
        isOpen={blacklistClientModalIsVisible}
        onClose={initiateCloseBlacklistClient}
        client={client}
        addToast={addToast}
      />
      <ClientPreferenceModal
        isOpen={clientKeyPreferenceModalIsVisible}
        onClose={initiateCloseKeyPreferenceModal}
        keyPreference={selectedKeyPreference}
        addToast={addToast}
      />
      <ClientPackageDetails
        isOpen={clientPackageDetailsModalIsVisible}
        closeModal={closeClientPackageDetailsModal}
        voucher={voucher}
      />
      <ClientRewardDetails
        isOpen={clientRewardDetailsModalIsVisible}
        closeModal={initiateCloseRewardModal}
        reward={reward}
      />
      <RecordRefundModal
        isVisible={isRecordRefundModalVisible}
        closeModal={() => {
          closeRecordRefundModal()
          refetch()
        }}
        client={client}
        addToast={addToast}
      />
      <DeleteClientDialog
        isVisible={isDeleteClientModalVisible}
        closeModal={closeDeleteClientModalAsync}
        client={client}
        addToast={addToast}
      />
      <DeleteClientKeyPreference
        isVisible={isDeleteClientPreferenceModalIsVisible}
        closeModal={_closeModalKeyPreferenceDelete}
        isLoading={deleteClientKeyPreferenceLoading}
      />
      <ViewTransactionModal
        isVisible={isViewWalletTransactionModalVisible}
        closeModal={closeWalletTransactionModal}
        walletTransaction={currentWalletTransaction}
      />
      <UpdateNotificationSettings
        isVisible={isNotificationModalVisible}
        closeModal={closeNotificationModalAsync}
        isLoading={addClientKeyPreferenceLoading}
        client={client}
        emailOrSmsNotificationSettingsType={emailOrSmsNotificationSettingsType}
      />
    </>
  )
}

export default ViewClient