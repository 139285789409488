import React, { useState } from 'react'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import { useToast } from 'hooks/useToast';
import { useUserCache } from 'hooks/useUserCache';
import RedeemVoucherTablePage from '../components/uicomponents/customerDasboard/RedeemVoucherTablePage';
import ClientLogin from '../components/uicomponents/customerDasboard/ClientLogin';
import RedeemHeader from '../components/uicomponents/customerDasboard/RedeemHeader';
import LoyaltyTablePage from '../components/uicomponents/customerDasboard/LoyaltyTablePage';

const CustomerDashboard = () => {
  const { toast, addToast } = useToast();
  const { getCustomerData } = useUserCache();
  const customerInfo = getCustomerData();
  const [currentTab, setCurrentTab] = useState('loyalty')

  const getCustomerPageView = () => {
    if (customerInfo && currentTab === 'loyalty') {
      return <LoyaltyTablePage customerInfo={customerInfo} addToast={addToast} />
    }
    if (customerInfo && currentTab === 'package') {
      return <RedeemVoucherTablePage customerInfo={customerInfo} addToast={addToast} />
    }
    return <ClientLogin addToast={addToast} customerInfo={null} />;
  }

  return (
    <>
      <RedeemHeader setCurrentTab={setCurrentTab} currentTab={currentTab} />
      <main className={`w-full flex flex-col ${customerInfo ? 'mt-0' : 'mt-[80px]'}`}>
        <ToastWrapper toast={toast as ToastProps} />
        {getCustomerPageView()}
      </main>
    </>
  )
}

export default CustomerDashboard;
