import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentMethodWallet = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M6.75 10.5h4"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.583 11.5h-2.602c-1.785 0-3.231 1.343-3.231 3s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002.54-.033.97-.432 1.005-.933.002-.032.002-.071.002-.148v-3.834c0-.077 0-.116-.002-.148-.036-.501-.465-.9-1.005-.933-.035-.002-.076-.002-.16-.002Z"
      stroke="currentColor"
      strokeWidth={1.2}
    />
    <path
      d="M21.715 11.5c-.078-1.872-.328-3.02-1.137-3.828C19.407 6.5 17.521 6.5 13.75 6.5h-3c-3.771 0-5.657 0-6.828 1.172C2.75 8.843 2.75 10.729 2.75 14.5c0 3.771 0 5.657 1.172 6.828C5.093 22.5 6.979 22.5 10.75 22.5h3c3.771 0 5.657 0 6.828-1.172.809-.808 1.06-1.956 1.137-3.828"
      stroke="currentColor"
      strokeWidth={1.2}
    />
    <path
      d="m6.75 6.5 3.735-2.476a3.237 3.237 0 0 1 3.53 0L17.75 6.5"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <path
      d="M18.741 14.5h.01"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPaymentMethodWallet;
