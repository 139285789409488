import { useMutation, useQuery, gql } from "@apollo/client";
import { Salon, SalonHour } from "core/generated";

const SALONS = gql`
  query Salons {
    salons {
      address
      bearsProcessingFee
      branchName
      callingCode
      city
      createdAt
      createdById
      deletedAt
      depositLinkValidityHours
      depositType
      depositValue
      email
      id
      intervalMinutes
      isDepositRequired
      isTaxVisible
      locationType
      onboardingStatus
      phone
      state
      status
      taxInclusivePricing
      totalNumberOfClients
      updatedAt
        virtualAccount {
            accountName
            accountNumber
            bankName
            businessId
            createdAt
            id
            subaccountId
            updatedAt
        }
      wallet {
        id
        balance
        availableBalance
        currency
      }
    }
  }
`;

export const useSalons = () => {
  return useQuery<{
    salons: Salon[];
  }>(SALONS);
};

const SALON = gql`
  query Salon($salonId: ID!) {
    salon(salonId: $salonId) {
      address
      bearsProcessingFee
      branchName
      callingCode
      city
      countryCode
      createdAt
      createdById
      deletedAt
      depositLinkValidityHours
      depositType
      depositValue
      email
      id
      intervalMinutes
      isDepositRequired
      isTaxVisible
      locationType
      onboardingStatus
      phone
      state
      status
      taxInclusivePricing
      totalNumberOfClients
        virtualAccount {
            accountName
            accountNumber
            bankName
            businessId
            createdAt
            id
            subaccountId
            updatedAt
        }
      wallet {
        availableBalance
        balance
        businessId
        createdAt
        currency
        deletedAt
        id
        salonId
        updatedAt
      }
    }
  }
`;

export const useSalon = (salonId?: string) => {
  return useQuery<{
    salon: Salon;
  }>(SALON, {
    variables: {
      salonId,
    },
    skip: !salonId,
  });
};

const UPDATE_SALON = gql`
  mutation UpdateSalon($input: UpdateSalonInput!) {
    updateSalon(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateSalon = () => {
  const [updateSalon, options] = useMutation<{
    updateSalon: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(UPDATE_SALON);

  return {
    updateSalon,
    ...options,
  };
};

const CREATE_SALON = gql`
  mutation CreateSalon($input: CreateSalonInput!) {
    createSalon(input: $input) {
      clientMutationId
      status
      salon {
        id
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateSalon = () => {
  const [createSalon, options] = useMutation<{
    createSalon: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      salon: {
        id: string;
      };
    };
  }>(CREATE_SALON);

  return {
    createSalon,
    ...options,
  };
};

const UPDATE_SALON_HOURS = gql`
  mutation UpdateSalonHour($input: UpdateSalonHourInput!) {
	updateSalonHour(input: $input) {
		clientMutationId
		status
		errors {
			message
			property
		}
		salonHours {
			closeTime
			createdAt
			day
			id
			openTime
			updatedAt
		}
	}
}
`;

export const useUpdateSalonHours = () => {
  const [updateSalonHour, options] = useMutation<{
    updateSalonHour: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      salonHours: SalonHour[];
    };
  }>(UPDATE_SALON_HOURS);

  return {
    updateSalonHour,
    ...options,
  };
};

const SALON_HOURS = gql`
  query SalonHours($salonId: ID!) {
    salonHours(salonId: $salonId) {
      closeTime
      createdAt
      day
      id
      openTime
      updatedAt
    }
  }
`;

export const useSalonHours = (salonId: string) => {
  return useQuery<{
    salonHours: SalonHour[];
  }>(SALON_HOURS, {
    variables: {
      salonId,
    },
    skip: !salonId,
  });
};

const DUPLICATE_SALON = gql`
  mutation DuplicateSalon($input: DuplicateSalonInput!) {
    duplicateSalon(input: $input) {
      clientMutationId
      status
      salon {
        id
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useDuplicateSalon = () => {
  const [duplicateSalon, options] = useMutation<{
    duplicateSalon: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
      salon: {
        id: string;
      };
    };
  }>(DUPLICATE_SALON);

  return {
    duplicateSalon,
    ...options,
  };
}