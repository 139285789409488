
import React from 'react'
import { PAGE_ROUTES } from 'constants/routes';
import { Svg404 } from 'ui';
import Heading from 'ui/atoms/heading/Heading';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Button from 'ui/atoms/button/Button';
import { COLORS } from 'constants/colors';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate()
  // Go to home page
  const goBack = () => {
    navigate(-1)
  }
  const goHome = () => {
    navigate(PAGE_ROUTES.HOME)
  }

  return (
    <div className="w-full h-screen flex justify-center items-center p-6">
      <div className='w-full max-w-[480px] flex flex-col items-center space-y-4'>
        <div className='w-full max-w-[360px] flex flex-col items-center'>
          <Svg404 width="100%" height="154px" />
          <Heading size='h4' variant='h1' weight='bold'>Error</Heading>
        </div>
        <Paragraph size="b2" weight="semiBold" className="text-center" color={COLORS.GREY[400]}>Opps, the page you’re looking for was not found</Paragraph>
        <div className='w-full max-w-[400px] flex space-x-3'>
          <Button
            variant='secondary'
            type='button'
            size='lg'
            rounded='lg'
            onClick={goBack}
            fontWeight='semiBold'
          >GO BACK</Button>
          <Button
            variant='primary'
            type='button'
            size='lg'
            rounded='lg'
            onClick={goHome}
            fontWeight='semiBold'
          >GO HOME</Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound