import React from 'react'
import Heading from 'ui/atoms/heading/Heading'
import Paragraph from 'ui/atoms/paragraph/Paragraph'

const BusinessNotFound = () => {
  return (
    <div className='flex justify-center'>
      <div className='w-full my-20 justify-center items-center xl:max-w-[400px] space-y-6'>
        <Heading size='h1' variant='h1' className='text-center'>404</Heading>
        <Paragraph size='b4' weight='normal' className='text-center'>Uh-oh, slight typo alert! For the legit link, hit up the right business on their socials. They've got the scoop you're after. Happy scrolling! 🚀🔍</Paragraph>
      </div>
    </div>
  )
}

export default BusinessNotFound