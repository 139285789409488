import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { PageProps } from '../types';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import { useSalonCache } from 'hooks/useSalonCache';
import { API_ERRORS } from 'constants/errors';
import AccountSetupArrayShimmer from 'ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import { COLORS } from 'constants/colors';
import Heading from 'ui/atoms/heading/Heading';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { SvgEdit, SvgTrash, SvgPlus } from 'ui';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import Button from 'ui/atoms/button/Button';
import AddOrEditTransfer from '../../../modals/AddOrEditTransfer';
import { DeleteTransferAccountDocument, TransferAccount, TransferAccountsDocument } from 'core/generated';
import { useDeleteTransferAccount, useGetTransferAccounts } from 'api/useMoney';

const BankTransfers = ({ onNextPage, onPrevPage }: PageProps) => {
  const [selectedBank, setSelectedBank] = useState<TransferAccount | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const { addToast, toast } = useToast();
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');

  const {
    data,
    loading: bankTransfersLoading,
    refetch
  } = useGetTransferAccounts(salonId)
  const bankTransfers = data?.transferAccounts || [];

  const showInitialLoadingShimmer = bankTransfersLoading && !bankTransfers && salonId;

  const {
    deleteTransferAccount
  } = useDeleteTransferAccount()

  const deleteBank = (bankId: string) => async () => {
    try {
      deleteTransferAccount({
        variables: { input: { id: bankId } },
      }).then(({ data }) => {
        if (data?.deleteTransferAccount?.status === 200) {
          addToast({
            message: 'Account deleted successfully',
            variant: 'success',
          })
          refetch();
        }

        if (data?.deleteTransferAccount?.errors?.length) {
          const message = data?.deleteTransferAccount?.errors[0]?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_BANK_ACCOUNT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const editBank = (bankId: string) => () => {
    const bank = bankTransfers?.find((bank) => bank?.id === bankId);
    if (!bank) return;
    setSelectedBank(bank);
    openModal();
  }

  const getBankContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(bankTransfers) && bankTransfers.length) {
      return (
        <>
          {bankTransfers.map((bank) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{bank?.accountName}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>{bank?.bankName}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editBank(bank?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deleteBank(bank?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You haven't added any bank transfer accounts yet.</Paragraph>
      </div>
    )
  }
  return (
    <>
      <AccountSetupTemplate
        title='Bank Transfers'
        subtitle='Add Bank Transfers'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <Button
            variant='primary'
            className=''
            size='sm'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Bank Transfer
          </Button>
        </div>
        <div className='w-full flex flex-col space-y-2 max-h-[400px] overflow-y-scroll'>
          {getBankContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={false}
            onClick={onNextPage}
            size='lg'
            rounded='lg'
          >
            Proceed
          </Button>
        </div>
      </AccountSetupTemplate>
      <AddOrEditTransfer
        isVisible={isVisible}
        closeModal={closeModal}
        addToast={addToast}
        transferInfo={selectedBank}
        refetch={refetch}
      />
    </>
  )
}

export default BankTransfers