import { useEffect } from "react";

declare global {
  interface Window {
    FrontChat: (action: string, options?: any) => void;
  }
}

const FrontChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "front-chat-script";
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    script.onload = initFrontChat;
    document.body.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      if (document.getElementById("front-chat-script")) {
        document.getElementById("front-chat-script")?.remove();
      }
    };
  }, []);

  // Function to initialize the FrontChat script
  function initFrontChat() {
    window.FrontChat("init", {
      chatId: "645c22f08e9c5bb898deb12f7bd1983c",
      useDefaultLauncher: true,
    });
  }

  // Function to manually open the chat
  const openChat = () => {
    if (window.FrontChat) {
      window.FrontChat('show'); // This opens the chat
    }
  };

  // open chat after 3 seconds
  setTimeout(openChat, 3000);

  return null;
}

export default FrontChat;