import * as React from 'react';
import { Paragraph } from '../..'
import { COLORS } from 'constants/colors';
import { NAV_BAR_ITEMS } from 'constants/menu';
import { NavLink } from 'react-router-dom';
import { useManageAuth } from 'modules/auth/hooks/useManageAuth';

const NavBar = () => {
  const { logout } = useManageAuth()

  return (
    <nav className="w-full py-3">
      <ul className="w-full flex flex-col justify-start items-start space-y-1">
        {Array.isArray(NAV_BAR_ITEMS) && NAV_BAR_ITEMS.length > 0 && NAV_BAR_ITEMS.map((link) => {
          const Icon = link?.icon;
          const activePage = window.location.pathname.startsWith(link?.path as string);

          return (
            <li key={link?.slug} className={`w-full flex flex-col cursor-pointer text-grey-400 hover:text-grey-900 hover:bg-grey-50 hover:rounded-lg
            ${activePage ? 'bg-grey-50 rounded-lg' : ''}
          `}>
              {link?.path && link?.show ? (
                <>
                  <NavLink to={link?.path} className={`w-full flex py-4 px-2 justify-start items-center space-x-3 no-underline`}>
                    <Icon height="24px" width="24px" color={!activePage ? COLORS.GREY[400] : COLORS.BLACK} />
                    <Paragraph size='b5' weight='semiBold' color={!activePage ? COLORS.GREY[400] : COLORS.BLACK}>{link?.title} </Paragraph>
                  </NavLink>
                  <div className='flex flex-col pl-[35px] pb-2'>
                    {activePage && link?.subs && Array.isArray(link?.subs) && link?.subs?.length ? (
                      <div className='pt-2 space-y-2'>
                        {link.subs.map((sub, index) => {
                          if (sub?.show) {
                            const subPageIsActive = location.pathname === sub.path;
                            return (
                              <NavLink key={index} to={sub?.path}
                                className={`w-fit flex items-center justify-start py-2 cursor-pointer no-underline text-grey-400 hover:text-grey-900 hover:bg-grey-100 hover:rounded-full hover:px-4 ${subPageIsActive ? 'bg-grey-100 rounded-full text-grey-900 px-4' : ''}`}
                              >
                                <Paragraph
                                  size='b5'
                                  weight={subPageIsActive ? 'semiBold' : 'normal'}
                                  color={subPageIsActive ? COLORS.BLACK : COLORS.GREY[300]}
                                >
                                  {sub?.title}
                                </Paragraph>
                              </NavLink>
                            );
                          }
                          return null;
                        })}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : link?.show ? (
                <button onClick={logout} className={`w-full flex py-4 px-2 justify-start items-center space-x-3 no-underline`}>
                  <Icon height="24px" width="24px" color={!activePage ? COLORS.GREY[400] : COLORS.BLACK} />
                  <Paragraph size='b5' weight='semiBold' color={!activePage ? COLORS.GREY[400] : COLORS.BLACK}>{link?.title} </Paragraph>
                </button>
              ) : null}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default NavBar