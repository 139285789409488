import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgTips = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={4} fill="#F3F6F6" />
    <g clipPath="url(#tips_svg__a)" stroke="#667368">
      <path d="M21.647 17.059H11.06c-.585 0-1.059.474-1.059 1.059v5.823c0 .585.474 1.059 1.059 1.059h10.588c.585 0 1.059-.474 1.059-1.059v-5.823c0-.585-.474-1.06-1.059-1.06Z" />
      <path d="M11.059 16a1.059 1.059 0 0 1 1.058-1.059h8.471A1.059 1.059 0 0 1 21.647 16v1.059H11.059V16ZM16.352 13.353a3.176 3.176 0 1 0 0-6.353 3.176 3.176 0 0 0 0 6.353Z" />
      <path d="m16.61 9.15-.512 2.053" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="tips_svg__a">
        <path fill="#fff" transform="translate(6.5 6.5)" d="M0 0h19v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTips;
