import { Button, Heading, Modal, Paragraph, SvgArrowBack, SvgChevronLeft } from "ui"
import { ClientRewardDetailsProps } from "../types"
import { getNumberMonthAndYearFromDate, formatInToPrice } from "components/utils/misc"

const ClientRewardDetails = ({
  reward,
  isOpen,
  closeModal
}: ClientRewardDetailsProps) => {
  const getUniqueStaffNames = (data) => {
    if (!data || !Array.isArray(data)) {
      return []
    }

    const staffNames = new Set()

    data.forEach((service) => {
      const staff =
        service?.appointmentServiceStaff?.[0]?.salonStaff?.user?.firstName
      if (staff) {
        staffNames.add(staff)
      }
    })

    return Array.from(staffNames) as unknown as string
  }
  const appointment = reward?.appointment || null
  const services = appointment?.appointmentServices || []
  const staff = services?.length ? getUniqueStaffNames(services) : 'N/A'
  const rewardMap = reward
    ? [
      {
        title: 'Date reached',
        value: getNumberMonthAndYearFromDate(
          reward?.createdAt
        )
      },
      {
        title: 'Date redeemed',
        value: appointment
          ? getNumberMonthAndYearFromDate(
            appointment?.startAt
          )
          : 'N/A'
      },
      {
        title: `Points ${appointment ? 'used' : 'earned'}`,
        value: reward?.milestone?.customerPointsAttained + 'pts'
      },
      {
        title: 'Services',
        value:
          Array?.isArray(services) && services?.length
            ? services?.map((service) => service?.name).join(', ')
            : 'N/A'
      },
      {
        title: 'Staff',
        value: staff
      },
      {
        title: 'Discount',
        value: appointment
          ? formatInToPrice(appointment?.discountAmount)
          : 'N/A'
      }
    ]
    : []
  return (
    <Modal show={isOpen} closeModal={closeModal} variant='right'>
      <div className='w-full relative my-[80px]'>
        <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
            </Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            >
              <SvgChevronLeft width='24px' height='24px' />
            </Button>
            <Paragraph size='b3' weight='bold'>
              View Reward Details
            </Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className='flex flex-col space-y-6'>
              <Heading variant='h1' size='h9' weight='semiBold'>
                Reward details
              </Heading>
              <Paragraph size='b4'>
                Find information about reward here
              </Paragraph>
            </div>
          </div>
          {reward && rewardMap?.length ? (
            <div className='w-full flex flex-col space-y-2 border border-grey-50 p-4'>
              {Array?.isArray(rewardMap) && rewardMap?.length
                ? rewardMap?.map((dataReward, index) => {
                  return (
                    <div
                      className='w-full p-3 flex justify-between items-center space-x-4 bg-grey-10'
                      key={index}
                    >
                      <Paragraph
                        size='b4'
                        color='grey-300'
                        className='text-left'
                      >
                        {dataReward?.title}
                      </Paragraph>

                      <Paragraph
                        size='b4'
                        color='grey-800'
                        weight='semiBold'
                        className='text-right'
                      >
                        {dataReward?.value}
                      </Paragraph>
                    </div>
                  )
                })
                : null}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default ClientRewardDetails