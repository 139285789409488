import * as React from "react";
import { TabProps } from "./types";
import { TAB_VARIANT_NAMES } from "./constants";
import { getFontWeight } from "../utils/utils";
import { FontWeight } from "../utils/types";

const Tab = ({ title, isActive, onClick, variant, fontWeight = FontWeight.normal, disabled }: TabProps) => {
  const baseClasses = "flex items-center w-fit xl:w-auto text-b5 3xl:text-b4 px-3 antialiased whitespace-nowrap focus:outline-none transition-all duration-200";
  const tabActiveClassName = variant === TAB_VARIANT_NAMES.underline ? 'border-b-2 border-green-300 cursor-pointer text-green-300' : 'border-b-0 border-green-300 bg-green-300 cursor-pointer text-white rounded-full';
  const tabVariantClassName = variant === TAB_VARIANT_NAMES.underline ? 'py-3' : 'py-2';
  const tabVariantFontClassName = variant === TAB_VARIANT_NAMES.underline ? 'text-grey-400' : 'text-grey-300';
  return (
    <button
      className={`${baseClasses} ${tabVariantClassName} ${getFontWeight(fontWeight)} ${isActive
          ? `${tabActiveClassName}`
          : `cursor-pointer ${tabVariantFontClassName}`
        }`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Tab;
