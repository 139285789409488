import * as React from "react";
import { TableHeadProps } from "./types";
import { TableHeader, TableRow } from "../..";

const TableHead = ({ headers, mobileHeaders, className = "" }: TableHeadProps) => {
  return (
    <>
      <thead className={`${className || "hidden md:table-row-group bg-grey-10 border-t border-b border-grey-150/45"}`}>
        <TableRow>
          {headers?.map((header, index) => (
            <TableHeader key={index}>{header?.label}</TableHeader>
          ))}
        </TableRow>
      </thead>
      {mobileHeaders && Array.isArray(mobileHeaders) && (
        <thead className={`${className || "table-row-group md:hidden border-t border-b border-grey-100"}`}>
          <TableRow>
            {mobileHeaders?.map((header, index) => (
              <TableHeader key={index}>{header.title}</TableHeader>
            ))}
          </TableRow>
        </thead>
      )}
    </>
  );
};

export default TableHead;
