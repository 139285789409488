import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgUnusedVouchers = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={8} fill="#F8F7F2" />
    <g
      clipPath="url(#unused-vouchers_svg__a)"
      stroke="#707A02"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 24.063A8.063 8.063 0 1 0 7.937 16" />
      <path d="M16 21.813A5.813 5.813 0 1 0 10.187 16" />
      <path d="M16 18.063a2.062 2.062 0 1 0 0-4.125 2.062 2.062 0 0 0 0 4.124Z" />
    </g>
    <defs>
      <clipPath id="unused-vouchers_svg__a">
        <path fill="#fff" transform="translate(7 7)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUnusedVouchers;
