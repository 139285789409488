import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import BookingDetailsCard from './BookingDetailsCard';
import BookingBreadcrumb from './BookingBreadcrumb';
import { BookingPersonalInformationFormInput, BookingPhoneFormInput } from './types';
import { sanitizePhoneNumber, validatePhoneNumber } from '../../utils/misc';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import { ERRORS } from 'constants/errors';
import { REGEX_PATTERNS } from 'constants/pattern';
import Button from 'ui/atoms/button/Button';
import { getHelperTextForReactHookFormErrors } from '../../utils/form';
import FormPhone from 'ui/molecules/input/FormPhone';
import BookContext from './context';
import { Client, ClientSearchDocument } from 'core/generated';
import axios from 'axios';
import BookingSteps from './BookingSteps';
import { INITIAL_STEP, TIME_AND_DATE } from './constants';
import { Heading } from '../../../ui';
import { SvgArrowLeft, SvgCartBag } from 'ui';
import { useSearchClient } from 'api/usePublic';

const CheckoutPhoneFormInput = () => {
  const { addToast, selectedServices, selectedPackages, serviceClient, selectedSalon, voucherToBeRedeemed, handleNextStep, setServiceClient, setRewards, isMobile } = useContext(BookContext);
  const {
    handleSubmit: serviceClientHandleSubmit,
    watch: serviceClientWatch,
    register: serviceClientRegister,
    setValue: serviceClientSetValue,
    formState: { errors: serviceErrors },
  } = useForm<BookingPhoneFormInput>({
    defaultValues: {
      callingCode: selectedSalon?.callingCode || '',
      countryCode: selectedSalon?.countryCode || '',
    },
  });

  const {
    data: checkPhoneNumberData,
    refetch: searchClient,
    loading: checkingPhoneNumberInput
  } = useSearchClient({
    salonId: selectedSalon?.id,
  })

  const onSubmit = async (input: BookingPhoneFormInput) => {
    const phone = sanitizePhoneNumber(input.phone, input?.callingCode);
    const pn = validatePhoneNumber(input?.phone, input?.countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      addToast({
        variant: 'error',
        message: 'Invalid phone number'
      })
      return;
    }


    const result = await searchClient({
      salonId: selectedSalon?.id,
      countryCode: input?.countryCode,
      phone,
    });
    const clientDetailsData: Client = result?.data?.clientSearch;
    if (clientDetailsData) {
      setServiceClient({
        phone: clientDetailsData.phone,
        countryCode: clientDetailsData?.countryCode,
        callingCode: clientDetailsData?.callingCode,
        fullName: clientDetailsData?.firstName + " " + clientDetailsData?.lastName,
        email: clientDetailsData?.email,
        address: clientDetailsData?.address,
      } as BookingPersonalInformationFormInput);
      setRewards(clientDetailsData?.activeClientMilestones)
    } else {
      setServiceClient({
        phone,
        countryCode: input?.countryCode,
        callingCode: input?.callingCode,
        fullName: '',
        email: ''
      } as BookingPersonalInformationFormInput)
      setRewards([])
    }
    handleNextStep();
  }

  const _sanitizeCustomerPhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, serviceClient?.callingCode);
    serviceClientSetValue('phone', _phoneNumber)
  }

  useEffect(() => {
    if (serviceClient) {
      serviceClientSetValue('phone', serviceClient?.phone)
      serviceClientSetValue('callingCode', serviceClient?.callingCode)
      serviceClientSetValue('countryCode', serviceClient?.countryCode)
    }
  }, [serviceClient, serviceClientSetValue])

  return (
    <>
      <div className='w-full flex flex-col px-[10px] xl:px-[80px] pb-[50px]'>
        <BookingBreadcrumb />
        <div className='w-full flex flex-col-reverse xl:flex-row gap-4'>
          {/* Card */}
          {!isMobile ? <BookingDetailsCard /> : null}
          {/* Cart Details */}
          {Array.isArray(selectedPackages) && selectedPackages?.length > 0 || Array.isArray(selectedServices) && selectedServices?.length > 0 || voucherToBeRedeemed ? (
            <div className='w-full h-fit flex flex-col xl:max-w-[40%] border border-grey-50 rounded-xl py-8 px-6 gap-8'>
              <div className='w-full hidden lg:flex justify-between'>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>Enter Phone Number</Paragraph>
                <BookingSteps page={"phone"} />
              </div>
              <form onSubmit={serviceClientHandleSubmit(onSubmit)} className="w-full space-y-6" autoComplete='off'>
                <FormPhone
                  country={{
                    disabled: false,
                    onSelect: (code, country: string) => {
                      serviceClientSetValue(`countryCode`, country);
                      serviceClientSetValue(`callingCode`, code);
                    },
                    value: serviceClientWatch(`countryCode`),
                  }}
                  phone={{
                    name: "phone",
                    type: "text",
                    placeholder: "9151930463",
                    rules: {
                      required: ERRORS.PHONE_REQUIRED,
                      pattern: REGEX_PATTERNS.NUMBER,
                      onChange: (e) => {
                        _sanitizeCustomerPhoneNumber(e.target.value);
                      },
                      disabled: false,
                    },
                    register: serviceClientRegister,
                    id: "phone-number",
                    label: "Phone Number",
                  }}
                  helperText={getHelperTextForReactHookFormErrors(
                    serviceErrors?.phone?.message as string
                  )}
                />
                <Button
                  variant='primary'
                  className=''
                  size='lg'
                  rounded='lg'
                  disabled={checkingPhoneNumberInput}
                  loading={checkingPhoneNumberInput}
                >
                  Continue
                </Button>
              </form>
            </div>
          ) : null}
          <div className='w-full flex lg:hidden flex-col space-y-4'>
            <div className='w-full flex flex-col space-y-2'>
              <div className='w-full flex space-x-2 items-center' onClick={() => handleNextStep(TIME_AND_DATE)}>
                <SvgArrowLeft />
                <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]} className=''>Back to Time Selection</Paragraph>
              </div>
              <Heading variant='h1' size='h9' weight='bold'>Checkout - Enter your phone</Heading>
            </div>
            <div className='w-full flex justify-center items-center py-3 gap-2 bg-green-300/10 rounded-sm text-green-300'>
              <SvgCartBag />
              <Button
                variant='text'
                size='none'
                type='button'
                className='border-0 text-green-300 underline'
                fontSize='b4'
                fontWeight='semiBold'
                onClick={() => handleNextStep(INITIAL_STEP)}
              >
                Click to manage cart
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CheckoutPhoneFormInput