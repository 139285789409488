import { TipsReport } from 'api/types/general'
import { useTipsReports } from 'api/useReports'
import ExportDataToCsvOrXlsx from 'components/uicomponents/ExportDataToCsvOrXlsx'
import { createFileNameFromDates, formatDateToOriginalDate, formatInToPrice, formatTableHeadersFilterArray, getDefaultQueryDates, getNumberMonthAndYearFromDate, handleDownload, handleExcelDownload } from 'components/utils/misc'
import { COLORS } from 'constants/colors'
import { SalonStaff } from 'core/generated'
import { useSalonCache } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { CalendarPicker, Heading, Layout, Paginator, Paragraph, SvgCalendar, SvgLocationPin, SvgTips, Tabs } from 'ui'
import ContentLoader from 'ui/atoms/contentLoader/ContentLoader'
import FullTable from 'ui/organism/table/Table'

const TIPS_REPORTS_TAB_NAME = {
  TIPS_RECEIVED: 'Tips received',
  TIPS_REFUNDED: 'Tips refunded',
} as const

const TipsReports = () => {
  const TIPS_REPORTS_TABS = [
    {
      title: TIPS_REPORTS_TAB_NAME.TIPS_RECEIVED,
      key: TIPS_REPORTS_TAB_NAME.TIPS_RECEIVED,
      show: true
    },
    {
      title: TIPS_REPORTS_TAB_NAME.TIPS_REFUNDED,
      key: TIPS_REPORTS_TAB_NAME.TIPS_REFUNDED,
      show: false
    },
  ]

  const [activeTab, setActiveTab] = useState<string>(
    TIPS_REPORTS_TABS.filter((tab) => tab.show)[0]?.key
  )

  const getTabView = () => {
    switch (activeTab) {
      case TIPS_REPORTS_TAB_NAME.TIPS_RECEIVED:
        return (
          <TipsReceivedReports />
        )
    }
  }

  return (
    <Layout pageTitle={activeTab} pageLevel={3}>
      <Tabs
        tabs={TIPS_REPORTS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className='w-full pb-20'>{getTabView()}</div>
    </Layout>
  )
}

const TipsReceivedReports = () => {
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(
    getDefaultQueryDates()
  )
  const [before, setBefore] = useState<string | null>(null)
  const [after, setAfter] = useState<string | null>(null)

  const startDate = formatDateToOriginalDate(selectedDates[0], "start")
  const endDate = formatDateToOriginalDate(selectedDates[1], "end")

  const {
    data,
    loading: isLoading,
    refetch
  } = useTipsReports({
    salonId,
    startDate,
    endDate
  })

  const tips = useMemo(() => {
    if (data) {
      return data?.tipsReport
    }
    return []
  }, [data])

  useEffect(() => {
    if (salonId) {
      refetch()
    }
  }, [refetch, salonId, selectedDates])

  const convertSalonStaffToCommaString = (staff: SalonStaff[]) => {
    return staff?.map((item) => {
      return item?.user?.firstName + ' ' + item?.user?.lastName
    }).join(', ')
  }
  const filename = `${createFileNameFromDates(selectedDates || getDefaultQueryDates())}_tips_report`;
  const formatTipsData = (data: TipsReport[]) => {
    const tableData = data?.map((item) => {
      return {
        'Team Member': convertSalonStaffToCommaString(item?.salonStaff),
        'Tip': formatInToPrice(item?.tipAmount),
        'Date': getNumberMonthAndYearFromDate(item?.dateReceived),
        'Customer': item?.client?.firstName + ' ' + item?.client?.lastName
      }
    })
    return tableData
  }
  const _handleDownload = () => {
    if (tips) {
      handleDownload(formatTipsData(tips), filename)
    }
  }

  const downloadExcel = () => {
    if (tips) {
      handleExcelDownload(formatTipsData(tips), filename, 'Tips')
    }
  }

  const goToNext = () => {
    // okay
  }

  const goToPrev = () => {
    // cool
  }

  const TIPS_HEADINGS = [
    {
      label: 'Team member',
      value: 'teamMember',
      show: true
    },
    {
      label: 'Tips received',
      value: 'tip',
      show: true
    },
    {
      label: 'Date received',
      value: 'date',
      show: true
    },
    {
      label: 'Tipping customer',
      value: 'client',
      show: true
    }
  ]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tipsReceivedHeadings, setTipsReceivedHeadings] = useState(TIPS_HEADINGS)

  const generateTipsTableData = (data: TipsReport[]) => {
    const tableData = data?.map((item) => {
      return {
        teamMember: convertSalonStaffToCommaString(item?.salonStaff),
        tip: formatInToPrice(item?.tipAmount),
        date: getNumberMonthAndYearFromDate(item?.dateReceived),
        client: item?.client?.firstName + ' ' + item?.client?.lastName
      }
    })
    return tableData
  }

  const rows = generateTipsTableData(tips)
  return (
    <>
      {isLoading ? <ContentLoader /> : null}
      <div className='w-full flex flex-col xl:flex-row justify-between lg:items-center p-2 md:p-3 lg:p-6 gap-2'>
        <div className='flex flex-col md:flex-row xl:flex-col gap-3'>
          <Heading size='h9' variant='h1' weight='bold'>
            Tips received
          </Heading>
          <div className='flex space-x-1 items-center'>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgCalendar width="16px" height="16px" />
              <span>{`${getNumberMonthAndYearFromDate(selectedDates[0])} - ${getNumberMonthAndYearFromDate(selectedDates[1])}`}</span>
            </Paragraph>
            <span>|</span>
            <Paragraph size='b4' color={COLORS.GREY[300]} className='flex space-x-1 items-center'>
              <SvgLocationPin width="16px" height="16px" />
              <span>{getSalonFieldValue('branchName')}</span>
            </Paragraph>
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:items-center gap-4 md:gap-2'>
          <Paragraph size="b4" className='hidden md:flex'>Showing:</Paragraph>
          <CalendarPicker {...{ selectedDates, setSelectedDates }} />
          <ExportDataToCsvOrXlsx downloadCsv={_handleDownload} downloadXlsx={downloadExcel} />
        </div>
      </div>
      <div className='w-full grid grid-col-1 xl:grid-cols-3 gap-2 p-3 md:p-6 border-t border-grey-100'>

        <div className='w-full flex flex-col justify-between space-y-3 xl:max-w-[374px] border border-grey-100 bg-grey-50/20 p-4 rounded-md'>
          <span className='w-[32px] h-[32px] flex justify-center items-center rounded-sm bg-grey-50'>
            <SvgTips width="18px" height="18px" />
          </span>

          <div className='w-full flex flex-col'>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='bold' className=''>
              Tips received
            </Paragraph>
            <Paragraph size='b1' weight='semiBold' className=''>
              {formatInToPrice(tips?.reduce((acc, item) => acc + item?.tipAmount, 0) || 0)}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className='w-full flex items-center justify-between border-t border-grey-100 p-3 md:p-6'>
        <Paragraph size='b4' weight='semiBold' className=''>
          Overview
        </Paragraph>
        {/* <Paginator pageInfo={null} {...{ goToNext, goToPrev }} /> */}
      </div>
      <FullTable
        rows={rows}
        headers={formatTableHeadersFilterArray(tipsReceivedHeadings)}
        hasBorder={true}
        hasFooterBg={true}
      />
    </>
  )
}

export default TipsReports
