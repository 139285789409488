import { useDeleteClient } from 'api/useClient'
import { COLORS } from 'constants/colors'
import React from 'react'
import { Heading, Paragraph, Button } from 'ui'
import { DeleteClientModalProps } from '../types'

const DeleteClientDialog = ({
  client,
  isVisible,
  closeModal,
  setClient,
  addToast
}: DeleteClientModalProps) => {
  const {
    loading,
    deleteClient
  } = useDeleteClient()

  const handleDelete = async () => {
    deleteClient({
      variables: {
        input: { id: client.id }
      }
    }).then(({ data }) => {
      const deleteClient_ = data?.deleteClient
      if (deleteClient_.status === 200) {
        addToast({
          message: 'Client deleted successfully',
          variant: 'success'
        })
        closeModal('deleted')
      } else {
        addToast({
          message: deleteClient_.errors[0].message,
          variant: 'error'
        })
      }
    })
  }

  const cancelAction = () => {
    setClient && setClient(null)
    closeModal()
  }

  return isVisible ? (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-[999999]'>
      <div className='table-row'>
        <div className='table-cell align-middle'>
          <div className='w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]'>
            <div className='flex flex-col items-center space-y-6'>
              <Heading
                variant='h1'
                size='h8'
                weight='bold'
                color={COLORS.BLACK}
              >
                Delete Client
              </Heading>
              <Paragraph
                size='b5'
                weight='medium'
                className='w-full text-center max-w-[400px]'
                color={COLORS.GREY[400]}
              >
                Are you sure you want to delete {client?.firstName}? This means
                that this client will be permanently deleted.
              </Paragraph>

              <Button
                variant='danger'
                size='lg'
                rounded='lg'
                fontSize='b5'
                className='capitalize'
                onClick={() => handleDelete()}
                disabled={loading}
                loading={false}
              >
                Yes, I'm sure
              </Button>
              <Button
                variant='text'
                size='none'
                rounded='none'
                fontSize='b5'
                className='mx-0'
                onClick={cancelAction}
                disabled={false}
              >
                No, go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default DeleteClientDialog
