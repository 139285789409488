import React, { useRef, useState } from 'react'
import ReactToPrint from "react-to-print";
import SaleReceiptPrintView from './SaleReceiptPrintView';
import { SaleReceiptPrintViewProps, SaleReceiptProps } from './types';
import { useSalonCache } from 'hooks/useSalonCache';
import { useUserCache } from 'hooks/useUserCache';
import { SPLICE_SALE_RECEIPT_LINK } from 'constants/information';
import { Modal } from 'ui/templates/modal/Modal';
import Heading from 'ui/atoms/heading/Heading';
import Button from 'ui/atoms/button/Button';
import { SvgClose } from 'ui';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { SvgFluentPrint28Regular } from 'ui';
import { copyTextToClipboard, formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from '../utils/misc';
import { API_ERRORS } from 'constants/errors';
import { useEmailSaleReceipt } from 'api/useSales';

class ComponentToPrint extends React.Component<SaleReceiptPrintViewProps> {
  render() {
    const { sale, business } = this.props;
    return (
      <SaleReceiptPrintView sale={sale} business={business} />
    );
  }
}

const ViewSaleReceiptModal = (props: SaleReceiptProps) => {
  const { getSalonData } = useSalonCache();
  const salon = getSalonData();
  const { getBusinessData } = useUserCache();
  const business = getBusinessData();
  const componentRef = useRef<ComponentToPrint>(null);
  const {
    loading: isLoading,
    emailSaleReceipt
  } = useEmailSaleReceipt()

  const copyLink = () => {
    copyTextToClipboard(`${SPLICE_SALE_RECEIPT_LINK}${props?.sale?.id}`);
    props?.addToast && props.addToast({
      variant: "success",
      message: 'Receipt link copied to clipboard',
    });
  };

  const emailReceiptToCustomer = async () => {
    emailSaleReceipt({
      variables: { input: { salonId: props?.salonId, id: props?.sale?.id } }
    }).then(({ data }) => {
      if (data?.emailSaleReceipt?.status === 200) {
        props?.addToast && props.addToast({
          message: "Email sent successfully",
          variant: 'success',
        })
      }

      if (data?.emailSaleReceipt?.errors?.length) {
        const message = data?.emailSaleReceipt?.errors[0]?.message || API_ERRORS.FAILED_TO_MAIL_CUSTOMER;
        props?.addToast({
          variant: 'error',
          message,
        })
      }
    })
  }

  return (
    <>
      <Modal
        title=""
        show={props.isVisible}
        closeModal={props.closeModal}
      >
        {props.sale && (
          <div className='w-full flex flex-col space-y-[40px]'>
            <div className='w-full space-y-4'>
              <div className="flex justify-between items-center">
                <Heading variant='h1' size='h8' weight='bold'>Transaction Details</Heading>
                <Button
                  variant='icon'
                  size='square'
                  rounded='md'
                  type='button'
                  className='hidden xl:flex max-w-[50px]'
                  onClick={props.closeModal}
                >
                  <SvgClose width="24px" height="24px" />
                </Button>
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Date: {getDayMonthAndNumberAndTimeFromDate(props.sale?.startAt) || "-"}</Paragraph>
                </div>
                <div className='flex flex-col space-y-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Billed to: {props.sale?.client?.firstName} {props.sale?.client?.lastName}</Paragraph>
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-2 px-4'>
              <div className='flex justify-between items-center bg-grey-50 px-4 py-2'>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Item</Heading>
                <Heading variant='h1' size='h11' color={COLORS.GREY[300]}>Amount</Heading>
              </div>

              {props.sale?.saleServices?.map((service) => (
                <div className='flex justify-between items-center px-4 py-2' key={service?.id}>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>{service?.name} x{service?.quantity}</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(service?.price)}</Paragraph>
                </div>
              ))}

              {props.sale?.saleProducts?.map((product) => (
                <div className='flex justify-between items-center px-4 py-2' key={product?.productId}>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>{product?.name} x{product?.quantity}</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(product?.price * product?.quantity)}</Paragraph>
                </div>
              ))}

              <div className='border-t border-grey-50'></div>
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5' color={COLORS.GREY[400]}>Subtotal</Paragraph>
                <Paragraph size='b5'>{formatInToPrice(props?.sale?.totalPrice)}</Paragraph>
              </div>
              {salon?.isTaxVisible ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[400]}>Tax</Paragraph>
                  <Paragraph size='b5'>{formatInToPrice(props?.sale?.taxAmount)}</Paragraph>
                </div>
              ) : null}
              {salon?.addOnAmount ? (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>Addon</Paragraph>
                  <Paragraph size='b5'>+{formatInToPrice(props?.sale?.addOnAmount) || 0}</Paragraph>
                </div>
              ) : null}
              <div className='flex justify-between items-center px-4 py-2'>
                <Paragraph size='b5' color={COLORS.GREY[400]}>Discount</Paragraph>
                <Paragraph size='b5'>-{formatInToPrice(props?.sale?.saleDiscount) || 0}</Paragraph>
              </div>
              <div className='border-t border-grey-50'></div>
              {props?.sale?.amountClientPaid > 0 && props?.sale?.amountClientPaid < (props?.sale?.totalPaid - props?.sale?.saleDiscount) ? (
                <>
                  <div className='flex justify-between items-center px-4 py-2'>
                    <Paragraph size='b4' color={COLORS.GREY[400]}>Amount Paid</Paragraph>
                    <Paragraph size='b4'>{formatInToPrice(props?.sale?.amountClientPaid)}</Paragraph>
                  </div>
                  <div className='flex justify-between items-center px-4 py-2 border-t border-grey-50'>
                    <Paragraph size='b4' weight='bold' className='inline' color={COLORS.GREY[300]}>Balance</Paragraph>
                    <Paragraph size='b4' weight='bold' className='inline'>{formatInToPrice(props?.sale?.totalPaid - props?.sale?.amountClientPaid - props?.sale?.saleDiscount)}</Paragraph>
                  </div>
                </>
              ) : (
                <div className='flex justify-between items-center px-4 py-2'>
                  <Paragraph size='b4' weight='bold' className='inline' color={COLORS.GREY[300]}>Total</Paragraph>
                  <Paragraph size='b4' weight='bold' className='inline'>{formatInToPrice(props?.sale?.totalPaid - props?.sale?.discountAmount)}</Paragraph>
                </div>
              )}
            </div>

            <div className='flex justify-between items-center space-y-2 px-4'>
              <ReactToPrint
                trigger={() => <Button variant='text' size='sm' fontSize='b5' fontWeight='semiBold'>
                  <SvgFluentPrint28Regular width='20px' height='20px' /> Print receipt
                </Button>}
                content={() => componentRef.current}
              />
              <div className='flex space-x-4'>
                <Button variant='secondary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={copyLink}>
                  Copy link
                </Button>
                <Button variant='primary' rounded='md' size='sm' fontSize='b5' fontWeight='semiBold' onClick={emailReceiptToCustomer} disabled={isLoading} loading={isLoading}>
                  Email
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <ComponentToPrint ref={componentRef}
        sale={props.sale}
        business={business}
      />
    </>
  )
}

export default ViewSaleReceiptModal