import * as React from "react";
import { Paragraph } from "../..";
import { COLORS } from "constants/colors";

const getSize = (size: string) => {
  switch (size) {
    case "sm":
      return "w-[36px] h-[36px]";
    case "md":
      return "w-[60px] h-[60px]";
    case "lg":
      return "w-[80px] h-[80px]";
  }
  return "w-[20px] h-[20px]";
}

const getText = (name: string, size: string) => {
  switch (size) {
    case "md":
      return (
        <Paragraph size='b2' color={COLORS.GREY[400]} weight='bold' className='uppercase'>
          {name}
        </Paragraph>
      )
    case "sm":
      return (
        <Paragraph size='b5' color={COLORS.GREY[400]} weight='bold' className='uppercase'>
          {name}
        </Paragraph>
      )
    case "lg":
      return (
        <Paragraph size='b1' color={COLORS.GREY[400]} weight='bold' className='uppercase'>
          {name}
        </Paragraph>
      );
  }
  return (
    <Paragraph size='b4' color={COLORS.GREY[400]} className='uppercase'>
      {name}
    </Paragraph>
  );
}
const NameAvatar = ({ name, size = "sm", mobile = false }: {
  name: string,
  size?: string,
  mobile?: boolean
}) => {
  return (
    <div className={`${mobile ? "flex" : "hidden"} xl:flex ${getSize(size)} rounded-full bg-grey-50 border border-grey-100 justify-center items-center`}>
      {getText(name, size)}
    </div>
  )
}

export default NameAvatar