import React from 'react'
import { ImportFileProps } from './utils';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import {SvgDownload} from 'ui';

const baseUrl = process.env.REACT_APP_API_BASE_URL as string
const SERVICE_TEMPLATE_URL = `${baseUrl}/home/download_template/service_import_template.csv`
const PRODUCT_TEMPLATE_URL = `${baseUrl}/home/download_template/product_import_template.csv`
const CLIENT_TEMPLATE_URL = `${baseUrl}/home/download_template/client_import_template.csv`
const STAFF_TEMPLATE_URL = `${baseUrl}/home/download_template/staff_import_template.csv`
export const csvTemplates = {
  client: CLIENT_TEMPLATE_URL,
  product: PRODUCT_TEMPLATE_URL,
  staff: STAFF_TEMPLATE_URL,
  service: SERVICE_TEMPLATE_URL
}

const ImportFileLink = ({ modules, showText = true }: ImportFileProps) => {

  const csvTemplateUrl = csvTemplates[modules];

  if (!csvTemplateUrl) {
    return null; // Return null if the module doesn't have a CSV template URL
  }

  return (
    <div className='flex justify-center w-fit'>
      <a
        href={csvTemplateUrl}
        target="_blank"
        download
      >
        <div className="flex justify-center items-center space-x-2">
          {showText && <Paragraph size='b5'>Download template </Paragraph>}
          <SvgDownload width="20px" height="20px" />
        </div>
      </a>
    </div>
  )
}

export default ImportFileLink