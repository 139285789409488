import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
// import { useSalonCache } from 'hooks/useSalonCache';
import { useToast } from 'hooks/useToast';
import { useModal } from 'hooks/useModal';
import { PageProps } from '../types';
import { API_ERRORS } from 'constants/errors';
import { COLORS } from 'constants/colors';
import AccountSetupArrayShimmer from 'ui/organism/accountSetupArrayShimmer/accountSetupArrayShimmer';
import Heading from 'ui/atoms/heading/Heading';
import { SvgTrash, SvgEdit, SvgPlus } from 'ui';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import AccountSetupTemplate from '../AccountSetupTemplate';
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper';
import { ToastProps } from 'ui/atoms/toast/types';
import Button from 'ui/atoms/button/Button';
import { DeletePosDeviceDocument, MarkOnboardingAsCompleteDocument, PosDevice, PosDevicesDocument, Salon, UserDocument } from 'core/generated';
import { useSalonCache } from 'hooks/useSalonCache';
import { PAGE_ROUTES } from 'constants/routes';
import { useUserCache } from 'hooks/useUserCache';
import AddOrEditPosDevice from '../../../modals/AddOrEditPosDevice';
import { useNavigate } from 'react-router-dom';
import { useDeletePosDevice, useGetPosDevices } from 'api/useMoney';
import { useMarkOnboardingComeplete } from 'api/useAccount';

const PosSetup = ({ onPrevPage }: PageProps) => {
  const navigate = useNavigate();
  const [selectedPos, setSelectedPos] = useState<PosDevice | null>(null);
  const {
    isVisible,
    openModal,
    closeModal,
  } = useModal();
  const { addToast, toast } = useToast();
  const { getSalonFieldValue } = useSalonCache();
  const { skipSetUp } = useUserCache();
  const salonId = getSalonFieldValue('id');
  const { setSalonData } = useSalonCache()
  const { setBusinessData } = useUserCache()
  const [buttonLoading, setButtonLoading] = useState(false)

  const {
    data,
    loading: posDevicesLoading,
    refetch
  } = useGetPosDevices(salonId)
  const posDevices = useMemo(() => data?.posDevices, [data])

  const {
    loading: deletePosIsLoading,
    deletePosDevice,
  } = useDeletePosDevice()

  const {
    markOnboardingAsComplete
  } = useMarkOnboardingComeplete()

  const showInitialLoadingShimmer = posDevicesLoading && !posDevices && salonId;

  const deletePos = (posId: string) => async () => {
    try {
      deletePosDevice({
        variables: {
          input: {
            id: posId,
            salonId
          }
        }
      }).then(({ data }) => {
        if (data?.deletePosDevice?.status === 200) {
          addToast({
            message: 'POS Device deleted successfully',
            variant: 'success',
          })
          refetch();
        }

        if (data?.deletePosDevice?.errors?.length) {
          const message = data?.deletePosDevice?.errors[0]?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
          addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (deletePosError) {
      if (axios.isAxiosError(deletePosError)) {
        const message = deletePosError?.response?.data?.message || API_ERRORS.SALON_POS_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const editPos = (posMachineId: string) => () => {
    const posMachine = posDevices?.find((posMachine) => posMachine?.id === posMachineId);
    if (!posMachine) return;
    setSelectedPos(posMachine);
    openModal();
  }

  const redirectToHome = async () => {
    markOnboardingAsComplete({
      variables: { input: { salonId } },
    }).then((res) => {
      if (res.data.markOnboardingAsComplete.status === 200) {
        navigate(PAGE_ROUTES.HOME, {
          replace: true,
        });
      }
    })
  };

  const getPosContent = () => {
    if (showInitialLoadingShimmer) {
      return <AccountSetupArrayShimmer />
    }
    if (Array.isArray(posDevices) && posDevices.length) {
      return (
        <>
          {posDevices.map((pos) => (
            <div className='flex items-center justify-between p-2 border-b border-grey-20'>
              <div className='flex flex-col space-y-1'>
                <Heading variant='h2' size='h10' weight='normal' className='text-black'>{pos?.name}</Heading>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]} className=''>{pos?.bankName}</Paragraph>
              </div>
              <div className='flex space-x-6'>
                <SvgEdit width="24px" height="24px" className='cursor-pointer' onClick={editPos(pos?.id)} />
                <SvgTrash width="24px" height="24px" className='cursor-pointer' onClick={deletePos(pos?.id)} />
              </div>
            </div>
          ))}
        </>
      )
    }

    return (
      <div className='flex w-full justify-center items-center py-4'>
        <Paragraph size='b5' weight='normal' color={COLORS.GREY[300]} className=''>You haven't added any point of sale terminal yet.</Paragraph>
      </div>
    )
  }

  return (
    <>
      <AccountSetupTemplate
        title='Point-of-Sale Terminals (POS)'
        subtitle='Add Point-of-Sale Terminals'
        skip={{
          text: 'Skip Business Setup',
        }}
      >
        <ToastWrapper toast={toast as ToastProps} />
        <div className='flex space-x-4'>
          <Button
            variant='primary'
            className=''
            size='sm'
            rounded='md'
            type='button'
            onClick={openModal}
          >
            <SvgPlus width="24px" height="24px" color='text-white' />
            Add Point-of-Sale Terminal (POS)
          </Button>
        </div>
        <div className='w-full flex flex-col space-y-2 max-h-[400px] overflow-y-scroll'>
          {getPosContent()}
        </div>
        <div className='w-full flex space-x-2'>
          <Button
            variant='secondary'
            className=''
            disabled={false}
            onClick={onPrevPage}
            size='lg'
            rounded='lg'
            type='button'
          >
            Prev
          </Button>
          <Button
            variant='primary'
            className=''
            disabled={buttonLoading}
            loading={buttonLoading}
            onClick={() => {
              redirectToHome();
              skipSetUp({
                skip: true
              })
            }}
            size='lg'
            rounded='lg'
          >
            Finish
          </Button>
        </div>
      </AccountSetupTemplate>
      <AddOrEditPosDevice
        isVisible={isVisible}
        closeModal={closeModal}
        addToast={addToast}
        refetch={refetch}
        pos={selectedPos}
      />
    </>
  )
}

export default PosSetup
