import { useQuery, useMutation, gql } from "@apollo/client";
import { LoyaltyOverviewData, LoyaltyProgram, LoyaltySalesReport, Milestone, Promo } from "core/generated";

const LOYALTY_PROGRAM = gql`
  query LoyaltyProgram {
    loyaltyProgram {
      business {
        name
      }
      createdAt
      id
      ruleValue
      rewardPoint
      ruleRequirement
      updatedAt
      validityMonths
      status
      includeSales
      milestones {
        createdAt
        customerPointsAttained
        customerReceives
        customerReceivesType
        milestoneProducts {
          createdAt
          id
          milestoneId
          productId
          updatedAt
        }
        milestoneServices {
          createdAt
          id
          milestoneId
          serviceId
          updatedAt
        }
        products {
          id
          name
        }
        services {
          id
          name
        }
        id
        loyaltyProgramId
        name
        updatedAt
      }
    }
  }

`;

export const useLoyaltyProgram = () => {
  return useQuery<{
    loyaltyProgram: LoyaltyProgram;
  }>(LOYALTY_PROGRAM);
};

const LOYALTY_OVERVIEW = gql`
  query LoyaltyOverviewData(
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
  ) {
    loyaltyOverviewData(startDate: $startDate, endDate: $endDate) {
      topServicePurchased
      redemptionRate {
        totalIssued
        totalRedeemed
      }
      topMembers {
        discountValue
        name
        pointsEarned
        spendValue
        spendType
        date
        discountType
      }
      visitSummaryData {
        averageVisitFrequency
        visitSummaryGraphData {
          numberOfClients
          numberOfVisits
        }
      }
    }
  }
`

export const useLoyaltyOverview = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
  return useQuery<{
    loyaltyOverviewData: LoyaltyOverviewData
  }>(LOYALTY_OVERVIEW, {
    variables: {
      startDate,
      endDate,
    },
  });
}

const ACTIVATE_LOYALTY = gql`
  mutation ActivateLoyaltyProgram($input: ActivateLoyaltyProgramInput!) {
    activateLoyaltyProgram(input: $input) {
        clientMutationId
        status
        errors {
            message
            property
        }
    }
  }
`;

export const useActivateLoyalty = () => {
  const [activateLoyalty, options] = useMutation<{
    activateLoyaltyProgram: {
      clientMutationId: string;
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(ACTIVATE_LOYALTY);

  return {
    activateLoyalty,
    ...options,
  };
};

const CREATE_LOYALTY = gql`
  mutation CreateLoyaltyProgram($input: CreateLoyaltyProgramInput!) {
    createLoyaltyProgram(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      loyaltyProgram {
        business {
          name
        }
        createdAt
        id
        ruleValue
        rewardPoint
        ruleRequirement
        updatedAt
        validityMonths
        status
        milestones {
          createdAt
          customerPointsAttained
          customerReceives
          customerReceivesType
          milestoneProducts {
            createdAt
            id
            milestoneId
            productId
            updatedAt
          }
          milestoneServices {
            createdAt
            id
            milestoneId
            serviceId
            updatedAt
          }
          products {
            id
            name
          }
          services {
            id
            name
          }
          id
          loyaltyProgramId
          name
          updatedAt
        }
      } 
    }
  }
`;

export const useCreateLoyalty = () => {
  const [createLoyalty, options] = useMutation<{
    createLoyaltyProgram: {
      clientMutationId: string;
      status: number;
      loyaltyProgram: LoyaltyProgram
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_LOYALTY);

  return {
    createLoyalty,
    ...options,
  };
};

const DELETE_LOYALTY_PROGRAM = gql`
  mutation DeleteLoyaltyProgram($input: DeleteLoyaltyProgramInput!) {
    deleteLoyaltyProgram(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteLoyaltyProgram = () => {
  const [deleteLoyaltyProgram, options] = useMutation<{
    deleteLoyaltyProgram: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_LOYALTY_PROGRAM);

  return {
    deleteLoyaltyProgram,
    ...options,
  };
};

const GET_PROMOS = gql`
  query Promos($q: String) {
    promos(q: $q) {
      topPerformingPromo {
        id
        title
      }
      promos {
        id
        description
        title
        isActive
        createdAt
        isServiceApplicable
        isProductApplicable
        isPackageApplicable
        requiredExpenditure
        startAt
        redemptionLimit
        promoType
        promoValue
        endAt
        totalClients
        totalSales
        promotionsApplied
        topPerformingPromo
        salons {
          id
          branchName
        }
        products {
          id
          name
        }
        services {
          id
          name
        }
        packages {
          id
          name
          isDiscountApplicable
        }
      }
    }
  }
`;

export const useGetPromos = (q?: string) => {
  return useQuery<{
    promos: {
      topPerformingPromo: {
        id: string,
        title: string
      };
      promos: Promo[];
    };
  }>(GET_PROMOS, {
    variables: {
      q,
    },
  });
};

const CREATE_PROMO = gql`
  mutation CreatePromo($input: CreatePromoInput!) {
    createPromo(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreatePromo = () => {
  const [createPromo, options] = useMutation<{
    createPromo: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(CREATE_PROMO);

  return {
    createPromo,
    ...options,
  };
};

const DELETE_PROMO = gql`
  mutation DeletePromo($input: DeletePromoInput!) {
    deletePromo(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeletePromo = () => {
  const [deletePromo, options] = useMutation<{
    deletePromo: {
      status: number;
      errors: {
        message: string;
        property: string;
      }[];
    };
  }>(DELETE_PROMO);

  return {
    deletePromo,
    ...options,
  };
};

const BUSINESS_SERVICES = gql`
  query BusinessServices($businessId: ID!) {
    businessServices(businessId: $businessId) {
      name
      id
    }
  }
`;

export const useBusinessServices = ({
  businessId,
}: {
  businessId: string;
  }) => {
  return useQuery<{
    businessServices: {
      name: string;
      id: string;
    }[];
  }>(BUSINESS_SERVICES, {
    variables: {
      businessId,
    },
    skip: !businessId,
  });
}

const LOYALTY_SALES = gql`
  query LoyaltySalesReport(
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    loyaltySalesReport(startDate: $startDate, endDate: $endDate) {
      client
      dateReached
      dateRedeemed
      location
      milestoneId
      milestoneName
      promoType
      promoValue
      services
      staff
    }
  }
`;

export const useLoyaltySales = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  return useQuery<{
    loyaltySalesReport: LoyaltySalesReport[];
  }>(LOYALTY_SALES, {
    variables: {
      startDate,
      endDate,
    },
  });
};

const MILESTONE = gql`
  query Milestone($id: ID!) {
    milestone(id: $id) {
      customerPointsAttained
      customerReceives
      customerReceivesType
      loyaltyProgramId
      name
    }
  }
`;

export const useMilestone = ({
  id,
}: {
  id: string;
}) => {
  return useQuery<{
    milestone: Milestone;
  }>(MILESTONE, {
    variables: {
      id,
    },
    skip: !id,
  });
}