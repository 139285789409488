import { PAGE_ROUTES } from "constants/routes";
import jwt_decode from "jwt-decode";
import { JsonDecodeProps } from "modules/auth";

export const checkPageTokenValidityAndReturnToken = async (token: string | null) => {
  if (token) {
    const decodedToken: JsonDecodeProps = jwt_decode(token);
    // if token is expired, redirect to login page
    if (!decodedToken) {
      await localStorage.clear()
      window.location.href = PAGE_ROUTES.LOGIN;
      return;
    }
    
    if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
      await localStorage.clear()
      window.location.href = PAGE_ROUTES.LOGIN;
      return;
    }

    return token;
  }

  localStorage.clear()
  window.location.href = PAGE_ROUTES.LOGIN;
}

export const checkReportsTokenFromRoute = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("t");
    if (!token) {
      return localStorage.getItem('token');
    }
    const decodedToken: JsonDecodeProps = jwt_decode(token);
    if (!decodedToken) {
      window.location.href = PAGE_ROUTES.LOGIN;
      return;
    }

    if (decodedToken && decodedToken.exp * 1000 < Date.now()) {
      window.location.href = PAGE_ROUTES.LOGIN;
      return;
    }

    return token
  } catch (error) {
    console.log(error);
    window.location.href = PAGE_ROUTES.LOGIN;
  }
}


export const checkSalonIdFromRoute = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const salon = urlParams.get("salon");
    if (!salon) {
      return null;
    }

    return salon
  } catch (error) {
    window.location.href = PAGE_ROUTES.LOGIN;
  }
}