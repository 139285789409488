import { formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from 'components/utils/misc';
import { COLORS } from 'constants/colors';
import { ClientWalletTransaction } from 'core/generated'
import React from 'react'
import { Button, Heading, Modal, Paragraph, SvgArrowBack, SvgChevronLeft } from 'ui';

const ViewTransactionModal = ({
  isVisible,
  walletTransaction,
  closeModal
}: {
  isVisible: boolean;
  walletTransaction: ClientWalletTransaction
  closeModal: () => void
}) => {
  return (
    <Modal
      show={isVisible}
      closeModal={closeModal}
      variant='right'
    >
      <div className='w-full relative my-[80px]'>
        <div className="fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10">
          <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
            <Button
              variant='text'
              size='none'
              type='button'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgArrowBack width="24px" height="24px" /> <span>Back</span></Button>
          </div>
          <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
            <Button
              variant='icon'
              size='square'
              type='button'
              rounded='md'
              className='w-fit'
              fontSize='b4'
              onClick={closeModal}
            ><SvgChevronLeft width="24px" height="24px" /></Button>
            <Paragraph size='b3' weight='bold'>View Voucher Details</Paragraph>
          </div>
        </div>
        <div className='w-full space-y-12 px-6 py-4'>
          <div className='w-full flex items-center justify-between'>
            <div className="flex flex-col space-y-6">
              <Heading variant='h1' size='h9' weight='semiBold'>Transaction details</Heading>
              <Paragraph size="b4">Find information about transaction here</Paragraph>
            </div>
          </div>
          <div className='flex flex-col gap-6 p-3 rounded-md border border-grey-125/45'>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Amount</Paragraph>
              {walletTransaction?.transactionType === 'debit' ? 
              <Paragraph size='b4' color={COLORS.RED[300]}>
                -{formatInToPrice(walletTransaction?.amount)}
              </Paragraph> : 
              <Paragraph size='b4' color={COLORS.GREEN[300]}>
              -{formatInToPrice(walletTransaction?.amount)}
            </Paragraph>
          }
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Transaction Type</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{walletTransaction?.transactionType}</Paragraph>
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Date</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{getDayMonthAndNumberAndTimeFromDate(walletTransaction?.createdAt) || "-"}</Paragraph>
            </div>
            <div className='flex items-center justify-between bg-grey-50/40 p-2'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>Description</Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>{walletTransaction?.description}</Paragraph>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewTransactionModal