import { gql, useMutation, useQuery } from "@apollo/client";
import { VerifyOtpCodeApiResponseData } from "api/types/auth";
import { User } from "core/generated";

const VERIFY_OTP = gql`
  mutation VerifyOtpCode($input: VerifyOtpCodeInput!) {
    verifyOtpCode(input: $input) {
      status
      token
      user {
        id
        firstName
        lastName
        fullName
        email
        business {
          isAccountSetupComplete
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useVerifyOtp = () => {
  const [verifyOtpCode, options] = useMutation<{
    verifyOtpCode: {
      status: number,
      token: string,
      user: User,
      errors: {
        message: string
      }[]
    };
  }>(VERIFY_OTP);

  return {
    verifyOtpCode,
    ...options,
  };
};

export const RESEND_OTP_CODE_QUERY = gql`
  query ResendOtpCode {
    resendOtpCode
  }
`;
