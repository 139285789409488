import React from 'react';
import AccountSetupTemplate from '../AccountSetupTemplate';
import { PageProps } from '../types';
import BankForm from '../../BankForm';
import { useUserCache } from 'hooks/useUserCache';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import Button from 'ui/atoms/button/Button';

const Bank = ({ onNextPage, onPrevPage }: PageProps) => {
  const { getBusinessData } = useUserCache();
  const _business = getBusinessData();
  return (
    <AccountSetupTemplate
      title='Payout Account information'
      subtitle="This is the bank account you will receive your payouts for payments you receive online"
      skip={{
        text: 'Skip Business Setup',
      }}
    >
      {_business?.payoutAccount ? (
        <>
          <div className='w-[70%] text-center mx-auto my-6'>
            <Paragraph size='b4' color={COLORS.GREY[300]} >Please contact customer support at <a className='text-green-600 font-semibold' href="mailto:info@withsplice.com">info@withsplice.com</a> to change your account number</Paragraph>
          </div>
          <div className='w-full flex space-x-2'>
            <Button
              variant='secondary'
              className=''
              disabled={false}
              onClick={onPrevPage}
              size='lg'
              rounded='lg'
              type='button'
            >
              Prev
            </Button>
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
              onClick={onNextPage}
              type='button'
            >
              Next
            </Button>
          </div>
        </>
      ) : (
        <BankForm onNextPage={onNextPage} onPrevPage={onPrevPage} />
      )}
    </AccountSetupTemplate>
  )
}

export default Bank;