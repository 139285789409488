import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPencil = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={24} height={24} rx={12} fill="#F7F8F7" />
    <path
      d="M17.027 7.05a2.083 2.083 0 0 0-2.982-.038l-6.562 6.563c-.23.229-.389.519-.46.835l-.613 2.765a.35.35 0 0 0 .417.418l2.749-.61c.327-.074.627-.238.864-.476l6.55-6.55a2.083 2.083 0 0 0 .037-2.907Zm-2.487.457a1.382 1.382 0 1 1 1.955 1.955l-.47.47-1.954-1.955.47-.47Zm-.964.966 1.955 1.954-5.586 5.586a1.063 1.063 0 0 1-.521.287l-2.212.491.496-2.228c.04-.187.135-.358.27-.493l5.598-5.597Z"
      fill="#667368"
    />
  </svg>
);
export default SvgPencil;
