import React from 'react'
import { COLORS } from 'constants/colors';
import { Paragraph } from '../../../ui';
import { SvgMinus, SvgPlus } from 'ui';
import { FaqRowTypes } from './types';

const FaqRow = ({ faq }: FaqRowTypes) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='w-full flex border-b border-grey-50 px-[5px] lg:px-[12px] xl:px-[20px] py-[10px] lg:py-[14px] xl:py-[24px] cursor-pointer' onClick={toggleCollapse}>
      <div className='w-full flex justify-between items-start'>
        <div className='w-full flex flex-col space-y-3 max-w-[90%]'>
          <Paragraph size='b4' color={COLORS.GREY['900']}>
            {faq?.question}
          </Paragraph>

          {isOpen ? (
            <Paragraph size='b4' color={COLORS.GREY['400']}>
              {faq?.answer}
            </Paragraph>
          ) : null}
        </div>
        {isOpen ? (
          <SvgMinus />
        ) : (
          <SvgPlus />
        )}
      </div>
    </div>
  )
}

export default FaqRow