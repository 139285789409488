import { Menu, Transition } from '@headlessui/react'
import React from 'react'
import { SvgSelectDropDown } from 'ui'

type ExportDataToCsvOrXlsxProps = {
  downloadCsv: () => void
  downloadXlsx: () => void
}

const ExportDataToCsvOrXlsx = ({ downloadCsv, downloadXlsx }: ExportDataToCsvOrXlsxProps) => {
  return (
    <div className='flex md:items-center space-x-2'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='flex space-x-2 w-full items-center justify-center rounded-md bg-green-300 px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85'>
            <span>Export</span>
            <SvgSelectDropDown width='10px' height='10px' />
          </Menu.Button>
        </div>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]'>
            <div className='flex flex-col p-4 space-y-4'>
              <Menu.Item>
                <span
                  className='text-b4 cursor-pointer'
                  onClick={downloadCsv}
                >
                  As csv
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  className='text-b4 cursor-pointer'
                  onClick={downloadXlsx}
                >
                  As xlsx
                </span>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default ExportDataToCsvOrXlsx