import React from 'react'
import { COLORS } from 'constants/colors'
import { Button, Heading, Paragraph } from '../../../ui'
import { Client } from 'core/generated'

type ActionModalProps = {
  isVisible: boolean
  closeModal: (action: string) => void
  isLoading: boolean,
  emailOrSmsNotificationSettingsType: string | null
  client: Client
}
const UpdateNotificationSettings = ({
  isVisible,
  closeModal,
  isLoading,
  emailOrSmsNotificationSettingsType,
  client
}: ActionModalProps) => {
  let warningText = ''

  if (emailOrSmsNotificationSettingsType === 'email') {
    if (client?.optOutEmail) {
      warningText = `Are you sure you want to turn this on? By doing so, ${client?.firstName} would receive emails including appointment reminders and confirmation`
    } else {
      warningText = `Are you sure you want to turn this off? By doing so, ${client?.firstName} won't receive emails including appointment reminders and confirmation`
    }
  } else if (emailOrSmsNotificationSettingsType === 'sms') {
    if (client?.optOutSms) {
      warningText = `Are you sure you want to turn this on? By doing so, ${client?.firstName} would receive sms including appointment reminders and confirmation`
    } else {
      warningText = `Are you sure you want to turn this off? By doing so, ${client?.firstName} won't receive sms including appointment reminders and confirmation`
    }
  }
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 min-w-[400px] xl:min-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Are you Sure</Heading>
                <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  {warningText}
                </Paragraph>

                <Button
                  variant="primary"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal('continue')}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Yes
                </Button>
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => closeModal('back')}
                  disabled={isLoading}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default UpdateNotificationSettings