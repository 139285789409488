/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import axios from 'axios';
import { useUserCache } from 'hooks/useUserCache';
import { ClientFormInput, RedeemVoucherPageProps } from './types';
import { useForm } from 'react-hook-form';
import { API_ERRORS, ERRORS } from 'constants/errors';
import { PAGE_ROUTES } from 'constants/routes';
import { sanitizePhoneNumber } from 'components/utils/misc';
import FormPhone from 'ui/molecules/input/FormPhone';
import { Logo } from 'ui/atoms/logo';
import Heading from 'ui/atoms/heading/Heading';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { REGEX_PATTERNS } from 'constants/pattern';
import { getHelperTextForReactHookFormErrors } from '../../utils/form';
import PasswordInput from 'ui/molecules/input/PasswordInput';
import Button from 'ui/atoms/button/Button';
import { AuthenticateVoucherDocument, ClientLoginDocument, ClientSetPasswordDocument } from 'core/generated';
import { logger } from 'core/logger';
import { Input } from '../../../ui';
import LoginClientIcon from './components/LoginClientIcon';
import LoginClientPlus from './components/LoginClientPlus';
import { useNavigate } from 'react-router-dom';
import { useClientLogin, useClientSetPassword } from 'api/usePublic';

const ClientLogin = ({
  addToast
}: RedeemVoucherPageProps) => {
  const navigate = useNavigate();
  const { setCustomerUserData } = useUserCache();
  const [formSection, setFormSection] = useState('landing')
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ClientFormInput>({
  });
  const [countryCode, setCountryCode] = useState("NG");
  const [callingCode, setCallingCode] = useState("+234");
  const {
    loading: clientLoginIsLoading,
    clientLogin
  } = useClientLogin()

  const {
    loading: clientSetupIsLoading,
    clientSetPassword
  } = useClientSetPassword()

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, '');
    setValue('phoneOrEmail', _phoneNumber)
  }

  const removeZero = (phoneOrEmail: string) => {
    // remove 0 if it is at tthe beginning of the text
    if (phoneOrEmail.startsWith('0')) {
      return phoneOrEmail.slice(1);
    }

    return phoneOrEmail;
  }

  const onSubmit = async (input: ClientFormInput) => {
    const dataInput = {
      phoneOrEmail: input?.phoneOrEmail?.trim(),
      password: input?.password,
    }

    try {
      clientLogin({
        variables: {
          input: {
            ...dataInput
          }
        }
      }).then(({ data }) => {
        if (data?.clientLogin?.status === 200 && data?.clientLogin?.client) {
          setCustomerUserData(data?.clientLogin?.client);
          localStorage.setItem('customer-token', data?.clientLogin?.token)
          navigate(PAGE_ROUTES.REDEEM_VOUCHER, {
            replace: true,
          });
        } else if (data?.clientLogin?.errors?.length) {
          addToast({
            variant: "error",
            message: data?.clientLogin?.errors[0]?.message || API_ERRORS.LOGIN_FAILED,
          });
        }
      }).catch((error) => {
        addToast({
          message: error?.message || API_ERRORS.LOGIN_FAILED,
          variant: "error",
        });
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.LOGIN_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`failed to login: customer: ${input.phoneOrEmail} - message: ${message}`);
        logger.exception(error as Error);
      }
    }
  };

  const setUpClientProfile = async (input: ClientFormInput) => {
    const dataInput = {
      phone: input?.phoneOrEmail,
      password: input?.password,
    }

    try {
      
      clientSetPassword({
        variables: { input: { ...dataInput } }
      }).then(({ data }) => {
        if (data?.clientSetPassword?.status === 200) {
          addToast({
            variant: "success",
            message: 'Your profile setup has been completed successfully. Please proceed to log in'
          });
          setFormSection('login')
        } else if (data?.clientSetPassword?.errors?.length) {
          addToast({
            variant: "error",
            message: data?.clientSetPassword?.errors[0]?.message || 'Failed to set client profile',
          });
        }

      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.LOGIN_FAILED;
        addToast({
          variant: "error",
          message,
        });
        logger.error(`failed to login: customer: ${input.phoneOrEmail} - message: ${message}`);
        logger.exception(error as Error);
      }
    }
  };

  const changeSection = () => {
    if (formSection === 'login') {
      setFormSection('register')
    } else {
      setFormSection('login')
    }
  }

  const changePage = (page: string) => () => {
    setFormSection(page)
  }

  return (
    <div className='w-full flex flex-col space-y-20'>
      <div className='w-full max-w-[500px] mx-auto rounded-xl md:shadow-lg border border-grey-25 py-8 px-6 space-y-8'>
        <Logo className='w-[100px]' />
        <div className={`flex flex-col py-2 space-y-2 border-b border-grey-50`}>
          <Heading variant='h1' size='h9' weight='bold'>{formSection === 'landing' ? 'Customer dashboard' : formSection === 'login' ? 'Login into your dashboard' : 'Create your unique password'}</Heading>
          <Paragraph size='b4' color={COLORS.GREY[300]}>
            {formSection === 'landing' ? 'Choose the option applicable to you.' : formSection === 'login' ? 'Enter the details below to access your dashboard' : 'Enter and confirm your password to get started'}
          </Paragraph>
        </div>
        <>
          {formSection === 'landing' ? (
            <div className='w-full flex flex-col space-y-6'>
              <div className='w-full flex rounded-md bg-green-300/5 p-4 items-center h-[90px] space-x-4 border border-green-300/20 shadow-md cursor-pointer' onClick={changePage("login")}>
                <LoginClientIcon />
                <div className='flex flex-col space-y-1 ml-4'>
                  <Heading variant='h1' size='h9' weight='bold' color={COLORS.GREY[300]}>Log in</Heading>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                    Existing user? Regain access to your account.
                  </Paragraph>
                </div>
              </div>

              <div className='w-full flex rounded-md bg-white p-4 items-center h-[90px] space-x-4 border border-grey-50 shadow-none cursor-pointer' onClick={changePage("register")}>
                <LoginClientPlus />
                <div className='flex flex-col space-y-1 ml-4'>
                  <Heading variant='h1' size='h9' weight='bold' color={COLORS.GREY[300]}>Create profile</Heading>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                    New user? Create a new account.
                  </Paragraph>
                </div>
              </div>
            </div>
          ) : formSection === 'login' ? (
            <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col space-y-4'>
              <Input
                name='phoneOrEmail'
                label='Phone number/Email address'
                id='email-phone'
                type='text'
                placeholder='Enter your phone number/Email address'
                control={control}
                rules={{
                  required: ERRORS.EMAIL_REQUIRED,
                }}
                error={errors.phoneOrEmail}
              />
              <PasswordInput
                name='password'
                label='Password'
                id='password'
                type='password'
                placeholder='**********'
                control={control}
                rules={{
                  required: ERRORS.PASSWORD_REQUIRED,
                  minLength: {
                    message: 'Password must be at least 6 characters',
                    value: 6
                  }
                }}
                error={errors.password}
              />

              <Button
                variant='primary'
                className=''
                disabled={clientLoginIsLoading}
                loading={clientLoginIsLoading}
                size='lg'
                rounded='lg'
              >
                Login
              </Button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(setUpClientProfile)} className='w-full flex flex-col space-y-4'>
              <FormPhone
                country={{
                  disabled: false,
                  onSelect: (code, country: string) => {
                    setCallingCode(code);
                    setCountryCode(country);
                  },
                  value: countryCode,
                }}
                phone={{
                  name: "phoneOrEmail",
                  type: "text",
                  placeholder: "9151930463",
                  rules: {
                    required: ERRORS.PHONE_REQUIRED,
                    pattern: REGEX_PATTERNS.NUMBER,
                    onChange: (e) => {
                      _sanitizePhoneNumber(e.target.value);
                    },
                    disabled: false,
                  },
                  register,
                  id: "phone-number",
                  label: "Enter Phone Number",
                }}
                helperText={getHelperTextForReactHookFormErrors(
                  errors?.phoneOrEmail?.message as string
                )}
              />
              <PasswordInput
                name='password'
                label='Password'
                id='password'
                type='password'
                placeholder='**********'
                control={control}
                rules={{
                  required: ERRORS.PASSWORD_REQUIRED,
                  minLength: {
                    message: 'Password must be at least 6 characters',
                    value: 6
                  }
                }}
                error={errors.password}
              />
              <PasswordInput
                name='confirmPassword'
                label='Confirm Password'
                id='password'
                type='password'
                placeholder='**********'
                control={control}
                rules={{
                  required: ERRORS.PASSWORD_REQUIRED,
                  minLength: {
                    message: 'Password must be at least 6 characters',
                    value: 6
                  }, validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || ERRORS.PASSWORD_MISMATCH;
                    },
                  },
                }}
                error={errors.confirmPassword}
              />

              <Button
                variant='primary'
                className=''
                disabled={clientSetupIsLoading}
                loading={clientSetupIsLoading}
                size='lg'
                rounded='lg'
              >
                Create profile
              </Button>
            </form>
          )}
        </>


        {formSection !== 'landing' ?
          <div className='w-full flex justify-center space-x-2'>
            <Button
              variant='text'
              className='text-b5 mx-0'
              fontWeight='bold'
              size='none'
              type='button'
              onClick={changeSection}
            >
              {formSection === 'login' ? 'Setup your profile' : 'Login into your dashboard'}
            </Button>
          </div> : null}
      </div>

      <div className='my-5 w-full flex justify-center items-center'>
        <Paragraph size='b4' color={COLORS.GREY[300]}>
          Copyright ©2024 WithSplice Limited. RC 6885578
        </Paragraph>
      </div>
    </div>
  )
}

export default ClientLogin