import React from 'react'
import { ModalProps } from '../uicomponents/appointmentComponents/types'
import { SLogo } from 'ui'
import Heading from 'ui/atoms/heading/Heading'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { COLORS } from 'constants/colors'
import Button from 'ui/atoms/button/Button'

const SuccessModal = ({
  isVisible,
  closeModal,
}: ModalProps) => {
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <SLogo width="100%" height="100%" className="w-[32px]" />
                <Heading variant='h1' size='h4' weight='bold' color={COLORS.BLACK}>Success</Heading>
                <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                  Appointment created successfully. It’s now available on your calendar
                </Paragraph>

                <Button
                  variant="primary"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal()}
                >
                  done
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default SuccessModal