/* eslint-disable @typescript-eslint/no-explicit-any */
import { Appointment, TopSeller } from "core/generated";

export const createAppointmentSummaryGroup = (appointments: Appointment[]) => {
  const colors = ["#F0CF60", "#663399", "#DDF3E3", "#000000", "#AC321D", "#198DB1", "#AC321D"];
  const labels = ['Pending', 'Checked In', 'Completed', 'No Show', 'Cancelled', 'Confirmed', 'Deposit Pending'];
  // loop through labels
  const counts = [];
  for (let i = 0; i < labels.length; i++) {
    counts.push(Array?.isArray(appointments) && appointments.filter((appointment) => appointment?.appointmentStatus === labels[i].toLowerCase()?.replaceAll(' ', "_")).length);
  }
  return {
    labels,
    datasets: [
      {
        label: 'Total Appointmennts',
        data: counts,
        backgroundColor: colors,
      },
    ]
  };
};

export const topSellersColors = ["#A6CECA", "#C1D5D3", "#DEE0E0", "#16796F", "#DEE0E0"];

export const createTopSellersGroup = (sellers: TopSeller[]) => {
  const labels = sellers.map((seller) => seller?.name);
  const counts = sellers.map((seller) => seller?.count);
  
  return {
    labels,
    datasets: [
      {
        label: 'Top Sellers',
        data: counts,
        backgroundColor: topSellersColors,
      },
    ]
  };
};
