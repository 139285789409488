import React from 'react'
import { COLORS } from 'constants/colors'
import { Button, Heading, Paragraph } from '../../../ui'
import { ActionModalProps } from '../appointmentComponents/types'
import { formatInToPrice, getNumberMonthAndYearFromDate } from '../../utils/misc'

const VoidSaleModal = ({
  isVisible,
  closeModal,
  saleReport,
  isLoading
}: ActionModalProps) => {
  return (
    isVisible ? (
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-50">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
              <div className="flex flex-col items-center space-y-6">
                <Heading variant='h1' size='h8' weight='bold' color={COLORS.BLACK}>Void Sale</Heading>
                <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  Are you sure you want to void this sale? This is a permanent action that can't be reversed
                </Paragraph>
                {saleReport?.paymentMethod ? <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  {formatInToPrice(saleReport?.amountClientPaid)} paid by {saleReport?.paymentMethod} {saleReport?.paymentDate ? `on ${getNumberMonthAndYearFromDate(saleReport?.paymentDate)}` : ''} will be deleted
                </Paragraph> : null}
                {saleReport?.product ? <Paragraph size="b5" weight='semiBold' className='w-full text-center max-w-[400px] py-2' color={COLORS.GREY[400]}>
                  {saleReport?.product} will be returned to inventory
                </Paragraph> : null}

                <Button
                  variant="danger"
                  size='lg'
                  rounded='lg'
                  fontSize='b5'
                  className='capitalize'
                  onClick={() => closeModal('void')}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Void sale
                </Button>
                <Button
                  variant="text"
                  size='none'
                  rounded='none'
                  fontSize='b5'
                  className='mx-0'
                  onClick={() => closeModal('back')}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default VoidSaleModal