import { useCreateClientNote } from 'api/useClient'
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from 'components/utils/form'
import { COLORS } from 'constants/colors'
import { ERRORS } from 'constants/errors'
import { CreateClientNoteInput } from 'core/generated'
import React, { ChangeEvent, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button, FormLabel, FormTextarea, Input, Modal, Paragraph, SvgMdiLightImage, SvgPlus, SvgRoundTrash, SvgUploadCloud } from 'ui'
import { ClientNoteAdditionModalProps } from '../types'
import { useUploadFile } from 'api/useAccount'

const AddNoteModal = ({
  isVisible,
  closeModal,
  client,
  addToast
}: ClientNoteAdditionModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm<CreateClientNoteInput>({ delayError: 100, mode: 'onChange' })
  const [attachments, setAttachments] = useState<string[]>([])
  // const [imageUrl, setImageUrl] = useState()

  const closeAddNoteModal = () => {
    closeModal()
  }

  const {
    loading: isCreating,
    createClientNote
  } = useCreateClientNote()

  const handleAdd = async (input: CreateClientNoteInput) => {
    createClientNote({
      variables: {
        input: {
          ...input,
          clientId: client?.id,
          imageUrl: attachments
        }
      }
    }).then(({ data }) => {
      const createClientNote_ = data?.createClientNote
      if (createClientNote_.status === 200) {
        addToast({
          message: 'Client note saved successfully',
          variant: 'success'
        })
        resetForm()
        setAttachments([])
        closeModal()
      } else {
        addToast({
          message: createClientNote_.errors[0].message,
          variant: 'error'
        })
      }
    })
  }

  const resetForm = () => {
    setValue('title', '')
    setValue('note', '')
  }

  const {
    loading: isFileUploading,
    uploadFile
  } = useUploadFile();

  const removeFileAttachment = (imgUrlToRemove: string) => {
    // Use the filter method to create a new array without imgUrlToRemove
    const updatedAttachments = attachments.filter(
      (imgUrl) => imgUrl !== imgUrlToRemove
    )

    // Update the state with the new array of attachments
    setAttachments(updatedAttachments)
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setAttachments([...attachments, uploadFile?.url])
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  return (
    <Modal
      title='Notes'
      subTitle='Notes about client'
      show={isVisible}
      closeModal={closeModal}
    >
      <form
        onSubmit={handleSubmit(handleAdd)}
        className='w-full my-6 space-y-4'
        autoComplete='off'
      >
        <Input
          name='title'
          label='Title'
          id='title'
          type='text'
          placeholder='Write a title'
          control={control}
          rules={{
            required: ERRORS.TITLE_REQUIRED
          }}
          error={errors.title}
        />
        <Controller
          control={control}
          name='note'
          render={({
            field: { onChange, onBlur, name, ref, value },
            formState: { errors }
          }) => {
            const maxLength = 1000
            const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
              errors?.note?.message as string
            )
            const helperTexts = []

            helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
            if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

            return (
              <FormTextarea
                type='text'
                id='note'
                label='Write a note'
                placeholder='Add a note'
                {...{
                  ref,
                  name,
                  value,
                  onChange,
                  onBlur,
                  maxLength
                }}
                helperText={helperTexts}
              />
            )
          }}
        />
        {attachments.length === 0 ? (
          <div className='w-full flex flex-col space-y-2'>
            <FormLabel htmlFor='attachment'>Attachment</FormLabel>
            <div className='w-full relative items-center cursor-pointer border rounded-md py-3 border-grey-20 justify-center'>
              <input
                style={{ height: '58px' }}
                className='cursor-pointer absolute block opacity-0 top-0 w-full'
                type='file'
                accept='.png,.jpeg,.jpg'
                onChange={handleFileUpload}
              />
              {isFileUploading ? (
                <div className='flex justify-center gap-x-2 py-3 cursor-pointer'>
                  {[1, 2, 3].map((item, index) => (
                    <div
                      key={index}
                      className='h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse'
                    ></div>
                  ))}
                </div>
              ) : (
                <div className={`w-full flex space-x-2 px-4 justify-center`}>
                  <div className='flex space-x-2 items-center'>
                    <SvgUploadCloud width='2rem' height='2rem' />
                    <Paragraph size='b5'>
                      Upload file or drop file here
                    </Paragraph>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col space-y-6'>
            <div className='flex justify-between items-center'>
              <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>
                Attachments ({attachments?.length || 0})
              </Paragraph>
              <div className='relative cursor-pointer'>
                <Button
                  variant='light'
                  size='xs'
                  type='button'
                  rounded='sm'
                  fontSize='b4'
                  className='text-grey-300'
                  disabled={isFileUploading}
                  loading={isFileUploading}
                >
                  <SvgPlus width='24px' height='24px' />
                  Add new
                </Button>
                <input
                  className='cursor-pointer absolute block opacity-0 top-0 w-full h-[40px]'
                  type='file'
                  accept='.png,.jpeg,.jpg'
                  onChange={handleFileUpload}
                />
              </div>
            </div>
            <div className='flex flex-col space-y-4'>
              {Array.isArray(attachments) &&
                attachments.length > 0 &&
                attachments.map((attachment, index) => (
                  <div
                    className='flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:justify-between items-center py-2 px-4 border border-grey-20 rounded-md'
                    key={index}
                  >
                    <div className='flex space-x-2 items-center w-[70%]'>
                      <SvgMdiLightImage width='2rem' height='2rem' />
                      <Paragraph size='b5'>{attachment}</Paragraph>
                    </div>
                    <div className='flex space-x-2'>
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => removeFileAttachment(attachment)}
                      >
                        <SvgRoundTrash width='28px' height='28px' />
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <Button
          variant='primary'
          className=''
          disabled={!isValid}
          loading={isCreating}
          size='lg'
          rounded='lg'
        >
          Submit
        </Button>
      </form>
      <div style={{ marginTop: '30px' }}>
        <Button
          variant='text'
          className='mx-auto text-red-500'
          size='none'
          onClick={closeAddNoteModal}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default AddNoteModal
