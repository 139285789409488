// import { IBusiness } from "modules/account/types";

import { Business } from "core/generated";

export interface RegisterInput {
  email: string;
  firstName: string;
  lastName: string;
  businessName: string;
  businessPhone: string;
  password: string;
  specialties: [];
  teamSize: string;
  bookingManagement: string;
  role: Role;
  rcNumber?: string;
  canServeCustomers: boolean;
};

export interface GeneratePasswordInput {
  password: string;
  confirmPassword: string;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface ForgotPasswordInput {
  email: string;
}

export interface ResetPasswordInput {
  email: string;
  password: string;
  token: string;
}

export interface VerifyEmailInput {
  email: string;
  code: string;
}

export interface SendEmailVerificationCodeInput {
  email: string;
}

enum RoleEnum {
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  OWNER = "owner",
  STAFF = "staff",
}

export type Role = keyof typeof RoleEnum;

export interface StateType {
  path: string;
}

export interface ProtectedRouteProps {
  children: JSX.Element;
}

export interface BaseUser {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  phone: string;
  canServeCustomers?: boolean;
}

export interface UserProps extends BaseUser {
  createdAt: string;
  updatedAt: string;
  roles?: Role[];
  level: number;
  accessToken?: string;
  business?: Business[];
}

export type TermsAndConditionModalProps = {
  isVisible: boolean;
  closeModal: () => void;
}

export type ValueOf<T> = T[keyof T];

export type JsonDecodeProps = UserProps & {
  iat: number;
  exp: number
}

// auth-types.ts

export type SetAppAuthTokenProps = {
  token: string;
};

export type TimestampProps = {
  timestamp: string;
};