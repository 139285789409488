import React from 'react'
import { Button, Paragraph } from '../..'
import { SvgChevronLeft, SvgChevronRight } from 'ui'
import { COLORS } from 'constants/colors'
import { PaginatorProps } from './types'

const Paginator = ({ pageInfo, goToNext, goToPrev }: PaginatorProps) => {
  return (
    <div className='border border-grey-100 px-2 py-1 items-center flex rounded-md'>
      <Button
        variant='text'
        size='none'
        type='button'
        className='w-fit'
        fontSize='b4'
        disabled={!pageInfo?.hasPreviousPage}
        onClick={goToPrev}
      >
        <SvgChevronLeft width='24px' height='24px' />
      </Button>
      <div className='flex space-x-2'>
        <Paragraph size='b4' color={COLORS.GREY[400]} className=''>
          1
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} className=''>
          -
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} className=''>
          30
        </Paragraph>
      </div>
      <Button
        variant='text'
        size='none'
        type='button'
        className='w-fit'
        fontSize='b4'
        disabled={!pageInfo?.hasNextPage}
        onClick={goToNext}
      >
        <SvgChevronRight width='24px' height='24px' />
      </Button>
    </div>
  )
}

export default Paginator
