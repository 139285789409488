import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { PromoFormInput } from './types';
import BookContext from './context';
import { formatInToPrice, getHoursAndMinutesString, getNumberMonthYearAndTimeFromDate, limitString } from '../../utils/misc';
import { calculatePackagesTotalPrice, convertSalonPackageServicesNamesToString, convertSelectedVouchersOrAllVouchersServiceesToString, convertServicesToAppointmentStaffServiceForPreviewPrice } from './utils';
import { APPOINTMENT_PAYMENT_METHOD, TIME_AND_DATE } from './constants';
import Heading from 'ui/atoms/heading/Heading';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import { COLORS } from 'constants/colors';
import Button from 'ui/atoms/button/Button';
import { SvgGreyPlus } from 'ui';
import { SvgGreyMinus, SvgInfo, SvgMdiCouponOutline, SvgTrash } from 'ui';
import Input from 'ui/molecules/input/Input';
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber';
import { AppointmentPreviewDocument } from 'core/generated';
import axios from 'axios';
import { API_ERRORS } from 'constants/errors';
import { useAppointmentPreview } from 'api/useAppointments';

const BookingDetailsCard = ({ saleSuccessful, previewPackagesPriceIsLoading, hide, hidePromoApplication, showOnlyPrices = false }: { saleSuccessful?: boolean, previewPackagesPriceIsLoading?: boolean, hide?: boolean, showOnlyPrices?: boolean, hidePromoApplication?: boolean }) => {
  const { addToast, serviceClient, handleNextStep, setPriceSummary, voucherServices, selectedServices, businessInfo, selectedSalon, modifyPackageInCart, selectedPackages, priceSummary, voucherToBeRedeemed, appointmentDateTime, promoCodeApplication, setPromoCodeApplication, packagesPriceSummary, setSelectedReward, selectedReward, setRewardSelected, rewardSelected } = useContext(BookContext);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const {
    loading: previewAppointmentPriceIsLoading,
    appointmentPreview
  } = useAppointmentPreview()
  const {
    control: promoControl,
    watch: promoWatch,
    handleSubmit: promoSubmit,
    setValue: promoSetValue,
  } = useForm<PromoFormInput>({});

  const onSubmitPromo = async (input: PromoFormInput) => {
    if (Array.isArray(selectedPackages) && selectedPackages.length > 0) {
      setPromoCodeApplication({
        promoCode: input.promoCode,
        promoValue: 0,
        promoType: 'amount',
        isValid: false,
        redemptionType: 'package',
        redemptionStatus: 'pending',
      })
      return;
    }

    if (!serviceClient) {
      addToast({
        variant: "error",
        message: "Please make sure to provide your name, email, and phone number before applying the promo code",
      });
      return
    }

    if (Array.isArray(selectedServices) && selectedServices.length > 0) {
      setPromoCodeApplication({
        promoCode: input.promoCode,
        promoValue: 0,
        promoType: 'amount',
        isValid: false,
      })
      previewServicesPrice();
      return;
    }
  }

  useEffect(() => {
    if (Array.isArray(selectedServices) && selectedServices.length > 0) {
      const total = (selectedServices.reduce((total, item) => {
        const { quantity, price, staffPrice } = item
        return total + (quantity * (staffPrice || price))
      }, 0));
      if (!priceSummary) {
        setTotalPrice(total);
        setSubtotal(total);
      }
    }
    previewServicesPrice()
  }, [selectedServices])

  useEffect(() => {
    if (Array.isArray(selectedPackages) && selectedPackages.length > 0) {
      // get the total amount of the selectedPackages, remember a package has quantity, and two use customPrice if usesCustomPrice is true in calculating the prices
      const total = selectedPackages.reduce((total, packageItem) => {
        const { quantity, usesCustomPrice, customPrice, totalPrice } = packageItem;

        // Use custom price if specified and applicable
        const pricePerUnit = usesCustomPrice ? customPrice : totalPrice;

        // Calculate total amount for the package (considering quantity)
        const packageTotal = pricePerUnit * quantity;

        // Add the package total to the overall total
        return total + packageTotal;
      }, 0);

      setTotalPrice(total);
      setSubtotal(total);
    }
  }, [selectedPackages])

  useEffect(() => {
    if (voucherToBeRedeemed) {
      setTotalPrice(voucherToBeRedeemed.price);
      setSubtotal(voucherToBeRedeemed.price);
    }
  }, [voucherToBeRedeemed])

  useEffect(() => {
    if (packagesPriceSummary) {
      setSubtotal(packagesPriceSummary?.subtotal);
      setTotalPrice(packagesPriceSummary?.totalAmountPayable);
    }
  }, [packagesPriceSummary])

  useEffect(() => {
    if (selectedReward) {
      previewServicesPrice()
    }
  }, [selectedReward])

  const previewServicesPrice = async () => {
    if (voucherToBeRedeemed || !appointmentDateTime) return;
    const services = convertServicesToAppointmentStaffServiceForPreviewPrice(selectedServices, appointmentDateTime, true);
    const appointmentData = {
      client: serviceClient?.phone ? {
        phone: serviceClient?.phone,
        countryCode: serviceClient?.countryCode as string,
      } : null,
      services,
      ...(promoWatch('promoCode') && { promoCode: promoWatch('promoCode').toLowerCase() }),
      paymentMethod: serviceClient?.paymentMethod || APPOINTMENT_PAYMENT_METHOD.card,
      ...(selectedReward && { milestoneId: selectedReward?.milestoneId }),
    }
    appointmentPreview({
      variables: { input: { ...appointmentData, salonId: selectedSalon?.id } }
    }).then(({ data }) => {
      const appointmentSummary = data?.appointmentPreview?.data;
      if (appointmentSummary) {
        setPriceSummary(appointmentSummary)
        setSubtotal(appointmentSummary?.totalServicesAmount);
        setTotalPrice(appointmentSummary?.totalPaid);

        if (appointmentSummary?.appointmentPromo && appointmentSummary?.appointmentPromo?.amount != 0.0) {
          setPromoCodeApplication({
            promoCode: appointmentSummary?.appointmentPromo?.title,
            promoValue: appointmentSummary?.appointmentPromo?.amount,
            promoType: 'amount',
            isValid: true,
          })
        } else {
          setPromoCodeApplication(null)
        }

        if (selectedReward && appointmentSummary && rewardSelected) {
          addToast({
            variant: "success",
            message: `Reward applied successfully - New Price is ${formatInToPrice(appointmentSummary?.totalPaid, businessInfo?.country?.currency?.symbol)?.replace(".00", "")}`,
          })
          setRewardSelected(false)
        }

        if (promoWatch('promoCode') && appointmentSummary?.appointmentPromo?.amount == 0.0) {
          addToast({
            variant: "error",
            message: "Promo code is not valid",
          });
        }
      } else if (data?.appointmentPreview?.errors?.length) {
        const message = data?.appointmentPreview?.errors?.[0]?.message || API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED;
        addToast({
          variant: "error",
          message,
        });
        if (selectedReward) {
          setRewardSelected(false)
          setSelectedReward(null)
        }
        return;
      }

      if (promoWatch("promoCode") && !appointmentSummary) {
        addToast({
          variant: "error",
          message: "An error oocured while trying to validate promo code, kindly try again",
        });
      }
    }).catch(error => {
      if (axios.isAxiosError(error)) {
        const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_PRICE_SUMMARY_FAILED;
        addToast({
          variant: "error",
          message,
        });
      }
    })
  }

  useEffect(() => {
    if (Array.isArray(selectedServices) && selectedServices.length > 0 && serviceClient) {
      previewServicesPrice()
    }
  }, [serviceClient])

  useEffect(() => {
    if (Array.isArray(selectedPackages) && selectedPackages.length > 0 && !promoCodeApplication) {
      const price = calculatePackagesTotalPrice(selectedPackages)
      setSubtotal(price);
      setTotalPrice(price);
    }
  }, [promoCodeApplication, selectedPackages])

  const removePromoCode = () => {
    setPromoCodeApplication(null);
    promoSetValue('promoCode', '');
    if (Array.isArray(selectedServices) && selectedServices.length > 0 && serviceClient) {
      previewServicesPrice()
    }
    if (Array.isArray(selectedPackages) && selectedPackages.length > 0) {
      const price = calculatePackagesTotalPrice(selectedPackages)
      setSubtotal(price);
      setTotalPrice(price);
    }
  }

  useEffect(() => {
    previewServicesPrice()
  }, [selectedReward])

  const pricesBreakDown = () => {
    return (
      <>
        <div className='w-full flex justify-between pt-4 border-t border-grey-50'>
          <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Subtotal</Paragraph>
          <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(subtotal, businessInfo?.country?.currency?.symbol)}</Paragraph>
        </div>
        {!hidePromoApplication && !saleSuccessful && !promoCodeApplication?.isValid && !voucherToBeRedeemed ? (
          <form onSubmit={promoSubmit(onSubmitPromo)} className="w-full flex gap-4 items-end" autoComplete='off'>
            <Input name="promoCode" id="promo-code" label='Promo code (optional)' type="text" placeholder='00000' className='uppercase' control={promoControl} />
            <Button
              variant='secondary'
              className=''
              disabled={previewAppointmentPriceIsLoading || previewPackagesPriceIsLoading || !!selectedReward}
              loading={previewAppointmentPriceIsLoading || previewPackagesPriceIsLoading}
              size='md'
              rounded='sm'
            >
              Apply
            </Button>
          </form>
        ) : null}
        {promoCodeApplication && promoCodeApplication?.isValid && (
          <div className='w-full flex flex-col space-y-2'>
            <div className='flex justify-between items-center'>
              <Paragraph size='b4' weight='normal'>Promo Code </Paragraph>
              <Paragraph size='b5' weight='normal'>-{businessInfo?.country?.currency?.symbol}<FormatNumber value={priceSummary?.appointmentPromo?.amount || promoCodeApplication?.promoValue} /></Paragraph>
            </div>
            <div className='flex justify-between items-center'>
              <span className='uppercase flex px-2 py-1 border border-dashed border-grey-400'><SvgMdiCouponOutline width="20px" height="20px" />{promoCodeApplication?.promoCode}</span>
              <Button
                variant='text'
                size='none'
                className='m-0 text-red-600'
                type='button'
                onClick={removePromoCode}
              >
                <SvgTrash />
              </Button>
            </div>
          </div>
        )}
        {priceSummary && priceSummary.cancellationFee ? (
          <div className='w-full flex justify-between items-center'>
            <div className='flex items-center'>
              <Paragraph size='b5' weight='normal'>Cancellation Fee</Paragraph>
              <div className='group ml-2 flex relative text-grey-300'>
                <SvgInfo width='15px' height='15px' />
                <span className='w-[200px] group-hover:opacity-100 transition-opacity bg-grey-400 p-2 text-b6 text-grey-20 rounded-md absolute left-2 -translate-x-2 opacity-0 m-4 mx-auto z-30'>
                  {priceSummary?.cancellationFeeMessage}
                </span>
              </div>
            </div>
            <Paragraph size='b5' color={COLORS.RED[500]} weight='normal'>{businessInfo?.country?.currency?.symbol}<FormatNumber value={priceSummary?.cancellationFee} /></Paragraph>
          </div>
        ) : null}
        {priceSummary && priceSummary?.taxAmount && selectedSalon?.isTaxVisible ? (
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b5' weight='normal'>Tax</Paragraph>
            <Paragraph size='b5' weight='normal'>{businessInfo?.country?.currency?.symbol}<FormatNumber value={priceSummary?.taxAmount} /></Paragraph>
          </div>
        ) : packagesPriceSummary && packagesPriceSummary?.processingFeeAmount && selectedSalon?.isTaxVisible ? (
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b5' weight='normal'>Tax</Paragraph>
            <Paragraph size='b5' weight='normal'>{businessInfo?.country?.currency?.symbol}<FormatNumber value={packagesPriceSummary?.taxAmount} /></Paragraph>
          </div>
        ) : null}
        {priceSummary && priceSummary?.processingFeeAmount && serviceClient?.paymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid ? (
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b5' weight='normal'>Online Processing Fee</Paragraph>
            <Paragraph size='b5' weight='normal'>{businessInfo?.country?.currency?.symbol}<FormatNumber value={priceSummary?.processingFeeAmount} /></Paragraph>
          </div>
        ) : packagesPriceSummary && packagesPriceSummary?.processingFeeAmount ? (
          <div className='w-full flex justify-between items-center'>
            <Paragraph size='b5' weight='normal'>Online Processing Fee</Paragraph>
            <Paragraph size='b5' weight='normal'>{businessInfo?.country?.currency?.symbol}<FormatNumber value={packagesPriceSummary?.processingFeeAmount} /></Paragraph>
          </div>
        ) : null}
        {selectedReward && priceSummary?.loyaltyDiscountAmount > 0 && (
          <div className='w-full flex justify-between'>
            <Paragraph size='b4' weight='normal' color={COLORS.GREY[300]}>Reward applied - {selectedReward?.milestone?.customerReceivesType === 'percentage' ? `${selectedReward?.milestone?.customerReceives}%` : formatInToPrice(selectedReward?.milestone?.customerReceives, businessInfo?.country?.currency?.symbol)} off</Paragraph>

            <div className='flex space-x-2 items-center'>
              <Paragraph size='b5' weight='normal'>-{businessInfo?.country?.currency?.symbol}<FormatNumber value={priceSummary?.loyaltyDiscountAmount} /></Paragraph>
              <Button
                variant='text'
                size='none'
                className='m-0 text-red-600'
                type='button'
                onClick={() => setSelectedReward(null)}
              >
                <SvgTrash width="24px" height="24px" />
              </Button>
            </div>
          </div>
        )}
        <div className='w-full flex justify-between pt-4 border-t border-grey-50'>
          <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>Total</Paragraph>
          <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(totalPrice, businessInfo?.country?.currency?.symbol)}</Paragraph>
        </div>
      </>
    )
  }

  return (
    <div className={`w-full h-fit ${hide ? 'hidden' : 'flex'} flex-col xl:max-w-[60%] ${!showOnlyPrices ? 'border py-8 px-6 gap-6' : 'gap-4'} border-grey-50 rounded-xl`}>
      {!showOnlyPrices ?
        <>
          <div className='w-full flex justify-between'>
            <div className='flex flex-col'>
              <Heading variant='h2' size='h8' weight='bold'>{businessInfo?.name}</Heading>
              <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>{selectedSalon?.address}, {selectedSalon?.city}, {selectedSalon?.state}</Paragraph>
            </div>

            {businessInfo?.logoUrl ? (
              <img
                src={businessInfo?.logoUrl}
                alt="business logo"
                className="w-full max-w-[80px]"
                loading="lazy"
              />
            ) : null}
          </div>
          <div className='w-full mt-4 border-b border-grey-50'></div>
          <Paragraph size='b5' weight='semiBold' color={COLORS.GREY['300']}>{voucherToBeRedeemed ? 'VOUCHER' : 'ORDER'} SUMMARY</Paragraph>
          {appointmentDateTime && (
            <div className='w-full flex justify-between items-center'>
              <Paragraph size='b5' weight='semiBold' color={COLORS.GREY[300]}>{getNumberMonthYearAndTimeFromDate(appointmentDateTime)}</Paragraph>
              <Button
                variant='text'
                size='none'
                className='m-0 text-grey-300'
                type='button'
                onClick={() => {
                  handleNextStep &&
                    handleNextStep(TIME_AND_DATE)
                }}
              >
                Change
              </Button>
            </div>
          )}
          {Array.isArray(selectedServices) && selectedServices.map((service) => {
            const quantity = service?.quantity || 1;
            return (
              <div className='w-full flex justify-between py-2' key={service?.id}>
                <div className='w-full flex flex-col gap-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{service?.name}</Paragraph>
                  <div className='w-full flex gap-2'>
                    <Paragraph size='b5' weight='normal' color={COLORS.BLACK}><span className='text-grey-300'>{getHoursAndMinutesString(service?.duration)}</span></Paragraph>
                    {service?.staff ? (<> <span className='text-grey-400'> | </span> <Paragraph size='b5' weight='normal' color={COLORS.GREY[400]}>Staff: <span className='text-grey-300'>{service?.staff?.user?.firstName} {service?.staff?.user?.lastName}</span></Paragraph> </>) : null}
                    {/* {!saleSuccessful ? (
                      <div className="w-fit flex space-x-2 items-end">
                        <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Qty:</Paragraph>
                        <div className="w-full flex">
                          <Button
                            variant='icon'
                            size="none"
                            type="button"
                            className="border-0"
                            onClick={() => modifyServiceInCart(service.id, 'add')}
                          >
                            <SvgGreyPlus width="22px" height="22px" />
                          </Button>
                          <div className="flex border-t border-b border-grey-25 px-2 justify-center h-[22px] items-center">
                            <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{quantity}</Paragraph>
                          </div>
                          <Button
                            variant='icon'
                            size="none"
                            type="button"
                            className="border-0"
                            onClick={() => modifyServiceInCart(service.id, 'remove')}
                          >
                            <SvgGreyMinus width="22px" height="22px" />
                          </Button>
                        </div>
                      </div>
                  ) : null} */}
                  </div>
                </div>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{service?.staffPrice ? formatInToPrice(service?.staffPrice * quantity, businessInfo?.country?.currency?.symbol) : service?.pricingType === 'from' ? (<span className='text-grey-400 bg-grey-100 text-b7 px-1 py-0.5 rounded-full mr-2'>from</span>) : null} {!service?.staffPrice ? formatInToPrice(service?.price * quantity, businessInfo?.country?.currency?.symbol) : null}</Paragraph>
              </div>
            )
          })}
          {Array.isArray(selectedPackages) && selectedPackages.map((selectedSalonPackage, index) => {
            const price = selectedSalonPackage?.usesCustomPrice ? selectedSalonPackage?.customPrice : selectedSalonPackage?.totalPrice;
            return (
              <div className='w-full flex justify-between py-2' key={index}>
                <div className='flex flex-col gap-2'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{selectedSalonPackage?.name}</Paragraph>
                  <Paragraph size='b6' weight='normal' color={COLORS.GREY['400']}>{convertSalonPackageServicesNamesToString(selectedSalonPackage?.packageServices)}</Paragraph>
                  {!saleSuccessful ? (
                    <div className="w-full flex space-x-2 items-end">
                      <Paragraph size='b5' weight='normal' color={COLORS.GREY['300']}>Qty:</Paragraph>
                      <div className="w-full flex pt-2">
                        <Button
                          variant='icon'
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'add')}
                        >
                          <SvgGreyPlus width="22px" height="22px" />
                        </Button>
                        <div className="flex border-t border-b border-grey-25 px-2 justify-center h-[22px] items-center">
                          <Paragraph size='b6' weight='normal' color={COLORS.BLACK}>{selectedSalonPackage?.quantity}</Paragraph>
                        </div>
                        <Button
                          variant='icon'
                          size="none"
                          type="button"
                          className="border-0"
                          onClick={() => modifyPackageInCart(selectedSalonPackage.id, 'remove')}
                        >
                          <SvgGreyMinus width="22px" height="22px" />
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(price, businessInfo?.country?.currency?.symbol)}</Paragraph>
              </div>
            )
          })}
          {voucherToBeRedeemed ? (
            <div className='w-full flex justify-between'>
              <div className='w-full flex flex-col gap-2'>
                <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']}>{voucherToBeRedeemed?.package?.name}</Paragraph>
                <Paragraph size='b4' weight='normal' color={COLORS.GREY['300']} className='uppercase'>{voucherToBeRedeemed?.code}</Paragraph>
                <Paragraph size='b5' weight='normal' color={COLORS.GREY['400']}>{limitString(convertSelectedVouchersOrAllVouchersServiceesToString(voucherServices))}</Paragraph>
              </div>
              <Paragraph size='b4' weight='semiBold' color={COLORS.GREY['900']}>{formatInToPrice(voucherToBeRedeemed?.price, businessInfo?.country?.currency?.symbol)}</Paragraph>
            </div>
          ) : null}
          {!saleSuccessful && !voucherToBeRedeemed ? (
            <div className='w-fit'>
              <Button
                variant='text'
                size='none'
                className='underline text-grey-300'
                fontSize='b4'
                type='button'
                onClick={() => handleNextStep(1)}
              >
                Add another {Array.isArray(selectedPackages) && selectedPackages?.length > 0 ? 'package' : Array.isArray(selectedServices) && selectedServices?.length > 0 ? 'service' : ''}
              </Button>
            </div>
          ) : null}

          {pricesBreakDown()}
        </>
        : (
          pricesBreakDown()
        )}

    </div>
  )
}

export default BookingDetailsCard