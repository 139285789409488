import * as React from 'react'
import { Logo } from 'ui'
import Heading from 'ui/atoms/heading/Heading'
import Button from 'ui/atoms/button/Button'
import { PAGE_ROUTES } from 'constants/routes'
import { ToastProps } from 'ui/atoms/toast/types'
import ToastWrapper from 'ui/molecules/toastWrapper/ToastWrapper'
import { useToast } from 'hooks/useToast'
import { useForm } from 'react-hook-form'
import { ForgotPasswordDocument, ForgotPasswordInput } from 'core/generated'
import axios from 'axios';
import { API_ERRORS, ERRORS } from 'constants/errors'
import Input from 'ui/molecules/input/Input'
import { REGEX_PATTERNS } from 'constants/pattern'
import FullStory from 'react-fullstory'
import { useNavigate } from 'react-router-dom'
import { useForgotPassword } from 'api/useForgot'

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordInput>();
  const { addToast, toast } = useToast();
  const {
    error,
    loading: forgotPasswordIsLoading,
    forgotPassword,
  } = useForgotPassword();

  const onSubmit = async (input: ForgotPasswordInput) => {
    forgotPassword({
      variables: {
        input: {
          email: input?.email?.toLowerCase(),
        }
      }
    }).then(({ data }) => {
      const { forgotPassword } = data;
      if (forgotPassword?.status === 200) {
        // go to otp page
        localStorage.setItem('token', forgotPassword.token)
        localStorage.setItem('forgotEmailData', JSON.stringify({ email: input?.email?.toLowerCase(), token: forgotPassword?.token, isVerified: false }))
        navigate(PAGE_ROUTES.FORGOT_PASSWORD_OTP)
      } else if (forgotPassword?.errors?.length) {
        addToast({
          variant: "error",
          message: forgotPassword?.errors[0]?.message || API_ERRORS.FORGOT_PASSWORD_FAILED,
        });
      } else {
        addToast({
          variant: "error",
          message: API_ERRORS.FORGOT_PASSWORD_FAILED,
        });
      }
    }).catch((error) => {
      addToast({
        variant: "error",
        message: API_ERRORS.FORGOT_PASSWORD_FAILED,
      });
    })
  }

  const goToLoginPage = () => {
    navigate(PAGE_ROUTES.LOGIN)
  }

  return (
    <>
      <main className="flex items-center justify-center min-h-screen bg-white xl:bg-grey-100">
        <div className="table-row">
          <div className="table-cell align-middle">
            <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-8 px-9 max-w-[480px] xl:w-[580px] xl:max-w-[700px] space-y-6">
              <div className="flex flex-col items-center space-y-6">
                <Logo />
                <Heading variant='h1' size="h8" weight='medium' className='w-full text-center'>Forgot Password</Heading>
              </div>
              <ToastWrapper toast={toast as ToastProps} />
              <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6" autoComplete='off'>
                <Input name="email" label="Email" id="email" type="email" placeholder='email@acme.com' control={control} rules={{
                  required: ERRORS.EMAIL_REQUIRED,
                  pattern: REGEX_PATTERNS.EMAIL,
                }} error={errors.email} />
                <Button
                  variant="primary"
                  className=''
                  disabled={forgotPasswordIsLoading}
                  loading={forgotPasswordIsLoading}
                  size='lg'
                  rounded='lg'
                >
                  Forgot password
                </Button>
              </form>
              <div className='w-full flex justify-center space-x-2'>
                <Button
                  variant="text"
                  className='text-b5 mx-auto'
                  fontWeight='bold'
                  size='none'
                  type='button'
                  onClick={goToLoginPage}
                >Back to login</Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ForgotPassword