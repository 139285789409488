/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { Controller, useForm } from 'react-hook-form'
import axios from 'axios'
import type { DatePickerProps } from 'antd'
import { DatePicker, TimePicker } from 'antd'
import dayjs from 'dayjs'
import {
  Appointment,
  AppointmentPreview,
  AppointmentPreviewDocument,
  AppointmentProduct,
  AppointmentService,
  Client,
  DeleteAppointmentMilestoneDocument,
  FeedbackMessage,
  PosDevice,
  Product,
  SalonStaff,
  Service,
  TransferAccount,
  UpdateAppointmentDocument
} from 'core/generated'
import {
  IAppointmentPriceSummary,
  SelectedProduct,
  SplitPaymentsInput,
  UpdateAppointmentServiceInputProps,
  ViewAppointmentModalProps
} from './types'
import { useUserCache } from 'hooks/useUserCache'
import { useModal } from 'hooks/useModal'
import {
  AppointmentCurrentServiceStaff,
  AppointmentInput,
  IAppointment,
  IAppointmentProduct,
  IAppointmentService,
  UpdateAppointmentInput
} from '../uicomponents/appointmentComponents/types'
import { MultiSelectOption } from 'ui/molecules/multiselect/types'
import {
  convertServicesToAppointment,
  fetchClientsData,
  fetchPosDevicesData,
  fetchProductsData,
  fetchServicesData,
  fetchTransferAccountsData,
  getTips,
  getUpdateAppointmentServiceInputData,
  removeFromOptions,
  removeProductFromSelectedServices,
  removeServiceFromSelectedServices
} from './utils'
import {
  addStartAtToViewAppointmentService,
  convertUpdateServicesToAppointmentStaffServiceForPreviewPrice,
  formatBankAccountsToSelectField,
  formatClientToSelectFieldAppointmentCreation,
  formatPosTerminalsToSelectField,
  formatProductsToSelectField,
  formatServicesToSelectField,
  formatStaffToSelectFieldWithEmptyFirstValue,
  getServiceTime
} from '../utils/utils'
import {
  convert12HourTo24Hour,
  convertFullDateStringToDate,
  convertFullDateStringToTime,
  formatInToPrice,
  formatNumber,
  formatSnakeCaseText,
  getHoursAndMinutesString,
  convertProductsToCommaSeparatedString,
  removeTimezoneOffset,
  sortServices,
  sortProducts,
  copyTextToClipboard,
  validateDecimalInput
} from '../utils/misc'
import Heading from 'ui/atoms/heading/Heading'
import { COLORS } from 'constants/colors'
import Paragraph from 'ui/atoms/paragraph/Paragraph'
import { SvgClose, SvgTrash, SvgArrowBack, SvgChevronLeft, SvgCheckMarkGreen, SvgUser, SvgSolarGiftLinear, SvgPlus, SvgCharmMenuKebab, SvgInfo, SvgHome, SvgPencil, SvgCopy, SvgFaqQuestion, ToggleOnly } from 'ui'
import SelectOnchangeField from 'ui/molecules/input/SelectOnchangeField'
import { DEFAULT_CURRENCY } from 'constants/currency'
import {
  APPOINTMENT_PAYMENT_METHOD,
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPES,
  PAYMENT_METHODS,
  PAYMENT_METHODS_OFFLINE_ONLY
} from '../uicomponents/bookingSales/constants'
import { FormatNumber } from 'ui/atoms/formatNumber/FormatNumber'
import Button from 'ui/atoms/button/Button'
import ViewAppointmentShimmer from '../uicomponents/appointmentComponents/modals/ViewAppointmentShimmer'
import { FormLabel } from 'ui/atoms/formLabel'
import Checkbox from 'ui/atoms/checkbox/Checkbox'
import { FormHelperText } from 'ui/atoms/helperText/FormHelperText'
import { API_ERRORS, ERRORS } from 'constants/errors'
import CollapseRow from 'ui/organism/collapseRow/CollapseRow'
import MultiSelect from 'ui/molecules/multiselect/multiselect'
import Input from 'ui/molecules/input/Input'
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm'
import { MAXIMUM_NOTE_LENGTH } from 'constants/form'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../utils/form'
import FormTextarea from 'ui/molecules/input/FormTextarea'
import SelectInput from 'ui/molecules/input/SelectInput'
import { Modal } from 'ui/templates/modal/Modal'
import ViewReceiptModal from '../uicomponents/appointmentComponents/modals/ViewReceiptModal'
import { useSalonCache } from 'hooks/useSalonCache'
import { SvgEdit, SvgGreyMinus, SvgGreyPlus, SvgWhiteDiamond } from 'ui'
import { YES_OR_NO_SETTINGS } from 'constants/information'
import { AppointmentPriceCard } from './AppointmentPriceBreakDown'
import { ContactLink, Pill } from 'ui'
import { REGEX_PATTERNS } from 'constants/pattern'
import { canPerformAction } from '../utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import { getStaffWithHighestPay } from '../uicomponents/appointmentComponents/utils'
import RemoveStaffFromAppointmentAlertModal from './RemoveStaffFromAppoinntmentAlertModal'
import { useGetStaffList } from 'api/useStaff'
import { useGetPosDevices, useGetTransferAccounts } from 'api/useMoney'
import { useGetProducts, useGetServices } from 'api/useCatalogue'
import { useAppointmentPreview, useDeleteAppointmentMilestone, useGetAppointment, useUpdateAppointment } from 'api/useAppointments'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS } from './constants'
import ViewClient from 'modules/clients/modals/Client'
import { useGetClient } from 'api/useClient'
import CreditWalletBalance from './CreditWalletBalance'

const ViewAppointmentModal = ({
  isVisible,
  closeModal,
  cancelAppointment,
  salonId,
  appointmentId,
  addToast,
  // refetchData,
  checkInOrOutAppointment,
  deleteAppointment,
  markAsNoShow,
  refetchCalendarData,
  revertCancellation
}: ViewAppointmentModalProps) => {
  const [actionIsLoading, setActionIsLoading] = useState(false)
  const [updatedAppointment, setUpdatedAppointment] =
    useState<Appointment | null>(null)
  const { getBusinessData } = useUserCache()
  const business = getBusinessData()
  const businessId = business?.businessId as string
  const { getSalonData } = useSalonCache()
  const salon = getSalonData()
  const [hasMadePayment, setHasMadePayment] = useState<string | null>(null)
  const [appointmentStatus, setAppointmentStatus] = useState<string>('pending')
  const [enableAddOns, setEnableAddons] = useState(false)
  const [enablePromo, setEnablePromo] = useState(false)
  const [enableDiscount, setEnableDiscount] = useState(false)
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)
  const [discountType, setDiscountType] = useState<string | null>(null)
  const [discountValue, setDiscountValue] = useState<string | null>(null)
  const [enableProductAddition, setEnableProductAddition] = useState(false)
  const [editServicePrice, setEditServicePrice] = useState<string | null>(null)
  const [serviceNewPrice, setServiceNewPrice] = useState<string | null>(null)
  const [selectedServices, setSelectedServices] = useState<
    UpdateAppointmentServiceInputProps[]
  >([])
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    []
  )
  const [changeStaffView, setChangeStaffView] = useState<boolean>(false)
  const [serviceStaffSelected, setServiceStaffSelected] = useState<
    string | null
  >(null)
  const [showServiceSelection, setShowServiceSelection] = useState(false)
  const [showProductSelection, setShowProductSelection] = useState(false)
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [alerts, setAlerts] = useState<FeedbackMessage[]>([]);
  const [promoCode, setPromoCode] = useState<{
    promoType: string
    promoValue: number
  } | null>(null)
  const [appointmentSummary, setAppointmentSummary] =
    useState<AppointmentPreview | null>(null)
  const [selectedStaff, setSelectedStaff] = useState('')
  const [amountClientPaid, setAmountClientPaid] = useState<string | null>(null)
  const [showReceipt, setShowReceipt] = useState<boolean>(false)
  const [currentServiceStaff, setCurrentServiceStaff] = useState<AppointmentCurrentServiceStaff | null>(null)
  const [showPaymentSelection, setShowPaymentSelection] = useState(false)
  const [showTipsSection, setShowTipsSection] = useState(false)
  const [client, setClient] = useState<Client | null>(null)
  const [creditWallet, setCreditWallet] = useState(false)
  const [amountToBeChecked, setAmountToBeChecked] = useState<number>(0)
  const [appointmentTip, setAppointmentTip] = useState<{
    label: string;
    value: number;
  }>(null)
  const [splitPayments, setSplitPayments] = useState<SplitPaymentsInput[]>([])
  const actions = {
    deleteAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.deleteAppointment),
    rescheduleAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.rescheduleAppointment),
    cancelAppointment: canPerformAction(PERMISSION_CONSTANTS?.calendar?.cancelAppointment),
    markNoShow: canPerformAction(PERMISSION_CONSTANTS?.calendar?.markNoShow),
    applyDiscounts: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyDiscounts),
    applyPromo: canPerformAction(PERMISSION_CONSTANTS?.calendar?.applyPromo),
    checkInOrOut: canPerformAction(PERMISSION_CONSTANTS?.calendar?.checkInOrOut),
    revertCancellation: canPerformAction(PERMISSION_CONSTANTS?.calendar?.revertCancellation),
    editPrices: canPerformAction(PERMISSION_CONSTANTS?.calendar?.editPrices),
    modifyAppointmennt: canPerformAction(PERMISSION_CONSTANTS?.calendar?.modifyAppointmennt)
  }
  const {
    isVisible: isReceiptModalVisible,
    openModal: openReceiptModal,
    closeModal: closeReceiptModal
  } = useModal()
  const {
    isVisible: isDepositLinkModalVisible,
    openModal: openDepositLinkModal,
    closeModal: closeDepositLinkModal,
  } = useModal();
  const {
    isVisible: isRemoveStaffAssignmentToServiceModalVisible,
    openModal: openRemoveStaffAssignmentToServiceModal,
    closeModal: closeRemoveStaffAssignmentToServiceModal
  } = useModal()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<UpdateAppointmentInput>({
    defaultValues: {
      appointmentType: 'online',
      paymentMethod: 'unpaid',
      newClient: false,
    }
  })
  const {
    openModal: openCreditWalletDialogModal,
    closeModal: closeCreditWalletDialogModal,
    isVisible: isCreditWalletDialogModalVisible
  } = useModal()
  const country = JSON.parse(localStorage.getItem('country'));
  const APPOINTMENT_PAYMENT_METHODS =
    country?.payoutsSupported === true && country?.collectionsSupported === true
      ? PAYMENT_METHODS
      : PAYMENT_METHODS_OFFLINE_ONLY;

  const {
    data: productsData,
    refetch: refetchProducts
  } = useGetProducts({
    salonId,
    isPurchasable: true
  });
  const products = useMemo(() => productsData?.products, [productsData])
  const productsOptions = useMemo(() => formatProductsToSelectField(products), [products])

  const {
    data: servicesData,
    refetch: refetchServices
  } = useGetServices(salonId)
  const services = useMemo(() => {
    setFilteredServices(sortServices(servicesData?.services))
    return servicesData?.services
  }, [servicesData])
  const servicesOptions = useMemo(() => formatServicesToSelectField(services), [services])

  const {
    data: bankTransfersData,
    refetch: refetchBankTransfers
  } = useGetTransferAccounts(salonId)
  const bankTransfers = useMemo(() => bankTransfersData?.transferAccounts, [bankTransfersData])
  const transfersOptions = useMemo(() => formatBankAccountsToSelectField(bankTransfers), [bankTransfers])

  const {
    data: posTerminalsData,
    refetch: refetchPosTerminals
  } = useGetPosDevices(salonId)
  const posTerminals = useMemo(() => posTerminalsData?.posDevices, [posTerminalsData])
  const posTerminalsOptions = useMemo(() => formatPosTerminalsToSelectField(posTerminals), [posTerminals])

  const {
    data: staffListData,
    refetch: refetchStaffList
  } = useGetStaffList({
    salonId,
    active: true
  })
  const staff = useMemo(() => staffListData?.staffManagement?.staffs, [staffListData])
  const staffMembersOptions = useMemo(() => formatStaffToSelectFieldWithEmptyFirstValue(staff), [staff])

  useEffect(() => {
    if (salonId) {
      refetchProducts();
      refetchServices();
      refetchBankTransfers();
      refetchPosTerminals();
      refetchStaffList();
    }
  }, [salonId, refetchProducts, refetchServices, refetchBankTransfers, refetchPosTerminals, refetchStaffList]);

  const {
    loading: previewAppointmentIsLoading,
    appointmentPreview
  } = useAppointmentPreview()
  const {
    loading: updateAppointmentIsLoading,
    updateAppointment
  } = useUpdateAppointment()

  const {
    loading: deleteAppointmentMilestoneIsLoading,
    deleteAppointmentMilestone
  } = useDeleteAppointmentMilestone()

  const removeService = (serviceId?: string) => {
    const watchServices = removeFromOptions(watch('services'), serviceId)
    setValue('services', watchServices as [])

    const getSelectedServices = removeServiceFromSelectedServices(
      selectedServices,
      serviceId
    )
    if (Array.isArray(getSelectedServices) && getSelectedServices.length)
      setSelectedServices(getSelectedServices)
  }

  const removeReward = async () => {
    try {
      deleteAppointmentMilestone({
        variables: { input: { appointmentId: appointment?.id } }
      }).then(({ data }) => {
        if (data?.deleteAppointmentMilestone?.status === 200) {
          refetchAppointmentDetails()
          setDiscountType(null)
          setDiscountValue(null)
          setValue('discountType', '')
          setValue('discountValue', 0)
          setIsDiscountApplied(false)
          setEnableDiscount(false)
          previewPrice()
          addToast &&
            addToast({
              message: 'Reward removed successfully',
              variant: 'success'
            })
        } else if (data?.deleteAppointmentMilestone?.errors?.length) {
          const message =
            data?.deleteAppointmentMilestone?.errors[0]?.message ||
            API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
          addToast &&
            addToast({
              variant: 'error',
              message
            })
        } else {
          const message = API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
          addToast &&
            addToast({
              variant: 'error',
              message
            })
        }
      })
    } catch (updateAppointmentError) {
      if (axios.isAxiosError(updateAppointmentError)) {
        const message =
          updateAppointmentError?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
        addToast &&
          addToast({
            message,
            variant: 'error'
          })
      }
    }
  }

  const removeProduct = (productId?: string) => {
    const watchProducts = removeFromOptions(watch('products'), productId)
    setValue('products', watchProducts as [])

    const getSelectedProducts = removeProductFromSelectedServices(
      selectedProducts,
      productId
    )
    if (Array.isArray(getSelectedProducts) && getSelectedProducts.length)
      setSelectedProducts(getSelectedProducts)
  }

  const getAppointmentDetails = async (appointment: Appointment) => {
    if (appointment) {
      setValue(
        'appointmentDate',
        convertFullDateStringToDate(appointment?.startAt)
      )
      setValue(
        'appointmentTime',
        convertFullDateStringToTime(appointment?.startAt as string)
      )
      if (appointment?.appointmentServices && Array?.isArray(appointment?.appointmentServices)) {
        const groupedServices = groupAndSortServicesByTime(appointment?.appointmentServices)
        const appointmentServices = groupedServices
          ?.map((service: AppointmentService) => {
            const matchedOption = services?.find(
              (option: { id: string }) => option?.id === service?.serviceId
            )
            return matchedOption
              ? {
                ...matchedOption,
                staff: service?.appointmentServiceStaff?.map((staff) => {
                  return {
                    firstName: staff?.salonStaff?.user?.firstName,
                    staffId: staff?.salonStaff?.id
                  }
                }) ?? null,
                quantity: service?.quantity,
                servicePrice: service?.price,
                startAt:
                  convertFullDateStringToTime(service?.startAt as string) ||
                  new Date()
              }
              : null
          })
          .filter(Boolean) as UpdateAppointmentServiceInputProps[]
        const priceCheckedServices = appointmentServices?.map((service) => {
          const servicePrice = checkStaffServicePrice(service);
          return { ...service, servicePrice };
        });
        setSelectedServices(priceCheckedServices);
      }
      const appointmentProducts = appointment?.appointmentProducts
        ?.map((product: AppointmentProduct) => {
          const matchedOption = products?.find(
            (option: { id: string }) => option?.id === product?.productId
          )
          return { ...matchedOption, quantity: product?.quantity }
        })
        .filter(Boolean) as Product[]
      setSelectedProducts(appointmentProducts)
      if (appointmentProducts?.length > 0) {
        setEnableProductAddition(true)
      }
      setValue('note', appointment?.clientNote?.note || '')
      setValue('appointmentType', appointment?.appointmentType)
      setValue('addOnReason', appointment?.addOnReason || '')
      setValue('addOnAmount', appointment?.addOnAmount)
      if (appointment?.addOnAmount && appointment?.addOnAmount !== 0.0) {
        setEnableAddons(true)
      }
      setValue('paymentMethod', appointment?.paymentMethod)
      setValue('paymentMethodId', appointment?.paymentMethodId || "")
      setValue('discountValue', appointment?.discountValue || 0)
      if (
        appointment?.appointmentPromo !== null &&
        appointment?.appointmentPromo?.amount != 0.0
      ) {
        setPromoCode({
          promoType: 'fixed_value',
          promoValue: appointment?.appointmentPromo?.amount || 0
        })
        setValue('promoCode', appointment?.appointmentPromo?.title)
        setEnablePromo(true)
      }

      if (
        appointment?.paymentMethod === APPOINTMENT_PAYMENT_METHOD.unpaid &&
        appointment?.depositPaymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid
      ) {
        setHasMadePayment('deposit')
      } else {
        if (appointment?.paymentMethod !== APPOINTMENT_PAYMENT_METHOD.unpaid) {
          setHasMadePayment('full')
        }
      }
      setAppointmentStatus(appointment?.appointmentStatus || 'confirmed')
    }
  }

  const {
    loading: isLoading,
    data: appointmentDetailsData,
    refetch: refetchAppointmentDetails
  } = useGetAppointment(appointmentId, salonId)
  const appointment = useMemo(() => appointmentDetailsData?.appointment, [appointmentDetailsData])
  useEffect(() => {
    if (appointment) {
      setDiscountType(appointment?.discountType)
      setDiscountValue(appointment?.discountAmount.toString())
      setIsDiscountApplied(appointment?.discountType ? true : false)
      getAppointmentDetails(appointment)
    }
  }, [appointment])

  const {
    data: clientData,
    loading: clientLoading,
    refetch
  } = useGetClient({
    clientId: appointment?.client?.id,
    salonId
  })
  const salonClient = useMemo(() => clientData?.salonClient, [clientData])
  useEffect(() => {
    if (salonClient) {
      setClient(salonClient)
    }
  }, [salonClient])

  useEffect(() => {
    refetchAppointmentDetails()
  }, [appointmentId, refetchAppointmentDetails])

  const showInitialLoadingShimmer =
    isLoading && !appointment && salonId && appointmentId

  const applyDiscount = async () => {
    await previewPrice()
    setIsDiscountApplied(true)
  }

  const groupAndSortServicesByTime = (services: AppointmentService[]) => {
    return [...services] // Create a shallow copy of the array
      .sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime())
      .map(service => ({
        ...service
      }));
  }

  const getServiceSummary = () => {
    return getServiceSummaryUtils(
      selectedServices,
      services,
      selectedProducts,
      products,
      removeService,
      removeProduct
    )
  }

  const removeStaff = (staffId: string, serviceId: string) => {
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff: service?.staff?.filter((staffItem) => staffItem?.staffId !== staffId)
        }
      }
      return service
    })
    const priceCheckedServices = serviceSelectedFromOptions?.map((service) => {
      const servicePrice = checkStaffServicePrice(service);
      return { ...service, servicePrice };
    });
    setSelectedServices(priceCheckedServices);
  }

  const handleServiceTimeChange = (
    serviceId: string,
    time: string,
    index?: number
  ) => {
    const timeString = removeTimezoneOffset(convert12HourTo24Hour(time)) as string
    if (serviceId) {
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === serviceId) {
          return {
            ...service,
            startAt: timeString
          }
        }
        return service
      })
      setSelectedServices(serviceSelectedFromOptions)
      if (index === 0) {
        setValue('appointmentTime', timeString)
      }
    }
  }

  const modifyCart = (
    id: string,
    type: 'add' | 'remove',
    inventory: 'product' | 'service' | 'voucher'
  ) => {
    // modify cart for services
    if (inventory === 'service') {
      let remove = false
      const serviceSelectedFromOptions = selectedServices.map((service) => {
        if (service?.id === id) {
          const quantity = service?.quantity || 1
          if (type === 'add') {
            return {
              ...service,
              quantity: quantity + 1
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...service,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return service
      })
      if (remove) {
        // get serive index from watch("services") via id
        removeService(id)
        return
      }
      setSelectedServices(serviceSelectedFromOptions)
    }

    // modify cart for products
    if (inventory === 'product') {
      let remove = false
      const productSelectedFromOptions = selectedProducts.map((product) => {
        if (product?.id === id) {
          const getAppointmentProduct = appointment?.appointmentProducts?.find(
            (item) => item?.productId === id
          )
          const quantity = product?.quantity || 1
          if (type === 'add') {
            const newQuantity = quantity + 1
            if (
              newQuantity - (getAppointmentProduct?.quantity || 0) >
              (product?.stockCount || 1)
            ) {
              // flag error stockCount
              addToast && addToast({
                variant: 'error',
                message: `You only have ${product?.stockCount} in stock`
              })
            } else {
              return {
                ...product,
                quantity: newQuantity
              }
            }
          }
          if (type === 'remove') {
            if (quantity > 1) {
              return {
                ...product,
                quantity: quantity - 1
              }
            } else {
              remove = true
            }
          }
        }
        return product
      })
      if (remove) {
        // get serive index from watch("services") via id
        removeProduct(id)
        return
      }
      setSelectedProducts(productSelectedFromOptions)
    }
  }

  const formatServiceStaffOptions = (service: UpdateAppointmentServiceInputProps): MultiSelectOption[] => {
    if (!staff || !staff.length || !staffMembersOptions?.length) return [];

    if (!service?.staff?.length) {
      return staffMembersOptions;
    }

    const staffOptions: MultiSelectOption[] = [];
    const selectedStaffIds = service.staff.map((staff) => staff.staffId);
    // show all staff in staffMembersOptions that is not in selectedStaffIds
    staffMembersOptions.forEach((staffOption) => {
      if (!selectedStaffIds.includes(staffOption.value)) {
        staffOptions.push(staffOption);
      }
    })

    return staffOptions;
  };

  const onChangeStaff = (selectedStaff: string, serviceId: string) => {
    const matchedItem = staff.find((item) => item.id === selectedStaff);

    // changeStaff(value, serviceId)
    const serviceSelectedFromOptions = selectedServices.map((service) => {
      if (service?.id === serviceId) {
        return {
          ...service,
          staff: Array?.isArray(service?.staff) && service?.staff?.length ? [...service.staff, {
            firstName: matchedItem?.user?.firstName,
            staffId: matchedItem?.id
          }] : [{
            firstName: matchedItem?.user?.firstName,
            staffId: matchedItem?.id
          }]
        }
      }

      return service
    }) as UpdateAppointmentServiceInputProps[]
    const priceCheckedServices = serviceSelectedFromOptions?.map((service) => {
      const servicePrice = checkStaffServicePrice(service);
      return { ...service, servicePrice };
    });
    setSelectedServices(priceCheckedServices);
    previewPrice()
  }

  const initiateCloseStaffAssignmentToServiceModal = (action?: string) => {
    if (action === 'back') {
      setCurrentServiceStaff(null)
      closeRemoveStaffAssignmentToServiceModal()
      return;
    }

    if (action === 'remove') {
      if (currentServiceStaff && currentServiceStaff?.staff && currentServiceStaff?.service) {
        removeStaff(currentServiceStaff?.staff?.staffId, currentServiceStaff?.service?.id)
      }
      setCurrentServiceStaff(null)
      closeRemoveStaffAssignmentToServiceModal()
      return;
    }
  }

  const getServiceSummaryUtils = (
    selectedServices: UpdateAppointmentServiceInputProps[],
    services: Service[],
    selectedProducts: SelectedProduct[],
    products: Product[],
    servicesCanBeRemoved?: (serviceId?: string) => void,
    productsCanBeRemoved?: (productId?: string) => void
  ) => {
    if (
      (Array.isArray(selectedProducts) && selectedProducts.length !== 0) ||
      (Array.isArray(selectedServices) && selectedServices.length !== 0)
    ) {
      return (
        <div className='flex flex-col p-3 space-y-6 border border-grey-20 rounded-md'>
          {appointmentSummary ? (
            <div className='w-full flex flex-col space-y-2 py-3'>
              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  Subtotal
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                  {formatInToPrice(
                    ((appointmentSummary?.totalServicesAmount as number) +
                      appointmentSummary?.totalProductsAmount) as number
                  )}
                </Paragraph>
              </div>

              {watch('addOnAmount') ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Addon
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    +{DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.addOnAmount} />
                  </Paragraph>
                </div>
              ) : null}
              {salon?.isTaxVisible ?
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Tax
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    {DEFAULT_CURRENCY}
                    <FormatNumber value={appointmentSummary?.taxAmount} />
                  </Paragraph>
                </div> : null}
              {appointment?.milestone ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Reward Discount - {
                      appointment?.milestone?.customerReceivesType === 'percentage'
                        ? `${appointment?.milestone?.customerReceives}%`
                        : `${formatInToPrice(appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                    }
                  </Paragraph>
                  <div className='flex items-center space-x-3'>
                    <Paragraph size='b4' color={COLORS.GREY[800]}>
                      {formatInToPrice(appointment?.discountAmount)}
                    </Paragraph>
                    <span
                      className='text-red-600 text-b5 cursor-pointer'
                      onClick={removeReward}
                    >
                      <SvgTrash width='14px' height='14px' />
                    </span>
                  </div>
                </div>
              ) : null}
              {!appointment?.milestone && ((enableDiscount && !isDiscountApplied && actions?.applyDiscounts && appointment?.appointmentStatus !== 'checked_in') ||
                (appointment?.appointmentStatus === 'checked_in' && actions?.editPrices && enableDiscount && !isDiscountApplied && actions?.applyDiscounts)) ? (
                <div className='flex justify-between items-center'>
                  <div>
                    <Paragraph size='b4' color={COLORS.GREY[300]}>
                      Discount
                    </Paragraph>
                  </div>
                  <div className='flex w-1/2 border rounded-lg'>
                    <div className='pl-2 py-2 rounded-l '>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'percentage'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('percentage')}
                      >
                        %
                      </Button>
                    </div>
                    <div className=' py-2'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='light'
                        className={
                          discountType === 'fixed_value'
                            ? 'border-2 text-green-500'
                            : ''
                        }
                        onClick={() => setDiscountType('fixed_value')}
                      >
                        {DEFAULT_CURRENCY}
                      </Button>
                    </div>
                    <div className='py-2 flex-1'>
                      <Input
                        control={control}
                        type='number'
                        id='discount'
                        name='discountValue'
                        className='h-5 py-4 border-0'
                        rules={{
                          pattern: REGEX_PATTERNS.NUMBER
                        }}
                      />
                    </div>
                    <div className='py-2 rounded-r'>
                      <Button
                        rounded='sm'
                        size='sm'
                        type='button'
                        variant='transparent'
                        onClick={() => applyDiscount()}
                        disabled={!discountType}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}

              {!appointment?.milestone && appointmentSummary.discountAmount && isDiscountApplied ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Discount
                  </Paragraph>
                  <div className='flex items-center'>
                    <Paragraph
                      className='mr-4'
                      size='b4'
                      color={COLORS.GREY[300]}
                    >
                      -{DEFAULT_CURRENCY}
                      <FormatNumber
                        value={appointmentSummary?.discountAmount}
                      />
                    </Paragraph>

                    {(actions?.applyDiscounts && appointment?.appointmentStatus !== 'checked_in') || (appointment?.appointmentStatus === 'checked_in' && actions?.applyDiscounts && actions?.editPrices) ?
                      <>
                        <span
                          className='mr-3'
                          style={{
                            marginRight: '10px',
                            marginLeft: '10px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setIsDiscountApplied(false)
                            setEnableDiscount(true)
                          }}
                        >
                          <SvgEdit width='15px' height='15px' />
                        </span>
                        {actions?.modifyAppointmennt ?
                          <span
                            className='text-red-600 cursor-pointer'
                            onClick={() => {
                              setIsDiscountApplied(false)
                              setEnableDiscount(false)
                              setValue('discountValue', null)
                              setDiscountType(null)
                            }}
                          >
                            <SvgTrash width='15px' height='15px' />
                          </span> : null}
                      </> : null}
                  </div>
                </div>
              ) : null}

              {appointmentSummary?.appointmentPromo ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Promo code
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    -{DEFAULT_CURRENCY}
                    <FormatNumber
                      value={appointmentSummary?.appointmentPromo?.amount}
                    />
                  </Paragraph>
                </div>
              ) : null}

              {(appointmentSummary?.depositAmount &&
                appointment?.depositPaymentMethod ===
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
                appointment?.appointmentStatus === 'deposit_pending') ||
                appointment?.appointmentStatus === 'deposit_abandoned' ? (
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    Deposit Required
                  </Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]}>
                    {formatInToPrice(appointmentSummary?.depositAmount)}
                  </Paragraph>
                </div>
              ) : null}

              {appointment.outstandingFee && appointment?.outstandingFee > 0 ? <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[400]}>
                  Cancellation fee
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  {DEFAULT_CURRENCY}
                  <FormatNumber
                    value={
                      appointment?.outstandingFee
                    }
                  />
                </Paragraph>
              </div> : null}

              <div className='flex justify-between items-center'>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  Total
                </Paragraph>
                <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                  {DEFAULT_CURRENCY}
                  {appointment?.appointmentStatus !== 'completed' ?
                    <FormatNumber
                      value={
                        appointmentSummary?.totalPaid -
                        appointmentSummary?.processingFeeAmount
                      }
                    /> :
                    <FormatNumber
                      value={
                        appointment?.actualTotalPaid
                      }
                    />}
                </Paragraph>
              </div>
              {appointment?.totalAmountPaid > 0 ?
                <div className='flex justify-between items-center'>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>Amount paid</Paragraph>
                  <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>{formatInToPrice(appointment?.totalAmountPaid)}</Paragraph>
                </div> : null}
              {appointmentSummary && appointment?.depositAmount > 0 && appointment?.totalAmountPaid > 0 && appointment?.totalAmountPaid < appointment?.depositAmount ?
                <div className='w-full flex flex-col space-y-2 border-t border-grey-100'>
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      Deposit Balance
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      {formatInToPrice(
                        appointmentSummary?.depositAmount - appointment?.totalAmountPaid
                      )}
                    </Paragraph>
                  </div>
                </div> : null}
              {appointmentSummary && appointmentSummary?.totalPaid && appointment?.paymentMethod ===
                APPOINTMENT_PAYMENT_METHOD.unpaid &&
                appointment?.totalPaid - appointment?.totalAmountPaid !== 0 &&
                appointment?.depositAmount &&
                appointment?.appointmentStatus !== 'deposit_pending' &&
                appointment?.appointmentStatus !== 'deposit_abandoned' ? (
                <div className='w-full flex flex-col space-y-2 border-t border-grey-100'>
                  <div className='flex justify-between items-center'>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                      Outstanding Balance
                    </Paragraph>
                    <Paragraph size='b4' color={COLORS.GREY[300]} weight='semiBold'>
                      {formatInToPrice(
                        appointmentSummary?.totalPaid - appointment?.totalAmountPaid
                      )}
                    </Paragraph>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )
    }
    return null
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setValue('appointmentDate', dateString as string)
  }

  const dateFormat = 'YYYY/MM/DD'

  const onChangeTime = (value: any, dateString: string) => {
    const timeString = removeTimezoneOffset(convert12HourTo24Hour(dateString)) as string
    setValue('appointmentTime', timeString)

    const firstService = selectedServices[0]
    if (firstService && dateString) {
      handleServiceTimeChange(firstService?.id, timeString)
    }
  }

  const previewPrice = async () => {
    if (Array.isArray(selectedServices) && selectedServices.length === 0) return
    let startAt = new Date().toISOString()
    if (watch('appointmentDate') && watch('appointmentTime')) {
      startAt = `${watch('appointmentDate')?.replace(
        /\//g,
        '-'
      )}T${removeTimezoneOffset(convert12HourTo24Hour(watch('appointmentTime')))}`
    }
    if (!appointment?.client?.phone) return
    let services = selectedServices;
    if (appointment?.appointmentVoucher?.packageVoucher?.redemptionMode === 'services') {
      const selectedServicesForPreview = selectedServices?.filter((service) => {
        const checkIfServiceExistInVoucherService = appointment?.appointmentVoucher?.packageVoucher?.voucherServices?.some((voucherService) => voucherService?.serviceId === service?.id);
        return !checkIfServiceExistInVoucherService;
      });

      services = selectedServicesForPreview
    }

    const appointmentData = {
      client: {
        phone: appointment?.client?.phone,
        countryCode: appointment?.client?.countryCode
      },
      services: convertUpdateServicesToAppointmentStaffServiceForPreviewPrice(
        services,
        startAt
      ),
      ...(selectedProducts.length && {
        products: convertProductsToCommaSeparatedString(selectedProducts)
      }),
      ...(watch('addOnAmount') && {
        addOnAmount: parseFloat(
          watch('addOnAmount')?.toString()?.replace(',', '')
        )
      }),
      ...(watch('promoCode') && watch('promoCode') !== ''
        ? { promoCode: watch('promoCode') }
        : {}),
      paymentMethod: watch('paymentMethod') === 'split' ? 'unpaid' : watch('paymentMethod'),
      discountType,
      discountValue: Number(watch('discountValue'))
    }

    appointmentPreview({
      variables: { input: { ...appointmentData, salonId: salon?.id } }
    }).then(({ data }) => {
      const priceSummary = data?.appointmentPreview?.data
      if (priceSummary) {
        setAppointmentSummary(priceSummary)
      }

      if (priceSummary?.appointmentPromo) {
        setPromoCode({
          promoType: 'amount',
          promoValue: priceSummary?.appointmentPromo?.amount
        })
      }

      if (data?.appointmentPreview?.errors?.length) {
        addToast({
          variant: 'error',
          message: data?.appointmentPreview?.errors?.[0]?.message,
        });
      }
    })
  }

  // useEffect(() => {
  //   const startTime = convert12HourTo24Hour(watch('appointmentTime'))
  //   const appointmentServices =
  //     Array?.isArray(services) && services?.length
  //       ? convertServicesToAppointment(services)
  //       : []
  //   if (watch('services') && appointmentServices?.length) {
  //     const serviceSelectedFromOptions = (appointmentServices ?? [])
  //       .filter((service) => {
  //         const serviceId = service?.id;
  //         const exists = selectedServices.some(
  //           (selectedService) => selectedService?.id === serviceId
  //         );
  //         return (
  //           !exists &&
  //           (watch('services') ?? []).some(
  //             (serviceSelected: MultiSelectOption) =>
  //               serviceSelected?.value === serviceId
  //           )
  //         );
  //       }) as UpdateAppointmentServiceInputProps[];

  //     const newServices = [
  //       ...selectedServices,
  //       ...serviceSelectedFromOptions
  //     ]
  //     setSelectedServices(addStartAtToViewAppointmentService(newServices, startTime as string))
  //   }
  // }, [watch('services')])

  useEffect(() => {
    if (watch('products')) {
      const productSelectedFromOptions =
        Array?.isArray(products) &&
        products
          ?.filter((product) => {
            const productId = product?.id
            const existsInProductsField = watch('products')?.some(
              (selectedProduct: MultiSelectOption) =>
                selectedProduct?.value === productId
            )
            const existsInSelectedProducts = selectedProducts.some(
              (selectedProduct) => selectedProduct.id === productId
            )

            if (!existsInProductsField && existsInSelectedProducts) {
              // Return null to indicate that this service should be removed
              setSelectedProducts((prevSelectedProducts) =>
                prevSelectedProducts.filter((p) => p.id !== productId)
              )
              return false
            }
            return existsInProductsField && !existsInSelectedProducts
          })
          .filter((product) => product !== null)
      if (
        Array?.isArray(productSelectedFromOptions) &&
        productSelectedFromOptions?.length
      ) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...productSelectedFromOptions
        ])
      }
    }
  }, [watch('products')])

  useEffect(() => {
    previewPrice()
  }, [
    selectedServices,
    selectedProducts,
    watch('promoCode'),
    watch('paymentMethod'),
    watch('appointmentTime'),
    watch('appointmentDate'),
    watch('addOnAmount'),
    watch('discountValue')
  ])

  const getCheckOutOrCheckInButton = () => {
    // const appointmentHasStarted = checkIfAppointmentHasStarted(
    //   appointment?.startAt as string
    // );

    // const appointmentCanCheckInOrCheckOut = appointment?.appointmentStatus === 'pending' || appointment?.appointmentStatus === 'confirmed' || appointment?.appointmentStatus === 'checked_in';
    // const appointmentCanCheckIn = appointmentHasStarted && (appointment?.appointmentStatus === 'pending' || appointment?.appointmentStatus === 'confirmed');
    // const appointmentCanCheckOut = appointmentHasStarted && appointment?.appointmentStatus === 'checked_in';
    // const status = appointment?.appointmentStatus && appointment?.appointmentStatus === 'pending' ? 'confirmed' : null;
    // let status = appointment?.appointmentStatus;

    // if (appointment?.appointmentStatus === 'pending') {
    //   status = 'confirmed';
    // }
    return (
      <>
        {appointment?.appointmentStatus === 'checked_in' ? (
          <Button
            variant='primary'
            className=''
            disabled={
              updateAppointmentIsLoading ||
              actionIsLoading ||
              isLoading ||
              previewAppointmentIsLoading
            }
            loading={updateAppointmentIsLoading || actionIsLoading}
            size='md'
            rounded='lg'
            onClick={() => {
              setAppointmentStatus('completed')
              appointment?.paymentMethod === 'unpaid' ? setValue('hasMadePayment', true) : null
            }}
          >
            {watch('paymentMethod') === 'unpaid'
              ? 'Checkout and pay'
              : 'Check Out'}
          </Button>
        ) : appointment?.appointmentStatus !== 'completed' ? (
          <Button
            variant='primary'
            className=''
            disabled={
              updateAppointmentIsLoading ||
              actionIsLoading ||
              isLoading ||
              previewAppointmentIsLoading ||
              appointment?.appointmentStatus === APPOINTMENT_STATUS.cancelled ||
              appointment?.appointmentStatus === APPOINTMENT_STATUS.no_show
            }
            loading={updateAppointmentIsLoading || actionIsLoading}
            size='md'
            rounded='lg'
            onClick={() => setAppointmentStatus('checked_in')}
          >
            Check in {watch('hasMadePayment') ? ' and pay' : ''}
          </Button>
        ) : null}
      </>
    )
  }

  useEffect(() => {
    if (Array?.isArray(selectedServices)) {
      const startTime = removeTimezoneOffset(convert12HourTo24Hour(watch('appointmentTime')))
      addStartAtToViewAppointmentService(selectedServices, startTime)

      const priceCheckedServices = selectedServices?.map((service) => {
        const servicePrice = checkStaffServicePrice(service);
        return { ...service, servicePrice };
      });
      setSelectedServices(priceCheckedServices);
    }
  }, [selectedServices?.length])

  const closeAddAppointmentModal = (action?: string) => {
    resetFormInput()
    action && setHasMadePayment(null)
    action && setUpdatedAppointment(null)
    setAppointmentStatus('pending')
    setEnableAddons(false)
    setEnablePromo(false)
    setEnableDiscount(false)
    setEnableProductAddition(false)
    setAppointmentSummary(null)
    action ? closeModal(action) : closeModal('close')
    setEditServicePrice(null)
    setShowProductSelection(false)
    setShowServiceSelection(false)
    setShowPaymentSelection(false)
    setShowTipsSection(false)
    setAmountClientPaid(null)
    setServiceNewPrice(null)
    setAmountClientPaid(null)
    setAppointmentTip(null)
    setSplitPayments([])
    setCreditWallet(false)
    setAmountToBeChecked(0)
  }

  const resetFormInput = () => {
    setValue('services', [])
    setValue('products', [])
    setValue('appointmentTime', '')
    setValue('hasMadePayment', null)
    setEnableAddons(false)
    setEnablePromo(false)
    setValue('appointmentDate', '')
    setValue('appointmentTime', '')
    setValue('promoCode', '')
    setValue('addOnAmount', 0)
    setValue('paymentMethod', 'unpaid')
    setValue('addOnReason', '')
    setValue('isDepositCompleted', false)
    setSelectedServices([])
    setSelectedProducts([])
    setShowProductSelection(false)
    setShowServiceSelection(false)
    setShowPaymentSelection(false)
    setShowTipsSection(false)
    setAmountClientPaid(null)
    setServiceNewPrice(null)
    setAmountClientPaid(null)
    setAppointmentTip(null)
    setSplitPayments([])
    setCreditWallet(false)
    setAmountToBeChecked(0)
  }

  const checkPromoCodeValidity = async () => {
    if (appointment) {
      // try {
      //   const { data, success } = await redeemPromo.mutateAsync({
      //     client: {
      //       countryCode: appointment?.client?.callingCode,
      //       callingCode: appointment?.client?.countryCode,
      //       phone: appointment?.client?.phone,
      //     },
      //     promoCode: debouncedSearchQuery,
      //     salonId: salonId as string,
      //     businessId: businessId as string
      //   });
      //   if (success && data) {
      //     setPromoCode({
      //       promoType: data?.promoType,
      //       promoValue: data?.promoValue
      //     })
      //   }
      // } catch (errorBusinessPromoCode) {
      //   if (axios.isAxiosError(errorBusinessPromoCode)) {
      //     const message = errorBusinessPromoCode?.response?.data?.message || 'Promo code could not be applied';
      //     addToast && addToast({
      //       variant: "error",
      //       message,
      //     });
      //   }
      // }
    }
  }

  const checkStaffServicePrice = (service: UpdateAppointmentServiceInputProps) => {
    // Start with the base service price
    let highestPrice = service.price;

    if (service?.staff?.length && service?.serviceStaffs?.length) {
      // Create a set of staff IDs from service.staff to identify relevant staff in serviceStaffs
      const staffIds = new Set(service.staff.map(staff => staff.staffId));

      // Filter serviceStaffs by matching staff IDs, then find the highest price among them
      const filteredServiceStaffPrices = service.serviceStaffs
        .filter(staffService => staffIds.has(staffService?.salonStaff?.id))
        .map(staffService => staffService.price);
      // Find the highest price among the filtered list, if any
      if (filteredServiceStaffPrices.length) {
        const highestServiceStaffPrice = Math.max(...filteredServiceStaffPrices);
        highestPrice = highestServiceStaffPrice;
      }
    }
    return highestPrice;
  };

  useEffect(() => {
    if (
      watch('promoCode') !== '' &&
      appointment &&
      watch('promoCode') !== appointment?.appointmentPromo?.title
    ) {
      checkPromoCodeValidity()
    }
  }, [watch('promoCode')])

  const initiateCloseReceiptModal = () => {
    closeReceiptModal()
    setUpdatedAppointment(null)
  }

  const enableViewReceiptModalAsync = () => {
    setShowReceipt(true)
  }

  const closeProductOrServiceSelectionAndAddAppointmentModal = () => {
    if (showProductSelection || showServiceSelection) {
      setShowProductSelection(false);
      setShowServiceSelection(false);
    } else if (showTipsSection) {
      setShowTipsSection(false);
      setShowPaymentSelection(true);
    } else if (showPaymentSelection) {
      setShowPaymentSelection(false);
      setShowTipsSection(false);
    } else {
      closeAddAppointmentModal();
    }
  }

  const searchItem = (search: string) => {
    // if showProducts is true
    if (showProductSelection) {
      if (search === '') {
        setFilteredProducts(sortProducts(products));
        return;
      }
      const filteredProducts = products?.filter((product) => {
        return product.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredProducts(sortProducts(filteredProducts));
    } else {
      if (search === '') {
        setFilteredServices(sortServices(services));
        return;
      }
      const filteredServices = services?.filter((service) => {
        return service.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredServices(sortServices(filteredServices));
    }
  }

  const addService = (id: string) => {
    // check service in services
    const service = services?.find((service) => service.id === id);
    // check if service exist in selected services
    const isServiceExist = selectedServices.find((service) => service.id === id);
    if (isServiceExist && service) {
      // removeService
      const newSelectedServices = selectedServices.filter(
        (service) => service.id !== id
      );
      setSelectedServices(newSelectedServices);
      return
    }
    if (service && !isServiceExist) {
      // addService
      const newService = { ...service, cartQuantity: 1, servicePrice: service?.price } as unknown as UpdateAppointmentServiceInputProps;
      setSelectedServices([...selectedServices, newService]);
      return;
    }
  }

  const addProduct = (id: string) => {
    // check product in products
    const product = products?.find((product) => product.id === id);
    // check if product exist in selected products
    const isProductExist = selectedProducts.find((product) => product.id === id);
    if (isProductExist && product) {
      // removeProduct
      const newSelectedProducts = selectedProducts.filter(
        (product) => product.id !== id
      );
      setSelectedProducts(newSelectedProducts);
      return
    }
    if (product && !isProductExist) {
      // addProduct
      const newProduct = { ...product, cartQuantity: 1, };
      setSelectedProducts([...selectedProducts, newProduct]);
      return;
    }
  }

  const updateServicePrice = (type: string) => {
    if (type === 'save' && serviceNewPrice && editServicePrice) {
      // check service
      const service = selectedServices.find((service) => service.id === editServicePrice);
      if (service) {
        const newSelectedServices = selectedServices.map((service) => {
          if (service.id === editServicePrice) {
            const price = serviceNewPrice?.replaceAll(",", '')
            return {
              ...service,
              servicePrice: +price,
            };
          }
          return service;
        });
        setSelectedServices(newSelectedServices);
      }
      setEditServicePrice(null);
      setServiceNewPrice(null);
    } else {
      setEditServicePrice(null);
      setServiceNewPrice(null);
    }
  }

  const getPaymentMethodSelectedValue = (value?: string) => {
    if (!value) return [];

    const paymentMethod = APPOINTMENT_PAYMENT_METHODS.find(
      (paymentMethod) => paymentMethod.value === value
    );
    return [paymentMethod];
  }

  const getPaymentMethodIdSelectedValue = (value?: string, type?: string) => {
    if (!value) return [];

    if (type === 'pos' && Array?.isArray(posTerminalsOptions)) {
      const paymentMethod = posTerminalsOptions.find(
        (paymentMethod) => paymentMethod?.value === value
      );
      return [paymentMethod];
    }

    if (type === 'bank' && Array?.isArray(transfersOptions)) {
      const paymentMethod = transfersOptions.find(
        (paymentMethod) => paymentMethod?.value === value
      );
      return [paymentMethod];
    }

    return []
  }

  const onChangeSplitPaymentMethod = (index: number) => (payment: MultiSelectOption) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          paymentMethod: payment?.value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const onChangeSplitPaymentMethodId = (index: number) => (payment: MultiSelectOption) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          paymentMethodId: payment?.value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const addMoreSplitPaymentMethod = () => {
    const newSplitPaymentMethods = [
      ...splitPayments,
      {
        paymentMethod: null,
        paymentMethodId: null,
        amount: 0,
      },
    ];
    setSplitPayments(newSplitPaymentMethods);
  }

  const onChangeSplitPaymentAmount = (index: number, value: number | string) => {
    const newSplitPaymentMethods = splitPayments.map((paymentMethod, i) => {
      if (i === index) {
        return {
          ...paymentMethod,
          amount: value,
        };
      }
      return paymentMethod;
    });
    setSplitPayments(newSplitPaymentMethods);
  }

  const {
    openModal: openViewClientModal,
    closeModal: closeViewClientModal,
    isVisible: isViewClientModalVisible
  } = useModal()
  const clientActions = {
    addClient: canPerformAction(`Clients::${PERMISSION_CONSTANTS.client.add}`),
    seeInfo: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ),
    viewClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ) || canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    deleteClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete}`
    ),
    recordRefund: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.refund}`
    ),
    editClient: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit}`
    ),
    addNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.add_note}`
    ),
    viewNotes: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_note}`
    ),
    editNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.edit_note}`
    ),
    deleteNote: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.delete_note}`
    ),
    viewHistory: canPerformAction(
      `Clients::${PERMISSION_CONSTANTS.client.view_appointment_history}`
    )
  }

  const chooseTip = (tip?: {
    label: string;
    value: number;
  }) => {
    if (!tip) return;

    setValue('tipAmount', tip?.value)
    setAppointmentTip(tip)
  }

  const removeSplitPayment = (index: number) => {
    const newSplitPaymentMethods = splitPayments.filter((paymentMethod, i) => i !== index);
    setSplitPayments(newSplitPaymentMethods);
  }

  const closeAlert = (index) => {
    const newAlerts = alerts.filter((alert, i) => i !== index);
    setAlerts(newAlerts);
  };

  const getAppointmentContent = () => {
    if (showInitialLoadingShimmer) {
      return <ViewAppointmentShimmer />
    }
    if (appointment) {
      return (
        <form
          onSubmit={handleSubmit(updateAppointmentAsync)}
          className='w-full relative pt-[80px] pb-[220px] md:pb-[120px]'
          autoComplete='off'
        >
          <div className='fixed top-0 w-full bg-white flex border-b border-grey-100 cursor-pointer z-10'>
            <div className='w-full hidden xl:flex space-x-2 px-6 py-5 items-start'>
              <Button
                variant='text'
                size='none'
                type='button'
                className='w-fit'
                fontSize='b4'
                onClick={closeProductOrServiceSelectionAndAddAppointmentModal}
              >
                <SvgArrowBack width='24px' height='24px' /> <span>Back</span>
              </Button>
            </div>
            <div className='w-full flex xl:hidden space-x-[69px] p-4 items-center'>
              <Button
                variant='icon'
                size='square'
                type='button'
                rounded='md'
                className='w-fit'
                fontSize='b4'
                onClick={() => closeAddAppointmentModal()}
              >
                <SvgChevronLeft width='24px' height='24px' />
              </Button>
              <Paragraph size='b3' weight='bold'>
                Appointment Details
              </Paragraph>
            </div>
          </div>
          {!showServiceSelection && !showProductSelection && !showTipsSection && !showPaymentSelection ?
            <>
              <div className='w-full flex flex-col px-6 py-4 space-y-6'>
                <div className="w-full flex space-x-2">
                  <Heading variant='h1' size='h9' weight='semiBold' className='flex items-center space-x-4'>
                    <span>{actions?.modifyAppointmennt ? 'Edit' : 'View'} appointment</span> {appointment && (appointment?.appointmentStatus === 'cancelled' || appointment?.appointmentStatus === 'no_show') ? <Pill variant={appointment?.appointmentStatus === "cancelled" ? "danger" : "primary"}>
                      {formatSnakeCaseText(appointment?.appointmentStatus)}
                    </Pill> : null}
                  </Heading>
                  <Button
                    variant="light"
                    size="xs"
                    rounded="lg"
                    type="button"
                    className="border border-grey-100 px-2"
                    onClick={openViewClientModal}
                  >
                    View Client
                  </Button>
                </div>
                <Paragraph size='b4'>Make changes to appointment details</Paragraph>
              </div>
              <div className="w-full xl:w-[400px] fixed flex flex-col h-[100px] right-0 top-8 z-[99999] overflow-y-scroll">
                {Array?.isArray(alerts) && alerts?.length
                  ? alerts?.map((alert, index) => (
                    <div
                      className={`w-full rounded-md p-3 flex space-x-2 bg-orange-25 text-orange-300`}
                      key={index}
                    >
                      <SvgInfo width="25px" height="25px" />
                      <div className="flex flex-col space-y-2">
                        <Heading
                          variant="h2"
                          size="h11"
                          weight="semiBold"
                          color={COLORS.ORANGE[300]}
                        >
                          {alert?.message}
                        </Heading>
                        <Paragraph size="b6" color={COLORS.ORANGE[300]}>
                          Kindly reassign the service to another staff or you
                          may choose to proceed with your selection.
                        </Paragraph>
                      </div>
                      <Button
                        variant="text"
                        size="none"
                        type="button"
                        className="w-fit text-orange-300"
                        fontSize="b4"
                        onClick={() => closeAlert(index)}
                      >
                        <SvgClose width="24px" height="24px" />
                      </Button>
                    </div>
                  ))
                  : null}
              </div>
              {appointment && (hasMadePayment || appointment?.totalAmountPaid > 0) ? (
                <div className='w-full px-6'>
                  <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                    <SvgCheckMarkGreen width='24px' height='24px' />
                    <div className='w-full flex flex-col'>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREEN[850]}
                        weight='semiBold'
                      >
                        {appointment?.client?.firstName +
                          ' ' +
                          appointment?.client?.lastName}
                        {appointment?.isActive ? ' has made ' : "'s "}
                        {appointment?.isActive ? hasMadePayment === 'full' ? 'full payment' : 'a deposit' : 'payment is yet to be confirmed.'}
                      </Paragraph>
                      {appointment?.client?.firstName?.toLowerCase() !== 'walkin' && actions?.checkInOrOut ?
                        <ContactLink
                          variant='tel'
                          value={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                          label={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                        /> : null}
                      <AppointmentPriceCard appointment={appointment} />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='w-full px-6'>
                  <div className='w-full flex p-4 rounded-md bg-green-75 space-x-4 items-start'>
                    <SvgUser width='24px' height='24px' />
                    <div className='w-full flex flex-col'>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREEN[850]}
                        weight='semiBold'
                      >
                        Client:{' '}
                        {appointment?.client?.firstName +
                          ' ' +
                          appointment?.client?.lastName}
                      </Paragraph>
                      {appointment?.client?.firstName?.toLowerCase() !== 'walkin' ?
                        <ContactLink
                          variant='tel'
                          value={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                          label={`${appointment?.client?.callingCode}${appointment?.client?.phone}`}
                        /> : null}
                    </div>
                  </div>
                </div>
              )}
              {appointment && appointment?.appointmentVoucher ? (
                <div className='w-full px-6 my-4'>
                  <div className='w-full flex p-4 rounded-md bg-grey-75 space-x-4 items-start'>
                    <SvgSolarGiftLinear width='24px' height='24px' />
                    <div className='w-full flex flex-col space-y-4 '>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[800]}
                        weight='semiBold'
                      >
                        The{' '}
                        {
                          appointment?.appointmentVoucher?.packageVoucher?.package
                            ?.name
                        }{' '}
                        voucher was used
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ) : null}
              {appointment && appointment?.milestone ? (
                <div className='w-full px-6 my-4'>
                  <div className='w-full flex p-4 rounded-md bg-[#E4B91F] space-x-4 items-center
                  '>
                    <SvgWhiteDiamond width="30px" height="30px" />
                    <div className='w-full flex flex-col space-y-4 '>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[800]}
                        weight='semiBold'
                        className='flex space-x-1'
                      >
                        <span>{
                          appointment?.milestone?.customerReceivesType === 'percentage'
                            ? `${appointment?.milestone?.customerReceives}%`
                            : `${formatInToPrice(appointment?.milestone?.customerReceives)?.replace(".00", "")}`
                        } - </span>
                        <span>Reward discount in use</span>

                      </Paragraph>
                    </div>
                  </div>
                </div>
              ) : null}
              {appointment &&
                appointment?.address &&
                appointment?.locationType === 'home' ? (
                <div className='w-full px-6 my-4'>
                  <div className='w-full flex p-4 rounded-md bg-grey-75 space-x-4 items-start'>
                    <SvgHome width='24px' height='24px' />
                    <div className='w-full flex flex-col space-y-4 '>
                      <Paragraph
                        size='b4'
                        color={COLORS.GREY[800]}
                        weight='semiBold'
                      >
                        Home Address: {appointment?.address}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className='w-full p-6 space-y-6'>
                <Controller
                  control={control}
                  name='appointmentType'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className='w-full flex flex-col space-y-4'>
                        <FormLabel htmlFor='appointment-type'>
                          Appointment type
                        </FormLabel>
                        <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                          {Array.isArray(APPOINTMENT_TYPES) &&
                            APPOINTMENT_TYPES.length
                            ? APPOINTMENT_TYPES.map(
                              (appointmentType: {
                                label: string
                                value: string
                              }) => {
                                return (
                                  <div
                                    className='flex'
                                    onClick={() =>
                                      onChange(appointmentType?.value)
                                    }
                                    key={appointmentType?.value}
                                  >
                                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                      <Checkbox
                                        isChecked={
                                          value === appointmentType?.value
                                        }
                                      />
                                      <span className='text-grey-900 whitespace-nowrap'>
                                        {appointmentType?.label}
                                      </span>
                                    </div>
                                  </div>
                                )
                              }
                            )
                            : null}
                        </div>
                      </div>
                    )
                  }}
                  rules={{
                    required: 'Select where you serve clients'
                  }}
                />
                <div className='w-full flex flex-col xl:flex-row gap-4'>
                  <div className='w-full xl:w-1/2'>
                    <Controller
                      control={control}
                      name='appointmentDate'
                      render={({ field: { value }, formState: { errors } }) => {
                        const errorMessage: string =
                          errors?.appointmentDate?.message
                        return (
                          <div className='flex flex-col space-y-2'>
                            <FormLabel htmlFor='appointment-date'>
                              Appointment Date
                            </FormLabel>
                            <DatePicker
                              className='splice-time-input'
                              value={dayjs(
                                value ||
                                convertFullDateStringToDate(appointment?.startAt),
                                dateFormat
                              )}
                              onChange={onChangeDate}
                              format={dateFormat}
                              disabled={!actions?.rescheduleAppointment || ['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              )}
                            />
                            {errorMessage && (
                              <FormHelperText variant='error'>
                                {errorMessage}
                              </FormHelperText>
                            )}
                          </div>
                        )
                      }}
                      rules={{
                        required: ERRORS.APPOINTMENT_DATE_REQUIRED
                      }}
                    />
                  </div>
                  <div className='w-full xl:w-1/2'>
                    <Controller
                      control={control}
                      name='appointmentTime'
                      render={({ field: { value }, formState: { errors } }) => {
                        const errorMessage: string =
                          errors?.appointmentTime?.message
                        return (
                          <div className='flex flex-col space-y-2'>
                            <FormLabel htmlFor='service'>
                              Appointment Time
                            </FormLabel>
                            <TimePicker
                              className='splice-time-input'
                              use12Hours
                              format='h:mm a'
                              value={dayjs(
                                value ||
                                convertFullDateStringToTime(
                                  appointment?.startAt as string
                                ),
                                'HH:mm'
                              )}
                              onChange={onChangeTime}
                              disabled={['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              )}
                            />
                            {errorMessage && (
                              <FormHelperText variant='error'>
                                {errorMessage}
                              </FormHelperText>
                            )}
                          </div>
                        )
                      }}
                      rules={{
                        required: 'Add appointment time'
                      }}
                    />
                  </div>
                </div>
                <div className='w-full flex flex-col space-y-3'>
                  <div className='w-full flex justify-between items-center'>
                    <Paragraph
                      size="b5"
                      color={COLORS.GREY[300]}
                      weight="bold"
                    > Add services </Paragraph>

                    {selectedServices?.length ?
                      <Button
                        variant="light"
                        size="xs"
                        rounded="lg"
                        type="button"
                        className="border-0 text-grey-300 font-semibold"
                        onClick={() => {
                          setShowServiceSelection(true);
                        }}
                        disabled={['no_show', 'cancelled', 'completed'].includes(
                          appointment.appointmentStatus
                        )}
                      >
                        <SvgPlus width="14px" height="14px" />
                        Add service
                      </Button> : null}
                  </div>
                  {!selectedServices || Array?.isArray(selectedServices) && !selectedServices?.length ?
                    <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                        weight="bold"
                      > No Services added</Paragraph>
                      <Button
                        variant="light"
                        size="xs"
                        rounded="lg"
                        type="button"
                        className="border-0 text-grey-300 font-semibold"
                        onClick={() => {
                          setShowServiceSelection(true);
                        }}
                        disabled={['no_show', 'cancelled', 'completed'].includes(
                          appointment.appointmentStatus
                        )}
                      >
                        <SvgPlus width="14px" height="14px" />
                        Add service
                      </Button>
                    </div> : null}
                  {Array?.isArray(selectedServices) && selectedServices?.length ? selectedServices?.map((service, index) => {
                    const quantity = service?.quantity || 1;
                    const canBeDeleted = (appointment?.appointmentStatus !==
                      APPOINTMENT_STATUS.completed && !appointment?.appointmentVoucher) || (appointment?.appointmentVoucher && appointment?.appointmentVoucher?.packageVoucher?.redemptionMode !== 'services');
                    const priceSlashed = appointment?.appointmentVoucher && appointment?.appointmentVoucher?.packageVoucher?.redemptionMode === 'services' && appointment?.appointmentVoucher?.packageVoucher?.voucherServices?.find((voucherService) => voucherService?.serviceId === service?.id);
                    const serviceTime = !service?.startAt ? getServiceTime(index, selectedServices[index - 1]?.startAt, selectedServices[index - 1]?.duration) : service?.startAt
                    return (
                      <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                        <div className='w-full max-w-[30%]'>
                          <Paragraph
                            size="b5"
                            color={COLORS.GREY[300]}
                            weight="bold"
                          > {service?.name} </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {getHoursAndMinutesString(service?.duration)}
                          </Paragraph>
                        </div>
                        <div className="flex gap-2 flex-wrap w-[30%]">
                          {Array.isArray(service?.staff) && service?.staff.length
                            ? service?.staff.map((eachStaff, index) => (
                              <div
                                className="flex space-x-2 items-center bg-white border border-grey-50 px-[10px] py-2 rounded-full"
                                key={index}
                              >
                                <Paragraph size="b6" color={COLORS.GREY[300]}>
                                  {eachStaff.firstName}
                                </Paragraph>
                                <span
                                  className="text-b6 cursor-pointer"
                                  onClick={() => {
                                    removeStaff(eachStaff?.staffId, service?.id);
                                  }}
                                >
                                  <SvgClose width="14px" height="14px" />
                                </span>
                              </div>
                            ))
                            : null}
                          <div className="w-full max-w-[80px]">
                            <SelectOnchangeField
                              options={formatServiceStaffOptions(service) || []}
                              onChange={(selectedValue) => {
                                onChangeStaff(selectedValue, service?.id); // Call the changeStaff function with the selected value
                              }}
                            />
                          </div>
                        </div>
                        <div className='flex flex-col w-full xl:w-[15%]'>
                          <TimePicker
                            use12Hours
                            disabled={
                              ['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              ) || !actions.modifyAppointmennt
                            }
                            format='h:mm a'
                            value={
                              serviceTime
                                ? dayjs(serviceTime, 'HH:mm')
                                : undefined
                            }
                            onChange={(value, dateString) => {
                              handleServiceTimeChange(
                                service?.id,
                                dateString as string,
                                index
                              )
                            }}
                          />
                        </div>
                        <div className='w-full max-w-[20%] flex justify-end space-x-3'>
                          {!editServicePrice || (editServicePrice && editServicePrice !== service?.id) ?
                            <>
                              <Paragraph
                                size="b4"
                                color={COLORS.GREY[300]}
                                className={`${priceSlashed ? 'line-through' : ''}`}
                              >
                                {DEFAULT_CURRENCY}
                                <FormatNumber
                                  value={service?.servicePrice * quantity}
                                />
                              </Paragraph>
                              {!priceSlashed && !['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              ) ?
                                <span
                                  className="text-b5 cursor-pointer"
                                  onClick={() => {
                                    setEditServicePrice(service?.id);
                                    setServiceNewPrice(formatNumber(service?.servicePrice))
                                  }}
                                  role='button'
                                >
                                  <SvgPencil width="20px" height="20px" />
                                </span> : null}
                            </> : null}
                          {editServicePrice && editServicePrice === service?.id ?
                            <div className="w-full flex items-center space-x-2">
                              <input
                                className="w-full background-none border-b border-grey-400 font-semibold text-b4 text-grey-200 focus:outline-none placeholder:text-grey-100"
                                type="text"
                                value={formatNumber(serviceNewPrice)}
                                onChange={(e) => {
                                  setServiceNewPrice(e.target.value);
                                }}
                                placeholder="20,000"
                              />
                              <span
                                className="text-b5 cursor-pointer"
                                onClick={() => updateServicePrice('save')}
                                role='button'
                              >
                                <SvgCheckMarkGreen width="20px" height="20px" />
                              </span>
                              <span
                                className="text-b5 cursor-pointer"
                                onClick={() => updateServicePrice('save')}
                                role='button'
                              >
                                <SvgClose width="20px" height="20px" />
                              </span>
                            </div> : null}
                          {canBeDeleted && actions?.modifyAppointmennt && !['no_show', 'cancelled', 'completed'].includes(
                            appointment.appointmentStatus
                          ) ?
                            <span
                              className="text-red-600 text-b5 cursor-pointer"
                              onClick={() => addService(service?.id)}
                              role='button'
                            >
                              <SvgTrash width="14px" height="14px" />
                            </span> : null}
                        </div>
                      </div>
                    )
                  }) : null}
                </div>
                <div className='w-full flex flex-col space-y-3'>
                  <div className='w-full flex justify-between items-center'>
                    <Paragraph
                      size="b5"
                      color={COLORS.GREY[300]}
                      weight="bold"
                    > Add products </Paragraph>

                    {selectedProducts?.length ?
                      <Button
                        variant="light"
                        size="xs"
                        rounded="lg"
                        type="button"
                        className="border-0 text-grey-300 font-semibold"
                        onClick={() => {
                          setShowProductSelection(true);
                        }}
                        disabled={['no_show', 'cancelled', 'completed'].includes(
                          appointment.appointmentStatus
                        )}
                      >
                        <SvgPlus width="14px" height="14px" />
                        Add product
                      </Button> : null}
                  </div>
                  {!selectedProducts || Array?.isArray(selectedProducts) && !selectedProducts?.length ?
                    <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center justify-between'>
                      <Paragraph
                        size="b5"
                        color={COLORS.GREY[300]}
                        weight="bold"
                      > No Products added</Paragraph>
                      <Button
                        variant="light"
                        size="xs"
                        rounded="lg"
                        type="button"
                        className="border-0 text-grey-300 font-semibold"
                        onClick={() => {
                          setShowProductSelection(true);
                        }}
                        disabled={['no_show', 'cancelled', 'completed'].includes(
                          appointment.appointmentStatus
                        )}
                      >
                        <SvgPlus width="14px" height="14px" />
                        Add product
                      </Button>
                    </div> : null}
                  {Array?.isArray(selectedProducts) && selectedProducts?.length ? selectedProducts?.map((product) => {
                    const quantity = product?.quantity || 1;
                    return (
                      <div className='w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex items-center'>
                        <div className='w-full max-w-[30%]'>
                          <Paragraph
                            size="b5"
                            color={COLORS.GREY[300]}
                            weight="bold"
                          > {product?.name} </Paragraph>
                        </div>
                        <div className="w-[50%] flex space-x-2">
                          <div className="w-full flex">
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() =>
                                modifyCart(product?.id, 'add', 'product')
                              }
                              disabled={['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              )}
                            >
                              <SvgGreyPlus width="22px" height="22px" />
                            </Button>
                            <div className="flex border-t border-b border-grey-50 px-2 justify-center h-[22px] items-center">
                              <Paragraph
                                size="b6"
                                weight="normal"
                                color={COLORS.BLACK}
                              >
                                {product?.quantity || 1}
                              </Paragraph>
                            </div>
                            <Button
                              variant="icon"
                              size="none"
                              type="button"
                              className="border-0"
                              onClick={() =>
                                modifyCart(product?.id, 'remove', 'product')
                              }
                              disabled={['no_show', 'cancelled', 'completed'].includes(
                                appointment.appointmentStatus
                              )}
                            >
                              <SvgGreyMinus width="22px" height="22px" />
                            </Button>
                          </div>
                        </div>
                        <div className='w-full max-w-[20%] flex justify-end space-x-3'>
                          <Paragraph
                            size="b4"
                            color={COLORS.GREY[300]}
                            className=""
                          >
                            {DEFAULT_CURRENCY}
                            <FormatNumber
                              value={product?.retailPrice * quantity}
                            />
                          </Paragraph>
                          <span
                            className="text-red-600 text-b5 cursor-pointer"
                            onClick={() => addProduct(product?.id)}
                            role='button'
                          >
                            <SvgTrash width="14px" height="14px" />
                          </span>
                        </div>
                      </div>
                    )
                  }) : null}
                </div>
                {(Array.isArray(selectedServices) && selectedServices?.length) ||
                  (Array.isArray(selectedProducts) && selectedProducts?.length)
                  ? getServiceSummary()
                  : null}
                <div className='flex flex-wrap gap-2'>
                  {!enableAddOns && appointment?.appointmentStatus !== 'checked_in' || (!enableAddOns && appointment?.appointmentStatus === 'checked_in' && actions?.editPrices) ? (
                    <Button
                      variant='light'
                      size='xs'
                      rounded='lg'
                      disabled={['no_show', 'cancelled'].includes(
                        appointment.appointmentStatus
                      )}
                      type='button'
                      className='border border-grey-100'
                      onClick={() => {
                        setEnableAddons(true)
                      }}
                    >
                      Add Extra Charge
                      <SvgPlus width='14px' height='14px' />
                    </Button>
                  ) : null}
                  {(!enablePromo && actions?.applyPromo && appointment?.appointmentStatus !== 'checked_in') || (!enablePromo && actions?.applyPromo && appointment?.appointmentStatus === 'checked_in' && actions?.editPrices) ? (
                    <Button
                      variant='light'
                      size='xs'
                      rounded='lg'
                      type='button'
                      disabled={['no_show', 'cancelled'].includes(
                        appointment.appointmentStatus
                      )}
                      className='border border-grey-100'
                      onClick={() => {
                        setEnablePromo(true)
                      }}
                    >
                      Add promo code
                      <SvgPlus width='14px' height='14px' />
                    </Button>
                  ) : null}
                  {!enableDiscount && !appointment.discountType && actions?.applyDiscounts ? (
                    <Button
                      variant='light'
                      size='xs'
                      rounded='lg'
                      type='button'
                      disabled={['no_show', 'cancelled'].includes(
                        appointment.appointmentStatus
                      )}
                      className='border border-grey-100'
                      onClick={() => {
                        setEnableDiscount(true)
                      }}
                    >
                      Add discount
                      <SvgPlus width='14px' height='14px' />
                    </Button>
                  ) : null}
                </div>
                {enableProductAddition ? (
                  <Controller
                    control={control}
                    name='products'
                    render={({
                      field: { onChange, value },
                      formState: { errors }
                    }) => {
                      const errorMessage: string = errors?.products?.message
                      return (
                        <div className='w-full flex flex-col space-y-2'>
                          <FormLabel htmlFor='service'>Add Product(s)</FormLabel>
                          <MultiSelect
                            selected={value || []}
                            options={productsOptions}
                            setSelected={onChange}
                            disabled={
                              appointment?.appointmentStatus ===
                              APPOINTMENT_STATUS.completed || !actions?.modifyAppointmennt
                            }
                          />
                          {errorMessage && (
                            <FormHelperText variant='error'>
                              {errorMessage}
                            </FormHelperText>
                          )}
                        </div>
                      )
                    }}
                  />
                ) : null}
                {enableAddOns ? (
                  <div className='w-full flex gap-x-4'>
                    <div className='w-1/2'>
                      <Input
                        name='addOnReason'
                        disabled={
                          appointment?.appointmentStatus ===
                          APPOINTMENT_STATUS.completed
                        }
                        label='Extra Charge'
                        id='addons'
                        type='text'
                        placeholder='Extra Charge'
                        control={control}
                        error={errors.addOnReason}
                      />
                    </div>
                    <Controller
                      control={control}
                      name='addOnAmount'
                      rules={{
                        required: watch('addOnReason')
                          ? ERRORS.ADDON_AMOUNT_REQUIRED
                          : false
                      }}
                      render={({ field }) => (
                        <div className='w-1/2 flex flex-col space-y-2'>
                          <FormLabel htmlFor='adds-on-amount'>
                            Amount
                          </FormLabel>
                          <input
                            className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                            type='text'
                            {...field}
                            value={formatNumber(field.value)}
                            onChange={(e) =>
                              field.onChange(formatNumber(e.target.value))
                            }
                            placeholder='Amount'
                            disabled={
                              appointment?.appointmentStatus ===
                              APPOINTMENT_STATUS.completed || (appointment?.appointmentStatus ===
                                APPOINTMENT_STATUS.checked_in && !actions?.editPrices)
                            }
                          />
                          {errors?.addOnAmount?.message && (
                            <FormHelperText variant='error'>
                              {errors.addOnAmount.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                ) : null}
                {enablePromo ? (
                  <div className='w-1/2 pr-2'>
                    <Input
                      name='promoCode'
                      disabled={!!appointment?.appointmentPromo?.title || !actions?.applyPromo}
                      label='Enter promo code'
                      id='promoCode'
                      type='text'
                      placeholder='Promo code'
                      control={control}
                      error={errors.promoCode}
                    />
                  </div>
                ) : null}
                <Controller
                  control={control}
                  name='note'
                  render={({
                    field: { onChange, onBlur, name, ref, value },
                    formState: { errors }
                  }) => {
                    const maxLength = MAXIMUM_NOTE_LENGTH
                    const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                      errors?.note?.message as string
                    )
                    const helperTexts = []

                    helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                    if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                    return (
                      <FormTextarea
                        type='text'
                        id='about'
                        label='Note'
                        placeholder='Add a note'
                        {...{
                          ref,
                          name,
                          value,
                          onChange,
                          onBlur,
                          maxLength
                        }}
                        helperText={helperTexts}
                      />
                    )
                  }}
                />
                {appointment.paymentMethod === 'unpaid' || (appointment?.appointmentStatus ===
                  APPOINTMENT_STATUS.deposit_pending) ?
                  <div className='w-full flex flex-col space-y-1'>
                    <Controller
                      control={control}
                      name='hasMadePayment'
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div
                            className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                            onClick={() => onChange(!value)}
                          >
                            <div className="flex items-center space-x-2">
                              <FormLabel htmlFor='hasMadePayment'>
                                Client has made payment
                              </FormLabel>
                              {appointment?.appointmentStatus ===
                                APPOINTMENT_STATUS.deposit_pending && appointment?.totalAmountPaid < appointmentSummary?.depositAmount ?
                                <span
                                  className='w-fit flex items-center text-green-300 text-b6 xl:text-b5 font-medium space-x-2 border-b border-dashed'
                                  role="button"
                                  onClick={openDepositLinkModal}
                                >
                                  Visit link <SvgFaqQuestion width="20px" height="20px" />
                                </span> : null}
                            </div>
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <ToggleOnly isChecked={value} />
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                  : null}
              </div>
            </> : null}
          {showServiceSelection ?
            <div className='w-full flex flex-col p-4 gap-y-3'>
              <div className='w-full flex justify-between items-center'>
                <Paragraph size="b3" color={COLORS.GREY[300]} weight='bold'>
                  Select service
                </Paragraph>

                <div className="w-2/5">
                  <SearchTerm
                    placeholder='Search.'
                    showIcon={false}
                    setDebouncedSearchQuery={searchItem}
                  />
                </div>
              </div>
              <div className='w-full flex flex-col space-y-3'>
                {filteredServices && Array?.isArray(filteredServices) && filteredServices?.length ? filteredServices?.map((service) => {
                  const isChecked = selectedServices?.some((selectedService) => selectedService?.id === service?.id);
                  return (
                    <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addService(service?.id)}>
                      <div className='flex space-x-2 items-start'>
                        <Checkbox isChecked={isChecked} />
                        <div className='flex flex-col space-y-1'>
                          <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                            {service?.name}
                          </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {getHoursAndMinutesString(service?.duration)}
                          </Paragraph>
                        </div>
                      </div>

                      <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                        {formatInToPrice(service?.price)}
                      </Paragraph>
                    </div>
                  )
                }) : null}
              </div>
            </div> : null}
          {showProductSelection ?
            <div className='w-full flex flex-col p-4 gap-y-3'>
              <div className='w-full flex justify-between items-center'>
                <Paragraph size="b3" color={COLORS.GREY[300]} weight='bold'>
                  Select product
                </Paragraph>

                <div className="w-2/5">
                  <SearchTerm
                    placeholder='Search.'
                    showIcon={false}
                    setDebouncedSearchQuery={searchItem}
                  />
                </div>
              </div>
              <div className='w-full flex flex-col space-y-3'>
                {filteredProducts && Array?.isArray(filteredProducts) && filteredProducts?.length ? filteredProducts?.map((product) => {
                  const isChecked = selectedProducts?.some((selectedProduct) => selectedProduct?.id === product?.id);
                  return (
                    <div className="w-full bg-grey-20/40 border-grey-100 rounded-sm border-l-4 py-3 px-4 flex justify-between items-start cursor-pointer" onClick={() => addProduct(product?.id)}>
                      <div className='flex space-x-2 items-start'>
                        <Checkbox isChecked={isChecked} />
                        <div className='flex flex-col space-y-1'>
                          <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                            {product?.name}
                          </Paragraph>
                          <Paragraph size="b6" color={COLORS.GREY[300]}>
                            {product?.stockCount} item(s) left
                          </Paragraph>
                        </div>
                      </div>

                      <Paragraph size="b5" color={COLORS.GREY[300]} weight='bold'>
                        {formatInToPrice(product?.retailPrice)}
                      </Paragraph>
                    </div>
                  )
                }) : null}
              </div>
            </div> : null}

          {showPaymentSelection ?
            <>
              <div className="w-full flex flex-col items-start px-6 py-4 space-y-6">
                <div className="w-full xl:w-1/2 flex flex-col space-y-4">
                  <Heading variant="h1" size="h9" weight="semiBold">
                    {appointment?.appointmentStatus === 'checked_in' ? 'Check out' : appointment?.appointmentStatus === 'completed' ? 'Save' : 'Check in'} Appointment
                  </Heading>
                  <Paragraph size="b4">
                    Select payment method and enter amount collected
                  </Paragraph>
                </div>

                <div className="w-full flex flex-col gap-6">
                  <div className='w-full border border-green-300 shadow-md flex flex-col space-y-3 py-3 px-4 rounded-md'>
                    {appointmentSummary?.depositAmount > 0 && (!appointment?.totalAmountPaid || appointment?.totalAmountPaid === 0) ?
                      <div className='w-full flex items-center justify-between'>
                        <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                          Deposit required
                        </Paragraph>

                        <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                          {formatInToPrice(appointmentSummary?.depositAmount)}
                        </Paragraph>
                      </div> : null}
                    <div className='w-full flex items-center justify-between'>
                      <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                        Total
                      </Paragraph>

                      <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                        {formatInToPrice(appointmentSummary?.totalPaid)}
                      </Paragraph>
                    </div>
                    {appointmentSummary && appointment?.depositAmount > 0 && appointment?.totalAmountPaid > 0 && appointment?.totalAmountPaid < appointment?.depositAmount ?
                      <div className='flex justify-between items-center'>
                        <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                          Deposit Balance
                        </Paragraph>
                        <Paragraph size='b4' color={COLORS.GREY[900]} weight='semiBold'>
                          {formatInToPrice(
                            appointmentSummary?.depositAmount - appointment?.totalAmountPaid
                          )}
                        </Paragraph>
                      </div> : null}
                    {appointmentSummary && appointmentSummary?.totalPaid && appointment?.paymentMethod ===
                      APPOINTMENT_PAYMENT_METHOD.unpaid &&
                      appointment?.totalPaid - appointment?.totalAmountPaid !== 0 &&
                      appointment?.depositAmount ? (
                      <div className='flex justify-between items-center'>
                        <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                          Outstanding Balance
                        </Paragraph>
                        <Paragraph size='b4' color={COLORS.GREY[900]} weight='semiBold'>
                          {formatInToPrice(
                            appointmentSummary?.totalPaid - appointment?.totalAmountPaid
                          )}
                        </Paragraph>
                      </div>
                    ) : null}
                    {appointmentSummary && appointmentSummary?.totalPaid && appointment?.paymentMethod ===
                      APPOINTMENT_PAYMENT_METHOD.unpaid &&
                      appointment?.totalPaid - appointment?.totalAmountPaid !== 0 &&
                      appointment?.depositAmount === 0 ? (
                      <div className='flex justify-between items-center'>
                        <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
                          Outstanding Balance
                        </Paragraph>
                        <Paragraph size='b4' color={COLORS.GREY[900]} weight='semiBold'>
                          {formatInToPrice(
                            appointmentSummary?.totalPaid - appointment?.totalAmountPaid
                          )}
                        </Paragraph>
                      </div>
                    ) : null}
                  </div>

                  <div className='w-full flex flex-col space-y-2'>
                    <Paragraph size="b4">
                      Select payment method
                    </Paragraph>
                    <div className='w-full flex flex-wrap gap-4'>
                      {Array.isArray(APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS) && APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS.length
                        ? APPOINTMENT_SPLIT_AND_WALLET_PAYMENT_METHODS.map(
                          (paymentMethod) => {
                            const Icon = paymentMethod?.icon;
                            const activePaymentMethod = watch('paymentMethod') === paymentMethod?.value;
                            const isDisabled = false;

                            return (
                              <div
                                className="flex"
                                onClick={() => !isDisabled ? setValue('paymentMethod', paymentMethod?.value) : null}
                                key={paymentMethod?.value}
                              >
                                <div className={`flex flex-col items-center justify-center w-[165px] h-[95px] ${activePaymentMethod ? 'bg-green-300/10' : 'bg-grey-50/70'} border ${activePaymentMethod ? 'border-green-300' : 'border-grey-100'} rounded-md ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-b5 ${activePaymentMethod ? 'text-green-300' : 'text-grey-300'} space-y-2`}>
                                  <Icon height="24px" width="24px" color={activePaymentMethod ? COLORS.GREEN[300] : COLORS.GREY[300]} />
                                  <span className={`flex flex-col items-center justify-center ${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                    {paymentMethod?.label}
                                    {paymentMethod?.value === 'client_wallet' && appointment?.client ? (
                                      <span className={`${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                        {formatInToPrice(appointment?.client?.walletBalance || 0)}
                                      </span>
                                    ) : null}
                                  </span>

                                </div>
                              </div>
                            );
                          }
                        )
                        : null}
                    </div>
                  </div>

                  {watch('paymentMethod') !== 'unpaid' && watch('paymentMethod') !== 'split' ?
                    <>
                      {watch('paymentMethod') === 'pos' &&
                        posTerminals &&
                        posTerminals.length ? (
                        <SelectInput
                          name="paymentMethodId"
                          id="transfers"
                          label="Choose your preferred POS for payment"
                          rules={{
                            required: ERRORS.POS_REQUIRED,
                          }}
                          control={control}
                          error={errors.paymentMethodId}
                          options={posTerminalsOptions}
                          placeholder="Select Point-of-Sale Terminal"
                        />
                      ) : null}
                      {watch('paymentMethod') === 'bank_transfer' &&
                        bankTransfers &&
                        bankTransfers.length ? (
                        <SelectInput
                          name="paymentMethodId"
                          id="pos"
                          label="Choose your preferred Bank for payment"
                          control={control}
                          rules={{
                            required: ERRORS.TRANSFER_ACCOUNT_REQUIRED,
                          }}
                          error={errors.paymentMethodId}
                          options={transfersOptions}
                          placeholder="Select Bank"
                        />
                      ) : null}
                      <div className='w-full flex flex-col space-y-2'>
                        <FormLabel htmlFor='amount'>
                          Amount
                        </FormLabel>
                        <div className='w-full flex space-x-3 p-3 rounded-md border border-grey-100'>
                          <Paragraph size='b3' weight='bold'>
                            {country?.currency?.symbol}
                          </Paragraph>
                          <input
                            className="w-full background-none font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                            type="text"
                            value={validateDecimalInput(amountClientPaid)}
                            onChange={(e) => {
                              setAmountClientPaid(e.target.value);
                            }}
                            placeholder="20,000"
                          />
                        </div>
                      </div>
                    </>
                    : null}
                  {watch('paymentMethod') && watch('paymentMethod') === 'split' ?
                    <>
                      <div className='w-full flex flex-col space-y-3'>
                        {Array.isArray(splitPayments) && splitPayments?.map((payment, index) => {
                          return (
                            <div className="w-full flex flex-col gap-3" key={index}>
                              <div className='w-full flex items-center gap-3'>
                                <div className='w-full flex flex-col space-y-2'>
                                  <FormLabel htmlFor='amount'>
                                    Payment method {index + 1}
                                  </FormLabel>
                                  <SingleSelect
                                    selected={getPaymentMethodSelectedValue(payment?.paymentMethod) || []}
                                    options={APPOINTMENT_PAYMENT_METHODS?.filter((paymentMethod) => paymentMethod?.value !== 'unpaid')}
                                    setSelected={onChangeSplitPaymentMethod(index)}
                                    placeholder='Select payment method'
                                  />
                                </div>

                                {/* amount input */}
                                <div className='w-full flex flex-col space-y-2'>
                                  <FormLabel htmlFor='amount'>
                                    Amount to collect
                                  </FormLabel>
                                  <div className='w-full flex space-x-3 px-3 py-2 rounded-md border border-grey-100'>
                                    <Paragraph size='b3' weight='bold'>
                                      {country?.currency?.symbol}
                                    </Paragraph>
                                    <input
                                      className="w-full background-none font-semibold text-b4 text-grey-900 focus:outline-none placeholder:text-grey-300"
                                      type="text"
                                      value={validateDecimalInput(payment?.amount)}
                                      onChange={(e) => {
                                        onChangeSplitPaymentAmount(index, e.target.value);
                                      }}
                                      placeholder="Enter amount"
                                    />
                                  </div>
                                </div>
                                <span
                                  className="text-red-600 text-b5 cursor-pointer"
                                  onClick={() => removeSplitPayment(index)}
                                  role='button'
                                >
                                  <SvgTrash width="14px" height="14px" />
                                </span>
                              </div>
                              {payment?.paymentMethod === 'pos' && posTerminals?.length ? (
                                <div className='flex flex-col space-y-2'>
                                  <FormLabel htmlFor='paymentMethodIdPos'>
                                    Choose your preferred POS for payment
                                  </FormLabel>
                                  <SingleSelect
                                    selected={getPaymentMethodIdSelectedValue(payment?.paymentMethodId, 'pos') || []}
                                    options={posTerminalsOptions}
                                    setSelected={onChangeSplitPaymentMethodId(index)}
                                    placeholder='Select Point-of-Sale Terminal'
                                  />
                                </div>
                              ) : null}
                              {payment?.paymentMethod === 'bank_transfer' && bankTransfers?.length ? (
                                <div className='flex flex-col space-y-2'>
                                  <FormLabel htmlFor='paymentMethodIdBank'>
                                    Choose your preferred Bank for payment
                                  </FormLabel>
                                  <SingleSelect
                                    selected={getPaymentMethodIdSelectedValue(payment?.paymentMethodId, 'bank') || []}
                                    options={transfersOptions}
                                    setSelected={onChangeSplitPaymentMethodId(index)}
                                    placeholder='Select Bank'
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                        {splitPayments && splitPayments?.length < 2 ?
                          <Button
                            variant='light'
                            size='xs'
                            rounded='lg'
                            type="button"
                            className='w-fit border border-grey-100'
                            onClick={addMoreSplitPaymentMethod}
                          >
                            Add payment method
                            <SvgPlus width="14px" height="14px" />
                          </Button> : null}
                      </div>
                    </> : null}
                </div>
              </div>
            </> : null}
          {showTipsSection && appointment?.appointmentStatus === APPOINTMENT_STATUS?.checked_in ?
            <>
              <div className="w-full flex flex-col items-start px-6 py-4 space-y-6">
                <div className="w-full xl:w-1/2 flex flex-col space-y-4">
                  <Heading variant="h1" size="h9" weight="semiBold">
                    {appointment?.appointmentStatus === 'checked_in' ? 'Check out' : appointment?.appointmentStatus === 'completed' ? 'Save' : 'Check in'} Appointment
                  </Heading>
                  <Paragraph size="b4">
                    Select tip
                  </Paragraph>
                </div>

                <div className="w-full flex flex-col gap-6">
                  <div className='w-full border border-green-300 shadow-md flex flex-col space-y-3 py-3 px-4 rounded-md'>
                    <div className='w-full flex items-center justify-between'>
                      <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[300]}>
                        Total
                      </Paragraph>

                      <Paragraph size="b4" weight='semiBold' color={COLORS.GREY[900]}>
                        {formatInToPrice(appointmentSummary?.totalPaid + (appointmentTip?.value || 0))}
                      </Paragraph>
                    </div>
                  </div>
                  <div className='w-full flex flex-col space-y-2'>
                    <Paragraph size="b4">
                      Select preferred tip
                    </Paragraph>
                    <div className='w-full flex flex-wrap gap-4'>
                      {getTips(appointmentSummary?.totalPaid)?.map(
                        (tip) => {
                          const activePaymentMethod = appointmentTip?.label === tip?.label;
                          return (
                            <div
                              className="flex"
                              onClick={() => chooseTip(tip)}
                              key={tip?.value}
                            >
                              <div className={`flex flex-col items-center justify-center w-[165px] h-[95px] ${activePaymentMethod ? 'bg-green-300/10' : 'bg-grey-50/70'} border ${activePaymentMethod ? 'border-green-300' : 'border-grey-100'} rounded-md cursor-pointer text-b5 ${activePaymentMethod ? 'text-green-300' : 'text-grey-300'} space-y-2`}>
                                <span className={`flex flex-col items-center justify-center ${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                  {tip?.label}
                                </span>
                                {tip?.value !== 0 ? <span className={`flex flex-col items-center justify-center ${activePaymentMethod ? 'text-green-300' : 'text-grey-400'} text-b4 whitespace-nowrap`}>
                                  +{formatInToPrice(tip?.value)}
                                </span> : null}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    {appointmentTip?.label?.toLowerCase() === 'custom' ? <div className='w-full flex flex-col space-y-2'>
                      <FormLabel htmlFor='amount'>
                        Amount to collect
                      </FormLabel>
                      <div className='w-full flex space-x-3 p-3 rounded-md border border-grey-100'>
                        <Paragraph size='b3' weight='bold'>
                          {country?.currency?.symbol}
                        </Paragraph>
                        <input
                          className="w-full background-none font-semibold text-b4 text-grey-300 focus:outline-none placeholder:text-grey-300"
                          type="text"
                          value={validateDecimalInput(appointmentTip?.value)}
                          onChange={(e) => {
                            setAppointmentTip({
                              ...appointmentTip,
                              value: +e.target.value,
                            });
                            console.log({
                              ...appointmentTip,
                              value: +e.target.value,
                            })
                            setValue('tipAmount', +e.target.value)
                          }}
                          placeholder="Enter amount"
                        />
                      </div>
                    </div> : null}
                  </div>
                </div>
              </div>
            </>
            : null}
          <div className='w-full max-w-[850px] fixed bottom-0 bg-white flex justify-between border-t border-grey-100 cursor-pointer'>
            {!showProductSelection && !showServiceSelection ?
              <div className='w-full flex flex-col-reverse md:flex-row gap-2 md:justify-between py-4 px-8'>
                {actions?.modifyAppointmennt ?
                  <div className='bg-white py-2 flex'>
                    <Button
                      variant='light'
                      size='md'
                      rounded='lg'
                      className=''
                      fontWeight='semiBold'
                      onClick={
                        appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.no_show &&
                          appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.cancelled &&
                          appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.completed
                          ? enableViewReceiptModalAsync
                          : openReceiptModal
                      }
                      type={
                        appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.no_show &&
                          appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.cancelled &&
                          appointment?.appointmentStatus !==
                          APPOINTMENT_STATUS.completed
                          ? 'submit'
                          : 'button'
                      }
                      disabled={
                        updateAppointmentIsLoading || previewAppointmentIsLoading
                      }
                      loading={
                        updateAppointmentIsLoading || previewAppointmentIsLoading
                      }
                    >
                      View Receipt
                    </Button>
                  </div> : null}
                <div className='w-full md:w-[60%] py-2 flex space-x-2 justify-start md:justify-end'>
                  {actions?.modifyAppointmennt && appointment?.appointmentStatus !==
                    APPOINTMENT_STATUS.cancelled &&
                    appointment?.appointmentStatus !==
                    APPOINTMENT_STATUS.completed ? (
                    <Button
                      variant='secondary'
                      className=''
                      disabled={
                        updateAppointmentIsLoading || previewAppointmentIsLoading
                      }
                      loading={
                        updateAppointmentIsLoading || previewAppointmentIsLoading
                      }
                      size='md'
                      rounded='lg'
                      onClick={() => {
                        setAppointmentStatus(appointment?.appointmentStatus)
                      }}
                    >
                      Save {watch('hasMadePayment') ? ' and pay' : ''}
                    </Button>
                  ) : null}
                  {actions?.checkInOrOut ? getCheckOutOrCheckInButton() : null}
                  {(actions?.cancelAppointment || actions?.deleteAppointment || actions?.markNoShow) && appointment?.appointmentStatus !==
                    APPOINTMENT_STATUS.completed &&
                    appointment?.appointmentStatus !== APPOINTMENT_STATUS.no_show &&
                    appointment?.appointmentStatus !==
                    APPOINTMENT_STATUS.cancelled ? (
                    <Menu
                      menuButton={
                        <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                          <SvgCharmMenuKebab width='24px' height='24px' />
                        </MenuButton>
                      }
                      key='top'
                      direction='top'
                      transition
                    >
                      {actions?.markNoShow ?
                        <MenuItem>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={markAsNoShow}
                          >
                            Mark as No Show
                          </span>
                        </MenuItem> : null}
                      {actions?.cancelAppointment ?
                        <MenuItem>
                          <span
                            className='text-red-600 text-b4 cursor-pointer'
                            onClick={cancelAppointment}
                          >
                            Cancel Appointment
                          </span>
                        </MenuItem> : null}
                      {actions?.deleteAppointment ?
                        <MenuItem>
                          <span
                            className='text-red-600 text-b4 cursor-pointer'
                            onClick={deleteAppointment}
                          >
                            Delete Appointment
                          </span>
                        </MenuItem> : null}
                    </Menu>
                  ) : null}
                  {actions?.revertCancellation && appointment.appointmentStatus ===
                    APPOINTMENT_STATUS.cancelled && (
                      <Menu
                        menuButton={
                          <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                            <SvgCharmMenuKebab width='24px' height='24px' />
                          </MenuButton>
                        }
                        key='top'
                        direction='top'
                        transition
                      >
                        <MenuItem>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={revertCancellation}
                          >
                            Undo cancellation
                          </span>
                        </MenuItem>
                      </Menu>
                    )}
                  {actions?.revertCancellation && appointment.appointmentStatus ===
                    APPOINTMENT_STATUS.no_show && (
                      <Menu
                        menuButton={
                          <MenuButton className='border border-grey-900 px-[14px] py-2 rounded-md'>
                            <SvgCharmMenuKebab width='24px' height='24px' />
                          </MenuButton>
                        }
                        key='top'
                        direction='top'
                        transition
                      >
                        <MenuItem className='w-[150px]'>
                          <span
                            className='text-b4 cursor-pointer'
                            onClick={revertCancellation}
                          >
                            Undo no show
                          </span>
                        </MenuItem>
                      </Menu>
                    )}
                </div>
              </div> : null}
            {showProductSelection || showServiceSelection ?
              <div className="w-full flex space-x-2 py-4 px-8 justify-end">
                <Button
                  variant="primary"
                  className=""
                  size="md"
                  rounded="lg"
                  type="button"
                  onClick={() => {
                    setShowProductSelection(false)
                    setShowServiceSelection(false)
                  }}
                >
                  Add
                </Button>
              </div> : null}
          </div>
          <CreditWalletBalance isVisible={isCreditWalletDialogModalVisible} closeModal={closeCreditWalletDialogModal} setCreditWallet={setCreditWallet} totalPrice={appointmentSummary?.totalPaid || 0} amountClientPaid={amountToBeChecked} />
        </form>
      )
    }

    return null
  }

  const closeAppointmentForRebook = () => {
    closeModal('')
    setHasMadePayment(null)
    resetFormInput()
  }


  const updateAppointmentAsync = async (input: UpdateAppointmentInput) => {
    const appointmentDate = input?.appointmentDate.replace(/\//g, '-')
    // const startAt = new Date(`${input.appointmentDate} ${input.appointmentTime}`).toISOString();
    const startAt = `${appointmentDate}T${removeTimezoneOffset(convert12HourTo24Hour(
      input.appointmentTime
    ))}`
    // throw an error is selectedServices is empty
    if (!selectedServices.length) {
      addToast &&
        addToast({
          message: 'Kindly select a service',
          variant: 'error'
        })
      return
    }

    const appointmentServices = getUpdateAppointmentServiceInputData(
      selectedServices,
      appointmentDate
    )

    const servicesWithoutStaff = appointmentServices.filter(
      (service) => service.staffIds === null || service?.staffIds?.length === 0
    )
    if (servicesWithoutStaff && servicesWithoutStaff.length) {
      addToast &&
        addToast({
          message: 'Kindly select a staff for each service',
          variant: 'error'
        })
      return
    }

    const servicesWithNullStartAt = appointmentServices.filter(
      (service) => service.startAt === null
    )
    if (servicesWithNullStartAt && servicesWithNullStartAt.length) {
      addToast &&
        addToast({
          message: 'Kindly select a time for each service',
          variant: 'error'
        })
      return
    }

    if (!showPaymentSelection && appointment?.paymentMethod === 'unpaid' && input?.paymentMethod === 'unpaid' && input?.hasMadePayment) {
      setShowTipsSection(false)
      setShowPaymentSelection(true)
      return;
    }

    if (showPaymentSelection && input?.paymentMethod === 'unpaid') {
      addToast({
        message: 'Kindly select a payment method',
        variant: 'error'
      })
      return;
    }

    let status = appointmentStatus
    if (appointmentStatus === APPOINTMENT_STATUS.pending) {
      status = APPOINTMENT_STATUS?.confirmed
    } else if (
      input?.isDepositCompleted &&
      input?.paymentMethod !== 'unpaid' &&
      status === APPOINTMENT_STATUS.deposit_pending
    ) {
      status = APPOINTMENT_STATUS?.confirmed
    } else if (
      input?.paymentMethod !== 'unpaid' &&
      status === APPOINTMENT_STATUS.deposit_pending
    ) {
      status = APPOINTMENT_STATUS?.confirmed
    }

    const amountCollected = amountClientPaid ? Number(amountClientPaid?.toString()?.replaceAll(",", "")) : 0;
    if (showPaymentSelection && input?.paymentMethod !== 'unpaid' && input?.paymentMethod !== 'split' && amountCollected === 0) {
      addToast({
        message: 'Kindly enter amount',
        variant: 'error'
      })
      return;
    }

    if (showPaymentSelection && input?.paymentMethod === 'client_wallet' && +amountCollected > client?.walletBalance) {
      addToast({
        message: 'Kindly enter amount less than or equal to client wallet balance',
        variant: 'error'
      })
      return;
    }

    if (appointment?.appointmentStatus ===
      APPOINTMENT_STATUS.checked_in && !appointmentTip && appointmentStatus === 'completed') {
      setShowPaymentSelection(false)
      setShowTipsSection(true)
      return;
    }

    let paymentMethodsInput;
    let appointmentSplitPayments;

    const totalAmountPaidSoFar = appointment?.totalAmountPaid + amountCollected;
    if (amountCollected > 0) {
      // if payment method is split kinda sum the amount and flag if amount is greate than appointmentSummary?.totalPaid
      if (input?.paymentMethod === 'split') {
        let totalAmount = totalAmountPaidSoFar
        splitPayments?.map((splitPayment) => {
          totalAmount += parseFloat(splitPayment?.amount?.toString().replace(/,/g, ""))
        })
        if (totalAmount > appointmentSummary?.totalPaid && !creditWallet) {
          setAmountToBeChecked(totalAmount)
          openCreditWalletDialogModal()
          return;
        }
      } else {
        if (totalAmountPaidSoFar > appointmentSummary?.totalPaid && !creditWallet) {
          setAmountToBeChecked(amountCollected)
          openCreditWalletDialogModal()
          return;
        }
      }
    }
    if (
      appointment?.depositPaymentMethod !== 'unpaid' &&
      input?.paymentMethod === 'unpaid'
    ) {
      paymentMethodsInput = {
        appointmentPaymentMethodId: appointment?.depositPaymentMethodId,
        appointmentPaymentMethod: appointment?.depositPaymentMethod,
        paymentType: 'deposit_payment'
      }
    } else if (
      appointment?.depositAmount > 0 &&
      appointment?.paymentMethod === 'unpaid' &&
      input?.paymentMethod !== 'unpaid' && input?.paymentMethod !== 'split' &&
      appointment?.depositPaymentMethod !== 'unpaid'
    ) {
      if (totalAmountPaidSoFar >= appointmentSummary?.totalPaid) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: input?.paymentMethodId,
          appointmentPaymentMethod: input?.paymentMethod,
          paymentType: 'balance_payment'
        }
      } else if (totalAmountPaidSoFar < appointmentSummary?.totalPaid) {
        appointmentSplitPayments = [
          {
            splitPaymentMethodId: input?.paymentMethodId || null,
            splitPaymentMode: input?.paymentMethod,
            amount: +amountCollected,
          },
        ];
      }
    } else if (
      (!appointment?.depositAmount || appointment?.depositAmount === 0) &&
      appointment?.paymentMethod === 'unpaid' &&
      input?.paymentMethod !== 'unpaid' && input?.paymentMethod !== 'split' &&
      appointment?.depositPaymentMethod === 'unpaid'
    ) {
      if (totalAmountPaidSoFar >= appointmentSummary?.totalPaid) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: input?.paymentMethodId,
          appointmentPaymentMethod: input?.paymentMethod,
          paymentType: appointment?.totalAmountPaid > 0 ? 'balance_payment' : 'full_payment',
        }
        appointmentSplitPayments = [
          {
            splitPaymentMethodId: input?.paymentMethodId || null,
            splitPaymentMode: input?.paymentMethod,
            amount: +amountCollected,
          },
        ];
      } else if (totalAmountPaidSoFar < appointmentSummary?.totalPaid) {
        appointmentSplitPayments = [
          {
            splitPaymentMethodId: input?.paymentMethodId || null,
            splitPaymentMode: input?.paymentMethod,
            amount: +amountCollected,
          },
        ];
        paymentMethodsInput = {
          appointmentPaymentMethodId: null,
          appointmentPaymentMethod: 'unpaid',
          paymentType: 'balance_payment',
        };
      }
    } else if (appointment?.depositAmount > 0 &&
      input?.paymentMethod !== 'unpaid' && input?.paymentMethod !== 'split' &&
      appointment?.depositPaymentMethod === 'unpaid' && appointment?.paymentMethod === 'unpaid'
    ) {
      if (appointment?.depositAmount > 0 && totalAmountPaidSoFar === appointmentSummary?.depositAmount) {
        paymentMethodsInput = {
          appointmentPaymentMethodId: input?.paymentMethodId,
          appointmentPaymentMethod: input?.paymentMethod,
          paymentType: 'deposit_payment',
        };
        appointmentSplitPayments = [
          {
            splitPaymentMethodId: input?.paymentMethodId || null,
            splitPaymentMode: input?.paymentMethod,
            amount: +amountCollected,
          },
        ];
      } else {
        appointmentSplitPayments = [
          {
            splitPaymentMethodId: input?.paymentMethodId || null,
            splitPaymentMode: input?.paymentMethod,
            amount: +amountCollected,
          },
        ];
        if (appointmentSummary?.depositAmount > 0 && totalAmountPaidSoFar >= appointmentSummary?.depositAmount) {
          paymentMethodsInput = {
            appointmentPaymentMethodId: input?.paymentMethodId,
            appointmentPaymentMethod: input?.paymentMethod,
            paymentType: 'deposit_payment',
          };
        } else if (appointmentSummary?.depositAmount > 0 && totalAmountPaidSoFar < appointmentSummary?.depositAmount) {
          paymentMethodsInput = {
            appointmentPaymentMethodId: null,
            appointmentPaymentMethod: 'unpaid',
            paymentType: 'deposit_payment',
          };
        }
      }
    } else if (input?.paymentMethod === 'split') {
      if (input?.paymentMethod === 'split' && Array?.isArray(splitPayments) && splitPayments?.length) {
        appointmentSplitPayments = splitPayments?.map((payment) => {

          return {
            splitPaymentMethodId: payment?.paymentMethodId,
            splitPaymentMode: payment?.paymentMethod,
            amount: parseFloat(payment?.amount?.toString().replace(/,/g, "")),
          }
        })

        // get sum of appointmentSplitPayments
        const sumOfSplitPayments = appointmentSplitPayments?.reduce((acc, payment) => {
          return acc + payment?.amount
        }, 0) as number;
        // get the appointmentSplitPayments with the highest amount if none, then retur the first

        // const splitPaymentRecord = appointmentSplitPayments?.find((payment) => payment?.splitPaymentMode !== 'client_wallet')
        const highestSplitPayment = appointmentSplitPayments?.reduce((acc, payment) => {
          if (payment?.amount > acc?.amount) {
            return payment
          }
          return acc
        }, appointmentSplitPayments[0])
        if (sumOfSplitPayments >= appointmentSummary?.totalPaid) {
          paymentMethodsInput = {
            appointmentPaymentMethodId: highestSplitPayment?.splitPaymentMethodId,
            appointmentPaymentMethod: highestSplitPayment?.splitPaymentMode,
            paymentType: appointment?.totalAmountPaid > 0 ? 'balance_payment' : 'full_payment',
          };
        } else if (sumOfSplitPayments === appointmentSummary?.depositAmount) {
          paymentMethodsInput = {
            appointmentPaymentMethodId: highestSplitPayment?.splitPaymentMethodId,
            appointmentPaymentMethod: highestSplitPayment?.splitPaymentMode,
            paymentType: 'deposit_payment',
          };
        } else if (sumOfSplitPayments < appointmentSummary?.totalPaid && appointmentSummary?.depositAmount === 0) {
          paymentMethodsInput = {
            appointmentPaymentMethodId: null,
            appointmentPaymentMethod: 'unpaid',
            paymentType: 'balance_payment',
          };
        }
      }
    }
    else {
      paymentMethodsInput = {
        appointmentPaymentMethodId: appointment?.paymentMethodId,
        appointmentPaymentMethod: appointment?.paymentMethod,
        ...(input?.paymentMethod !== 'unpaid' &&
          appointment?.paymentMethod !== 'voucher'
          ? { paymentType: 'full_payment' }
          : {})
      }
    }

    const payload = {
      id: appointmentId,
      salonId,
      services: appointmentServices,
      products: selectedProducts.length
        ? convertProductsToCommaSeparatedString(selectedProducts)
        : [],
      startAt,
      note: input?.note,
      appointmentStatus: status,
      appointmentType: input?.appointmentType,
      ...(input?.addOnAmount
        ? {
          addOnAmount: parseFloat(
            input?.addOnAmount.toString().replace(',', '')
          )
        }
        : {}),
      addOnReason: input?.addOnReason,
      splitPayments: appointmentSplitPayments,
      tipAmount: input?.tipAmount ? +input?.tipAmount : 0,
      ...paymentMethodsInput,
      ...(input?.promoCode &&
        input?.promoCode !== appointment?.appointmentPromo?.title
        ? { promoCode: input?.promoCode }
        : {}),
      discountType,
      discountValue: Number(watch('discountValue'))
    }

    try {
      updateAppointment({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.updateAppointment?.appointment) {
          const appointmentData = data?.updateAppointment?.appointment
          setUpdatedAppointment(appointmentData || null)
          if (
            appointmentData?.paymentMethod ===
            APPOINTMENT_PAYMENT_METHOD.unpaid &&
            appointmentData?.depositPaymentMethod !==
            APPOINTMENT_PAYMENT_METHOD.unpaid
          ) {
            setHasMadePayment('deposit')
          } else if (
            appointmentData?.paymentMethod !==
            APPOINTMENT_PAYMENT_METHOD.unpaid
          ) {
            setHasMadePayment('full')
          } else {
            setHasMadePayment(null)
          }
          if (showReceipt) {
            openReceiptModal()
            setShowReceipt(false)
            return;
          }
          if (appointmentStatus === 'completed') {
            openReceiptModal()
          }

          if (!showReceipt) {
            resetFormInput()
            closeModal('updatedAppointment')
            addToast &&
              addToast({
                message: 'Appointment updated successfully',
                variant: 'success'
              })
          }
        } else if (data?.updateAppointment?.errors?.length) {
          const message =
            data?.updateAppointment?.errors[0]?.message ||
            API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
          addToast &&
            addToast({
              variant: 'error',
              message
            })
        } else {
          const message = API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
          addToast &&
            addToast({
              variant: 'error',
              message
            })
        }
      }).catch((error) => {
        addToast &&
          addToast({
            message: error?.message,
            variant: 'error'
          })
      })
    } catch (updateAppointmentError) {
      if (axios.isAxiosError(updateAppointmentError)) {
        const message =
          updateAppointmentError?.response?.data?.message ||
          API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
        addToast &&
          addToast({
            message,
            variant: 'error'
          })
      }
    }
  }

  const copyDepositLink = () => {
    copyTextToClipboard(appointment?.depositLink)
    addToast &&
      addToast({
        message: 'Deposit link copied to clipboard',
        variant: 'success'
      })
  }

  return (
    <>
      <Modal
        show={isVisible}
        closeModal={closeAddAppointmentModal}
        variant='right'
      >
        {getAppointmentContent()}
      </Modal>
      <ViewReceiptModal
        isVisible={isReceiptModalVisible}
        closeModal={initiateCloseReceiptModal}
        closeViewReceiptModal={closeAppointmentForRebook}
        appointmentSummary={appointmentSummary}
        hasMadePayment={hasMadePayment}
        refetchCalendarData={refetchCalendarData}
        appointment={updatedAppointment || appointment}
        salonId={salonId as string}
        addToast={addToast}
      />
      <RemoveStaffFromAppointmentAlertModal
        isVisible={isRemoveStaffAssignmentToServiceModalVisible}
        closeModal={initiateCloseStaffAssignmentToServiceModal}
        serviceStaff={currentServiceStaff}
      />
      <Modal
        show={isDepositLinkModalVisible}
        closeModal={closeDepositLinkModal}
        variant="topCenter"
      >
        <div className="w-full flex flex-col items-start space-y-6">
          <div className="w-full flex flex-col space-y-4">
            <Heading variant="h1" size="h9" weight="semiBold">
              Deposit link details
            </Heading>
            <Paragraph size="b4">
              Find the deposit link and message details here.
            </Paragraph>
          </div>
          <div className='w-full flex flex-col border border-grey-100 rounded-md p-4'>
            <div className='w-full flex space-x-3'>
              <div className='w-full flex flex-col space-y-2'>
                <Paragraph size="b4" color={COLORS.GREY[300]}>
                  Link:
                </Paragraph>
                <div className='w-full flex items-center space-x-3'>
                  <Paragraph size="b4">
                    {appointment?.depositLink}
                  </Paragraph>
                  <span role='button' onClick={copyDepositLink}>
                    <SvgCopy width="24px" height="24px" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex space-x-3'>
            <Button
              variant="secondary"
              className=""
              size="lg"
              rounded="lg"
              type="button"
              onClick={closeDepositLinkModal}
            >
              Cancel
            </Button>
            {/* <Button
              variant="primary"
              className=""
              size="lg"
              rounded="lg"
              type="button"
              onClick={closeDepositLinkModal}
            >
              Resend
            </Button> */}
          </div>
        </div>
      </Modal>
      <ViewClient
        isVisible={isViewClientModalVisible}
        closeModal={() => {
          refetch()
          closeViewClientModal()
        }}
        client={client}
        actions={clientActions}
        addToast={addToast}
      />
    </>
  )
}

export default ViewAppointmentModal