/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import Skeleton from 'ui/atoms/skeleton/Skeleton'

const ViewAppointmentShimmer = () => {
  return (
    <div className="flex my-6">
      <div className="flex flex-col w-1/4 space-y-6">
        <Skeleton width="80%" height="40px" />
        <Skeleton width="80%" height="1.5rem" />
      </div>
      <div className="w-full max-w-[500px] space-y-6 h-screen">
        {[...Array(10)].map((_, index) => (
          <Skeleton width="100%" height="1rem" />
        ))}
      </div>
    </div>
  )
}

export default ViewAppointmentShimmer