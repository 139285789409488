/* eslint-disable react-hooks/rules-of-hooks */
import React, { ChangeEvent, useState, useEffect, useMemo } from 'react'
import {
  Anchor,
  BookingPageSetup,
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  FormPhone,
  FormTextarea,
  Heading,
  Input,
  Layout,
  Modal,
  MultiSelectOption,
  Paragraph,
  PasswordInput,
  SelectInput,
  Table,
  TableSkeleton,
  Tabs,
  ToastProps,
  ToastWrapper,
  ToggleOnly
} from 'ui'
import { useModal, useToast, useUserCache } from 'hooks'
import { COLORS } from 'constants/colors'
import { Controller, useForm } from 'react-hook-form'
import { IMAGE_UPLOAD_PATTERN, REGEX_PATTERNS } from 'constants/pattern'
import {
  ChangePasswordInput,
  Salon,
  UpdateAccountInput,
  UpdateSalonInput,
  User,
  SalonStatusEnum,
  LocationTypeEnum,
  UpdateBusinessInput,
  Business,
  DepositTypeEnum,
  CreateTransferAccountInput,
  Bank,
  TransferAccount,
  CreatePosDeviceInput,
  PosDevice,
  CreateFaqInput,
  Faq,
  AddonStatus,
  Card
} from 'core/generated'
import { getImageUploadContent } from '../components/utils/upload'
import {
  YES_OR_NO_SETTINGS
} from 'constants/information'
import { ERRORS } from 'constants/errors'
import { useSalonCache } from 'hooks/useSalonCache'
import {
  getHelpTextForCharacterLeft,
  getHelperTextForReactHookFormErrors
} from '../components/utils/form'
import BankForm from '../components/uicomponents/BankForm'
import BusinessHoursForm from '../components/uicomponents/BusinessHoursForm'
import {
  copyTextToClipboard,
  formatInToPrice,
  formatNumber,
  getShortDate,
  removeEmpty,
  removeSpaceAndSpecialCharacters,
  sanitizePhoneNumber,
  validatePhoneNumber
} from '../components/utils/misc'
import { DEFAULT_CURRENCY } from 'constants/currency'
import { DEPOSIT_DURATION_INTERVAL } from 'constants/form'
import {
  SvgArrowRight,
  SvgCharmMenuKebab,
  SvgDeleteRegular,
  SvgEditRegular,
  SvgExternal,
  SvgGiftBox,
  SvgGroupLink,
  SvgMasterCard,
  SvgMessageSquare,
  SvgPercent,
  SvgPlus,
  SvgVerve,
  SvgVisa,
} from 'ui'
import NewLocation from '../components/uicomponents/NewLocation'
import { getLastFiveImages } from '../components/uicomponents/bookingSales/utils'
import { Menu, Transition } from '@headlessui/react'
import SingleSelect from 'ui/molecules/singleSelect/SingleSelect'
import { canPerformAction, formatPermissionSettingsFields, transformPermissionsToInput } from '../components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import AddonDetails from '../components/uicomponents/AddonDetails'
import { ContactUs } from './Support'
import { PAGE_ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { useManageAuth } from 'modules/auth/hooks/useManageAuth'
import { useSalon, useUpdateSalon } from 'api/useSalon'
import { GOOGLE_PLACES_SEARCH, useCards, useChangePassword, useCreateFaq, useDeleteFaq, useGetFaqs, useGooglePlaceSearch, USER, useUpdateAccount, useUpdateBusiness, useUpdatePermissions, useUploadFile, useUser } from 'api/useAccount'
import { useLazyQuery } from '@apollo/client'
import { useAddPosDevice, useAddTransferAccount, useDeletePosDevice, useDeleteTransferAccount, useGetBanks, useGetPosDevices, useGetTransferAccounts } from 'api/useMoney'

const countryString = localStorage.getItem('country');
const country = countryString ? JSON.parse(countryString) : null

const ROLES = [
  {
    label: 'Owner',
    value: 'owner'
  },
  {
    label: 'Manager',
    value: 'manager'
  },
  {
    label: 'Receptionist',
    value: 'receptionist'
  },
  {
    label: 'Staff',
    value: 'staff'
  }
]

const SETTINGS_TAB_NAME = {
  PERSONAL: 'Personal',
  BUSINESS: 'My Business',
  BOOKING: 'Online Booking',
  PAYMENT: 'Payment',
  ADDONS: 'Business Add-ons & Billings',
  SUPPORT: 'Support'
}

const ACCOUNT_SETUP_ERRORS = {
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last name is required',
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Email is invalid',
  PHONE_REQUIRED: 'Phone number is required',
  PHONE_INVALID: 'Phone number is invalid',
  ROLE_REQUIRED: 'Role is required',
  DOB_REQUIRED: 'Date of birth is required',
  SERVICES_REQUIRED: 'At least one service is required'
}

export const SideMenu = ({
  active,
  setTab,
  tabs
}: {
  active: string
  setTab: (tab: string) => void
  tabs: { key: string; title: string, show?: boolean }[]
}) => {
  return (
    <div className='w-full xl:max-w-[350px] flex justify-center xl:justify-start xl:items-start pt-4 px-2 xl:px-10'>
      <ul className='w-full flex flex-wrap justify-center flex-row xl:flex-col gap-2 xl:gap-0'>
        {Array.isArray(tabs) &&
          tabs.map((tab) => tab?.show ? (
            <li
              key={tab.key}
              className={`flex items-center justify-between space-x-2 cursor-pointer px-[10px] py-3 ${active === tab.key
                ? 'text-grey-900 bg-grey-50'
                : 'text-grey-400 bg-white'
                }`}
              onClick={() => setTab(tab.key)}
            >
              <span className='text-b5'>{tab.title}</span>
              <SvgArrowRight width='10px' height='10px' />
            </li>
          ) : null)}
      </ul>
    </div>
  )
}

const AccountInformation = () => {
  const { getUserData } = useUserCache();
  const user = getUserData()
  const [userData, setUser] = useState<User | null>(user)
  const urlParams = new URLSearchParams(window.location.search);
  const [countryCode, setCountryCode] = useState('')
  const [callingCode, setCallingCode] = useState('')
  const { toast, addToast } = useToast()
  const {
    loading, updateAccount
  } = useUpdateAccount()

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = useForm<UpdateAccountInput>({
    delayError: 100,
    mode: 'onChange',
    defaultValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      logoUrl: userData?.business?.logoUrl,
      phone: sanitizePhoneNumber(userData?.business?.phone || "", userData?.business?.callingCode || ""),
      canServeCustomers: userData?.salonStaff?.canServeCustomers,
      // @ts-expect-error Expecting an enum but a string also works
      role: userData?.salonStaff?.role,
      businessName: userData?.business?.name,
      rcNumber: userData?.business?.rcNumber
    }
  })

  const [getUser, { error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      setUser(currentUser)
    }

    return currentUser;
  }, [data]);

  const handleSave = async (input: UpdateAccountInput) => {
    updateAccount({
      variables: {
        input: {
          ...input,
          callingCode,
          logoUrl: userData?.business?.logoUrl,
          role: input.role
        }
      }
    }).then(({ data }) => {
      const { updateAccount } = data
      if (updateAccount.status === 200) {
        getUser()
        addToast({
          variant: 'success',
          message: 'Account information saved successfully'
        })
      } else {
        addToast({ variant: 'error', message: updateAccount.errors[0].message })
      }
    })
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form
        onSubmit={handleSubmit(handleSave)}
        className='w-full space-y-6'
        autoComplete='off'
      >
        <Paragraph
          size='b4'
          color={COLORS.GREY[300]}
          weight='bold'
          className='pt-12'
        >
          Business information
        </Paragraph>
        <Input
          name='businessName'
          id='business-name'
          label='Business name'
          type='text'
          placeholder='Enter business name here'
          control={control}
          error={errors.businessName}
        />
        <Input
          name='rcNumber'
          id='rc-number'
          label='RC Number'
          type='text'
          placeholder='Enter rcNumber'
          control={control}
        />
        <SelectInput
          name='role'
          id='role'
          label='Role at Business'
          control={control}
          error={errors.role}
          options={ROLES}
          value={watch('role')}
          placeholder='Select Role'
        />
        <FormPhone
          country={{
            disabled: true,
            onSelect: (code, country: string) => {
              setCallingCode(code)
              setCountryCode(country)
            },
            value: countryCode
          }}
          phone={{
            name: 'phone',
            type: 'text',
            placeholder: '9151930463',
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              disabled: false
            },
            register,
            id: 'phone-number',
            label: 'Business Phone Number'
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors.phone?.message as string
          )}
        />
        <Controller
          control={control}
          name='canServeCustomers'
          render={({ field: { onChange, value } }) => {
            return (
              <div className='w-full flex flex-col space-y-4'>
                <FormLabel htmlFor='canServeCustomers'>
                  Do you as the business owner/manager/supervisor, personally
                  service customers?
                </FormLabel>
                <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (canServeCustomers: {
                        label: string
                        value: boolean
                      }) => {
                        return (
                          <div
                            className='flex'
                            onClick={() => onChange(canServeCustomers?.value)}
                            key={canServeCustomers?.label}
                          >
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <Checkbox
                                isChecked={value === canServeCustomers?.value}
                              />
                              <span className='text-grey-900 whitespace-nowrap'>
                                {canServeCustomers?.label}
                              </span>
                            </div>
                          </div>
                        )
                      }
                    )
                    : null}
                </div>
              </div>
            )
          }}
        />
        <Button
          variant='primary'
          className=''
          disabled={!isValid}
          loading={loading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
      </form>
    </>
  )
}

const PersonalInformation = () => {
  const { getUserData } = useUserCache();
  const user = getUserData()
  const [userData, setUser] = useState<User | null>(user)
  const { toast, addToast } = useToast()
  const {
    loading, updateAccount
  } = useUpdateAccount()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<UpdateAccountInput>({
    delayError: 100,
    mode: 'onChange',
    defaultValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
      logoUrl: userData?.business?.logoUrl,
      phone: sanitizePhoneNumber(userData?.business?.phone || '', userData?.business?.callingCode || ''),
      canServeCustomers: userData?.salonStaff?.canServeCustomers,
      // @ts-expect-error Expecting an enum but a string also works
      role: userData?.salonStaff?.role,
      businessName: userData?.business?.name,
      rcNumber: userData?.business?.rcNumber
    }
  })

  const [getUser, { error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      setUser(currentUser)
    }

    return currentUser;
  }, [data]);

  const handleSave = async (input: UpdateAccountInput) => {
    updateAccount({
      variables: {
        input: {
          ...input,
          callingCode: userData?.business?.callingCode
        }
      }
    }).then(({ data }) => {
      const { updateAccount } = data;
      if (updateAccount.status === 200) {
        getUser()
        addToast({
          variant: 'success',
          message: 'Personal information saved successfully'
        })
      } else {
        addToast({ variant: 'error', message: updateAccount.errors[0].message })
      }
    })
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      <form
        onSubmit={handleSubmit(handleSave)}
        className='w-full space-y-6'
        autoComplete='off'
      >
        <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
          Personal information
        </Paragraph>
        <div className='w-full flex gap-x-4'>
          <Input
            name='firstName'
            label='First name'
            id='first-name'
            type='text'
            placeholder='Enter first name here'
            control={control}
            rules={{
              required: ACCOUNT_SETUP_ERRORS.FIRST_NAME_REQUIRED,
              pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
            }}
            error={errors.firstName}
          />
          <Input
            name='lastName'
            label='Last name'
            id='last-name'
            type='text'
            placeholder='Enter last name here'
            control={control}
            rules={{
              required: ACCOUNT_SETUP_ERRORS.LAST_NAME_REQUIRED,
              pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH
            }}
            error={errors.lastName}
          />
        </div>
        <Input
          name='email'
          label='Email'
          id='email'
          type='email'
          placeholder='email@acme.com'
          control={control}
          rules={{
            required: ACCOUNT_SETUP_ERRORS.EMAIL_REQUIRED,
            pattern: REGEX_PATTERNS.EMAIL
          }}
          error={errors.email}
        />
        <Button
          variant='primary'
          className=''
          disabled={!isValid}
          loading={loading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
      </form>
    </>
  )
}

const Password = () => {
  const navigate = useNavigate()
  const { logout: _logout } = useManageAuth()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ChangePasswordInput>({ delayError: 100, mode: 'onChange' })
  const { addToast, toast } = useToast()
  const { changePassword, loading: isLoading } = useChangePassword()

  const submitData = async (input: ChangePasswordInput) => {
    changePassword({
      variables: { input }
    }).then(({ data }) => {
      const { changePassword } = data
      if (changePassword.status === 200) {
        addToast({
          variant: 'success',
          message: 'Password changed successfully'
        })
        _logout()
        navigate(PAGE_ROUTES.LOGIN)
      }

      if (changePassword.status === 422) {
        addToast({
          variant: 'error',
          message: changePassword.errors[0].message
        })
      }
    })
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <form
        onSubmit={handleSubmit(submitData)}
        className='w-full space-y-6'
        autoComplete='off'
      >
        <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold'>
          Password
        </Paragraph>
        <PasswordInput
          name='oldPassword'
          label='Old Password'
          id='password'
          type='password'
          placeholder='**********'
          control={control}
          rules={{
            required: ERRORS.PASSWORD_REQUIRED
          }}
          error={errors.oldPassword}
        />
        <PasswordInput
          name='newPassword'
          label='New Password'
          id='new-password'
          type='password'
          placeholder='**********'
          control={control}
          rules={{
            required: ERRORS.PASSWORD_REQUIRED,
            pattern: REGEX_PATTERNS.PASSWORD_STRENGTH
          }}
          error={errors.newPassword}
        />
        <Button
          variant='primary'
          className=''
          disabled={!isValid}
          loading={isLoading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
      </form>
    </>
  )
}

const Tax = () => {
  const { control, handleSubmit, setValue } = useForm<UpdateSalonInput>()
  const { getSalonData, setSalonData } = useSalonCache()
  const { toast, addToast } = useToast()
  const salon = getSalonData() as Salon;
  const { loading, updateSalon } = useUpdateSalon()
  const {
    refetch,
    data
  } = useSalon(salon?.id)

  // use useMemo save new data in setSalonData
  const memoizedData = useMemo(() => {
    if (data?.salon) {
      setSalonData(data.salon)
      return data.salon
    }
  }, [data])

  const submitData = async (input: UpdateSalonInput) => {
    delete salon?.deletedAt
    delete salon?.createdAt
    delete salon?.updatedAt
    delete salon?.createdById
    delete salon?.onboardingStatus
    delete salon?.wallet
    delete salon?.countryCode
    delete salon?.totalNumberOfClients

    const { __typename, ...salonData } = salon
    const payload = { ...salonData, ...input }
    updateSalon({
      variables: { input: payload }
    }).then(({ data }) => {
      const { updateSalon } = data
      if (updateSalon.status === 200) {
        refetch()
        addToast({
          variant: 'success',
          message: 'Tax settings saved successfully'
        })
        setValue('taxInclusivePricing', input?.taxInclusivePricing)
        setValue('isTaxVisible', input?.isTaxVisible)
      } else {
        addToast({ variant: 'error', message: updateSalon.errors[0].message })
      }
    })
  }

  useEffect(() => {
    if (salon?.id) {
      console.log({
        salon
      })
      setValue('taxInclusivePricing', salon?.taxInclusivePricing)
      setValue('isTaxVisible', salon?.isTaxVisible)
    }
  }, [salon?.id, setValue])

  return (
    <form
      onSubmit={handleSubmit(submitData)}
      className='w-full space-y-6'
      autoComplete='off'
    >
      <ToastWrapper toast={toast} />
      <Controller
        control={control}
        name='taxInclusivePricing'
        render={({ field: { onChange, value } }) => {
          return (
            <div className='w-full flex flex-col space-y-4'>
              <FormLabel htmlFor='taxInclusivePricing'>
                Are your display prices inclusive of VAT?
              </FormLabel>
              <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                  ? YES_OR_NO_SETTINGS.map(
                    (taxSetting: { label: string; value: boolean }) => {
                      return (
                        <div
                          className='flex'
                          onClick={() => onChange(taxSetting?.value)}
                          key={taxSetting?.label}
                        >
                          <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                            <Checkbox
                              isChecked={value === taxSetting?.value}
                            />
                            <span className='text-grey-900 whitespace-nowrap'>
                              {taxSetting?.label}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  )
                  : null}
              </div>
            </div>
          )
        }}
      />
      <Controller
        control={control}
        name='isTaxVisible'
        render={({ field: { onChange, value } }) => {
          return (
            <div className='w-full flex flex-col space-y-4'>
              <FormLabel htmlFor='isTaxVisible'>
                Do you want to display tax in receipt?
              </FormLabel>
              <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                  ? YES_OR_NO_SETTINGS.map(
                    (taxSetting: { label: string; value: boolean }) => {
                      return (
                        <div
                          className='flex'
                          onClick={() => onChange(taxSetting?.value)}
                          key={taxSetting?.label}
                        >
                          <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                            <Checkbox
                              isChecked={value === taxSetting?.value}
                            />
                            <span className='text-grey-900 whitespace-nowrap'>
                              {taxSetting?.label}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  )
                  : null}
              </div>
            </div>
          )
        }}
      />
      <Button
        variant='primary'
        className=''
        disabled={false}
        loading={loading}
        size='lg'
        rounded='lg'
      >
        Save
      </Button>
    </form>
  )
}

const PersonalSettingTab = () => {
  const { toast } = useToast()

  const PERSONAL_INFORMATION_TABS = [
    {
      key: 'personal_information',
      title: 'Personal information',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.editPersonalInfo)
    },
    {
      key: 'password',
      title: 'Password',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.changePassword)
    }
  ]

  const [active, setActive] = useState(PERSONAL_INFORMATION_TABS?.filter((personal) => personal?.show)[0]?.key)
  const setTab = (tab: string) => {
    setActive(tab)
  }

  const getPersonalHeaderView = () => {
    let headerText, descriptionText

    switch (active) {
      default:
        headerText = 'Personal information'
        descriptionText = 'Find your personal information '
        break
      case 'password':
        headerText = 'Password'
        descriptionText = 'Set up your password'
        break
    }

    return (
      <div className='w-full flex flex-col border-b border-grey-50 px-10 py-6'>
        <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
          {headerText}
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
          {descriptionText}
        </Paragraph>
      </div>
    )
  }

  const getTabView = () => {
    switch (active) {
      case 'password':
        return <Password />
      default:
        return <PersonalInformation />
    }
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>
        <SideMenu
          active={active}
          setTab={setTab}
          tabs={PERSONAL_INFORMATION_TABS}
        />
        <div className='w-full border-l border-grey-50 flex flex-col shadow-sm'>
          {getPersonalHeaderView()}
          <div className='w-full h-full px-10 py-6'>{getTabView()}</div>
        </div>
      </div>
    </>
  )
}

const ProcessingFee = () => {
  const { control, handleSubmit, setValue } = useForm<UpdateSalonInput>()
  const { getSalonData, setSalonData } = useSalonCache()
  const { toast, addToast } = useToast()
  const salon = getSalonData() as Salon
  const { loading, updateSalon } = useUpdateSalon()
  const {
    refetch,
    data
  } = useSalon(salon?.id)

  // use useMemo save new data in setSalonData
  const memoizedData = useMemo(() => {
    console.log({
      data
    })
    if (data?.salon) {
      setSalonData(data.salon)
      return data.salon
    }
  }, [data])

  const submitData = async (input: UpdateSalonInput) => {
    const payload = { ...salon, ...input }
    delete payload?.wallet
    delete payload?.updatedAt
    delete payload?.createdAt
    delete payload?.createdById
    delete payload?.deletedAt
    delete payload?.__typename
    delete payload?.onboardingStatus
    delete payload?.totalNumberOfClients
    delete payload?.countryCode

    updateSalon({
      variables: { input: payload }
    }).then(({ data }) => {
      const { updateSalon } = data
      if (updateSalon.status === 200) {
        refetch()
        addToast({
          variant: 'success',
          message: 'Settings saved successfully'
        })
      } else {
        addToast({ variant: 'error', message: updateSalon.errors[0].message })
      }
    })
  }

  useEffect(() => {
    if (salon) {
      setValue('bearsProcessingFee', salon.bearsProcessingFee)
    }
  }, [salon, setValue])

  return (
    <form
      onSubmit={handleSubmit(submitData)}
      className='w-full max-w-[500px] space-y-6'
      autoComplete='off'
    >
      <ToastWrapper toast={toast as ToastProps} />
      <Controller
        control={control}
        name='bearsProcessingFee'
        render={({ field: { onChange, value } }) => {
          return (
            <div className='w-full flex flex-col space-y-4'>
              <FormLabel htmlFor='taxInclusivePricing'>
                Do you want to bear the 2% online payment Processing Fee?
              </FormLabel>
              <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                {Array.isArray(YES_OR_NO_SETTINGS) && YES_OR_NO_SETTINGS.length
                  ? YES_OR_NO_SETTINGS.map(
                    (taxSetting: { label: string; value: boolean }) => {
                      return (
                        <div
                          className='flex'
                          onClick={() => onChange(taxSetting?.value)}
                          key={taxSetting?.label}
                        >
                          <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                            <Checkbox
                              isChecked={value === taxSetting?.value}
                            />
                            <span className='text-grey-900 whitespace-nowrap'>
                              {taxSetting?.label}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  )
                  : null}
              </div>
            </div>
          )
        }}
      />
      <Button
        variant='primary'
        className=''
        disabled={false}
        loading={loading}
        size='lg'
        rounded='lg'
      >
        Save
      </Button>
    </form>
  )
}

const Business_ = ({ action }: {
  action?: string | null
}) => {
  const { getUserData } = useUserCache()
  const BUSINESS_TABS = [
    {
      key: 'account_information',
      title: 'Account information',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.editBusinessInfo)
    },
    {
      key: 'location',
      title: 'Location',
      show: true
    },
    {
      key: 'business_hours',
      title: 'Business Hours',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.businessHours)
    },
    {
      key: 'permission',
      title: 'Permissions',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.permissions)
    },
    {
      key: 'tax',
      title: 'Tax Settings',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.taxes)
    }
  ]
  const user = getUserData()
  const [userData, setUserData] = useState<User | null>(user)
  const [active, setActive] = useState(BUSINESS_TABS?.filter((tab) => tab?.show)[0]?.key)
  const { toast } = useToast()
  const setTab = (tab: string) => {
    setActive(tab)
  }
  const { getSalonFieldValue } = useSalonCache()

  useEffect(() => {
    if (action && action === 'rcnumber') {
      setActive('account_information')
    }
  }, [action])

  const getTabView = () => {
    switch (active) {
      case 'business_hours':
        return (
          <BusinessHoursForm
            buttonText='Save'
            businessId={userData?.business?.id}
            salonId={getSalonFieldValue('id')}
            accountSetup={false}
          />
        )
      case 'location':
        return <BusinessLocation />
      case 'tax':
        return <Tax />
      case 'permission':
        return <Permissions />
      default:
        return <AccountInformation />
    }
  }

  const getTabHeaderView = () => {
    let headerText, descriptionText

    switch (active) {
      case 'business_hours':
        headerText = 'Business hours'
        descriptionText = 'Set your business hours'
        break
      case 'location':
        headerText = 'Location'
        descriptionText = 'Find your branch information'
        break
      case 'tax':
        headerText = 'Tax Settings'
        descriptionText = 'Configure your tax settings'
        break
      case 'permission':
        headerText = 'Permissions';
        descriptionText = 'Set your staff permissions';
        break;
      default:
        headerText = 'Account information'
        descriptionText = 'Find your account information'
        break
    }

    return (
      <div className='w-full flex flex-col border-b border-grey-50 px-10 py-6'>
        <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
          {headerText}
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
          {descriptionText}
        </Paragraph>
      </div>
    )
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>
        <SideMenu active={active} setTab={setTab} tabs={BUSINESS_TABS} />
        <div className='w-full border-l border-grey-50 flex flex-col shadow-sm'>
          {getTabHeaderView()}
          <div className='w-full h-full px-10 py-6'>{getTabView()}</div>
        </div>
      </div>
    </>
  )
}

interface Level {
  levelTitle: string;
  modules: Module[];
}

interface Module {
  label: string;
  ids: string[];
  levels: boolean[];
  module: string[];
}

const Permissions = () => {
  const { toast, addToast } = useToast();
  const { getUserData } = useUserCache()
  const user = getUserData()
  const [userData, setUser] = useState<User | null>(user)
  const [permissionList, setPermissionList] = useState<Level[]>([])
  const {
    loading: updateRoleIsLoading,
    updatePermissions
  } = useUpdatePermissions()

  const handleCheckboxChange = (index: number, moduleIndex: number, permissionIndex: number) => {
    // Clone the permissionList
    const permissions = [...permissionList];

    // Get the specific permission object
    const permission = { ...permissions[permissionIndex] };

    // Clone the modules array and get the specific module
    const modules = [...permission.modules];
    const module = { ...modules[moduleIndex] };

    // Clone the levels array and toggle the specific level
    const levels = [...module.levels];
    levels[index] = !levels[index];

    // Update the module's levels and the permission's modules
    module.levels = levels;
    modules[moduleIndex] = module;
    permission.modules = modules;

    // Update the permission in the permissions list
    permissions[permissionIndex] = permission;

    // Update the state
    setPermissionList(permissions);
  }

  useEffect(() => {
    const permissions = formatPermissionSettingsFields();
    setPermissionList(permissions)
  }, [])

  const updateRoleAsync = async () => {
    const permissions = transformPermissionsToInput(permissionList)
    updatePermissions({
      variables: { input: { permissions } }
    }).then(({ data }) => {
      const { updatePermissions } = data;
      if (updatePermissions.status === 200) {
        const roles = updatePermissions?.roles;

        if (userData) {
          const { business } = userData;
          business.roles = roles;

          setUser({ ...userData, business });
          localStorage.setItem('userData', JSON.stringify({ ...userData, business }))
          addToast({
            variant: 'success',
            message: 'Permissions updated successfully'
          })
        }
      }

      if (updatePermissions.status === 422) {
        addToast({
          variant: 'error',
          message: updatePermissions.errors[0].message
        })
      }
    })
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='w-full flex flex-col'>
        <div className='w-full flex h-[59px] space-x-2 bg-grey-10 items-center px-4'>
          <Heading
            variant='h3'
            size='h11'
            color={COLORS.GREY[300]}
            weight='bold'
            className='w-full max-w-[60%]'
          >
            Permissions
          </Heading>

          <Heading
            variant='h3'
            size='h11'
            color={COLORS.GREY[300]}
            weight='semiBold'
            className='w-[10%]'
          >
            Level 1
          </Heading>
          <Heading
            variant='h3'
            size='h11'
            color={COLORS.GREY[300]}
            weight='semiBold'
            className='w-[10%]'
          >
            Level 2
          </Heading>
          <Heading
            variant='h3'
            size='h11'
            color={COLORS.GREY[300]}
            weight='semiBold'
            className='w-[10%]'
          >
            Level 3
          </Heading>
          <Heading
            variant='h3'
            size='h11'
            color={COLORS.GREY[300]}
            weight='semiBold'
            className='w-[10%]'
          >
            Level 4
          </Heading>
        </div>
        <div className='w-full flex flex-col'>
          {Array?.isArray(permissionList) && permissionList?.length ? permissionList?.map((permission, permissionIndex) => {
            return (
              <div className='flex flex-col' key={permissionIndex}>
                <div className='w-full flex h-[59px] space-x-2 items-center px-4'>
                  <Heading
                    variant='h4'
                    size='b5'
                    color={COLORS.GREY[300]}
                    weight='bold'
                    className=''
                  >
                    {permission?.levelTitle}
                  </Heading>
                </div>
                <div className='w-full flex flex-col border-b border-t border-grey-100 px-4'>
                  {Array?.isArray(permission?.modules) && permission?.modules?.length ? permission?.modules?.map((module, moduleIndex) => {
                    return (
                      <div className='w-full flex h-[59px] space-x-2 items-center' key={moduleIndex}>
                        <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal' className='w-full max-w-[60%]'>
                          {module?.label}
                        </Paragraph>
                        {Array?.isArray(module?.levels) && module?.levels?.length ? module?.levels.map((level, index) => (
                          <div className="relative flex items-center cursor-pointer text-b5 text-grey-900 space-x-2 w-[10%]" key={index} onClick={() => handleCheckboxChange(index, moduleIndex, permissionIndex)}>
                            <Checkbox
                              isChecked={level}
                            />
                            <input
                              type="checkbox"
                              className="absolute left-0 w-[24px] h-[24px] opacity-0 cursor-pointer" value={level ? 1 : 0} />
                          </div>
                        )) : null}
                      </div>
                    )
                  }) : null}
                </div>
              </div>
            )
          }) : null}
        </div>
        <Button
          variant='primary'
          className=''
          loading={updateRoleIsLoading}
          size='md'
          rounded='lg'
          onClick={updateRoleAsync}
        >
          Save
        </Button>
      </div>
    </>
  )
}


const Settings = () => {
  const SETTINGS_TABS = [
    {
      key: SETTINGS_TAB_NAME.PERSONAL,
      title: SETTINGS_TAB_NAME.PERSONAL,
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.editPersonalInfo) || canPerformAction(PERMISSION_CONSTANTS?.settings?.changePassword)
    },
    {
      key: SETTINGS_TAB_NAME.BUSINESS,
      title: SETTINGS_TAB_NAME.BUSINESS,
      show: true,
    },
    {
      key: SETTINGS_TAB_NAME.BOOKING,
      title: SETTINGS_TAB_NAME.BOOKING,
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.publicUrl)
    },
    {
      key: SETTINGS_TAB_NAME.PAYMENT,
      title: SETTINGS_TAB_NAME.PAYMENT,
      show: (country?.collectionsSupported === true && country?.payoutsSupported === true) && (canPerformAction(PERMISSION_CONSTANTS?.settings?.addPos) || canPerformAction(PERMISSION_CONSTANTS?.settings?.addBankTransfers) || canPerformAction(PERMISSION_CONSTANTS?.settings?.deleteBankTransfers) || canPerformAction(PERMISSION_CONSTANTS?.settings?.deletePos) || canPerformAction(PERMISSION_CONSTANTS?.settings?.processingFees) || canPerformAction(PERMISSION_CONSTANTS?.settings?.taxes))
    },
    {
      key: SETTINGS_TAB_NAME.ADDONS,
      title: SETTINGS_TAB_NAME.ADDONS,
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.addons) || canPerformAction(PERMISSION_CONSTANTS?.settings?.billing)
    },
    {
      key: SETTINGS_TAB_NAME.SUPPORT,
      title: SETTINGS_TAB_NAME.SUPPORT,
      show: true
    }
  ]
  const [activeTab, setActiveTab] = useState<string>(SETTINGS_TABS.filter((tab) => tab?.show)[0]?.key)
  const urlParams = new URLSearchParams(window.location.search);
  const action = urlParams.get("action");

  useEffect(() => {
    if (action && action === 'rcnumber') {
      setActiveTab(SETTINGS_TAB_NAME.BUSINESS)
    }
  }, [action])

  const getCurrentSettingsView = () => {
    switch (activeTab) {
      case SETTINGS_TAB_NAME.BUSINESS:
        return <Business_ action={action} />
      case SETTINGS_TAB_NAME.PERSONAL:
        return <PersonalSettingTab />
      case SETTINGS_TAB_NAME.BOOKING:
        return <Booking />
      case SETTINGS_TAB_NAME.PAYMENT:
        return <PaymentWrapper />
      case SETTINGS_TAB_NAME.ADDONS:
        return <BillingsWrapper />
      case SETTINGS_TAB_NAME.SUPPORT:
        return <ContactUs />
      default:
        break
    }
  }

  return (
    <Layout pageTitle='Settings' pageLevel={2}>
      <Tabs
        tabs={SETTINGS_TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {getCurrentSettingsView()}
    </Layout>
  )
}

type HeaderImagesInput = {
  headerImageUrl1: string
  headerImageUrl2: string
  headerImageUrl3: string
  headerImageUrl4: string
}
const Booking = () => {
  const {
    loading,
    updateBusiness
  } = useUpdateBusiness()
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [uploadingSubImageIndex, setUploadingSubImageIndex] = useState(null)
  const user = JSON.parse(localStorage.getItem('userData')) as User
  const business = user.business
  const spliceBookingLink_ = process.env?.REACT_APP_SPLICE_BOOKING_LINK
  const [bookingPageLinkStatus, setBookingPageLinkStatus] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>(
    business.bookingUrlIdentifier
      ? business?.bookingUrlIdentifier?.split('/s/')[1]
      : ''
  )
  const [typeSign, setTypeSign] = useState<string | null>('-')
  const { control, handleSubmit, setValue, watch, register } = useForm<
    CreateFaqInput & IUpdateBusinessInput & HeaderImagesInput
  >()

  const [getUser, { error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
    }

    return currentUser;
  }, [data]);
  const [formValues, setFormValues] = useState({})
  const { addToast, toast } = useToast()
  const [imageUrl, setImageUrl] = useState(business?.headerImageUrl)
  const [logoUrl, setLogoUrl] = useState(business?.logoUrl)
  const [headerImages, setHeaderImages] = useState([])
  const { getSalonData } = useSalonCache()
  const salon = getSalonData() as Salon
  const {
    loading: createFaqIsLoading,
    createFaq
  } = useCreateFaq()
  const {
    loading: deletingFaq,
    deleteFaq
  } = useDeleteFaq()
  const {
    data: faqsData,
    loading: getFaqsIsLoading,
    refetch
  } = useGetFaqs({
    salonId: salon?.id
  })
  const faqs = useMemo(() => faqsData?.faqs, [faqsData])
  const [showFaqForm, setShowFaqForm] = useState(false)
  const [faq, setFaq] = useState<Faq>(null)
  const {
    loading: headerIsLoading,
    uploadFile
  } = useUploadFile();
  const {
    loading: logoIsLoading,
    uploadFile: uploadLogo
  } = useUploadFile();
  const {
    uploadFile: uploadHeaders
  } = useUploadFile();
  const actions = {
    addFaq: canPerformAction(PERMISSION_CONSTANTS?.settings?.addFaq),
    editFaq: canPerformAction(PERMISSION_CONSTANTS?.settings?.editFaq),
    deleteFaq: canPerformAction(PERMISSION_CONSTANTS?.settings?.deleteFaq),
    cancellationFees: canPerformAction(PERMISSION_CONSTANTS?.settings?.cancellationFees),
    noShowFees: canPerformAction(PERMISSION_CONSTANTS?.settings?.noShowFees),
    publicUrl: canPerformAction(PERMISSION_CONSTANTS?.settings?.publicUrl),
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setImageUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  const handleHeaderImageUpload = async (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setUploadingSubImageIndex(index)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadHeaders({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          const updatedHeaderImages = [...headerImages]
          updatedHeaderImages[index] = uploadFile?.url
          setHeaderImages(updatedHeaderImages)
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
      // update headerImages base off the index
      setUploadingSubImageIndex(null)
    }

    reader.readAsDataURL(file)
  }

  const handleLogoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadLogo({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setLogoUrl(uploadFile?.url);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  interface IUpdateBusinessInput extends UpdateBusinessInput {
    hasNoShowFee: boolean
    hasCancellationFee: boolean
    applyCancellationFeeToNoCharge: boolean
    cancellationFeeType: string
  }

  const onSubmitData = async (input: IUpdateBusinessInput) => {
    const payload = {
      about: input?.about,
      acceptsOnlineBookings: input?.acceptsOnlineBookings,
      cancellationPolicy: input?.cancellationPolicy,
      allowServiceStaffSelection: input?.allowServiceStaffSelection,
      headerImageUrl: imageUrl,
      headerImageUrls: removeEmpty([imageUrl, ...headerImages]),
      // pointValue: business?.pointValue || 0,
      logoUrl,
      bookingUrlIdentifier:
        removeSpaceAndSpecialCharacters(debouncedSearchQuery),
      cancellationFee:
        input.cancellationFee && input?.hasCancellationFee ?
          Number(input?.cancellationFee.toString()?.replace(/,/g, '')) : 0,
      cancellationFeeType: input?.cancellationFeeType,
      noShowFee: input?.hasCancellationFee ? input.applyCancellationFeeToNoCharge
        ? Number(input?.cancellationFee.toString()?.replace(/,/g, ''))
        : Number(input?.noShowFee.toString()?.replace(/,/g, '')) : 0
    }
    updateBusiness({
      variables: {
        input: payload
      }
    }).then(({ data }) => {
      const { updateBusiness } = data
      if (updateBusiness.status === 200) {
        const b = updateBusiness?.business as Business
        setValue('about', b?.about)
        setValue('cancellationPolicy', b?.cancellationPolicy)
        setValue('bookingUrlIdentifier', b?.bookingUrlIdentifier)
        setValue('acceptsOnlineBookings', b?.acceptsOnlineBookings)
        setValue('allowServiceStaffSelection', b?.allowServiceStaffSelection)
        getUser()
        addToast({
          message: 'Business details saved successfully',
          variant: 'success'
        })
      } else {
        addToast({
          message: updateBusiness?.errors[0]?.message,
          variant: 'error'
        })
      }
    })
  }

  // const handleCheckboxChange =
  //   (social: string) => (e: ChangeEvent<HTMLInputElement>) => {
  //     // console.log(e)
  //     const socialValue = formValues[social]
  //     const updatedFormValues = {
  //       ...formValues,
  //       [social]: {
  //         isAvailable: !socialValue.isAvailable,
  //         username: ''
  //       }
  //     }
  //     setFormValues(updatedFormValues)
  //   }

  // const handleInputChange =
  //   (social: string, fieldName: 'username') =>
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const { value } = e.target
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [social]: {
  //         ...prevValues[social],
  //         [fieldName]: value
  //       }
  //     }))
  //   }

  const copyBookingLink = () => {
    copyTextToClipboard(`${spliceBookingLink_}${debouncedSearchQuery}`)
    addToast({
      variant: 'success',
      message: 'Booking link copied to clipboard'
    })
  }

  useEffect(() => {
    if (business) {
      // console.log(business)
      setValue('about', business?.about)
      setValue('cancellationPolicy', business?.cancellationPolicy)
      setValue('acceptsOnlineBookings', business?.acceptsOnlineBookings)
      setValue(
        'allowServiceStaffSelection',
        business?.allowServiceStaffSelection || false
      )
      setValue(
        'bookingUrlIdentifier',
        business?.bookingUrlIdentifier?.split('/s/')[1]
      )
      setValue('cancellationFee', business?.cancellationFee)
      setValue('cancellationFeeType', business?.cancellationFeeType)
      setValue('noShowFee', business?.noShowFee)
      setValue('hasCancellationFee', business?.cancellationFee > 0)
      setValue(
        'applyCancellationFeeToNoCharge',
        business.cancellationFee === business.noShowFee
      )
      const headerImagesArray = getLastFiveImages(business?.headerImageUrls)
      setHeaderImages(headerImagesArray)
    }
  }, [])

  const deleteFaqAsync = async (faq: Faq) => {
    deleteFaq({
      variables: {
        input: { id: faq?.id }
      }
    }).then(({ data }) => {
      const { deleteFaq } = data
      if (deleteFaq.status === 200) {
        addToast({ message: 'Faq deleted successfully', variant: 'success' })
        refetch()
      } else {
        addToast({ message: deleteFaq.errors[0].message, variant: 'error' })
      }
    }).catch((error) => {
      addToast({ message: error?.message, variant: 'error' })
    })
  }

  useEffect(() => {
    if (faq) {
      setValue('question', faq?.question)
      setValue('answer', faq?.answer)
    }
  }, [faq])

  const resetFaqForm = () => {
    setValue('question', '')
    setValue('answer', '')
    setShowFaqForm(false)
  }

  const saveFaq = async () => {
    const question = watch('question')
    const answer = watch('answer')
    // create faq
    if (question && answer) {
      createFaq({
        variables: {
          input: { salonId: salon?.id, id: faq?.id || null, question, answer }
        }
      }).then(({ data }) => {
        const { createFaq } = data
        if (createFaq.status === 200) {
          addToast({
            message: 'Faq created successfully',
            variant: 'success'
          })
          resetFaqForm()
          refetch()
        } else {
          addToast({ message: createFaq.errors[0].message, variant: 'error' })
        }
      }).catch((error) => {
        addToast({ message: error?.message, variant: 'error' })
      })
    } else {
      addToast({
        message: 'Question and answer are required',
        variant: 'error'
      })
    }
  }

  const LINKS = [
    { title: 'Upload logo', key: 'upload-logo-image', show: true },
    { title: 'Upload header image', key: 'upload-header-image', show: true },
    { title: 'Add more images', key: 'add-more-images', show: true },
    {
      title: 'Add frequently asked questions',
      key: 'add-frequently-asked-questions',
      show: true
    },
    { title: 'Website link', key: 'website-link', show: true },
    { title: 'About', key: 'about', show: true },
    { title: 'Cancellation policy', key: 'cancellationPolicy', show: true },
    { title: 'Accept bookings online', key: 'acceptsOnlineBookings', show: true },
    { title: 'Allow online staff selection', key: 'allowServiceStaffSelection', show: true }
  ]

  const goToField = (key: string) => () => {
    const element = document.getElementById(key)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (watch('cancellationFeeType')) {
      if (watch('cancellationFeeType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('cancellationFeeType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign('-')
    }
  }, [watch('cancellationFeeType')])

  const PERCENTAGE_AND_FIXED_VALUE_REQUIREMENTS = [
    {
      value: 'percentage',
      label: 'Percentage'
    },
    {
      value: 'fixed_value',
      label: 'Fixed Amount'
    }
  ]

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>
        <div className='w-full xl:max-w-[350px] flex justify-center xl:justify-start xl:items-start pt-4 px-2 xl:px-10'>
          <ul className='w-full flex flex-wrap justify-center flex-row xl:flex-col gap-2 xl:gap-0'>
            <li
              className={`flex items-center justify-between space-x-2 cursor-pointer px-[10px] py-3 text-grey-900 bg-grey-50`}
            >
              <span className='text-b5'>Online booking information</span>
              <SvgArrowRight width='10px' height='10px' />
            </li>
            <div className='hidden xl:flex flex-col w-full pl-[20px] space-y-3 py-2'>
              {Array?.isArray(LINKS) && LINKS?.length
                ? LINKS?.map((value, index) => value?.show ? (
                  <span
                    className='cursor-pointer text-grey-400'
                    key={`${index}-link-booking`}
                    onClick={goToField(value?.key)}
                  >
                    {value?.title}
                  </span>
                ) : null)
                : null}
            </div>
          </ul>
        </div>
        <div className='w-full border-l border-grey-50 flex flex-col shadow-sm'>
          <div className='w-full flex flex-col border-b border-grey-50 px-10 py-6'>
            <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
              Online booking
            </Paragraph>
            <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
              Find your online booking information
            </Paragraph>
          </div>
          <form
            onSubmit={handleSubmit(onSubmitData)}
            className='w-full space-y-6 px-10 py-4'
          >

            <div className='w-full flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal'>
                Upload logo
              </Paragraph>
              <div
                id='upload-logo-image'
                style={{ height: '130px' }}
                className='w-full flex border rounded-sm border-grey-20 border-dashed justify-center items-center'
              >
                <Controller
                  control={control}
                  name='logoUrl'
                  render={() => {
                    // const logoUrl = value
                    return (
                      <div className='flex flex-col'>
                        <div className='flex items-center space-x-4'>
                          <div className='relative cusor-pointer'>
                            {getImageUploadContent({
                              imageUrl: logoUrl,
                              uploadIsLoading: logoIsLoading
                            })}
                            <input
                              style={{ height: '96px' }}
                              className='cursor-pointer absolute block opacity-0 top-0 w-full'
                              type='file'
                              accept={IMAGE_UPLOAD_PATTERN}
                              onChange={handleLogoUpload}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
            </div>
            <Paragraph size='b7' color={COLORS.GREY[300]} className='text-center'>
              Kindly upload a logo with dimensions not exceeding 250px by 150px
            </Paragraph>
            <div className='w-full flex flex-col space-y-2'>
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal'>
                Upload header images
              </Paragraph>
              <div
                id='upload-header-image'
                className='w-full flex border rounded-sm border-grey-20 border-dashed h-[130px] justify-center items-center'
              >
                <Controller
                  control={control}
                  name='headerImageUrl'
                  render={({ field: { value }, formState: { errors } }) => {
                    const headerImageUploadUrl = imageUrl || value
                    return (
                      <div className='flex flex-col'>
                        <div className='flex items-center space-x-4'>
                          <div className='relative cursor-pointer'>
                            {getImageUploadContent({
                              imageUrl: headerImageUploadUrl,
                              uploadIsLoading: isFileUploading
                            })}
                            <input
                              className='cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]'
                              type='file'
                              accept={IMAGE_UPLOAD_PATTERN}
                              onChange={handleFileUpload}
                              id='headerImageUrl'
                            />
                          </div>
                        </div>
                        {errors?.headerImageUrl?.message && (
                          <FormHelperText variant='error'>
                            {errors.headerImageUrl.message.toString()}
                          </FormHelperText>
                        )}
                      </div>
                    )
                  }}
                />
              </div>
            </div>
            <div
              id='add-more-images'
              className='w-full flex flex-col space-y-2'
            >
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal'>
                Add more images <span className='text-grey-400'>(up to 5)</span>
              </Paragraph>
              <div className='w-full flex flex-wrap justify-between gap-2'>
                {Array?.isArray(headerImages) && headerImages?.length
                  ? headerImages.map((url, index) => {
                    const count = index + 1
                    const fieldName =
                      count === 1
                        ? 'headerImageUrl1'
                        : count === 2
                          ? 'headerImageUrl2'
                          : count === 3
                            ? 'headerImageUrl3'
                            : 'headerImageUrl4'
                    const isSubFileUploading =
                      uploadingSubImageIndex === index
                    return (
                      <Controller
                        control={control}
                        name={fieldName}
                        render={({ field: { value } }) => {
                          const uploadUrl = url || value
                          return (
                            <div
                              className='flex border border-dashed border-grey-20 items-center justify-center space-x-4 w-full max-w-[120px] h-[120px] p-4 cursor-pointer'
                              key={index}
                            >
                              <div className='relative cursor-pointer'>
                                {getImageUploadContent({
                                  imageUrl: uploadUrl,
                                  uploadIsLoading: isSubFileUploading,
                                  size: 'small'
                                })}
                                <input
                                  className='cursor-pointer absolute block opacity-0 top-0 w-full h-[96px]'
                                  type='file'
                                  accept={IMAGE_UPLOAD_PATTERN}
                                  onChange={(e) =>
                                    handleHeaderImageUpload(index, e)
                                  }
                                />
                              </div>
                            </div>
                          )
                        }}
                      />
                    )
                  })
                  : null}
              </div>
            </div>
            <div
              id='add-frequently-asked-questions'
              className='w-full flex flex-col space-y-4'
            >
              <Paragraph size='b4' color={COLORS.GREY[900]} weight='normal'>
                Add frequently asked questions{' '}
                <span className='text-grey-400'>(up to 5)</span>
              </Paragraph>

              {Array?.isArray(faqs) && faqs?.length
                ? faqs?.map((faq) => {
                  return (
                    <div
                      className='w-full flex border border-grey-50 rounded-md p-5'
                      key={faq?.id}
                    >
                      <div className='w-[90%] flex flex-col space-y-3'>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[300]}
                          weight='semiBold'
                        >
                          {faq?.question}
                        </Paragraph>
                        <Paragraph
                          size='b4'
                          color={COLORS.GREY[400]}
                          weight='normal'
                        >
                          {faq?.answer}
                        </Paragraph>
                      </div>
                      {actions?.editFaq || actions?.deleteFaq ?
                        <div className='w-[10%] flex justify-end items-start'>
                          <Menu
                            as='div'
                            className='relative inline-block text-left'
                          >
                            <div>
                              <Menu.Button
                                id='faq-option-button'
                                className='flex space-x-2 w-full items-center justify-center px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0'
                              >
                                <SvgCharmMenuKebab width='24px' height='24px' />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={React.Fragment}
                              enter='transition ease-out duration-100'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'
                            >
                              <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                                <div className='flex flex-col p-4 space-y-4'>
                                  {actions?.editFaq ?
                                    <Menu.Item>
                                      <span
                                        className={`text-b4 cursor-pointer flex items-center space-x-2`}
                                        onClick={() => {
                                          setFaq(faq)
                                          setShowFaqForm(true)
                                        }}
                                      >
                                        {/* Your menu item content */}
                                        <SvgEditRegular
                                          width='20px'
                                          height='20px'
                                        />{' '}
                                        <span>Edit</span>
                                      </span>
                                    </Menu.Item> : null}
                                  {actions?.deleteFaq ?
                                    <Menu.Item>
                                      <span
                                        className={`text-b4 cursor-pointer flex items-center space-x-2`}
                                        onClick={() => deleteFaqAsync(faq)}
                                      >
                                        {/* Your menu item content */}
                                        <SvgDeleteRegular
                                          width='20px'
                                          height='20px'
                                        />
                                        <span>Delete</span>
                                      </span>
                                    </Menu.Item> : null}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div> : null}
                    </div>
                  )
                })
                : null}
              {showFaqForm ? (
                <div className='w-full flex flex-col space-y-3 border border-grey-50 rounded-md p-5'>
                  <Paragraph
                    size='b4'
                    color={COLORS.GREY[300]}
                    weight='semiBold'
                  >
                    {faq ? 'Edit FAQ' : 'New FAQ'}
                  </Paragraph>
                  <div className='flex flex-col lg:flex-row gap-2'>
                    <Input
                      name='question'
                      label='What is your question?'
                      id='faq_question'
                      type='text'
                      placeholder='Enter question'
                      control={control}
                    />
                    <Input
                      name='answer'
                      label='Enter answer'
                      id='faq_answer'
                      type='text'
                      placeholder='Enter answer'
                      control={control}
                    />
                  </div>
                  <Button
                    variant='primary'
                    className='w-[30%]'
                    disabled={createFaqIsLoading}
                    loading={createFaqIsLoading}
                    size='md'
                    rounded='lg'
                    id='save-faq-button'
                    type='button'
                    onClick={saveFaq}
                  >
                    {faq ? 'Save' : 'Submit'}
                  </Button>
                </div>
              ) : null}
              {actions?.addFaq && Array?.isArray(faqs) && faqs?.length < 5 ? (
                <Button
                  variant='light'
                  size='xs'
                  rounded='lg'
                  type='button'
                  className='w-fit border border-grey-100'
                  id='add-faq-button'
                  onClick={() => {
                    setShowFaqForm(true)
                  }}
                >
                  Add new faq
                  <SvgPlus width='14px' height='14px' />
                </Button>
              ) : null}
            </div>
            <div id='website-link' className='flex flex-col space-y-4'>
              <div className='w-full flex gap-x-4 items-center'>
                <div className='flex justify-center items-center gap-x-1'>
                  <Paragraph size='b4' weight='normal' color={COLORS.GREY[400]}>
                    {spliceBookingLink_}
                  </Paragraph>
                  <BookingPageSetup
                    defaultSearchTerm={business?.bookingUrlIdentifier}
                    setDebouncedSearchQuery={setDebouncedSearchQuery}
                    successIcon={bookingPageLinkStatus}
                    disabled={!actions?.publicUrl}
                  />
                  <SvgGroupLink
                    width='60px'
                    height='60px'
                    className='cursor-pointer'
                    onClick={copyBookingLink}
                  />
                  <Anchor
                    to={`${spliceBookingLink_}${debouncedSearchQuery}`}
                    target='_blank'
                    id='view-link'
                  >
                    <div className='flex flex-col items-center space-y-2'>
                      <SvgExternal width='2em' height='2em' />
                    </div>
                  </Anchor>
                </div>
              </div>
            </div>

            <Controller
              control={control}
              name='acceptsOnlineBookings'
              render={({ field: { onChange, value } }) => {
                const _value = value || ''
                return (
                  <div
                    id='acceptsOnlineBookings'
                    className='w-full flex flex-col space-y-4'
                  >
                    <FormLabel htmlFor='acceptsOnlineBookings'>
                      Do you wish to make this business's online bookings
                      public?
                    </FormLabel>
                    <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                      {Array.isArray(YES_OR_NO_SETTINGS) &&
                        YES_OR_NO_SETTINGS.length
                        ? YES_OR_NO_SETTINGS.map(
                          (acceptsOnlineBookings: {
                            label: string
                            value: boolean
                          }) => {
                            return (
                              <div
                                className='flex'
                                onClick={() =>
                                  onChange(acceptsOnlineBookings?.value)
                                }
                                key={acceptsOnlineBookings?.label}
                              >
                                <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                                  <Checkbox
                                    isChecked={
                                      value === acceptsOnlineBookings?.value
                                    }
                                  />
                                  <span className='text-grey-900 whitespace-nowrap'>
                                    {acceptsOnlineBookings?.label}
                                  </span>
                                </div>
                              </div>
                            )
                          }
                        )
                        : null}
                    </div>
                  </div>
                )
              }}
            />

            <Controller
              control={control}
              name='about'
              render={({
                field: { onChange, onBlur, name, ref, value },
                formState: { errors }
              }) => {
                const maxLength = 1000
                const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                  errors?.about?.message as string
                )
                const helperTexts = []

                helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                return (
                  <FormTextarea
                    type='text'
                    id='about'
                    label='About'
                    placeholder='Add a short about the business, this shows on the booking page.'
                    {...{
                      ref,
                      name,
                      value,
                      onChange,
                      onBlur,
                      maxLength
                    }}
                    helperText={helperTexts}
                  />
                )
              }}
            />
            <Controller
              control={control}
              name='cancellationPolicy'
              render={({
                field: { onChange, onBlur, name, ref, value },
                formState: { errors }
              }) => {
                const maxLength = 1000
                const formErrorsHelpTexts = getHelperTextForReactHookFormErrors(
                  errors?.cancellationPolicy?.message as string
                )
                const helperTexts = []

                helperTexts.push(getHelpTextForCharacterLeft(maxLength, value))
                if (formErrorsHelpTexts) helperTexts.push(formErrorsHelpTexts)

                return (
                  <FormTextarea
                    type='text'
                    id='cancellationPolicy'
                    label='Cancellation Policy'
                    placeholder='Add cancellation policy'
                    {...{
                      ref,
                      name,
                      value,
                      onChange,
                      onBlur,
                      maxLength
                    }}
                    helperText={helperTexts}
                  />
                )
              }}
            />

            <Controller
              control={control}
              name='hasCancellationFee'
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                    onClick={() => onChange(!value)}
                  >
                    <FormLabel htmlFor='optOutEmail'>
                      Charge cancellation and no-show fees
                    </FormLabel>
                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <ToggleOnly isChecked={value} />
                    </div>
                  </div>
                )
              }}
            />

            <div className='flex flex-col w-full gap-6'>
              {actions?.cancellationFees && watch('hasCancellationFee') && (
                <div className='w-full flex items-center gap-x-4'>
                  <SelectInput
                    name='cancellationFeeType'
                    id='depositValue-type'
                    label='Enter cancellation charge'
                    control={control}
                    rules={{
                      required: ERRORS.DEPOSIT_TYPE_REQUIRED
                    }}
                    value={watch('cancellationFeeType')}
                    options={[
                      {
                        value: 'percentage',
                        label: 'Percentage'
                      },
                      {
                        value: 'fixed',
                        label: 'Fixed Amount'
                      }
                    ]}
                    placeholder='Select Requirement'
                  />
                  <Controller
                    control={control}
                    name='cancellationFee'
                    rules={{ required: 'Select' }}
                    render={({ field, formState: { errors } }) => (
                      <div className='w-full flex flex-col mt-7'>
                        <div className='flex w-full'>
                          <input
                            className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                            type='text'
                            {...field}
                            value={formatNumber(field.value)}
                            onChange={(e) =>
                              field.onChange(formatNumber(e.target.value))
                            }
                            placeholder='10'
                          />
                          <div className='px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900'>
                            {typeSign}
                          </div>
                        </div>
                        {errors?.cancellationFee?.message && (
                          <FormHelperText variant='error'>
                            {errors.cancellationFee.message}
                          </FormHelperText>
                        )}
                      </div>
                    )}
                  />
                </div>
              )}

              {actions?.noShowFees && watch('hasCancellationFee') &&
                !watch('applyCancellationFeeToNoCharge') && (
                  <div>
                    <Controller
                      control={control}
                      name='noShowFee'
                      rules={{ required: 'Enter charge' }}
                      render={({ field, formState: { errors } }) => (
                        <div className='w-full flex flex-col space-y-2'>
                          <FormLabel htmlFor='price'>
                            Enter no show charge
                          </FormLabel>
                          <input
                            className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                            type='text'
                            {...field}
                            value={formatNumber(field.value)}
                            onChange={(e) =>
                              field.onChange(formatNumber(e.target.value))
                            }
                            placeholder='Enter no show charge'
                          />
                          {errors?.noShowFee?.message && (
                            <FormHelperText variant='error'>
                              {errors.noShowFee.message}
                            </FormHelperText>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}
            </div>

            {actions?.noShowFees && watch('hasCancellationFee') ? (
              <Controller
                control={control}
                name='applyCancellationFeeToNoCharge'
                render={({ field: { onChange, value } }) => {
                  return (
                    <div
                      className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                      onClick={() => onChange(!value)}
                    >
                      <FormLabel htmlFor='optOutEmail'>
                        Use the same charge for no-show
                      </FormLabel>
                      <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                        <ToggleOnly isChecked={value} />
                      </div>
                    </div>
                  )
                }}
              />) : null}

            <Controller
              control={control}
              name='allowServiceStaffSelection'
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'
                    onClick={() => onChange(!value)}
                  >
                    <FormLabel htmlFor='optOutEmail'>
                      Allow online staff selection for service(s)?
                    </FormLabel>
                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <ToggleOnly isChecked={value} />
                    </div>
                  </div>
                )
              }}
            />

            <Button
              variant='primary'
              className=''
              disabled={false}
              loading={false}
              size='lg'
              rounded='lg'
              id='update-online-button'
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

interface IUpdateSalonInput extends UpdateSalonInput {
  locationTypeHome: boolean
  locationTypeBusiness: boolean
}
const BusinessLocation = () => {
  const {
    handleSubmit,
    setValue,
    register,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<IUpdateSalonInput>()
  const [userData, setUser] = useState<User>(
    JSON.parse(localStorage.getItem('userData')) as User
  )
  const [typeSign, setTypeSign] = useState<string | null>('-')
  const [countryCode, setCountryCode] = useState('')
  const [callingCode, setCallingCode] = useState('')
  const { getSalonData, setSalonData } = useSalonCache()
  const salon = getSalonData() as Salon
  const { toast, addToast } = useToast()
  const [locationSearchTerm, setLocationSearchTerm] = useState('')
  const {
    loading,
    updateSalon
  } = useUpdateSalon()

  const {
    isVisible: isSalonLocationVisible,
    openModal: openSalonLocationModal,
    closeModal: closeSalonLocationModal
  } = useModal()

  const submitData = async (input: IUpdateSalonInput) => {
    const payload: UpdateSalonInput = {
      id: salon.id,
      // @ts-expect-error error expected
      address: input?.address?.value,
      bearsProcessingFee: salon?.bearsProcessingFee,
      branchName: input?.branchName,
      callingCode,
      city: input?.city,
      depositLinkValidityHours: Number(input.depositLinkValidityHours),
      depositType: input?.depositType || null,
      depositValue: input?.depositValue && input?.isDepositRequired ? Number(input?.depositValue?.toString()?.replace(/,/g, '')) : 0,
      email: input.email,
      intervalMinutes: salon?.intervalMinutes || 30,
      isDepositRequired: input?.isDepositRequired,
      isTaxVisible: salon?.isTaxVisible,
      locationType:
        input.locationTypeBusiness && input?.locationTypeHome
          ? LocationTypeEnum.Flexible
          : input.locationTypeBusiness
            ? LocationTypeEnum.Salon
            : LocationTypeEnum.Home,
      phone: input.phone,
      state: input.state,
      status: SalonStatusEnum.Open,
      taxInclusivePricing: salon?.taxInclusivePricing,
      logoUrl: userData.business.logoUrl
    }

    updateSalon({
      variables: { input: payload }
    }).then(({ data }) => {
      const {
        updateSalon
      } = data

      if (updateSalon.status === 200) {
        addToast({ message: 'Salon saved successfully', variant: 'success' })
      } else {
        addToast({ message: updateSalon.errors[0].message, variant: 'error' })
      }
    })
  }
  const LOYALTY_TYPE_REQUIREMENTS = [
    {
      value: 'percentage',
      label: 'Percentage'
    },
    {
      value: 'fixed_value',
      label: 'Fixed Amount'
    }
  ]

  const _sanitizePhoneNumber = (phoneNumber: string) => {
    const _phoneNumber = sanitizePhoneNumber(phoneNumber, callingCode)
    setValue('phone', _phoneNumber)

    const pn = validatePhoneNumber(_phoneNumber, countryCode);

    if (!pn.valid) {
      // If invalid, trigger an error
      setError('phone', {
        type: 'manual',
        message: 'Invalid phone number',
      });
    } else {
      // Clear the error if the phone number is valid
      clearErrors('phone');
    }
  }

  useEffect(() => {
    if (watch('depositType')) {
      if (watch('depositType') === 'fixed_value') {
        setTypeSign(DEFAULT_CURRENCY)
      } else if (watch('depositType') === 'percentage') {
        setTypeSign('%')
      }
    } else {
      setTypeSign('-')
    }
  }, [watch('depositType')])

  useEffect(() => {
    if (salon) {
      setValue('branchName', salon?.branchName)
      // @ts-expect-error error expected
      setValue('address', { label: salon?.address, value: salon.address })
      setValue('city', salon?.city)
      setValue('state', salon?.state)
      setValue('email', salon?.email)
      setValue('phone', salon?.phone)
      if (salon?.locationType === 'salon') {
        setValue('locationTypeBusiness', true)
      }
      if (salon?.locationType === 'home') {
        setValue('locationTypeHome', true)
      }
      if (salon?.locationType === 'flexible') {
        setValue('locationTypeHome', true)
        setValue('locationTypeBusiness', true)
      }
      setValue('depositValue', salon?.depositValue)
      setValue('depositType', (salon?.depositType || '') as DepositTypeEnum)
      setValue('depositLinkValidityHours', salon?.depositLinkValidityHours)
      setValue('isDepositRequired', salon?.isDepositRequired || false)
    }
  }, [])

  const searchLocation = (e) => {
    setLocationSearchTerm(e)
  }

  const {
    data,
    loading: googlePlacesSearchLoading,
    error: googlePlacesSearchError,
    refetch: googlePlacesSearchRefetch
  } = useGooglePlaceSearch({
    q: locationSearchTerm
  })

  const locationOptions = useMemo(() => {
    if (data?.googlePlacesSearch) {
      return data?.googlePlacesSearch.map((prediction) => {
        return {
          label: prediction.label,
          value: prediction?.value
        }
      })
    }
    return []
  }, [data])

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (locationSearchTerm) {
        googlePlacesSearchRefetch()
      }
    }, 500)

    return () => clearTimeout(delaySearch)
  }, [locationSearchTerm])

  return (
    <>
      <ToastWrapper toast={toast} />
      <form
        onSubmit={handleSubmit(submitData)}
        className='w-full space-y-6'
        autoComplete='off'
      >
        <Input
          name='branchName'
          id='branch-name'
          label='Branch name'
          type='text'
          placeholder='Enter branch name here'
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.ALPHANUMERIC_WITH_SPACE_OR_DASH,
            required: ERRORS.BUSINESS_BRANCH_NAME_REQUIRED
          }}
          error={errors.branchName}
        />
        {/* <Input
          name='address'
          id='business-address'
          label='Business Address'
          type='text'
          placeholder='Enter business address here'
          control={control}
          rules={{
            required: ERRORS.ADDRESS_REQUIRED
          }}
          error={errors.address}
        /> */}

        <div className='w-full flex flex-col space-y-2'>
          <Controller
            control={control}
            name='address'
            render={({ field: { onChange, value }, formState: { errors } }) => {
              const errorMessage: string = errors?.address?.message

              return (
                <>
                  <FormLabel htmlFor='client'>Business Address</FormLabel>
                  <SingleSelect
                    // @ts-expect-error exprect error
                    selected={value || []}
                    options={locationOptions as []}
                    setSelected={onChange}
                    isLoading={googlePlacesSearchLoading}
                    onInputChange={searchLocation}
                    placeholder='Enter address'
                  />
                  {errorMessage && (
                    <FormHelperText variant='error'>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              )
            }}
          />
        </div>

        <div className='flex flex-col space-y-4'>
          <FormLabel htmlFor='locationSetupSalon'>
            Where do you serve clients?
          </FormLabel>
          <div className='flex space-x-4'>
            <Controller
              control={control}
              name='locationTypeBusiness'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='flex' onClick={() => onChange(!value)}>
                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <Checkbox isChecked={value} />
                      <span className='text-grey-900 whitespace-nowrap'>
                        Business Address
                      </span>
                    </div>
                  </div>
                )
              }}
            />
            <Controller
              control={control}
              name='locationTypeHome'
              render={({ field: { onChange, value } }) => {
                return (
                  <div className='flex' onClick={() => onChange(!value)}>
                    <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                      <Checkbox isChecked={value} />
                      <span className='text-grey-900 whitespace-nowrap'>
                        Home Service
                      </span>
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </div>
        <Controller
          control={control}
          name='isDepositRequired'
          render={({ field: { onChange, value } }) => {
            return (
              <div className='w-full flex flex-col space-y-4'>
                <FormLabel htmlFor='isDepositRequired'>
                  Is deposit required for booking?
                </FormLabel>
                <div className='w-full flex flex-wrap gap-4 xl:gap-0 xl:space-x-4'>
                  {Array.isArray(YES_OR_NO_SETTINGS) &&
                    YES_OR_NO_SETTINGS.length
                    ? YES_OR_NO_SETTINGS.map(
                      (isDepositRequired: {
                        label: string
                        value: boolean
                      }) => {
                        return (
                          <div
                            className='flex'
                            onClick={() => onChange(isDepositRequired?.value)}
                            key={isDepositRequired?.label}
                          >
                            <div className='flex items-center cursor-pointer text-b5 text-grey-900 space-x-2'>
                              <Checkbox
                                isChecked={value === isDepositRequired?.value}
                              />
                              <span className='text-grey-900 whitespace-nowrap'>
                                {isDepositRequired?.label}
                              </span>
                            </div>
                          </div>
                        )
                      }
                    )
                    : null}
                </div>
              </div>
            )
          }}
        />
        {watch('isDepositRequired') && (
          <>
            <div className='w-full flex items-center gap-x-4'>
              <SelectInput
                name='depositType'
                id='depositValue-type'
                label='How much deposit is required?'
                control={control}
                rules={{
                  required: ERRORS.DEPOSIT_TYPE_REQUIRED
                }}
                value={watch('depositType')}
                error={errors.depositType}
                options={LOYALTY_TYPE_REQUIREMENTS}
                placeholder='Select Requirement'
              />
              <Controller
                control={control}
                name='depositValue'
                rules={{ required: 'How much deposit is required?' }}
                render={({ field, formState: { errors } }) => (
                  <div className='w-full flex flex-col mt-7'>
                    <div className='flex w-full'>
                      <input
                        className='w-full flex items-center appearance-none border border-grey-20 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-tl-lg rounded-bl-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400'
                        type='text'
                        {...field}
                        value={formatNumber(field.value)}
                        onChange={(e) =>
                          field.onChange(formatNumber(e.target.value))
                        }
                        placeholder='10'
                      />
                      <div className='px-5 py-3 bg-grey-20 rounded-tr-lg rounded-br-lg text-grey-900'>
                        {typeSign}
                      </div>
                    </div>
                    {errors?.depositValue?.message && (
                      <FormHelperText variant='error'>
                        {errors.depositValue.message}
                      </FormHelperText>
                    )}
                  </div>
                )}
              />
            </div>

            <SelectInput
              name='depositLinkValidityHours'
              id='depositLinkValidityHours'
              label='Select Payment Link Duration'
              control={control}
              rules={{
                required: ERRORS.DEPOSIT_INTERVAL_REQUIRED
              }}
              value={watch('depositLinkValidityHours')}
              error={errors.depositLinkValidityHours}
              options={DEPOSIT_DURATION_INTERVAL}
              placeholder='Select Duration'
            />
          </>
        )}
        <Paragraph
          size='b4'
          color={COLORS.GREY[300]}
          weight='bold'
          className='pt-3'
        >
          Contact information
        </Paragraph>
        <Input
          name='email'
          label='Contact Email'
          id='email'
          type='email'
          placeholder='email@acme.com'
          control={control}
          rules={{
            pattern: REGEX_PATTERNS.EMAIL,
            required: ERRORS.EMAIL_REQUIRED
          }}
          error={errors.email}
        />
        <FormPhone
          country={{
            disabled: false,
            onSelect: (code, country: string) => {
              setCallingCode(code)
              setCountryCode(country)
            },
            value: countryCode
          }}
          phone={{
            name: 'phone',
            type: 'text',
            placeholder: '9151930463',
            rules: {
              required: ERRORS.PHONE_REQUIRED,
              pattern: REGEX_PATTERNS.NUMBER,
              onChange: (e) => {
                _sanitizePhoneNumber(e.target.value)
              },
              disabled: false
            },
            register,
            id: 'phone-number',
            label: 'Contact Phone Number'
          }}
          helperText={getHelperTextForReactHookFormErrors(
            errors?.phone?.message as string
          )}
        />
        {canPerformAction(PERMISSION_CONSTANTS?.settings?.addLocation) ?
          <Button
            variant='text'
            className='mx-auto'
            disabled={false}
            loading={false}
            size='none'
            type='button'
            onClick={openSalonLocationModal}
          >
            Add another location
          </Button> : null}
        <Button
          variant='primary'
          className=''
          disabled={false}
          loading={loading}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
      </form>
      <NewLocation
        isVisible={isSalonLocationVisible}
        closeModal={closeSalonLocationModal}
        addToast={addToast}
      />
    </>
  )
}

const PaymentWrapper = () => {
  return <PaymentSettingsTabPage />
}

const PaymentSettingsTabPage = () => {
  const PAYMENT_SETTING_TABS = [
    {
      key: 'pos',
      title: 'POS devices',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.addPos) || canPerformAction(PERMISSION_CONSTANTS?.settings?.deletePos)
    },
    {
      key: 'transfer',
      title: 'Bank Transfers',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.addBankTransfers) || canPerformAction(PERMISSION_CONSTANTS?.settings?.deleteBankTransfers)
    },
    {
      key: 'bank',
      title: 'Payout Account',
      show: true
    },
    {
      key: 'fee',
      title: ' Online Processing Fee',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.processingFees)
    }
  ]
  const [paymentActive, setPaymentActive] = useState(PAYMENT_SETTING_TABS?.filter((payment) => payment?.show)[0]?.key)
  const [userData, setUserData] = useState<User>(
    JSON.parse(localStorage.getItem('userData')) as User
  )
  const { toast } = useToast()
  const setPaymentTab = (tab: string) => {
    setPaymentActive(tab)
  }
  const { isVisible, openModal, closeModal } = useModal()

  const [getUser, { error, data, called }] = useLazyQuery(USER);
  useMemo(() => {
    const currentUser: User = data?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      setUserData(currentUser)
    }

    return currentUser;
  }, [data]);

  const BankDetails = () => {
    const bankName = userData?.business?.payoutAccount?.bankName || null
    const accountNumber =
      userData?.business?.payoutAccount?.accountNumber || null
    const accountName = userData?.business?.payoutAccount?.accountName || null
    return (
      <div className='flex flex-col space-y-4'>
        <Paragraph size='b5' color={COLORS.GREY[300]} weight='bold'>
          {bankName}
        </Paragraph>

        <div className='flex flex-col space-y-2'>
          <Heading
            variant='h1'
            size='h6'
            color={COLORS.GREY[900]}
            weight='bold'
          >
            {accountNumber}
          </Heading>
          <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
            {accountName}
          </Paragraph>
        </div>
        <Button
          variant='primary'
          type='button'
          size='md'
          rounded='md'
          className='w-fit'
          onClick={openModal}
        >
          Change account
        </Button>
        <Modal title='' show={isVisible} closeModal={closeModal}>
          <div className='w-[70%] text-center mx-auto my-6'>
            <Paragraph size='b4' color={COLORS.GREY[300]}>
              Please contact customer support at{' '}
              <a
                className='text-green-600 font-semibold'
                href='mailto:info@withsplice.com'
              >
                info@withsplice.com
              </a>{' '}
              to change your account number
            </Paragraph>
          </div>
          <Button
            variant='secondary'
            type='button'
            size='md'
            rounded='md'
            className='w-fit mx-auto'
            onClick={closeModal}
          >
            Close
          </Button>
        </Modal>
      </div>
    )
  }

  const getTabView = () => {
    switch (paymentActive) {
      case 'transfer':
        return <Transfers />
      case 'pos':
        return <Pos />
      case 'bank':
        return userData.business.payoutAccount ? (
          <BankDetails />
        ) : (
          <BankForm buttonText='Save' refetch={getUser} />
        )
      default:
        return <ProcessingFee />
    }
  }

  const getTabHeaderView = () => {
    let headerText, descriptionText

    switch (paymentActive) {
      case 'transfer':
        headerText = 'Bank Transfers'
        descriptionText = 'Find your bank information '
        break
      case 'bank':
        headerText = 'Payout Account information'
        descriptionText = 'This is the bank account you will receive your payouts for payments you receive online'
        break
      case 'pos':
        headerText = 'POS Devices'
        descriptionText = 'Find your POS devices information '
        break
      default:
        headerText = 'Online Processing Fee'
        descriptionText = 'Set your online processing fee'
        break
    }

    return (
      <div className='w-full flex flex-col border-b border-grey-50 px-10 py-6'>
        <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
          {headerText}
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
          {descriptionText}
        </Paragraph>
      </div>
    )
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>
        <SideMenu
          active={paymentActive}
          setTab={setPaymentTab}
          tabs={PAYMENT_SETTING_TABS}
        />
        <div className='w-full border-l border-grey-50 flex flex-col shadow-sm'>
          {getTabHeaderView()}
          <div className='w-full h-full px-10 py-6'>{getTabView()}</div>
        </div>
      </div>
    </>
  )
}

const Pos = () => {
  const { getSalonData } = useSalonCache()
  const { toast, addToast } = useToast()
  const salon = getSalonData() as Salon
  const POS_HEADINGS = [
    {
      label: 'Name',
      value: 'name'
    },
    { label: 'Bank', value: 'bank' }
  ]
  const MOBILE_POS_HEADINGS = [
    {
      key: 'name',
      title: 'Name'
    },
    {
      key: 'bank',
      title: 'Bank'
    }
  ]
  const actions = {
    addPos: canPerformAction(PERMISSION_CONSTANTS?.settings?.addPos),
    deletePos: canPerformAction(PERMISSION_CONSTANTS?.settings?.deletePos)
  }

  const {
    data: posDevicesData,
    loading,
    refetch
  } = useGetPosDevices(salon?.id)
  const posDevices = posDevicesData?.posDevices || []

  const {
    data: banksData,
  } = useGetBanks()
  const banks = banksData?.banks || []

  const [pos, setPos] = useState<PosDevice | null>(null)
  const {
    openModal: openAddOrEditPosModal,
    closeModal: closeAddOrEditPosModal,
    isVisible: isAddOrEditPosModalVisible
  } = useModal()

  const _closeAddOrEditPosModal = () => {
    closeAddOrEditPosModal()
    setPos(null)
    refetch()
  }

  const generatePosTerminalsTableData = (accounts: PosDevice[]) => {
    return accounts?.map?.((account) => ({
      name: account?.name,
      bank: account?.bankName
    }))
  }

  const openPosModal = (index: number) => {
    if (!actions?.deletePos) return;
    if (Array?.isArray(posDevices) && posDevices?.length) {
      const pos = posDevices[index];
      if (!pos) return;
      setPos(pos)
    }
    openAddOrEditPosModal()
  }

  const getPosContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array.isArray(posDevices) && posDevices.length) {
      const rows = generatePosTerminalsTableData(posDevices)
      return (
        <>
          <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
            Point-of-Sale Terminal
          </Paragraph>
          <Table
            headers={POS_HEADINGS}
            mobileHeaders={MOBILE_POS_HEADINGS}
            rows={rows}
            onClick={openPosModal}
          />
          {actions?.addPos ?
            <Button
              variant='primary'
              size='md'
              type='button'
              rounded="lg"
              fontSize='b5'
              className='text-grey-400'
              fontWeight='semiBold'
              onClick={openAddOrEditPosModal}
            >
              Add new POS
            </Button> : null}
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <Heading variant='h2' size='h6'>
            Point-of-Sale Terminal
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You have not added any pos yet.
          </Paragraph>
          <div className='w-full space-x-2'>
            {actions?.addPos ? (
              <Button
                variant='primary'
                size='lg'
                disabled={false}
                loading={false}
                type='button'
                rounded='lg'
                onClick={openAddOrEditPosModal}
              >
                Add POS
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      {getPosContent()}
      <AddOrEditPos
        isVisible={isAddOrEditPosModalVisible}
        closeModal={_closeAddOrEditPosModal}
        pos={pos}
        banks={banks}
        addToast={addToast}
      />
    </>
  )
}

type AddPosModalProps = {
  isVisible: boolean
  closeModal: () => void
  pos?: PosDevice
  transferAccount?: TransferAccount
  banks?: Bank[]
  addToast?: (toast: ToastProps) => void
}
const AddOrEditPos = ({ isVisible, closeModal, pos, banks, addToast }: AddPosModalProps) => {
  const {
    loading: saving,
    addPosDevice,
  } = useAddPosDevice()

  const {
    loading: deleting,
    deletePosDevice
  } = useDeletePosDevice()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreatePosDeviceInput>()
  const { getSalonFieldValue } = useSalonCache()

  const handleClose = () => {
    setValue('bankCode', '')
    setValue('name', '')
    closeModal()
  }

  useEffect(() => {
    if (pos) {
      setValue('bankCode', pos.bankCode)
      setValue('name', pos.name)
    } else {
      setValue('bankCode', '')
      setValue('name', '')
    }
  }, [pos])

  const savePos = async (input: CreatePosDeviceInput) => {
    const payload = {
      id: pos ? pos.id : null,
      name: input.name,
      bankCode: input.bankCode,
      salonId: getSalonFieldValue('id')
    }
    addPosDevice({
      variables: { input: payload }
    }).then(({ data }) => {
      const { createPosDevice } = data;

      if (createPosDevice.status === 201) {
        addToast && addToast({
          message: 'POS device saved successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: createPosDevice.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleSaveOrCancel = () => {
    if (pos) {
      deletePosDevice({
        variables: {
          input: { id: pos.id, salonId: getSalonFieldValue('id') },
        }
      }).then(({ data }) => {
        const { deletePosDevice } = data;

        if (deletePosDevice.status === 200) {
          addToast && addToast({
            message: 'POS device deleted successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          addToast({ message: deletePosDevice.errors[0].message, variant: 'error' })
        }
      })
    } else {
      handleClose()
    }
  }

  const bankListOptions = banks.map((bank: Bank) => ({
    value: bank.code,
    label: bank.name
  }))

  return (
    <Modal
      title={
        pos ? 'Point of Sale Terminal Details' : 'Add Point of Sale Terminal'
      }
      show={isVisible}
      closeModal={handleClose}
    >
      <form onSubmit={handleSubmit(savePos)} className='w-full space-y-6 pt-6'>
        <Input
          name='name'
          id='pos-name'
          label='Point of Sale Terminal Name'
          type='text'
          placeholder='Enter Point of Sale Terminal Name'
          control={control}
          rules={{
            required: ERRORS.POS_NAME_REQUIRED,
            pattern: REGEX_PATTERNS.ALPHANUMERIC
          }}
          error={errors.name}
        />

        <SelectInput
          name='bankCode'
          id='bank-name'
          label='Bank Name '
          control={control}
          rules={{
            required: ERRORS.BANK_NAME_REQUIRED
          }}
          error={errors.bankCode}
          options={bankListOptions}
          placeholder='Select Bank'
        />

        <Button
          variant='primary'
          className=''
          disabled={saving || deleting}
          loading={saving || deleting}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          className={`mx-auto ${pos && 'text-red-500'}`}
          disabled={deleting || saving}
          loading={deleting || saving}
          size='none'
          type='button'
          onClick={handleSaveOrCancel}
        >
          {pos ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  )
}

const Transfers = () => {
  const { getSalonData } = useSalonCache()
  const { toast, addToast } = useToast()
  const salon = getSalonData() as Salon
  const {
    data: banksData,
  } = useGetBanks()
  const banks = banksData?.banks || [];

  const {
    loading,
    data: transferAccountsData,
    refetch
  } = useGetTransferAccounts(salon?.id);
  const transferAccounts = transferAccountsData?.transferAccounts || [];

  const POS_HEADINGS = [
    { label: 'Name', value: 'name' },
    { label: 'Bank', value: 'bank' }
  ]
  const MOBILE_POS_HEADINGS = [
    {
      key: 'name',
      title: 'Name'
    },
    {
      key: 'bank',
      title: 'Bank'
    }
  ]
  const actions = {
    addTransferAccount: canPerformAction(
      PERMISSION_CONSTANTS?.settings?.addBankTransfers
    ),
    deleteTransferAccount: canPerformAction(
      PERMISSION_CONSTANTS?.settings?.deleteBankTransfers
    )
  }
  const [transferAccount, setTransferAccount] =
    useState<TransferAccount | null>(null)
  const {
    openModal: openAddOrEditTransferAccountModal,
    closeModal: closeAddOrEditTransferAccountModal,
    isVisible: isAddOrEditTransferAccountModalVisible
  } = useModal()

  const _closeAddOrEditTransferAccountModal = () => {
    closeAddOrEditTransferAccountModal()
    setTransferAccount(null)
    refetch()
  }

  const generateTransferAccountsTableData = (accounts: TransferAccount[]) => {
    return accounts?.map?.((account) => ({
      name: account?.accountName,
      bank: account?.bankName
    }))
  }

  const openTransferAccountModal = (id: number) => {
    setTransferAccount(transferAccounts[id])
    openAddOrEditTransferAccountModal()
  }

  const getTransferAccountContent = () => {
    if (loading) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array.isArray(transferAccounts) && transferAccounts.length) {
      const rows = generateTransferAccountsTableData(transferAccounts)
      return (
        <>
          <Paragraph size='b4' color={COLORS.GREY[300]} weight='bold'>
            Bank Transfer Accounts
          </Paragraph>
          <Table
            headers={POS_HEADINGS}
            mobileHeaders={MOBILE_POS_HEADINGS}
            rows={rows}
            onClick={openTransferAccountModal}
          />
          {actions?.addTransferAccount ?
            <Button
              variant='primary'
              size='md'
              type='button'
              fontSize='b5'
              rounded='lg'
              className='text-grey-400'
              fontWeight='semiBold'
              onClick={openAddOrEditTransferAccountModal}
            >
              Add Transfer Account
            </Button> : null}
        </>
      )
    }

    return (
      <div className='w-full flex h-full justify-center items-center'>
        <div className='flex flex-col justify-center items-center space-y-6 w-full max-w-[450px] p-12'>
          <Heading variant='h2' size='h6'>
            Transfer Accounts
          </Heading>
          <Paragraph size='b5' className='mt-4'>
            You have not added any transfer account yet.
          </Paragraph>
          <div className='w-full space-x-2'>
            {actions?.addTransferAccount ?
              <Button
                variant='primary'
                size='lg'
                disabled={false}
                loading={false}
                type='button'
                rounded='lg'
                onClick={openAddOrEditTransferAccountModal}
              >
                Add Transfer Account
              </Button> : null}
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      {getTransferAccountContent()}
      <AddOrEditTransferAccountModal
        isVisible={isAddOrEditTransferAccountModalVisible}
        closeModal={_closeAddOrEditTransferAccountModal}
        transferAccount={transferAccount}
        banks={banks}
        addToast={addToast}
      />
    </>
  )
}

const AddOrEditTransferAccountModal = ({
  isVisible,
  closeModal,
  transferAccount,
  banks,
  addToast
}: AddPosModalProps) => {
  const {
    loading: saving,
    addTransferAccount,
  } = useAddTransferAccount()

  const {
    loading: deleting,
    deleteTransferAccount
  } = useDeleteTransferAccount()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<CreateTransferAccountInput>()
  const { getSalonFieldValue } = useSalonCache()

  const handleClose = () => {
    setValue('bankCode', '')
    setValue('accountNumber', '')
    closeModal()
  }

  useEffect(() => {
    if (transferAccount) {
      setValue('bankCode', transferAccount.bankCode)
      setValue('accountNumber', transferAccount.accountNumber)
    } else {
      setValue('bankCode', '')
      setValue('accountNumber', '')
    }
  }, [transferAccount])

  const saveTransferAccount = async (input: CreateTransferAccountInput) => {
    const payload = {
      id: transferAccount ? transferAccount.id : null,
      accountNumber: input.accountNumber,
      bankCode: input.bankCode,
      salonId: getSalonFieldValue('id')
    }
    addTransferAccount({
      variables: { input: payload }
    }).then(({ data }) => {
      const { createTransferAccount } = data;
      if (createTransferAccount.status === 201) {
        addToast({
          message: 'Transfer account saved successfully',
          variant: 'success'
        })
        handleClose()
      } else {
        addToast({ message: createTransferAccount.errors[0].message, variant: 'error' })
      }
    })
  }

  const handleSaveOrCancel = () => {
    if (transferAccount) {
      deleteTransferAccount({
        variables: {
          input: { id: transferAccount.id },
        }
      }).then(({ data }) => {
        const { deleteTransferAccount } = data;
        if (deleteTransferAccount.status === 200) {
          addToast({
            message: 'Transfer account deleted successfully',
            variant: 'success'
          })
          handleClose()
        } else {
          addToast({ message: deleteTransferAccount.errors[0].message, variant: 'error' })
        }
      })
    } else {
      handleClose()
    }
  }

  const bankListOptions = banks.map((bank: Bank) => ({
    value: bank.code,
    label: bank.name
  }))

  return (
    <Modal title='Add Bank Account' show={isVisible} closeModal={handleClose}>
      <form
        onSubmit={handleSubmit(saveTransferAccount)}
        className='w-full space-y-6 pt-6'
      >
        <Input
          name='accountNumber'
          id='account-number'
          label='Account Number'
          type='text'
          placeholder='Enter account number here'
          control={control}
          rules={{
            required: ERRORS.ACCOUNT_NUMBER_REQUIRED,
            pattern: REGEX_PATTERNS.ACCOUNT_NUMBER
          }}
          error={errors.accountNumber}
        />

        <SelectInput
          name='bankCode'
          id='bank-name'
          label='Bank Name '
          control={control}
          rules={{
            required: ERRORS.BANK_NAME_REQUIRED
          }}
          error={errors.bankCode}
          options={bankListOptions}
          placeholder='Select Bank'
        />

        <Button
          variant='primary'
          className=''
          disabled={saving || deleting}
          loading={saving || deleting}
          size='lg'
          rounded='lg'
        >
          Save
        </Button>
        <Button
          variant='text'
          className={`mx-auto ${transferAccount && 'text-red-500'}`}
          disabled={deleting || saving}
          loading={deleting || saving}
          size='none'
          type='button'
          onClick={handleSaveOrCancel}
        >
          {transferAccount ? 'Delete' : 'Cancel'}
        </Button>
      </form>
    </Modal>
  )
}

const Addons = () => {
  const [addons, setAddons] = useState<AddonStatus[]>([])
  const [addon, setAddon] = useState<AddonStatus>()
  const { toast, addToast } = useToast()
  const {
    isVisible: isAddonModalVisible,
    openModal: openAddonModal,
    closeModal: closeAddonModal
  } = useModal()

  const {
    loading,
    data: cardsData
  } = useCards()
  const cards = useMemo(() => cardsData?.cards, [cardsData])

  const {
    loading: userLoading,
    data: userData,
    refetch
  } = useUser()

  useMemo(() => {
    const currentUser: User = userData?.user;

    if (currentUser) {
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify(currentUser));
      localStorage.setItem(
        'role',
        JSON.stringify(currentUser?.salonStaff?.staffRole)
      );
      setAddons(currentUser?.business?.addons);
    }

    return currentUser;
  }, [userData]);

  const initiateCloseAddonModal = () => {
    setAddon(null)
    refetch()
    closeAddonModal()
  }

  const getAddonIcon = (addonName: string) => {
    if (addonName?.toLowerCase()?.includes('loyalty')) {
      return <SvgPercent width="20px" height="20px" />
    }

    if (addonName?.toLowerCase()?.includes('voucher')) {
      return <SvgGiftBox width="25px" height="25px" />
    }

    if (addonName?.toLowerCase()?.includes('messaging')) {
      return <SvgMessageSquare width="20px" height="20px" />
    }

    if (addonName?.toLowerCase()?.includes('sms')) {
      return <SvgMessageSquare width="20px" height="20px" />
    }
  }

  const unlockSubscribe = (addon: AddonStatus) => {
    setAddon(addon)
    openAddonModal()
  }

  const getDescription = (list: string[]) => {
    if (!list?.length) return '';

    return list[0]
  }

  return (
    <>
      <ToastWrapper toast={toast} />
      <div className='w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4'>
        {Array?.isArray(addons) && addons?.length ? addons?.map((addon) => {
          return (
            <div className='flex flex-col space-y-6 w-full max-w-[450px] p-4 border-grey-100 border rounded-md cursor-pointer' onClick={() => unlockSubscribe(addon)}>
              <div className='p-2 rounded-sm border border-grey-100 w-fit'>{getAddonIcon(addon?.addon?.name)}</div>
              <div className='flex flex-col space-y-2'>
                <Heading variant='h2' size='h11'>
                  {addon?.addon?.name}
                </Heading>
                <div className='w-full flex items-start space-x-2'>
                  <Paragraph size='b6' className=''>
                    {getDescription(addon?.addon?.featureList)}
                  </Paragraph>
                </div>
                <br />
                <div className='w-full flex items-start space-x-2'>
                  <Paragraph size='b6' className=''>
                    Unit Price:
                  </Paragraph>

                  <Paragraph size='b6' className='' weight='semiBold'>
                    {formatInToPrice(addon?.unitPrice)}
                  </Paragraph>
                </div>
                <div className='w-full flex items-start space-x-2'>
                  <Paragraph size='b6' className=''>
                    Branches:
                  </Paragraph>

                  <Paragraph size='b6' className='' weight='semiBold'>
                    x {addon?.salonCount}
                  </Paragraph>
                </div>
                <div className='w-full flex items-start space-x-2'>
                  <Paragraph size='b6' className=''>
                    Total Price:
                  </Paragraph>

                  <Paragraph size='b6' className='' weight='bold'>
                    {formatInToPrice(addon?.price)}
                  </Paragraph>
                </div>
              </div>
              {!addon?.subscribed ?
                <Button
                  variant='primary'
                  size='sm'
                  type='button'
                  rounded='lg'
                  onClick={() => unlockSubscribe(addon)}
                  className='w-fit'
                >
                  Enable
                </Button>
                : addon?.expiryDate ? <span className='w-fit px-2 py-1 bg-green-300 text-b5 font-semibold text-white rounded-full'>
                  Expires on {getShortDate(addon?.expiryDate)}
                </span> : null}

            </div>
          )
        }) : null
        }
      </div>
      <AddonDetails addon={addon} isOpen={isAddonModalVisible} closeModal={initiateCloseAddonModal} cards={cards} addToast={addToast} />
    </>
  )
}

const PaymentCards = () => {
  const {
    loading,
    data: cardsData
  } = useCards()
  const cards = useMemo(() => cardsData?.cards, [cardsData])

  return (
    <div className='w-full flex flex-wrap py-6 gap-2'>
      {Array?.isArray(cards) && cards?.length ? cards?.map((card) => {
        return (
          <div className='w-full max-w-[300px]'>
            <PaymentCard card={card} />
          </div>
        )
      }) : (
        <div className='w-full h-[70px] flex flex-col space-y-2 items-center justify-center max-w-[400px] border-2 border-dashed rounded-md border-green-300'>
          <Heading variant='h1' size='h8' weight='semiBold' color={COLORS.GREY[900]}>No cards found</Heading>
        </div>
      )}
    </div>
  )
}

type PaymentCardProps = {
  card: Card
}

const getBranchLogo = (cardType: string) => {
  if (cardType?.toLowerCase() === 'visa') {
    return <SvgVisa width="40px" height="40px" />
  }

  if (cardType?.toLowerCase() === 'verve') {
    return <SvgVerve width="40px" height="40px" />
  }

  return <SvgMasterCard width="40px" height="40px" />
}

const PaymentCard = ({ card }: PaymentCardProps) => {
  return (
    <div className="bg-[url('https://res.cloudinary.com/dqqrgidob/image/upload/v1722700311/lt4otfwy13i1lgnzfmre.png')] w-full flex flex-col justify-between bg-green-300 rounded-md bg-cover bg-center bg-no-repeat h-[200px] p-6">
      <div className='flex items-center justify-end'>
        {getBranchLogo(card?.cardType?.trim())}
      </div>
      <div className="w-full flex justify-between items-center">
        <div className='w-full flex flex-col space-y-3'>
          <Paragraph size='b4' weight='semiBold' color={COLORS.WHITE} className='uppercase'>{card?.accountName}</Paragraph>
          <Paragraph size='b4' weight='normal' color={COLORS.WHITE}>**** **** **** {card?.last4}</Paragraph>
        </div>
        <Paragraph size='b4' weight='normal' color={COLORS.WHITE}>{card?.expMonth}/{card?.expYear}</Paragraph>
      </div>
    </div>
  )
}

const BillingsWrapper = () => {
  const BILLING_SETTING_TABS = [
    {
      key: 'add-ons',
      title: 'Business Add-ons',
      show: canPerformAction(PERMISSION_CONSTANTS?.settings?.addons)
    },
    {
      key: 'billing',
      title: 'Manage Payment Cards',
      show: true
    },
  ]
  const [billingTabActive, setBillingTabActive] = useState(BILLING_SETTING_TABS?.filter((payment) => payment?.show)[0]?.key)
  const { toast } = useToast()
  const getTabView = () => {
    switch (billingTabActive) {
      case 'billing':
        return <PaymentCards />
      default:
        return <Addons />
    }
  }

  const getTabHeaderView = () => {
    let headerText, descriptionText

    switch (billingTabActive) {
      case 'add-ons':
        headerText = 'Business add-ons '
        descriptionText = 'Unlock your business add-ons'
        break
      default:
        headerText = 'Manage Cards'
        descriptionText = "Manage cards for addons"
        break
    }

    return (
      <div className='w-full flex flex-col border-b border-grey-50 px-10 py-6'>
        <Paragraph size='b4' color={COLORS.GREY[900]} weight='bold'>
          {headerText}
        </Paragraph>
        <Paragraph size='b4' color={COLORS.GREY[400]} weight='normal'>
          {descriptionText}
        </Paragraph>
      </div>
    )
  }

  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      <div className='w-full flex flex-col xl:flex-row space-y-6 xl:space-y-0 p-0'>
        <SideMenu
          active={billingTabActive}
          setTab={setBillingTabActive}
          tabs={BILLING_SETTING_TABS}
        />
        <div className='w-full border-l border-grey-50 flex flex-col shadow-sm'>
          {getTabHeaderView()}
          <div className='w-full h-full px-10 py-6'>{getTabView()}</div>
        </div>
      </div>
    </>
  )
}

export default Settings
