import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgClientEmpty = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 380 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M190 363.227c98.886 0 179.048-46.283 179.048-103.375 0-57.093-80.162-103.376-179.048-103.376S10.952 202.759 10.952 259.852c0 57.092 80.162 103.375 179.048 103.375Z"
      fill="#F5F5F5"
    />
    <path
      d="m249.599 290.069 39.148 22.717 33.964-19.608-39.444-22.884-33.668 19.775Z"
      fill="#E0E0E0"
    />
    <path
      d="m285.73 279.543 38 21.979 33.956-19.608-38.296-22.146-33.66 19.775ZM202.061 321.016c25.205 0 45.638-12.406 45.638-27.709 0-15.304-20.433-27.71-45.638-27.71s-45.638 12.406-45.638 27.71c0 15.303 20.433 27.709 45.638 27.709Z"
      fill="#E0E0E0"
    />
    <path
      d="m115.801 310.703 43.221-24.951-52.485-30.301-43.221 24.951 52.485 30.301Z"
      fill="#E0E0E0"
    />
    <path
      d="m45.311 294.834-27.785-16.043 58.748-33.813 27.694 15.991-58.657 33.865Z"
      fill="#E0E0E0"
    />
    <path
      d="m45.41 291.916 60.481-34.861v-56.909L45.41 235v56.916Z"
      fill="#16796F"
    />
    <path
      opacity={0.75}
      d="m45.41 291.916 60.481-34.861v-56.909L45.41 235v56.916Z"
      fill="#fff"
    />
    <path
      d="M28.979 225.515 45.41 235l60.481-34.854-16.424-9.485-60.488 34.854Z"
      fill="#16796F"
    />
    <path
      opacity={0.9}
      d="M28.979 225.515 45.41 235l60.481-34.854-16.424-9.485-60.488 34.854Z"
      fill="#fff"
    />
    <path
      d="M28.979 225.515 45.41 235v56.916l-16.431-9.485v-56.916Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="M28.979 225.515 45.41 235v56.916l-16.431-9.485v-56.916Z"
      fill="#fff"
    />
    <path
      opacity={0.1}
      d="m105.898 215.133-44.201 25.475v-14.987l-.441.251v14.987l-15.846 9.135v4.743l15.846-9.135v37.179l.44-.259v-37.171l44.195-25.475.007-4.743Z"
      fill="#000"
    />
    <path
      d="m45.41 216.053 16.287 9.568v57.213l4.104-2.364v-57.22l-16.287-9.568-4.104 2.371Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="m65.8 242.212-4.103 2.371v.76l4.104-2.371v-.76ZM28.979 240.51v4.742l16.431 9.485v-4.743l-16.431-9.484Z"
      fill="#000"
    />
    <path
      d="m28.979 239.742 16.423 9.485 60.489-34.854v4.743l-60.489 34.853-16.423-9.485v-4.742Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="M45.41 253.969v-4.742l-16.431-9.485v4.742l16.431 9.485Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="m65.8 223.25-4.103 2.371-16.287-9.568 4.104-2.371L65.8 223.25Z"
      fill="#fff"
    />
    <path
      opacity={0.1}
      d="m44.832 216.387 16.424 9.485.44-.251-16.286-9.568-.578.334Z"
      fill="#000"
    />
    <path
      d="m75.658 265.08 42.986 24.814v16.546l-42.986-24.814V265.08Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="m75.658 265.08 42.986 24.814v16.546l-42.986-24.814V265.08Z"
      fill="#000"
    />
    <path
      d="m118.644 289.894 35.818-20.679v16.545l-35.818 20.68v-16.546ZM154.462 269.215l-42.985-24.814-35.819 20.679 42.986 24.814 35.818-20.679Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m154.462 269.215-42.985-24.814-35.819 20.679 42.986 24.814 35.818-20.679Z"
      fill="#fff"
    />
    <path
      d="M135.873 250.602H85.219v16.546c0 3.746 2.477 7.486 7.425 10.343 9.88 5.708 25.924 5.708 35.811 0 4.948-2.857 7.418-6.597 7.418-10.343v-16.546Z"
      fill="#37474F"
    />
    <path
      d="M110.2 281.77a41.41 41.41 0 0 0 9.66-1.011v-21.173h-9.66v22.184Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="M110.2 281.77a41.41 41.41 0 0 0 9.66-1.011v-21.173h-9.66v22.184Z"
      fill="#000"
    />
    <path
      d="M92.636 240.266c-9.88 5.716-9.88 14.972 0 20.68s25.924 5.708 35.812 0c9.887-5.708 9.88-14.964 0-20.68-9.88-5.715-25.924-5.707-35.812 0Z"
      fill="#455A64"
    />
    <path
      d="M109.356 236.003c-3.24.072-6.46.531-9.59 1.368L110.2 265.24a41.033 41.033 0 0 0 9.66-1.011l-10.504-28.226Z"
      fill="#16796F"
    />
    <path
      d="M315.332 192.759c1.459-.844 3.245-.76 5.038.296 4.135 2.387 7.494 9.28 7.494 15.367v14.357a.653.653 0 1 1-1.307 0v-14.357c0-5.669-3.04-12.061-6.84-14.234-1.391-.806-2.683-.912-3.732-.297-1.52.897-2.455 3.177-2.455 6.263v14.288a.653.653 0 0 1-1.299 0v-14.288c0-3.565 1.125-6.255 3.101-7.395Z"
      fill="#37474F"
    />
    <path
      d="m283.267 275.052 4.021-68.993 11.506-6.627 3.77 64.478-19.297 11.142Z"
      fill="#16796F"
    />
    <path
      opacity={0.6}
      d="m283.267 275.052 4.021-68.993 11.506-6.627 3.77 64.478-19.297 11.142Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m283.267 275.052 4.021-68.993 11.506-6.627 3.77 64.478-19.297 11.142Z"
      fill="#000"
    />
    <path
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m339.226 222.779 3.77 64.471-40.432-23.34-3.77-64.478 40.432 23.347Z"
      fill="#000"
    />
    <path
      d="m339.226 222.779-11.514 6.619-4.013 69.001 19.297-11.149-3.77-64.471Z"
      fill="#16796F"
    />
    <path
      opacity={0.55}
      d="m339.226 222.779-11.514 6.619-4.013 69.001 19.297-11.149-3.77-64.471Z"
      fill="#fff"
    />
    <path
      opacity={0.05}
      d="m323.699 298.399 8.292-11.651 1.041-40.668 1.034 39.961 8.93 1.209-19.297 11.149Z"
      fill="#000"
    />
    <path
      d="m283.267 275.052 40.432 23.347 4.013-69.001-40.432-23.339-4.013 68.993Zm30.97-46.543c0-.927.646-1.307 1.444-.843a3.208 3.208 0 0 1 1.444 2.5c0 .92-.653 1.3-1.444.851a3.179 3.179 0 0 1-1.444-2.508Zm-14.333-8.36c0-.935.646-1.307 1.444-.851a3.208 3.208 0 0 1 1.444 2.5c0 .92-.646 1.3-1.444.852a3.172 3.172 0 0 1-1.444-2.47v-.031Z"
      fill="#16796F"
    />
    <path
      opacity={0.7}
      d="m283.267 275.052 40.432 23.347 4.013-69.001-40.432-23.339-4.013 68.993Zm30.97-46.543c0-.927.646-1.307 1.444-.843a3.208 3.208 0 0 1 1.444 2.5c0 .92-.653 1.3-1.444.851a3.179 3.179 0 0 1-1.444-2.508Zm-14.333-8.36c0-.935.646-1.307 1.444-.851a3.208 3.208 0 0 1 1.444 2.5c0 .92-.646 1.3-1.444.852a3.172 3.172 0 0 1-1.444-2.47v-.031Z"
      fill="#fff"
    />
    <path
      d="M303.802 199.318c1.46-.836 3.246-.76 5.039.304 4.127 2.386 7.486 9.272 7.486 15.367v14.349a.663.663 0 0 1-.383.667.663.663 0 0 1-.533 0 .66.66 0 0 1-.356-.396.656.656 0 0 1-.027-.271v-14.349c0-5.677-3.04-12.061-6.84-14.243-1.391-.798-2.683-.904-3.74-.296-1.52.897-2.447 3.184-2.447 6.262V221c0 .174-.069.34-.191.463a.657.657 0 0 1-.925 0 .657.657 0 0 1-.191-.463v-14.28c0-3.564 1.132-6.262 3.108-7.402Z"
      fill="#455A64"
    />
    <path
      d="M281.443 217.482a4.281 4.281 0 0 1 4.401.258c3.602 2.082 6.536 8.094 6.536 13.414v12.525a.57.57 0 0 1-1.14 0v-12.525c0-4.955-2.676-10.526-5.966-12.426a3.191 3.191 0 0 0-3.261-.266c-1.36.76-2.143 2.782-2.143 5.472v12.464a.57.57 0 0 1-1.14 0v-12.464c0-3.108.988-5.464 2.713-6.452Z"
      fill="#37474F"
    />
    <path
      d="m253.46 289.302 3.504-60.215 10.047-5.784 3.291 56.271-16.842 9.728Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="m253.46 289.302 3.504-60.215 10.047-5.784 3.291 56.271-16.842 9.728Z"
      fill="#000"
    />
    <path
      d="m302.29 243.679 3.298 56.27-35.286-20.375-3.291-56.271 35.279 20.376Z"
      fill="#16796F"
    />
    <path
      opacity={0.3}
      d="m302.29 243.679 3.298 56.27-35.286-20.375-3.291-56.271 35.279 20.376Z"
      fill="#000"
    />
    <path
      d="m302.29 243.679-10.04 5.776-3.511 60.215 16.849-9.721-3.298-56.27Z"
      fill="#16796F"
    />
    <path
      opacity={0.15}
      d="m302.29 243.679-10.04 5.776-3.511 60.215 16.849-9.721-3.298-56.27Z"
      fill="#000"
    />
    <path
      opacity={0.15}
      d="m288.739 309.67 7.243-10.162.904-35.492.905 34.877 7.797 1.056-16.849 9.721Z"
      fill="#000"
    />
    <path
      d="m253.46 289.302 35.279 20.368 3.511-60.215-35.286-20.368-3.504 60.215Zm27.026-40.615c0-.813.562-1.147 1.261-.76a2.793 2.793 0 0 1 1.254 2.181c0 .806-.562 1.14-1.254.76a2.749 2.749 0 0 1-1.261-2.181Zm-12.51-7.281c0-.805.562-1.14 1.262-.76a2.783 2.783 0 0 1 1.261 2.182c0 .805-.562 1.132-1.261.76a2.752 2.752 0 0 1-1.262-2.182Z"
      fill="#16796F"
    />
    <path
      d="M271.373 223.204a4.306 4.306 0 0 1 4.401.266c3.61 2.075 6.543 8.094 6.543 13.407v12.525a.57.57 0 0 1-1.14 0v-12.525c0-4.948-2.683-10.526-5.973-12.426a3.17 3.17 0 0 0-3.261-.259c-1.353.76-2.135 2.774-2.135 5.465v12.471a.567.567 0 0 1-.57.57.567.567 0 0 1-.57-.57v-12.471c0-3.109.988-5.457 2.705-6.453Z"
      fill="#455A64"
    />
    <path
      d="m148.071 169.769-19-5.153-3.23 13.171 18.483 4.697 6.156-4.644-2.409-8.071Zm-1.52 8.079a1.376 1.376 0 0 1-1.283-.849 1.379 1.379 0 0 1 .294-1.51 1.39 1.39 0 0 1 1.509-.303 1.382 1.382 0 0 1 .463 2.24 1.37 1.37 0 0 1-.953.414l-.03.008Z"
      fill="#16796F"
    />
    <path
      d="M165.878 187.272c-5.32 0-8.474-2.987-11.233-5.624-2.227-2.128-4.325-4.135-7.311-4.477a.401.401 0 0 1-.35-.441.406.406 0 0 1 .152-.269.406.406 0 0 1 .296-.088c3.253.38 5.442 2.478 7.76 4.697 2.835 2.706 5.768 5.533 10.974 5.404 7.692-.16 12.092-10.77 12.16-10.876a.39.39 0 0 1 .213-.218.398.398 0 0 1 .525.522c-.183.456-4.644 11.195-12.852 11.4l-.334-.03ZM138.32 169.617c.338.149.65.349.927.593.235.203.438.441.601.706.141.234.238.492.288.76.041.216.041.438 0 .654a.29.29 0 0 1-.144.213.381.381 0 0 1-.258 0l-.821-.213a.49.49 0 0 1-.289-.152.768.768 0 0 1-.129-.251 1.523 1.523 0 0 0-.411-.646 2.114 2.114 0 0 0-1.01-.486 2.35 2.35 0 0 0-.601-.084 1.5 1.5 0 0 0-.532.069.977.977 0 0 0-.403.22.763.763 0 0 0-.22.372.696.696 0 0 0 0 .472c.077.162.195.301.342.402.218.155.447.295.684.418l1.049.525c.387.186.755.407 1.102.661.267.191.498.428.684.699.152.228.248.488.281.76a2.66 2.66 0 0 1-.084.897c-.031.31-.133.609-.296.874a2.39 2.39 0 0 1-.692.593 2.845 2.845 0 0 1-.957.319 3.962 3.962 0 0 1-1.171 0l-.197.806a.334.334 0 0 1-.403.235l-.958-.235a.338.338 0 0 1-.235-.403l.197-.806a4.867 4.867 0 0 1-.927-.524 3.295 3.295 0 0 1-.691-.684 2.288 2.288 0 0 1-.396-.798 1.828 1.828 0 0 1 0-.882.279.279 0 0 1 .145-.205c.081-.03.17-.03.251 0l.828.205a.618.618 0 0 1 .289.152.513.513 0 0 1 .121.259c.035.122.078.241.13.357.055.132.138.251.243.349.135.129.289.237.456.32.24.121.496.211.76.266.229.058.463.099.699.121.207.028.416.028.623 0 .173-.036.336-.109.479-.213a.76.76 0 0 0 .274-.41.63.63 0 0 0-.061-.471 1.439 1.439 0 0 0-.433-.411 13.516 13.516 0 0 0-.76-.433 24.734 24.734 0 0 1-1.08-.555 6.662 6.662 0 0 1-.95-.6 2.605 2.605 0 0 1-.608-.661 1.766 1.766 0 0 1-.266-.76 2.687 2.687 0 0 1-.03-1.011c.07-.293.202-.568.388-.806.184-.233.411-.429.668-.577a2.57 2.57 0 0 1 .882-.335 3.798 3.798 0 0 1 1.026-.06l.205-.806a.306.306 0 0 1 .152-.198.297.297 0 0 1 .118-.044.293.293 0 0 1 .125.006l.958.236a.342.342 0 0 1 .205.152.309.309 0 0 1 .044.122.312.312 0 0 1-.006.129l-.205.798Z"
      fill="#455A64"
    />
    <path
      d="m219.442 130.819-38.653-.319.479-58.52a18.539 18.539 0 0 1 11.579-17.046 18.537 18.537 0 0 1 7.109-1.354h3.298a16.815 16.815 0 0 1 15.455 10.509 16.83 16.83 0 0 1 1.227 6.447l-.494 60.283Z"
      fill="#263238"
    />
    <path
      d="M246.673 203.498c-3.192-8.087-9.515-21.417-18.46-39.665l-.874-1.626c-3.838-7.798-7.767-13.437-8.36-18.666l.251-6.08c.463-2.508 1.284-8.702 1.52-12.16 6.528-5.928 4.05-10.898 1.292-15.352a36.806 36.806 0 0 0-3.542-4.355c-1.14-8.892-6.506-9.378-8.238-9.325l-12.92.365c-6.149.912-13.285 1.718-14.782 2.044-2.866.639-3.58 3.694-3.686 7.737-.107 4.423.57 6.916 3.724 12.502l5.525 11.081.669 6.901s-3.55 5.092-5.693 8.527c-9.485 13.748-7.539 22.861-5.084 48.784 1.178 12.366 2.774 26.6 2.774 26.6s-5.001 7.106-3.8 21.356c.539 6.293 3.04 26.866 4.431 35.082.296 2.28.478 4.56.562 6.673 0 .57.091 1.087.091 1.641v1.384s9.455 7.493 9.455-2.714v-1.444l.418-6.08c.562-7.159 2.508-20.626 3.45-29.161 1.611-14.546 3.952-23.469 4.811-31.015.843-7.38 2.903-26.555 4.005-36.83l23.34 31.578a59.414 59.414 0 0 0-5.503 10.245c-2.409 5.715-4.02 11.795-7.091 24.457-1.314 5.418-2.363 9.72-4.172 15.124-1.52 4.028 6.787 6.247 6.787 6.247 4.727-7.403 11.635-19.927 17.48-30.811 5.046-9.363 9.226-19.281 10.176-21.485 2.06-4.712 2.873-7.273 1.444-11.559Z"
      fill="#B16668"
    />
    <path
      d="M210.315 96.3c6.133-.312 7.478 0 15.048 10.457 7.326 10.116 14.166 18.514 14.166 18.514l16.591-19.289 7.326 5.571s-14.607 22.002-17.86 26.129c-2.325 2.956-4.856 5.228-11.498-.487-5.032-4.332-21.235-21.28-21.235-21.28L210.315 96.3Z"
      fill="#B16668"
    />
    <path
      d="M256.12 105.982c3.26-4.058 5.981-5.366 9.325-5.654 3.344-.289 3.147-9.485 6.483-8.125 3.336 1.36 3.481 2.91 4.188 5.32.706 2.41 2.758 8.565-2.045 10.557-4.803 1.991-10.024 2.644-13.497 7.812-3.474 5.168-4.454-9.91-4.454-9.91Z"
      fill="#B16668"
    />
    <path
      d="m181.716 303.696 1.026-.213c-.137-3.253-.502-6.209.205-7.79l-1.717-3.853.486 11.856ZM178.258 289.074c.372 2.211 1.778 4.415 2.143 8.793a37.1 37.1 0 0 1 .228 5.616l1.087.213-.486-11.856-2.972-2.766Z"
      fill="#263238"
    />
    <path
      d="M204.546 305.224a2.954 2.954 0 0 1-.608 1.672c-.532.456-2.226 2.006-7.957 2.021-5.73.015-7.82-1.269-9.88-2.926-2.059-1.657-2.865-6.308-3.291-8.497-.836-4.24-2.751-6.368-4.56-8.42a3.807 3.807 0 0 1-.304-2.204s4.705-.555 7.076 4.56c2.371 5.114 4.347 11.08 9.059 12.243a72.609 72.609 0 0 0 10.465 1.551Z"
      fill="#263238"
    />
    <path
      d="M201.347 300.436c1.839 1.398 3.199 1.983 3.139 3.397-.061 1.413-1.52 2.128-2.676 2.553-2.751 1.019-5.95.867-8.785.54-2.835-.327-6.08-1.452-7.425-4.058a10.889 10.889 0 0 1-.829-2.425c-.684-2.728-1.33-5.517-2.652-8.018a18.13 18.13 0 0 0-1.763-3.04c-.806-1.011-1.771-2.113-1.992-3.443-.182-1.102.578-2.72 1.589-4.468v-.038a9.68 9.68 0 0 0 1.193-6.301l-1.573-11.4 13.452 1.437-1.117 13.224c-.32 2.979.76 6.118 1.596 8.922a40.244 40.244 0 0 0 3.8 8.953 8.704 8.704 0 0 0 1.444 1.991 25.125 25.125 0 0 0 2.599 2.174Z"
      fill="#B16668"
    />
    <path
      d="M178.478 283.594a36.12 36.12 0 0 0 1.992-3.177 9.306 9.306 0 0 1 1.474 2.28c1.619 2.774 2.174 7.243 3.8 11.621 1.239 3.283 3.093 4.947 5.54 5.715a9.558 9.558 0 0 0 4.743.235c2.941-.592 2.113-2.69 2.113-2.69.76.904 4.081 3.2 5.912 4.309 1.163.707 1.102 2.364.441 3.344-.76.76-3.488 2.675-10.64 2.083-6.353-.525-8.003-3.337-8.687-4.614a31.232 31.232 0 0 1-1.672-5.829 18.715 18.715 0 0 0-2.591-6.133 27.033 27.033 0 0 0-.456-.638 27.612 27.612 0 0 0-1.619-1.908c-.471-.502-.828-.76-.996-1.231a4.453 4.453 0 0 1 .646-3.367Z"
      fill="#37474F"
    />
    <path
      d="m201.271 283.001 1.049.19c1.14-3.116 2.606-7.509 4.651-7.843l-1.52-3.747-4.18 11.4Z"
      fill="#263238"
    />
    <path
      d="M203.292 267.315c-.509 2.227.373 5.411-.995 9.675a38.961 38.961 0 0 1-1.961 5.38l.935.631 4.172-11.4-2.151-4.286Z"
      fill="#263238"
    />
    <path
      d="M223.242 291.908a1.89 1.89 0 0 1-1.117 1.976c-1.277.646-4.773 1.072-8.892-.646-4.56-1.907-5.669-3.473-6.262-6.08-.646-2.872.418-6.581-.213-9.743-.798-4.035-2.28-6.84-3.481-9.933.137-1.132.289-1.786.289-1.786s5.366 1.307 5.609 7.038c.243 5.73-.221 12.16 3.762 15.063 3.982 2.903 10.305 4.111 10.305 4.111Z"
      fill="#263238"
    />
    <path
      d="M221.031 287.569c1.193 2.037 2.189 3.093 1.641 4.415-.425 1.019-2.462.905-4.43.677a10.642 10.642 0 0 1-5.32-2.022 10.228 10.228 0 0 1-4.066-5.685c-.54-2.173-.19-4.423-.13-6.619a12.532 12.532 0 0 0-.243-3.245 22.721 22.721 0 0 0-1.201-2.858 61.851 61.851 0 0 0-1.656-3.891 4.726 4.726 0 0 1-.593-3.405c.258-1.11 1.603-2.341 3.237-3.595a10.683 10.683 0 0 0 3.8-5.616l6.156-22.891 12.92 10.898S222.68 259.449 221 263.203a31.785 31.785 0 0 0-2.226 8.938 41.604 41.604 0 0 0 .114 9.918 9.18 9.18 0 0 0 .585 2.432c.446 1.061.967 2.09 1.558 3.078Z"
      fill="#B16668"
    />
    <path
      d="M217.193 293.178a14.96 14.96 0 0 1-5.913-2.204c-1.307-.897-2.614-2.052-3.04-3.588-.509-1.74-.236-3.374-.198-4.932a28.887 28.887 0 0 0-.456-6.384 22.392 22.392 0 0 0-1.558-4.849c-.456-1.003-.904-2.014-1.36-3.04a11.287 11.287 0 0 1-1.079-2.531 4.023 4.023 0 0 1 1.983-3.214c1.368-.905 2.6-1.087 3.603-1.847.197.585.292 1.199.281 1.816.654 4.408 1.14 8.854 2.47 13.133a37.115 37.115 0 0 0 2.516 6.08c1.337 2.561 4.24 3.412 4.772 2.052.867 2.348 2.41 4.317 3.884 6.316 1.018 1.383.479 2.394-.935 2.933a10.508 10.508 0 0 1-4.97.259Z"
      fill="#37474F"
    />
    <path
      d="M211.987 61.37c2.143 1.2 4.887 6.452 4.894 17.602 0 9.446-2.994 11.856-4.514 12.562-1.52.707-4.499.373-7.395 0l.137 5.67s8.177 8.512 1.573 9.515c-3.298.502-7.516-1.52-10.382-3.64a11.6 11.6 0 0 1-3.8-5.867l-.228-12.328s-1.656 1.688-4.56-.76c-2.424-2.014-3.306-5.494-1.52-7.417a4.139 4.139 0 0 1 5.38-.35c.524.395.946.908 1.232 1.497 0 0 1.688.92 3.306-2.28a20.822 20.822 0 0 0 8.93-2.409 12.747 12.747 0 0 0 6.947-11.795Z"
      fill="#B16668"
    />
    <path
      d="M204.607 76.296a1.187 1.187 0 0 1-1.977.916 1.186 1.186 0 0 1 .7-2.063 1.21 1.21 0 0 1 1.277 1.147Z"
      fill="#263238"
    />
    <path
      d="m206.256 84.603 3.086.905a1.575 1.575 0 0 1-1.961 1.147 1.69 1.69 0 0 1-1.125-2.052Z"
      fill="#9A4A4D"
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.5}
    />
    <path
      d="m215.255 72.344-2.455-1.61a1.4 1.4 0 0 1 .894-.654 1.406 1.406 0 0 1 1.09.197 1.52 1.52 0 0 1 .471 2.067ZM203.27 71.44l-2.706 1.117a1.398 1.398 0 0 1-.03-1.114 1.403 1.403 0 0 1 .79-.786 1.519 1.519 0 0 1 1.946.783ZM214.685 75.795a1.203 1.203 0 0 1-.642 1.181 1.2 1.2 0 0 1-.453.134 1.224 1.224 0 0 1-1.276-1.148 1.195 1.195 0 0 1 .621-1.244 1.197 1.197 0 0 1 1.378.19 1.195 1.195 0 0 1 .372.887Z"
      fill="#263238"
    />
    <path
      d="m208.97 75.248.357 7.144 3.739-1.254-4.096-5.89ZM204.972 91.496c-3.04-.326-9.333-1.968-10.366-4.415a6.434 6.434 0 0 0 2.28 3.1c1.907 1.52 8.147 3.208 8.147 3.208l-.061-1.893Z"
      fill="#9A4A4D"
      style={{
        mixBlendMode: "multiply",
      }}
      opacity={0.5}
    />
    <path
      d="m192.782 77.816.114 4.97a3.381 3.381 0 0 0 3.298-3.457l-.106-4.56-3.306 3.047Z"
      fill="#263238"
    />
    <path
      d="M234.43 176.001c-3.39-6.962-6.932-14.144-8.923-18.195-1.991-4.05-5.221-10.776-6.361-14.113.084-1.52.251-3.336.228-5.973.6-3.686 1.148-7.752 1.52-12.388 7.038-4.857 3.724-12.16 2.105-14.577-2.964-4.423-11.711-12.996-13.68-14.44a17.648 17.648 0 0 0-4.18.106s4.781 4.135 9.515 13.87c2.744 5.647 1.209 11.902 1.209 11.902a111.496 111.496 0 0 0-11.887-12.571c-4.278-3.8-12.866-11.46-14.63-11.87a20.888 20.888 0 0 0-4.202.554c3.883 3.557 7.858 14.949 7.311 20.186-.547 5.236-3.04 6.954-5.943 8.048l1.466 3.42.928 6.946a117.73 117.73 0 0 0-8.072 11.628c-5.829 9.44-7.66 18.142-6.786 70.46 3.214.342 6.642.669 10.237.874l3.868-43.236 6.947 43.418a79.28 79.28 0 0 0 29.86-6.353c14.402-6.323 18.635-13.148 19.958-16.295-3.086-5.882-7.418-15.086-10.488-21.401Z"
      fill="#16796F"
    />
    <path
      opacity={0.1}
      d="M195.746 122.976a10.067 10.067 0 0 0 8.99 6.224 10.635 10.635 0 0 0 9.584-4.689s-1.003 7.227-9.743 6.49c-9.257-.783-8.831-8.025-8.831-8.025Z"
      fill="#000"
    />
    <path
      d="M188.837 158.901a21.083 21.083 0 0 1-5.776-3.458c1.25.151 2.513.151 3.762 0a2.28 2.28 0 0 0 2.045-2.28c-.069-.487-1.262-.236-3.162-.829-2.424-.76-3.967-1.618-6.711-1.77-1.026-.054-1.854-.852-2.607-1.52-.752-.669-4.202-6.78-7.318-13.399l14.181-19c5.085-2.28 6.164-10.823 1.847-18.24-10.359 1.125-9.029 2.28-28.31 31.768-3.306 5.137-3.177 7.6 9.014 22.663a107.855 107.855 0 0 0 8.36 9.211c7.037 6.567 11.856 5.32 15.078 4.18 6.164-2.31 4.393-5.396-.403-7.326Z"
      fill="#B16668"
    />
    <path
      d="m288.754 161.538-2.986-47.751-8.528-4.909-.828 46.572 12.342 6.088Z"
      fill="#37474F"
    />
    <path
      d="m247.251 126.19-2.797 44.4 31.958-15.14.828-46.572-29.989 17.312Z"
      fill="#263238"
    />
    <path
      d="m247.251 126.19 8.535 4.91 2.979 47.751-14.311-8.261 2.797-44.4Z"
      fill="#37474F"
    />
    <path
      d="m258.765 178.851-6.149-8.634-.775-26.744-.767 26.22-6.62.897 14.311 8.261Z"
      fill="#263238"
    />
    <path
      d="M285.76 113.787 255.786 131.1l2.979 47.751 29.989-17.313-2.994-47.751Zm-21.052 18.514c-.593.334-1.072.053-1.072-.631a2.37 2.37 0 0 1 1.072-1.854c.593-.342 1.072-.061 1.072.63a2.375 2.375 0 0 1-1.064 1.855h-.008Zm10.64-6.187c-.593.335-1.072.054-1.072-.63a2.372 2.372 0 0 1 1.072-1.855c.593-.342 1.072-.061 1.072.631a2.334 2.334 0 0 1-1.08 1.854h.008Z"
      fill="#455A64"
    />
    <path
      d="M264.716 131.396a.342.342 0 0 1-.342-.342v-25.916c0-3.04.304-6.133 2.591-6.133h.236c-.164.26-.355.502-.57.722-1.087.274-1.573 1.976-1.573 5.411v25.916a.344.344 0 0 1-.211.316.33.33 0 0 1-.131.026Z"
      fill="#E6E6E6"
    />
    <path
      d="M256.728 125.21h-.076a.343.343 0 0 1-.251-.41c0-.137 3.177-13.354 4.112-18.104.859-4.37 1.695-7.242 5.32-7.242a.343.343 0 0 1 .342.342.34.34 0 0 1-.342.342c-3.04 0-3.8 2.143-4.659 6.695-.942 4.758-4.089 17.989-4.119 18.126a.337.337 0 0 1-.327.251ZM275.34 125.21a.348.348 0 0 1-.218-.08.344.344 0 0 1-.116-.201l-2.782-16.196.646-.243 2.805 16.325a.331.331 0 0 1-.053.255.333.333 0 0 1-.221.14h-.061Z"
      fill="#E6E6E6"
    />
    <path
      d="M138.024 86.572v-9.326l7.866-.684a.574.574 0 0 1 .326.061l6.742 3.876a.585.585 0 0 1 .272.41.58.58 0 0 1-.143.472l-8.36 9.044-6.703-3.853Z"
      fill="#16796F"
    />
    <path
      d="m144.742 90.44 8.36-9.044a.574.574 0 0 0 .092-.643.576.576 0 0 0-.563-.322l-7.874.691-.015 9.318Z"
      fill="#16796F"
    />
    <path
      d="m138.024 77.246 6.718 3.861 7.874-.691a.645.645 0 0 1 .273.045c-.631-.372-6.384-3.678-6.673-3.838a.574.574 0 0 0-.326-.06l-7.866.683Z"
      fill="#16796F"
    />
    <path
      opacity={0.5}
      d="m138.024 77.246 6.718 3.861 7.874-.691a.645.645 0 0 1 .273.045c-.631-.372-6.384-3.678-6.673-3.838a.574.574 0 0 0-.326-.06l-7.866.683Z"
      fill="#fff"
    />
    <path
      d="M144.75 69.821a4.311 4.311 0 0 0-1.954-3.382l-1.588-.92a4.317 4.317 0 0 0-3.906 0l-24.389 14.106a4.323 4.323 0 0 0-1.953 3.382v26.828a4.325 4.325 0 0 0 1.953 3.382l1.589.92a4.315 4.315 0 0 0 3.906 0l24.388-14.106a4.312 4.312 0 0 0 1.954-3.382V69.821Z"
      fill="#16796F"
    />
    <path
      opacity={0.2}
      d="M110.96 109.835a4.325 4.325 0 0 0 1.953 3.382l1.589.92a4.358 4.358 0 0 0 3.572.159c-.92.35-1.619-.174-1.619-1.284V86.176c.025-.678.221-1.34.57-1.922l-5.495-3.17a4.006 4.006 0 0 0-.57 1.923v26.828Z"
      fill="#000"
    />
    <path
      opacity={0.5}
      d="M144.681 69.266c-.228-.828-.98-1.094-1.885-.577L118.408 82.84a3.847 3.847 0 0 0-1.376 1.452l-5.494-3.17c.326-.595.8-1.097 1.375-1.459l24.381-14.105a4.361 4.361 0 0 1 3.906 0l1.596.92a4.312 4.312 0 0 1 1.885 2.788Z"
      fill="#fff"
    />
    <path
      d="M122.968 92.424c.015-1.515.333-3.011.935-4.4a7.687 7.687 0 0 1 2.812-3.596c1.915-1.132 3.519 1.17 3.8 3.876.228-2.986 1.801-7.151 3.716-8.276a2.099 2.099 0 0 1 2.827.281 5.052 5.052 0 0 1 .958 3.291c0 4.56-2.736 8.732-5.039 13.095l-2.424 4.56-2.463-1.695c-2.325-1.65-5.107-2.554-5.122-7.136Z"
      fill="#FAFAFA"
    />
  </svg>
);
export default SvgClientEmpty;
