import { ChangeEvent, useState } from 'react';
import { getImageSize } from 'react-image-size';
import { FILE_SIZE } from 'constants/information';
// import { ISignCloudinaryMediaAssetUploadProps } from './types';
import axios from 'axios';
import { API_ERRORS } from 'constants/errors';
import { UploadFileDocument } from 'core/generated';
import { useUploadFile } from 'api/useAccount';

export const useImageUploader = (dimensions: { width: number, height: number }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadIsLoading, setUploadingIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    uploadFile
  } = useUploadFile()

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target) {
      return;
    }
    const imageFiles = e.target.files;
    if (imageFiles) {
        const file = imageFiles[0];
        // check the file size
        if (file.size > FILE_SIZE) {
          const message = `Image size should not exceed 2MB`;
          setErrorMessage(message);
        }
        const image = new Image();
        image.src = URL.createObjectURL(file);
        const imageDimensions = await getImageSize(image.src);

        if (imageDimensions.width > dimensions.width || imageDimensions.height > dimensions.height) {
          const message = `Image dimensions should not exceed ${dimensions.width}px by ${dimensions.height}px`;
          setErrorMessage(message);
          return;
        }

        setUploadingIsLoading(true);

        const fileContentString = await readFileAsString(file);

        uploadFile({
          variables: { input: { data: fileContentString as string } },
        }).then(({ data }) => {
          if (data?.uploadFile?.status === 200) {
            setImageUrl(data?.uploadFile?.url);
            setUploadingIsLoading(false);
            setErrorMessage(null);
          }

          if (data?.uploadFile?.errors) {
            setErrorMessage(data.uploadFile?.errors[0]?.message || API_ERRORS.IMAGE_UPLOAD_FAILED);
            setUploadingIsLoading(false);
          }
      }).catch (error => {
        if (axios.isAxiosError(error)) {
          const message =
            error?.response?.data?.message || API_ERRORS.IMAGE_UPLOAD_FAILED;
          setErrorMessage(message);
        }
        setUploadingIsLoading(false);
      })
    }
  };

  if (errorMessage) {
    setInterval(() => {
      setErrorMessage(null)
    }, 5000);
  }

  return {
    imageUrl,
    errorMessage,
    handleImageUpload,
    uploadIsLoading,
  };
};

// export const useFileUploader_ = async (data: any) => {
//   const [fileData, setFileData] = useState<{
//     status: number,
//     url: string,
//   }>();
//   const {
//     loading: uploadIsLoading,
//     uploadFile
//   } = useUploadFile();
//   uploadFile({
//     variables: { input: { data } }
//   }).then(({ data }) => {
//     const { uploadFile } = data;
//     if (uploadFile?.status === 200) {
//       setFileData(uploadFile);
//     }
//   })

//   return fileData
// }

export const useFileUploader = () => {
  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile()
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target) {
      return;
    }
    const imageFiles = e.target.files;
    if (imageFiles) {
      const file = imageFiles[0];
      setFileName(file?.name)
      const fileContentString = await readFileAsString(file);
      uploadFile({
        variables: { input: { data: fileContentString as string } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          setFileUrl(uploadFile?.url);
          setErrorMessage(null);
        }
        if (uploadFile?.errors?.length) {
          setErrorMessage(uploadFile?.errors[0]?.message || API_ERRORS.IMAGE_UPLOAD_FAILED);
        }
      }).catch(error => {
        if (axios.isAxiosError(error)) {
          const message =
            error?.response?.data?.message || API_ERRORS.IMAGE_UPLOAD_FAILED;
          setErrorMessage(message);
        }
      })
    }
  };

  if (errorMessage) {
    setInterval(() => {
      setErrorMessage(null)
    }, 5000);
  }

  return {
    fileUrl,
    fileName,
    errorMessage,
    handleFileUpload,
    uploadIsLoading,
  };
};

const readFileAsString = (file: Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
})
