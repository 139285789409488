import { gql, useMutation, useQuery } from "@apollo/client";
import { User, Notification, SalonStaff, Card, Role, Faq, Business, Social } from "core/generated";

const JOIN_WAITLIST = gql`
  mutation JoinWaitlist($input: JoinWaitlistInput!) {
    joinWaitlist(input: $input) {
      clientMutationId
      result
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useJoinWaitlist = () => {
  const [joinWaitlist, options] = useMutation<{
    joinWaitlist: {
      result: string;
      status: number;
      errors: { message: string; property: string }[];
    };
  }>(JOIN_WAITLIST);

  return {
    joinWaitlist,
    ...options,
  };
};

// fetch countres query
const GET_COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
      code
      countryCode
      payoutsSupported
    }
  }
`;

export const useCountries = () => {
  const { data, loading, error } = useQuery<{
    countries: {
      id: string;
      name: string;
      code: string;
      countryCode: string;
      payoutsSupported: boolean;
    }[];
  }>(GET_COUNTRIES);

  return {
    countries: data?.countries,
    loading,
    error,
  };
};

export const USER = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      emailVerified
      business {
        noShowFee
        headerImageUrl
        headerImageUrls {
          businessId
          createdAt
          id
          imageUrl
          updatedAt
        }
        logoUrl
        name
        rcNumber
        phone
        callingCode
        countryCode
        bookingUrlIdentifier
        bvn
        cancellationFee
        cancellationFeeType
        noShowFee
        about
        id
        addedById
        cancellationPolicy
        acceptsOnlineBookings
        isAccountSetupComplete
        isAppointmentLinkSetup
        allowServiceStaffSelection
        socials {
          id
          siteName
          username
        }
        payoutAccount {
          accountNumber
          bankName
          accountName
        }
        products {
          id
          name
        }
        services {
          id
          name
        }
        packages {
          id
          name
          isDiscountApplicable
        }
        virtualAccount {
          accountName
          accountNumber
          bankName
          businessId
          createdAt
          id
          subaccountId
          updatedAt
        }
        addons {
          expiryDate
          subscribed
          price
          unitPrice
          salonCount
          addon {
            createdAt
            featureList
            id
            name
            updatedAt
          }
        }
        country {
          name
          code
          emojiFlag
          collectionsSupported
          payoutsSupported
          timezone
          utcOffset
          currency {
            code
            symbol
          }
        }
        activeAddons {
          createdAt
          featureList
          id
          name
          updatedAt
        }
        salons {
          id
          address
          bearsProcessingFee
          branchName
          callingCode
          city
          depositLinkValidityHours
          depositType
          depositValue
          email
          intervalMinutes
          isDepositRequired
          onboardingStatus
          locationType
          phone
          state
          status
          taxInclusivePricing
          isTaxVisible
          wallet {
            id
            balance
            availableBalance
            currency
          }
        }
        roles {
          createdAt
          id
          name
          updatedAt
          authorizations {
            createdAt
            description
            id
            name
            updatedAt
            addon {
              createdAt
              name
              updatedAt
            }
          }
        }
      }
      phone
      callingCode
      salonStaff {
        role
        salonId
        canServeCustomers
        id
        level
        staffRole {
          createdAt
          description
          id
          name
          updatedAt
          authorizations {
            createdAt
            description
            id
            name
            updatedAt
          }
        }
      }
    }
  }
`;

export const useUser = () => {
  const { data, loading, error, refetch } = useQuery<{
    user: User;
  }>(USER)

  return {
    data,
    loading,
    error,
    refetch
  };
}

const NOTIFICATIONS = gql`
  query Notifications($salonId: ID!) {
    notifications(salonId: $salonId) {
      id
      contentType
      contentId
      title
      message
      isActionable
      isRead
      createdAt
    }
  }
`;

export const useNotifications = ({ salonId }: { salonId: string }) => {
  return useQuery<{
    notifications: Notification[];
  }>(NOTIFICATIONS, {
    variables: {
      salonId,
    },
    skip: !salonId,
  });
}

const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($input: MarkNotificationsAsReadInput!) {
    markNotificationsAsRead(input: $input) {
      clientMutationId
      status
    }
  }
`;

export const useMarkNotificationsAsRead = () => {
  const [markNotificationsAsRead, options] = useMutation<{
    markNotificationsAsRead: {
      status: number;
    };
  }>(MARK_NOTIFICATIONS_AS_READ);

  return {
    markNotificationsAsRead,
    ...options,
  };
}

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    status
    errors {
      message
      property
    }
    user {
      id
      email
      firstName
      lastName
      emailVerified
      business {
        headerImageUrl
        logoUrl
        name
        rcNumber
        isAccountSetupComplete
        products {
          id
          name
        }
        services {
          id
          name
        }
        packages {
          id
          name
          isDiscountApplicable
        }
        salons {
          id
          address
          bearsProcessingFee
          branchName
          callingCode
          city
          depositLinkValidityHours
          depositType
          depositValue
          email
          intervalMinutes
          isDepositRequired
          locationType
          phone
          state
          status
          taxInclusivePricing
          isTaxVisible
        }
      }
      salonStaff {
        role
        salonId
        canServeCustomers
      }
    }
    salonStaff {
      id
      role
      level
      canServeCustomers
    }
  }
}

`;

export const useUpdateAccount = () => {
  const [updateAccount, options] = useMutation<{
    updateAccount: {
      status: number;
      errors: { message: string; property: string }[];
      user: User;
      salonStaff: SalonStaff;
    };
  }>(UPDATE_ACCOUNT);

  return {
    updateAccount,
    ...options,
  };
}

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useChangePassword = () => {
  const [changePassword, options] = useMutation<{
    changePassword: {
      status: number;
      errors: { message: string; property: string }[];
    };
  }>(CHANGE_PASSWORD);

  return {
    changePassword,
    ...options,
  };
}

const CARDS = gql`
  query Cards {
    cards {
      accountName
      authorizationCode
      cardType
      createdAt
      expMonth
      expYear
      id
      last4
      updatedAt
    }
  }
`;

export const useCards = () => {
  return useQuery<{
    cards: Card[];
  }>(CARDS);
}

const UPDATE_PERMISSIONS = gql`
  mutation UpdatePermissions($input: UpdatePermissionsInput!) {
    updatePermissions(input: $input) {
      clientMutationId
      status
      roles {
        createdAt
        id
        name
        updatedAt
        authorizations {
          createdAt
          description
          id
          name
          updatedAt
          addon {
            createdAt
            name
            updatedAt
          }
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdatePermissions = () => {
  const [updatePermissions, options] = useMutation<{
    updatePermissions: {
      status: number;
      roles: Role[];
      errors: { message: string; property: string }[];
    };
  }>(UPDATE_PERMISSIONS);

  return {
    updatePermissions,
    ...options,
  };
}

export const FAQS = gql`
  query Faqs ($salonId: ID!) {
    faqs(salonId: $salonId) {
      answer
      businessId
      createdAt
      id
      question
      salonId
      updatedAt
    }
  }
`;

export const useGetFaqs = ({ salonId }: { salonId: string }) => {
  return useQuery<{
    faqs: Faq[];
  }>(FAQS, {
    variables: {
      salonId,
    },
    skip: !salonId,
  });
}

const CREATE_FAQ = gql`
  mutation CreateFaq($input: CreateFaqInput!) {
    createFaq(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useCreateFaq = () => {
  const [createFaq, options] = useMutation<{
    createFaq: {
      status: number;
      faq: Faq;
      errors: { message: string; property: string }[];
    };
  }>(CREATE_FAQ);

  return {
    createFaq,
    ...options,
  };
} 

const DELETE_FAQ = gql`
  mutation DeleteFaq($input: DeleteFaqInput!) {
    deleteFaq(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteFaq = () => {
  const [deleteFaq, options] = useMutation<{
    deleteFaq: {
      status: number;
      errors: { message: string; property: string }[];
    };
  }>(DELETE_FAQ);

  return {
    deleteFaq,
    ...options,
  };
}

const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness($input: UpdateBusinessInput!) {
    updateBusiness(input: $input) {
      clientMutationId
      status
      business {
        about
        acceptsOnlineBookings
        addedById
        bookingManagement
        bookingUrlIdentifier
        bvn
        allowServiceStaffSelection
        allowEmail
        allowSms
        bvn
        callingCode
        cancellationPolicy
        cancellationFee
        cancellationFeeType
        countryCode
        createdAt
        deletedAt
        headerImageUrl
        headerImageUrls {
          businessId
          createdAt
          id
          imageUrl
          updatedAt
        }
        id
        isAccountSetupComplete
        isBvnVerified
        isDeleted
        logoUrl
        name
        note
        ownerId
        phone
        rcNumber
        slug
        teamSize
        updatedAt
        country {
          name
          code
          emojiFlag
          collectionsSupported
          payoutsSupported
          timezone
          utcOffset
          currency {
            code
            symbol
          }
        }
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateBusiness = () => {
  const [updateBusiness, options] = useMutation<{
    updateBusiness: {
      status: number;
      business: Business;
      errors: { message: string; property: string }[];
    };
  }>(UPDATE_BUSINESS);

  return {
    updateBusiness,
    ...options,
  };
}

const UPLOAD_IMAGE = gql`
  mutation UploadFile($input: UploadFileInput!) {
    uploadFile(input: $input) {
      status
      url
      errors {
        message
        property
      }
    }
  }
`;

export const useUploadFile = () => {
  const [uploadFile, options] = useMutation<{
    uploadFile: {
      status: number;
      url: string;
      errors: { message: string; property: string }[];
    };
  }>(UPLOAD_IMAGE);

  return {
    uploadFile,
    ...options,
  };
}

export const GOOGLE_PLACES_SEARCH = gql`
  query GooglePlacesSearch($q: String!) {
    googlePlacesSearch(q: $q) {
      label
      value
      disabled
    }
  }
`;

export const useGooglePlaceSearch = ({ q }: { q: string }) => {
  return useQuery<{
    googlePlacesSearch: { label: string; value: string; disabled: boolean }[];
  }>(GOOGLE_PLACES_SEARCH, {
    variables: {
      q,
    },
    skip: !q || q.trim() === '',
    notifyOnNetworkStatusChange: true,
  });
};

const MARK_ONBOARDING_AS_COMPLETE = gql`
  mutation MarkOnboardingAsComplete($input: MarkOnboardingAsCompleteInput!) {
    markOnboardingAsComplete(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useMarkOnboardingComeplete = () => {
  const [markOnboardingAsComplete, options] = useMutation<{
    markOnboardingAsComplete: {
      status: number;
      errors: { message: string; property: string }[];
    };
  }>(MARK_ONBOARDING_AS_COMPLETE);

  return {
    markOnboardingAsComplete,
    ...options,
  };
}

const UNSUBSCRIBE_ADDON = gql`
  mutation UnsubscribeFromAddon($input: UnsubscribeFromAddonInput!) {
    unsubscribeFromAddon(input: $input) {
      clientMutationId
      message
      status
      errors {
        message
        property
      }
    }
  }
`

export const useUnsubscribeAddon = () => {
  const [unsubscribeAddon, options] = useMutation<{
    unsubscribeFromAddon: {
      status: number;
      message: string;
      errors: { message: string; property: string }[];
    };
  }>(UNSUBSCRIBE_ADDON);

  return {
    unsubscribeAddon,
    ...options,
  };
}

const SUBSCRIBE_ADDON = gql`
  mutation SubscribeToAddon($input: SubscribeToAddonInput!) {
    subscribeToAddon(input: $input) {
      clientMutationId
      message
      status
      errors {
        message
        property
      }
      paymentAuthorization {
        accessCode
        authorizationUrl
        reference
      }
    }
  }
`;

export const useSubscribeToAddon = () => {
  const [subscribeToAddon, options] = useMutation<{
    subscribeToAddon: {
      status: number;
      message: string;
      errors: { message: string; property: string }[];
      paymentAuthorization: {
        accessCode: string;
        authorizationUrl: string;
        reference: string;
      };
    };
  }>(SUBSCRIBE_ADDON);

  return {
    subscribeToAddon,
    ...options,
  };
}

const UPDATE_SOCIAL = gql`
  mutation UpdateSocial($input: UpdateSocialInput!) {
    updateSocial(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      socials {
        addedById
        businessId
        createdAt
        id
        siteName
        updatedAt
        username
      }
    }
  }
`;

export const useUpdateSocial = () => {
  const [updateSocial, options] = useMutation<{
    updateSocial: {
      status: number;
      errors: { message: string; property: string }[];
      socials: Social[];
    };
  }>(UPDATE_SOCIAL);

  return {
    updateSocial,
    ...options,
  };
}