import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgVoucherUsed = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={32} height={32} rx={8} fill="#F2F8F4" />
    <path
      d="m11.935 20.25 7.22-10.314a.56.56 0 0 1 .946.01.563.563 0 0 1-.025.635L12.524 21.37a.563.563 0 0 1-.799.128l-4.05-3.038a.562.562 0 1 1 .675-.9l3.585 2.69Zm8.002-5.375a.563.563 0 0 1 0-1.125h3.375a.562.562 0 1 1 0 1.125h-3.375Zm-2.25 3.375a.563.563 0 0 1 0-1.125h5.624a.563.563 0 1 1 0 1.125h-5.623Zm-2.25 3.375a.563.563 0 0 1 0-1.125h7.874a.563.563 0 1 1 0 1.125h-7.873Z"
      fill="#027A48"
    />
  </svg>
);
export default SvgVoucherUsed;
