import Papa from 'papaparse'
import React, { useState } from 'react'
import { BatchUploadProps } from './types'
import axios from 'axios'
import { API_ERRORS } from 'constants/errors'
import { CSV_UPLOAD_PATTERN } from 'constants/pattern'
import Button from 'ui/atoms/button/Button'
import { formatServicesData } from './utils'
import { useSalonCache } from 'hooks/useSalonCache'
import { UploadServiceDocument, UploadServiceInput } from 'core/generated'
import { print } from 'graphql'
import { useUploadService } from 'api/useCatalogue'
import { useUploadFile } from 'api/useAccount'

const ImportServices = (props: BatchUploadProps) => {
  const { getSalonFieldValue } = useSalonCache();
  const salonId = getSalonFieldValue('id');

  const {
    loading,
    uploadService
  } = useUploadService()

  const {
    loading: isLoading,
    uploadFile
  } = useUploadFile();

  const uploadServiceAsync = async (input: UploadServiceInput) => {
    try {
      uploadService({
        variables: { input }
      }).then(({ data }) => {
        const { uploadService: us } = data
        if (us?.status === 200) {
          props?.addToast({ message: 'Services uploaded successfully. This may take a few minutes to process', variant: 'success' })
        } else {
          props?.addToast({ message: us?.errors[0]?.message, variant: 'error' })
        }
      }).catch((error) => {
        props?.addToast({ message: error?.message, variant: 'error' })
      })
    } catch (error) {
      props?.addToast({ message: error?.message, variant: 'error' })
    }
  }

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result;
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          const payload = {
            salonId,
            url: uploadFile?.url
          }

          uploadServiceAsync(payload)
        }

        if (uploadFile?.errors?.length) {
          props?.addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        props?.addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  };
  return (
    <Button
      variant='success'
      className='relative'
      // disabled={uploadSalonServices?.isLoading}
      size='sm'
      rounded={props.rounded || 'md'}
      fontSize='b5'
      type='button'
    >
      {!isLoading ?
        (
          <>
            Import Services
            <input
              className="cursor-pointer absolute block opacity-0 top-0 w-[128px] h-full"
              type="file"
              accept={CSV_UPLOAD_PATTERN}
              onChange={handleCsvUpload}
            />
          </>
        ) : (
          <div className="flex justify-center gap-x-2 cursor-pointer">
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="h-1 w-1 rounded-full bg-gradient-to-r from-grey-200 via-grey-300 to-grey-200 animate-pulse" />
            ))}
          </div>
        )}
    </Button>
  )
}

export default ImportServices