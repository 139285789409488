import { PackageVoucher } from "core/generated";
import { BookingVoucherService } from "../../../modals/types";
import { formatInToPrice } from "../../../utils/misc";
import { ISalonAppointmentAvailabilityProps } from "../../bookingSales/types";
import { convertVoucherServicesNamesToString } from "../../bookingSales/utils";


export const showRedeemPackageDetails = (salonPackage?: PackageVoucher) => {
  if (!salonPackage) return [];
  const servicesString = convertVoucherServicesNamesToString(salonPackage?.voucherServices);
  // get count of the services that has been redeemed
  const redeemedCount = salonPackage?.voucherServices?.reduce((acc, curr) => {
    if (curr.isRedeemed) {
      return acc + 1
    }
    return acc
  }, 0)
  return [{
    label: 'Name',
    value: salonPackage?.package?.name
  }, {
    label: 'Services',
    value: servicesString,
  }, {
    label: 'Total Price',
    value: salonPackage?.package?.usesCustomPrice ? formatInToPrice(salonPackage?.package?.customPrice) : formatInToPrice(salonPackage?.package?.totalPrice),
  }, {
    label: 'Total quantity',
    value: salonPackage?.voucherServices?.length,
  }, {
    label: 'Quantity left',
    value: salonPackage?.voucherServices?.length - redeemedCount,
  }, {
    label: 'Status',
    value: salonPackage?.status,
  }, 
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatVouchersInToOptions = (vouchers: PackageVoucher[]) => {
  return vouchers.filter((voucher) => voucher?.status === 'active').map?.((voucher) => {
    return {
      label: `${voucher?.package?.name} - ${voucher?.code} - ${voucher?.business?.name}`,
      value: voucher?.id,
    }
  })
}

export const convertAvailabilitiesToCalendarFormat = (newDates: ISalonAppointmentAvailabilityProps) => {
  const dates = Array.isArray(newDates) && newDates?.map((date) => {
    const timeSlots = date.results.map((result) => {
      return {
        time: result.startTime,
        isAvailable: true,
      }
    })
    return {
      date: date.date,
      isAvailable: timeSlots.length > 0,
      timeSlots,
    }
  })
  return dates;
};

export const convertVoucherServicesToTimeAvailabilityPayload = (services: BookingVoucherService[]): {
  serviceId: string;
  staffId: string | null;
}[] => {
  return Array?.isArray(services) && services.map((service) => {
    return {
      serviceId: service.serviceId,
      staffId: service?.staff?.id || null,
    }
  })
}

export const convertVoucherServicesToTimeAvailabilityPayloadFromBookingSite = (services: BookingVoucherService[]): {
  serviceId: string;
  staffId: string | null;
}[] => {
  return Array?.isArray(services) && services.filter((service) => service.selected).map((service) => {
    return {
      serviceId: service.serviceId,
      staffId: service?.staff?.id || null,
    }
  })
}

export const convertVoucherServicesIdsToString = (services: BookingVoucherService[]): string[] => {
  return services.filter((service) => service.selected).map((service) => service.serviceId);
}