import * as React from 'react'
import axios from 'axios'
import { Listbox, Transition } from '@headlessui/react'
import { Salon, SalonsDocument, User } from 'core/generated'
import ToastWrapper from '../molecules/toastWrapper/ToastWrapper'
import { ToastProps } from '../atoms/toast'
import { useToast, useUserCache } from 'hooks'
import { useSalonCache } from 'hooks/useSalonCache'
import { useEffect, useMemo } from 'react'
import { SvgLocationPin, SvgDropDown } from 'ui'
import { canPerformAction } from '../../components/utils/permission'
import { PERMISSION_CONSTANTS } from 'constants/permission'
import { limitString } from '../../components/utils/misc'
import { useSalons } from 'api/useSalon'

const SalonSelection = () => {
  const { getUserData } = useUserCache()
  const { toast, addToast } = useToast()
  const user = getUserData()
  const { getSalonData, setSalonData } = useSalonCache();
  const salon = getSalonData();

  const {
    data,
    refetch
  } = useSalons();

  const salons = useMemo(() => {
    if (!data?.salons) return [];
  
    if (canPerformAction(PERMISSION_CONSTANTS?.account?.locations)) {
      return data.salons;
    } else {
      return data.salons.filter((salon: Salon) => salon.id === user?.salonStaff?.salonId);
    }
  }, [data?.salons, user?.salonStaff?.salonId]);

  const [selected, setSelected] = React.useState<Salon | null>(salon);

  useEffect(() => {
    if (selected && salon && selected?.id !== salon?.id) {
      setSalonData(selected);
    } else if (!selected && salon) {
      setSelected(salon);
    }
  }, [selected, salon])

  useEffect(() => {
    if (Array?.isArray(salons) && salons?.length && !salon && !selected) {
      setSelected(salons[0]);
    }
  }, [salon?.id, salons]);

  useEffect(() => {
    if (Array?.isArray(salons) && salons?.length && salon) {
      const exists = salons.some((eachSalon) => eachSalon.id === salon.id);
      if (!exists) {
        setSelected(salons[0]);
      }
    }
  }, [salons]);

  const onChange = (salon: Salon) => {
    setSelected(salon);
    setSalonData(salon);
    addToast({
      message: "Switching branch",
      variant: "success"
    })
    setTimeout(() => {
      // Your code to execute after 10 seconds
      window.location.reload();
    }, 1000);
  }

  useEffect(() => {
    if (salon?.id) {
      refetch();
    }
  }, [salon?.id, refetch])
  return (
    <>
      <ToastWrapper toast={toast as ToastProps} />
      {Array.isArray(salons) && salons.length > 0 ? (
        <Listbox value={selected} onChange={onChange}>
          <div className='relative'>
            <Listbox.Button className='relative w-full md:min-w-[200px] cursor-pointer rounded-full bg-white border border-green-100 py-3 px-3 flex justify-between items-center focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
              <div className='w-full flex justify-between items-center'>
                <div className='flex items-center space-x-2'>
                  <span className='text-grey-300'>
                    <SvgLocationPin width="20px" height="20px" />
                  </span>
                  <span className='truncate hidden md:inline text-grey-900'>
                    {selected?.branchName || 'Select Branch'}
                  </span>
                </div>
                <span className='pointer-events-none items-center pr-2'>
                  <SvgDropDown width='24px' height='24px' aria-hidden='true' />
                </span>
              </div>
            </Listbox.Button>
            <Transition
              as={React.Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className='absolute max-h-60 w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[9999]'
                style={{
                  top: 50,
                  bottom: 'auto',
                  right: 'auto'
                }}
              >
                {salons.map((salon, salonIdx) => (
                  <Listbox.Option
                    key={salonIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-grey-900 text-white' : 'text-grey-900'
                      }`
                    }
                    value={salon}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {salon?.branchName}
                        </span>
                        {selected ? (
                          <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-grey-900'></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      ) : null}
    </>
  )
}

export default SalonSelection