import { Category, Client, Package, PackageService, PosDevice, Product, Salon, SalonStaff, Service, TransferAccount } from "core/generated";
import { AppointmentServiceInputProps, SelectedService, UpdateAppointmentServiceInputProps } from "../modals/types";
import { MultiSelectOption, SvgFacebook, SvgInstagram, SvgTwitter } from "ui";
import { convertSalonPackageServicesNamesToString } from "../uicomponents/bookingSales/utils";
import { cleanUpText, convert12HourTo24Hour, formatInToPrice } from "./misc";


export const getSocialIcon = (social: string) => {
  if (social === 'instagram') {
    return SvgInstagram
  } else if (social === 'twitter') {
    return SvgTwitter
  } else {
    return SvgFacebook
  }
};

export const convertServicesToAppointmentStaffServiceForPreviewPrice = (services: AppointmentServiceInputProps[], startAt: string) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
    startAt: string;
    price?: number
  }[] = Array.isArray(services) && services.length ? services.map((service, index) => ({
    serviceId: service?.id,
    staffIds: service?.staff?.map((staff) => staff?.staffId) || null,
    quantity: service?.quantity || 1,
    startAt: getServiceTime(index, startAt, services[index - 1]?.duration || 0),
    price: service?.servicePrice
  })) : [];

  return newServiceIds;
}

export const addStartAtToViewAppointmentService = (
  services: UpdateAppointmentServiceInputProps[],
  startTime: string
) => {
  // Format function to add minutes to a given time
  function addMinutes(time: string, minutes: number) {
    const [hours, mins] = time.split(':').map(Number);
    const date = new Date(0, 0, 0, hours, mins + minutes);
    return date.toTimeString().slice(0, 5);
  }

  // Ensure startTime is in the correct format (HH:mm)
  const initialStartTime = startTime
    ? `${startTime.split(':')[0]}:${startTime.split(':')[1]}`
    : "09:00";

  let currentStartAt = initialStartTime;

  for (let i = 0; i < services.length; i++) {
    const service = services[i];

    if (i === 0) {
      // For the first service, use the given start time or set to the initial time
      service.startAt = initialStartTime || service.startAt;
    } else if (service?.startAt) { 
      currentStartAt = service?.startAt;
    } else {
      // Calculate startAt for services without a startAt
      service.startAt = addMinutes(currentStartAt, services[i - 1].duration || 0);
      currentStartAt = service.startAt;
    }
  }

  return services;
};

export const convertUpdateServicesToAppointmentStaffServiceForPreviewPrice = (services: UpdateAppointmentServiceInputProps[], startAt: string) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
    startAt: string;
    price?: number
  }[] = Array.isArray(services) && services.length ? services.map((service, index) => ({
    serviceId: service?.id,
    staffIds: service?.staff?.map((staff) => staff?.staffId) || null,
    quantity: service?.quantity || 1,
    startAt: service?.startAt ? updateAppointmentServiceTime(service?.startAt, startAt) as string : getServiceTime(index, startAt, services[index - 1]?.duration || 0),
    price: service?.servicePrice
  })) : [];

  return newServiceIds;
}

export const convertServicesToAppointmentStaffService = (services: SelectedService[], startAt: string, bookingSite?: boolean) => {
  const newServiceIds: {
    staffIds: string[] | null;
    serviceId: string;
    quantity: number;
    startAt: string;
    price?: number
  }[] = Array.isArray(services) && services.length ? services.map((service, index) => ({
    serviceId: service?.id,
    staffIds: null,
    quantity: service?.quantity || 1,
    price: service?.servicePrice,
    startAt: getServiceTimeFromBookingSite(index, startAt, services[index - 1]?.duration || 0, bookingSite),
  })) : [];

  return newServiceIds;
}

export const formatStaffToSelectField = (staff: SalonStaff[], firstNameOnly?: boolean) => {
  return staff
    ?.filter(eachStaff => eachStaff.canServeCustomers) // Filter only staff who can serve customers
    .map(eachStaff => ({
      label: `${eachStaff.user.firstName}${!firstNameOnly ? ` ${eachStaff.user.lastName}` : ''} ${
        eachStaff.specialty ? `- ${cleanUpText(eachStaff.specialty)}` : ""
      }`,
      value: eachStaff.id,
    }));
};

export const formatStaffToSelectFieldWithEmptyFirstValue = (
  staff: SalonStaff[],
  firstNameOnly?: boolean
) => {
  const list = [{ label: "Assign", value: "-" }];

  return list.concat(formatStaffToSelectField(staff, firstNameOnly));
};

export const formatClientToSelectFieldAppointmentCreation = (
  clients: Client[]
) => {
  const list = [{ label: "New Client", value: "new-client" }];

  return list.concat(formatClientsToSelectField(clients));
};

export const formatCategoriesToSelectFieldCreation = (
  categories: Category[]
) => {
  const list = [{ label: "Add New Category", value: "new-cate" }];

  if (Array?.isArray(categories) && categories?.length) {
    const categoryList = categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));

    return list.concat(categoryList);
  }

  return list;
};

export const formatServicesToSelectField = (services: Service[]) => {
  return services?.map((service) => ({
    label: `${service?.name} - ${formatInToPrice(service?.price || 0)}`,
    value: service?.id,
  }));
};

export const formatBusinessServicesToSelectField = (
  services: Service[]
) =>
  services.map((service) => ({
    label: service.name,
    value: service.id,
  }));

export const formatClientsToSelectField = (clients: Client[]) => {
  return clients?.map((client) => ({
    label: client?.firstName + " " + client?.lastName + " - " + client?.phone,
    value: client?.id,
  }));
}

export const formatProductsToSelectField = (products: Product[]) => {
  return products?.map((product) => ({
    label: `${product?.name} ${product?.stockCount === 0 ? '(Out of stock)': ''}`,
    value: product?.id,
    disabled: product?.stockCount === 0, // Disable the option if the product is out of stock
  }));
};

export const formatBankAccountsToSelectField = (
  accounts: TransferAccount[]
) => {
  return accounts?.map((account) => ({
    label: account?.accountName + " " + account?.bankName,
    value: account?.id,
  }));
};

export const formatSalonBranchesToSelectField = (branches: Salon[]) => {
  return branches?.map((branch) => ({
    label: `${branch.branchName}`,
    value: branch.id,
  }));
};

export const formatPosTerminalsToSelectField = (devices: PosDevice[]) => {
  return devices?.map((device) => ({
    label: device?.name + " " + device?.bankName,
    value: device?.id,
  }));
}

export const formatSalonPackagesToSelectField = (packages: Package[]) => {
  return packages?.map((salonPackage) => ({
    label: `${salonPackage?.name} - ${salonPackage?.usesCustomPrice ? formatInToPrice(salonPackage?.customPrice || 0) : formatInToPrice(salonPackage?.totalPrice)} (${convertSalonPackageServicesNamesToString(salonPackage?.packageServices as unknown as PackageService[])})`,
    value: salonPackage?.id,
  }));
}

export const getServiceTime = (index: number, startAt: string, duration: number) => {
  if (index === 0) {
    return startAt;
  } else {
    const startTime = new Date(startAt);
    // Add the duration to the startAt time
    const endTime = new Date(startTime.getTime() + (duration * 60000)); // Multiply duration by 60000 to convert minutes to milliseconds
    // Return the updated startAt time
    
    return getTimeToHHmmss(endTime);
  }
}

export const getServiceTimeFromBookingSite = (index: number, startAt: string, duration: number, bookingSite?: boolean) => {
  const appointmentStartAt = bookingSite ? `${startAt}:00` : startAt += ':00+00:00'
  if (index === 0) {
    return appointmentStartAt;
  } else {
    const startTime = new Date(appointmentStartAt);
    // Add the duration to the startAt time
    const endTime = new Date(startTime.getTime() + (duration * 60000)); // Multiply duration by 60000 to convert minutes to milliseconds
    // Return the updated startAt time

    return getTimeToHHmmss(endTime);
  }
}

const getTimeToHHmmss = (dateString: string | Date) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

const updateAppointmentServiceTime = (serviceTime: string, appointmentDateTime: string) => {
  if (!serviceTime || !appointmentDateTime) return;
  const appointmentDateArray = appointmentDateTime?.split("T");
  const appointmentTime = convert12HourTo24Hour(serviceTime);

  return `${appointmentDateArray[0]}T${appointmentTime}`;
}

export const formatTimeToIncludeSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(":");
  return `${hours}:${minutes}:${seconds || "00"}`;
}

export const sortOptions = (data: MultiSelectOption[]) => {
  if (!data) return [];
  const sortedData = [...data].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return sortedData;
}