import { Button, Modal, Paragraph } from "../../../ui"
import React from 'react';
import { formatInToPrice, getDayMonthAndNumberAndTimeFromDate } from "../../utils/misc";
import { COLORS } from "constants/colors";
import { Payment } from "core/generated";

type ViewPaymentModalProps = {
  isVisible: boolean
  closeModal: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment: Payment | null
}
const ViewPaymentTransactionModal = ({
  isVisible,
  closeModal,
  payment
}: ViewPaymentModalProps) => {
  return (
    <Modal title='Transaction details' show={isVisible} closeModal={closeModal}>
      <>
        {payment && (
          <div className='w-full flex flex-col my-12 space-y-6'>
            <div className='w-full flex space-x-4'>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  Client
                </Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>
                  {payment?.client?.firstName} {payment?.client?.lastName}
                </Paragraph>
              </div>
              <div className='flex flex-col space-y-2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  Amount
                </Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>
                  {formatInToPrice(payment?.amount)}
                </Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>
                Services/Voucher
              </Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>
                {payment?.description}
              </Paragraph>
            </div>
            <div className='flex flex-row space-x-4'>
              <div className='flex flex-col space-y-2 w-1/2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  Payment method
                </Paragraph>
                <Paragraph
                  size='b4'
                  color={COLORS.BLACK}
                  className='capitalize'
                >
                  {payment?.mode}
                </Paragraph>
              </div>
              <div className='flex flex-col space-y-2 w-1/2'>
                <Paragraph size='b5' color={COLORS.GREY[300]}>
                  Time
                </Paragraph>
                <Paragraph size='b4' color={COLORS.BLACK}>
                  {getDayMonthAndNumberAndTimeFromDate(
                    payment?.createdAt
                  ) || '-'}
                </Paragraph>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <Paragraph size='b5' color={COLORS.GREY[300]}>
                Payment reference
              </Paragraph>
              <Paragraph size='b4' color={COLORS.BLACK}>
                {payment?.paymentReference || '-'}
              </Paragraph>
            </div>
          </div>
        )}
      </>
      <Button
        variant='primary'
        className=''
        size='lg'
        rounded='lg'
        onClick={closeModal}
      >
        Done
      </Button>
    </Modal>
  )
}

export default ViewPaymentTransactionModal;