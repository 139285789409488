export const SALES_HISTORY_HEADERS = [
  { label: 'Service', value: 'service' },
  { label: 'Date', value: 'date' },
  { label: 'Payment Method', value: 'paymentMethod' },
  { label: 'Discount', value: 'discount' },
  { label: 'Amount', value: 'amount' },
  { label: 'Points', value: 'points' },
  { label: 'Status', value: 'status' }
]

export const REWARDS_HISTORY_HEADERS = [
  { label: 'Date Reached', value: 'milestone' },
  { label: 'Reward value', value: 'date' },
  { label: 'Status', value: 'reward' }
]

export const COMMUNICATION_LOG_HISTORY_HEADERS = [
  { label: 'Subject line', value: 'subject' },
  { label: 'Body', value: 'body' },
  { label: 'Type', value: 'communicationType' },
  { label: 'Status', value: 'deliveryStatus' }
]

export const CLIENT_PACKAGES_HISTORY_HEADERS = [
  { label: 'Name', value: 'name' },
  { label: 'Date of Purchase', value: 'purchasedAt' },
  { label: 'Expiry Date', value: 'validityMonths' },
  { label: 'Status', value: 'status' },
  { label: 'Total Price', value: 'price' },
  { label: 'Total Redeemed', value: 'redeemed' }
]
export const CLIENT_PACKAGES_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Name',
    key: 'name'
  },
  {
    title: 'Price',
    key: 'price'
  }
]


export const CLIENTS_HEADINGS = [
  {
    label: 'Client name',
    value: 'name',
    show: true
  },
  {
    label: 'Date',
    value: 'date',
    show: true
  },
  {
    label: 'Mobile number',
    value: 'phone',
    show: true
  },
  {
    label: 'Email address',
    value: 'email',
    show: true
  },
  {
    label: 'Total sales',
    value: 'total',
    show: true
  }
]

export const PREFERENCES_HEADINGS = [
  {
    label: 'Preference',
    value: 'preference',
    show: true
  },
  {
    label: 'Details',
    value: 'details',
    show: true
  },
  {
    label: 'Date added',
    value: 'date',
    show: true
  }
]

export const MOBILE_PREFERENCES_HEADINGS = [
  {
    title: 'Preference',
    key: 'preference'
  },
  {
    title: 'Details',
    key: 'details'
  },
  {
    title: 'Date added',
    key: 'date'
  }
]

export const MOBILE_CLIENTS_HEADINGS = [
  {
    title: 'Client name',
    key: 'name'
  },
  {
    title: 'Date',
    key: 'date'
  },
  {
    title: 'Mobile',
    key: 'phone'
  },
  {
    title: 'Email',
    key: 'email'
  },
  {
    title: 'Total sales',
    key: 'total'
  }
]

export const CLIENT_TAB_NAME = {
  BOOKING: 'Booking History',
  PACKAGES: 'Vouchers',
  SALES: 'Sales',
  REWARDS: 'Rewards',
  ACTIVITY: 'Activity',
  COMMUNICATION_LOG_HISTORY: 'Messages',
  OVERVIEW: 'Overview',
  PREFERENCES: 'Preferences',
  WALLET: 'Wallet',
}

export const CLIENT_TABS = [
  {
    key: CLIENT_TAB_NAME.OVERVIEW,
    title: CLIENT_TAB_NAME.OVERVIEW
  },
  {
    key: CLIENT_TAB_NAME.WALLET,
    title: CLIENT_TAB_NAME.WALLET
  },
  {
    key: CLIENT_TAB_NAME.BOOKING,
    title: CLIENT_TAB_NAME.BOOKING
  },
  {
    key: CLIENT_TAB_NAME.SALES,
    title: CLIENT_TAB_NAME.SALES
  },
  {
    key: CLIENT_TAB_NAME.PACKAGES,
    title: CLIENT_TAB_NAME.PACKAGES
  },
  {
    key: CLIENT_TAB_NAME.PREFERENCES,
    title: CLIENT_TAB_NAME.PREFERENCES
  },
  {
    key: CLIENT_TAB_NAME.REWARDS,
    title: CLIENT_TAB_NAME.REWARDS
  },
  {
    key: CLIENT_TAB_NAME.ACTIVITY,
    title: CLIENT_TAB_NAME.ACTIVITY
  },
  {
    key: CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY,
    title: CLIENT_TAB_NAME.COMMUNICATION_LOG_HISTORY
  }
]

export const BOOKING_HISTORY_HEADERS = [
  { label: 'Service', value: 'service' },
  { label: 'Appointment Date', value: 'date' },
  { label: 'Payment Method', value: 'paymentMethod' },
  { label: 'Amount', value: 'amount' },
  { label: 'Status', value: 'status' },
  { label: 'Points', value: 'points' }
]

export const WALLET_HISTORY_HEADERS = [
  { label: 'Date', value: 'date' },
  { label: 'Transaction type', value: 'transactionType' },
  { label: 'Amount', value: 'amount' },
]

export const MOBILE_WALLET_HISTORY_HEADERS = [
  {
    title: 'Date',
    key: 'date'
  },
  {
    title: 'Transaction type',
    key: 'transactionType'
  },
  {
    title: 'Amount',
    key: 'amount'
  }
]

export const BOOKING_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Service',
    key: 'service'
  },
  {
    title: 'Payment',
    key: 'amount'
  }
]
export const REWARDS_HISTORY_MOBILE_HEADERS = [
  {
    title: 'Date Reached',
    key: 'milestone'
  },
  {
    title: 'Reward value',
    key: 'date'
  },
  {
    title: 'Status',
    key: 'reward'
  }
]

export const MODAL_VIEW = {
  client: 'client',
  topUpWallet: 'topUpWallet',
  addPreferences: 'addPreferences'
}