import * as React from 'react'
import { useState, useEffect } from 'react';
import { SearchTermProps } from './types';
import { FormLabel } from '../..';
import { SvgFluentSearch20Regular } from 'ui';

const BookingSiteSearch = ({ showIcon = true, className, placeholder, label, ...props }: SearchTermProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const delay = 500; // Debounce delay in milliseconds

    const timer = setTimeout(() => {
      props.setDebouncedSearchQuery(searchQuery);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (props?.defaultSearchTerm) {
      setSearchQuery(props?.defaultSearchTerm)
    }
  }, [props?.defaultSearchTerm])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className='flex flex-col space-y-2'>
      {label ? (
        <FormLabel htmlFor="service">
          {label}
        </FormLabel>
      ) : null}
      <div className='relative'>
        {showIcon ? (
          <div className='absolute inset-y-0 flex items-center left-2'>
            <SvgFluentSearch20Regular aria-hidden="true" width="24px" height="24px" />
          </div>
        ) : null}
        <input
          type="text"
          className={`w-full flex items-center appearance-none border border-grey-20 ${className || ""} ${showIcon ? 'pl-10' : 'pl-4'} pr-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg focus:outline-none focus:border-grey-20 focus:shadow-grey-100 placeholder:text-grey-400`}
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={placeholder || "Search"}
          disabled={props?.disabled || false}
        />
      </div>
    </div>
  )
}

export default BookingSiteSearch