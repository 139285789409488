import React from 'react';
import { SLogo } from 'ui';
import { COLORS } from 'constants/colors';
import Paragraph from 'ui/atoms/paragraph/Paragraph';
import Button from 'ui/atoms/button/Button';
import Heading from 'ui/atoms/heading/Heading';

const SuccessFeedBackModal = () => {

  const closeTab = () => {
    window.close();
  };

  return (
    <main className='fixed top-0 left-0 w-full h-screen bg-grey-100 flex items-center justify-center'>
      <div className="table-row">
        <div className="table-cell align-middle">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 max-w-[400px] xl:max-w-[500px]">
            <div className="flex flex-col items-center space-y-6">
              <SLogo width="100%" height="100%" className="w-[32px]" />
              <Heading variant='h1' size='h4' weight='bold' color={COLORS.BLACK}>Thank you</Heading>
              <Paragraph size="b5" weight='medium' className='w-full text-center max-w-[400px]' color={COLORS.GREY[400]}>
                Your feedback means a lot to us.
              </Paragraph>
              <Button
                variant='primary'
                className=''
                disabled={false}
                loading={false}
                size='lg'
                rounded='lg'
                onClick={closeTab}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SuccessFeedBackModal