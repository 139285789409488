import * as React from 'react'
import { useController } from 'react-hook-form';
import { InputProps } from './types';
import { useMemo, useState } from 'react';
import { getHelperTextForReactHookFormErrors } from '../../../components/utils/form';
// import { FormHelperTextList, FormLabel, HelperTextProps, Paragraph } from '../..';
import { COLORS } from 'constants/colors';
import { HelperTextProps } from '../../atoms/helperText/types';
import { FormLabel } from '../../atoms/formLabel';
import Paragraph from '../../atoms/paragraph/Paragraph';
import { FormHelperTextList } from '../../atoms/helperText/FormHelperTextList';

const PasswordInput: React.FC<InputProps> = ({ name, label, control, className, rules, error, id, ...rest }) => {
  const [passwordType, setPasswordType] = useState('password');
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  });

  const helperText = getHelperTextForReactHookFormErrors(error?.message || "");

  const changePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  }

  const helperTexts: HelperTextProps[] = useMemo(() => {
    if (Array.isArray(helperText)) {
      return helperText;
    }

    if (helperText) {
      return [helperText];
    }

    return [];
  }, [helperText]);

  return (
    <div className='w-full flex flex-col space-y-2'>
      {label && (
        <FormLabel htmlFor={id}>{label}</FormLabel>
      )}
      <div className="flex w-full">
        <input
          {...rest}
          {...inputProps}
          id={id}
          type={passwordType}
          ref={ref}
          className={`w-full flex items-center border border-grey-100 px-4 py-3 font-normal text-b6 xl:text-b4 text-grey-900 rounded-lg rounded-tr-none rounded-br-none focus:outline-none placeholder:text-grey-600 ${className || ""
            }`}
        />
        <div
          className={`flex cursor-pointer justify-center items-center border border-l-0 border-grey-100 rounded-tr-lg rounded-br-lg py-3 px-4`}
          onClick={changePasswordType}
        >
          <Paragraph
            size="b4"
            color={COLORS.GREY[300]}
            className="whitespace-nowrap"
          >
            {passwordType === 'password' ? 'show' : 'hide'}
          </Paragraph>
        </div>
      </div>
      {error && (
        <FormHelperTextList helperTexts={helperTexts} />
      )}
    </div>
  );
};

export default PasswordInput