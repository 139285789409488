import React from 'react'
import { CustomVoucherCardProps } from '../types'
import { getNumberMonthAndYearFromDate } from '../../../utils/misc'
import { Paragraph } from '../../../../ui'

const PackageCustomerBackCard = (props: CustomVoucherCardProps) => {
  const voucher = props?.voucher;


  const getLocation = () => {
    const salonId = voucher?.salonId;
    const salons = voucher?.business?.salons;

    const salon = Array?.isArray(salons) && salons.find(salon => salon?.id === salonId);

    if (salon) {
      return salon?.branchName;
    }

    return "-"
  }

  const nameMap = {
    "services": "Services",
    "totalServices": "Total Services",
    "location": "Location",
    "dateOfPurchase": "Date of Purchase",
  };

  const voucherMap = {
    "services": voucher?.voucherServices
    ?.map((service) => `${service?.name} ${service?.quantity - service?.redeemedCount || 0}/${service?.quantity}`)
    ?.join(', '),
    "totalServices": voucher?.voucherServices?.length,
    "location": getLocation(),
    "dateOfPurchase": getNumberMonthAndYearFromDate(voucher?.createdAt, voucher?.business?.country?.timezone) || "-",
  };
  return (
    <div className="bg-green-300/15 w-full border-2 border-green-300 max-w-[360px] h-fit cursor-pointer rounded-md" onClick={() => props?.onClick()}>
      <div className='w-full h-full flex flex-col space-y-2 p-2'>
        {Object.entries(voucherMap).map(([key, value]) => (
          nameMap[key] && value !== null && (
            <div key={key} className="px-2 flex justify-between items-start space-x-4">
              <Paragraph size="b5" color="grey-300" className="text-left">
                {nameMap[key]}
              </Paragraph>
              <Paragraph size="b5" color="grey-800" weight="semiBold" className="text-right">
                {value}
              </Paragraph>
            </div>
          )
        ))}
      </div>
    </div>
  )
}

export default PackageCustomerBackCard
