import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "ui";
import { PAGE_ROUTES } from "constants/routes";
import { useManageAuth } from "../hooks/useManageAuth";

export const AppLoader = () => {
  const { getAppAuthToken } = useManageAuth();
  const appAuthToken = getAppAuthToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (appAuthToken) {
      navigate(PAGE_ROUTES.HOME);
    } else {
      navigate(PAGE_ROUTES.LOGIN);
    }
  }, [appAuthToken, navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <Logo />
    </div>
  );
};
