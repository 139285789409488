import { routes as authRoutes } from "modules/auth/routes";
import { routes as pageRoutes } from "pages/pageRoutes";
// import { routes as bookingRoutes } from "modules/booking/routes";
// import { routes as clientRoutes } from "modules/clients/routes";
// import { routes as inventoryRoutes } from "modules/inventory/routes";
// import { routes as loyaltyAndPromosRoutes } from "modules/loyaltyAndPromos/routes";
// import { routes as homeRoutes } from "modules/home/routes";
// import { routes as settingsRoutes } from "modules/settings/routes";
// import { routes as appointmentRoutes } from "modules/appointment/routes";
// import { routes as workingHoursRoutes } from "modules/workingHours/routes";
// import { routes as messagingRoutes } from "modules/messaging/routes";
// import { routes as notFoundRoutes } from "modules/notFound/routes";
// import { routes as moneyRoutes } from "modules/money/routes";
// import { routes as reportRoutes } from "modules/reports/routes";
// import { routes as reviewRoutes } from "modules/reviews/routes";
// import { routes as receiptRoutes } from "modules/receipt/routes";
// import { routes as salesRoutes } from "modules/sales/routes";
// import { routes as redeemVoucherRoutes } from "modules/redeemVoucher/routes";

export const routes = [
  ...authRoutes,
  ...pageRoutes,
  // ...accountSetupRoutes,
  // ...bookingRoutes,
  // ...clientRoutes,
  // ...inventoryRoutes,
  // ...loyaltyAndPromosRoutes,
  // ...homeRoutes,
  // ...settingsRoutes,
  // ...appointmentRoutes,
  // ...workingHoursRoutes,
  // ...messagingRoutes,
  // ...notFoundRoutes,
  // ...moneyRoutes,
  // ...reportRoutes,
  // ...reviewRoutes,
  // ...receiptRoutes,
  // ...salesRoutes,
  // ...redeemVoucherRoutes,
];
