import * as React from "react";
import Base from "./Base";
import { ToastBaseProps } from "./types";
import { TOAST_BG_COLORS } from "constants/colors";

const Info = (props: ToastBaseProps) => {
  return (
    <Base {...props} bgColor={TOAST_BG_COLORS.INFO}>
      {props.children}
    </Base>
  );
};

export default Info;
