import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgPaymentMethodSplit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12.425 2.5a.625.625 0 0 1 .625.625v18.75a.625.625 0 1 1-1.25 0V3.125a.625.625 0 0 1 .625-.625Zm-9.375 5A2.5 2.5 0 0 1 5.55 5h5v1.25h-5A1.25 1.25 0 0 0 4.3 7.5v10a1.25 1.25 0 0 0 1.25 1.25h5V20h-5a2.5 2.5 0 0 1-2.5-2.5v-10ZM19.3 6.25a1.25 1.25 0 0 1 1.25 1.25v.625a.625.625 0 0 0 1.25 0V7.5A2.5 2.5 0 0 0 19.3 5h-.625a.625.625 0 0 0 0 1.25h.625Zm0 12.5a1.25 1.25 0 0 0 1.25-1.25v-.625a.625.625 0 0 1 1.25 0v.625a2.5 2.5 0 0 1-2.5 2.5h-.625a.625.625 0 1 1 0-1.25h.625ZM21.175 10a.625.625 0 0 0-.625.625v3.75a.625.625 0 0 0 1.25 0v-3.75a.625.625 0 0 0-.625-.625Zm-5-5a.625.625 0 1 1 0 1.25h-1.25a.625.625 0 0 1 0-1.25h1.25Zm.625 14.375a.625.625 0 0 0-.625-.625h-1.25a.625.625 0 1 0 0 1.25h1.25a.625.625 0 0 0 .625-.625Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPaymentMethodSplit;
