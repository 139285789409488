import React, { useState } from "react"
import PaymentModal from "../modals/PaymentModal"
import { Button, Heading, Paragraph, SvgBrandLoyalty, SvgBrandMessaging, SvgBrandSms, SvgBrandVoucher, SvgMasterCard, SvgVerve, SvgVisa } from "ui"
import { COLORS } from "constants/colors"
import { getAddonContent } from "./settingsUtils"
import { Card, PaystackPaymentAuthorization, SubscribeToAddonDocument, UnsubscribeFromAddonDocument } from "core/generated"
import axios from "axios"
import { useModal } from "hooks"
import { AddonModalProps } from "./types"
import { getShortDate } from "../utils/misc"
import PromptCardSelection from "./PromptCardSelection"
import { useSalonCache } from "hooks/useSalonCache"
import { useSubscribeToAddon, useUnsubscribeAddon } from "api/useAccount"

const AddonDetails = (props: AddonModalProps) => {
  const [paymentAuthorization, setPaymentAuthorization] = useState<PaystackPaymentAuthorization>(null)
  const [showCards, setShowCards] = useState(false)
  const [card, setCard] = useState<Card | null>(null)
  const { getSalonFieldValue } = useSalonCache()
  const salonId = getSalonFieldValue('id')
  const {
    isVisible: paymentModalIsvisible,
    openModal: openPaymentModal,
    closeModal: closePaymentModal,
  } = useModal();
  const {
    isVisible: promptPaymentModalIsvisible,
    openModal: openPromptPaymentModal,
    closeModal: closePromptPaymentModal,
  } = useModal();
  
  const {
    loading: unsubscribeIsLoading,
    unsubscribeAddon
  } = useUnsubscribeAddon()

  const {
    loading: isPaymentPageLoading,
    subscribeToAddon
  } = useSubscribeToAddon()

  const subscribeToAddonAsync = async (selectedCard?: Card) => {
      subscribeToAddon({
        variables: {
          input: {
            addonId: props?.addon.addon?.id,
            ...(selectedCard
              ? {
                cardId: selectedCard?.id,
              }
              : {}),
          },
        },
      }).then(({ data }) => {
        const { subscribeToAddon 
        } = data;
        
        if (subscribeToAddon?.status === 200 && subscribeToAddon?.paymentAuthorization) {
          setPaymentAuthorization(subscribeToAddon?.paymentAuthorization)
          openPaymentModal()
        }

        if (subscribeToAddon?.status == 200 && !subscribeToAddon?.paymentAuthorization) {
          props.addToast({ message: subscribeToAddon?.message, variant: 'success' })
          props.closeModal('reload')
          closePromptPaymentModal()
        }

        if (subscribeToAddon?.status === 422) {
          props.addToast({ message: subscribeToAddon?.errors[0]?.message, variant: 'error' })
        }

        if (subscribeToAddon?.status === 404) {
          props.addToast({ message: 'Error using card, kindly try again', variant: 'error' })
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // addToast({ message: err.message, variant: 'error' })
      })
  }

  const unsubscribeFromAddon = async () => {
    unsubscribeAddon({
      variables: {
        input: {
          addonId: props?.addon.addon?.id,
        },
      },
    }).then(({ data }) => {
      const { unsubscribeFromAddon } = data;

      if (unsubscribeFromAddon?.status === 200) {
        props.closeModal()
      }

      if (unsubscribeFromAddon?.status === 422) {
        props.addToast({ message: unsubscribeFromAddon?.errors[0]?.message, variant: 'error' })
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((err) => {
        // props.addToast({ message: err.message, variant: 'error' })
      })
  }

  const confirmPayment = () => {
    props.addToast({ message: 'Addon subscribed successfully', variant: 'success' })
    closePaymentModal();
    setShowCards(false)
    props?.closeModal("reload")
  }

  const closeModal = () => {
    setShowCards(false)
    props?.closeModal()
  }

  const details = getAddonContent(props?.addon?.addon?.name)

  const getFeatureImage = (addonName: string) => {
    if (addonName?.toLowerCase() === "loyalty") {
      return <SvgBrandLoyalty width="100%" height="100%" />
    }

    if (addonName?.toLowerCase() === "sms reminders") {
      return <SvgBrandSms width="100%" height="100%" />
    }

    if (addonName?.toLowerCase() === "messaging") {
      return <SvgBrandMessaging width="100%" height="100%" />
    }

    if (addonName?.toLowerCase() === "vouchers") {
      return <SvgBrandVoucher width="100%" height="100%" />
    }
  }

  const selectCard = () => {
    setShowCards(true)
  }

  const selectCardOption = (card?: Card | null) => {
    if (card) {
      setCard(card)
      openPromptPaymentModal()
      return;
    }
    subscribeToAddon()
  }

  const initiateClosePromptCard = (action?: string) => {
    if (card && action === 'proceed') {
      subscribeToAddonAsync(card)
    } else {
      closePromptPaymentModal()
    }
  }

  const getBranchLogo = (cardType: string) => {
    if (cardType?.toLowerCase() === 'visa') {
      return <SvgVisa width="30px" height="30px" />
    }

    if (cardType?.toLowerCase() === 'verve') {
      return <SvgVerve width="30px" height="30px" />
    }

    return <SvgMasterCard width="30px" height="30px" />
  }

  const getCardsOptions = () => {
    return <div className='w-full flex flex-col space-y-4'>
      <Heading variant='h2' size='h10' weight='semiBold'>Select a payment option</Heading>
      <div className='w-full flex flex-wrap gap-4'>
        {Array?.isArray(props?.cards) && props?.cards?.length ? props?.cards?.map((card) => {
          return <div className='w-full flex items-center space-x-4 max-w-[300px] border border-grey-100 rounded-md px-4 py-2 cursor-pointer' onClick={() => selectCardOption(card)}>
            <span className='rounded-md px-3 bg-green-300 w-fit'>
              {getBranchLogo(card?.cardType?.trim())}
            </span>
            <div className='flex flex-col space-y-1'>
              <Paragraph size='b4' weight='medium'>Use card  **** **** {card?.last4}</Paragraph>
            </div>
          </div>
        }) : null}
        <div className='w-full flex items-center space-x-4 max-w-[300px] border border-grey-100 rounded-md px-4 py-2 cursor-pointer' onClick={() => selectCardOption()}>
          <span className='rounded-md px-3 bg-green-300 h-[30px] w-[55px] text-white flex justify-center items-center'>****</span>
          <div className='flex flex-col space-y-1'>
            <Paragraph size='b4' weight='medium'>Use new card</Paragraph>
          </div>
        </div>
      </div>
    </div>
  }
  return (
    props?.isOpen ? (
      <>
        <div className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black/70 backdrop-blur-[2px] z-30">
          <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 px-9 h-fit max-w-[1200px]">
            <div className="w-full flex flex-col-reverse lg:flex-row lg:justify-between items-center gap-6">
              <div className="w-full flex flex-col space-y-4">
                <span className='text-green-300 font-semibold rounded-full py-2 px-4 border border-green-150 w-fit shadow-md'>Addon</span>
                <div className='w-full flex flex-col space-y-2'>
                  <Heading variant='h1' size='h9' weight='semiBold' color={COLORS.GREY[900]}>{details?.title}</Heading>
                  <Paragraph size='b3' color={COLORS.GREY[300]}>{details?.subTitle}</Paragraph>
                </div>

                <div className='w-full flex flex-col space-y-4'>
                  {details?.featuredList && details?.featuredList?.length ? details?.featuredList?.map((list) => {
                    return <div className='w-full flex items-start sapce-x-4'>
                      <span className='text-b5'>✨️</span>
                      <div className='flex flex-col space-y-2'>
                        {list?.name && list?.name !== "" ? <Paragraph size='b4'>{list?.name}</Paragraph> : null}
                        <Paragraph size='b4' color={COLORS.GREY[300]}>{list?.description}</Paragraph>
                      </div>
                    </div>
                  }) : null}
                </div>
                <div className='w-full flex flex-col space-y-6'>
                  {props?.addon?.subscribed ? <Paragraph size='b4' weight="semiBold" className="w-full text-center">Expires on {getShortDate(props?.addon?.expiryDate)}</Paragraph> : null}
                  {showCards && props?.cards && props?.cards?.length ?
                    getCardsOptions() :
                    <Button
                      variant='primary'
                      size='lg'
                      type='button'
                      disabled={isPaymentPageLoading}
                      loading={isPaymentPageLoading}
                      rounded='lg'
                      onClick={props?.addon?.subscribed ? () => unsubscribeFromAddon() : props?.cards && props?.cards?.length ? () => selectCard() : () => subscribeToAddonAsync()}
                    >
                      {props?.addon?.subscribed ? 'Unsubscribe' : 'Subscribe'}
                    </Button>}

                  <Button
                    variant='text'
                    size='none'
                    type='button'
                    rounded='lg'
                    onClick={closeModal}
                    className='w-full text-center'
                  >
                    I'll do that later
                  </Button>
                </div>
              </div>
              <div className='w-full max-w-[350px] flex justify-center items-center'>
                {getFeatureImage(props?.addon?.addon?.name)}
              </div>
            </div>
          </div>
        </div>

        <PaymentModal
          isVisible={paymentModalIsvisible}
          closeModal={closePaymentModal}
          confirmPayment={confirmPayment}
          paymentAuthorization={paymentAuthorization || null}
          salonId={salonId}
        ></PaymentModal>
        <PromptCardSelection isVisible={promptPaymentModalIsvisible} closeModal={initiateClosePromptCard} card={card} isLoading={isPaymentPageLoading}></PromptCardSelection>
      </>
    ) : null
  )
}

export default AddonDetails
