import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Menu, Transition } from '@headlessui/react';
import TableSkeleton from 'ui/organism/tableSkeleton/TableSkeleton';
import Label from 'ui/atoms/formLabel/Label';
import Checkbox from 'ui/atoms/checkbox/Checkbox';
import SearchTerm from 'ui/organism/debounceQuery/SearchTerm';
import { SvgFilter } from 'ui';
import CalendarPicker from 'ui/molecules/calendarPicker/CalendarPicker';
import { formatDateToOriginalDate, formatTableHeadersFilterArray, getTodayQueryDates } from '../../utils/misc';
import Table from 'ui/organism/table/Table';
import { APPOINTMENT_HEADINGS, MOBILE_APPOINTMENT_SALES_HEADINGS } from './constants';
import { SalesPageProps } from './types';
import { Appointment, DeleteAppointmentDocument, RevertAppointmentCancellationDocument, UpdateAppointmentStatusDocument } from 'core/generated';
import { fetchAppointments } from '../../modals/utils';
import { generateAppointmentSalesTableData } from './StatusUtils';
import { useModal } from 'hooks';
import axios from 'axios';
import NoShowAppointmentModal from '../appointmentComponents/modals/NoShowAppointmentModal';
import DeleteAppointmentModal from '../appointmentComponents/modals/DeleteAppointmentModal';
import CancelAppointmentModal from '../appointmentComponents/modals/CancelAppointmentModal';
import { API_ERRORS } from 'constants/errors';
import ViewAppointmentModal from '../../modals/ViewAppointmentModal';
import { useAppointments, useDeleteAppointment, useRevertAppointmentCancellation, useUpdateAppointmentStatus } from 'api/useAppointments';

const AppointmentSales = ({ addToast, ...props }: SalesPageProps) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(getTodayQueryDates());
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [appointmentHeadings, setAppointmentHeadings] = useState(APPOINTMENT_HEADINGS);

  const [start, end] = selectedDates || getTodayQueryDates();
  const [appointment, setAppointment] = useState<Appointment>();

  const {
    loading: deleteAppointmentIsLoading,
    deleteAppointment
  } = useDeleteAppointment()

  const {
    loading: updateAppointmentStatusIsLoading,
    updateAppointmentStatus
  } = useUpdateAppointmentStatus()

  const {
    loading: appointmentCancellationIsLoading,
    revertAppointmentCancellation
  } = useRevertAppointmentCancellation()

  const {
    isVisible: isViewAppointmentModalVisible,
    openModal: openViewAppointmentModal,
    closeModal: closeViewAppointmentModal,
  } = useModal();

  const {
    isVisible: isNoShowAppointmentModalVisible,
    openModal: openNoShowAppointmentModal,
    closeModal: closeNoShowAppointmentModal
  } = useModal();

  const {
    isVisible: isCancelAppointmentModalVisible,
    openModal: openCancelAppointmentModal,
    closeModal: closeCancelAppointmentModal,
  } = useModal();

  const {
    isVisible: isDeleteAppointmentModalVisible,
    openModal: openDeleteAppointmentModal,
    closeModal: closeDeleteAppointmentModal,
  } = useModal();

  const {
    loading: isLoading,
    refetch,
    data: appointmentsData,
  } = useAppointments({
    salonId: props?.salonId,
    startDate: formatDateToOriginalDate(start, 'start'),
    endDate: formatDateToOriginalDate(end, "end"),
  });
  const appointments = useMemo(() => appointmentsData?.appointments, [appointmentsData])

  useEffect(() => {
    if (props?.salonId) {
      refetch()

      props?.setRefreshData(false)
    }
  }, [props?.salonId, start, end, refetch, props?.refreshData])

  const showInitialLoadingShimmer = isLoading && !appointments && !debouncedSearchQuery;

  const handleHeadingCheckboxChange = (value: string) => {
    // search for the heading with that value in Headings then update the show
    const updatedHeadings = appointmentHeadings.map(heading => {
      if (heading.value === value) {
        return {
          ...heading,
          show: !heading.show
        }
      }
      return heading
    })
    setAppointmentHeadings(updatedHeadings)
  }

  const openAppointment = (id: number) => {
    const appointment = Array?.isArray(appointments) ? appointments[id] : undefined;

    if (!appointment) return;
    setAppointment(appointment)
    openViewAppointmentModal()
  }

  const checkInOrOutAppointmentAsync = async ({
    appointmentId, status, applyCancellationFee, chargeCustomerWallet
  }: {
    appointmentId: string,
    status: string,
    applyCancellationFee: boolean,
    chargeCustomerWallet: boolean
  }) => {
    try {
      const payload = {
        appointmentStatus: status,
        id: appointmentId,
        salonId: props?.salonId,
        applyCancellationFee,
        chargeCustomerWallet
      }

      updateAppointmentStatus({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.updateAppointmentStatus?.status === 200) {
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }

        if (data?.updateAppointmentStatus?.errors?.length) {
          const message = data?.updateAppointmentStatus?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (checkoutAppointmentError) {
      if (axios.isAxiosError(checkoutAppointmentError)) {
        const message = checkoutAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  };

  const finalizeAppointmentCancellation = (action: string, applyCancellationFee = false, chargeCustomerWallet = false) => {
    if (action === 'cancel') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointment?.id as string, status: 'cancelled', applyCancellationFee, chargeCustomerWallet
      })
      closeCancelAppointmentModal();
    } else if (action === 'no_show') {
      checkInOrOutAppointmentAsync({
        appointmentId: appointment?.id as string, status: 'no_show', applyCancellationFee, chargeCustomerWallet
      })
      closeNoShowAppointmentModal()
    }
    else {
      closeCancelAppointmentModal();
      closeNoShowAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const deleteAppointmentAsync = async (id: string | null) => {
    if (!id) return;

    try {
      deleteAppointment({
        variables: {
          input: {
            salonId: props?.salonId,
            id,
          }
        }
      }).then(({ data }) => {
        const clientDetailsData = data?.deleteAppointment;
        if (clientDetailsData) {
          refetch();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment removed successfully",
            variant: 'success',
          })
        } else {
          const message = API_ERRORS.APPOINTMENT_DELETION_FAILED;
          addToast({
            message,
            variant: 'error',
          })
        }
      })
    } catch (deleteAppointmentError) {
      if (axios.isAxiosError(deleteAppointmentError)) {
        const message = deleteAppointmentError?.response?.data?.message || API_ERRORS.APPOINTMENT_DELETION_FAILED;
        addToast({
          message,
          variant: 'error',
        })
      }
    }
  }

  const finalizeAppointmentDeletion = (action: string) => {
    if (action === 'delete') {
      deleteAppointmentAsync(appointment?.id)
      closeDeleteAppointmentModal();
    } else {
      closeDeleteAppointmentModal();
      openViewAppointmentModal();
    }
  }

  const getContent = () => {
    if (showInitialLoadingShimmer) {
      return (
        <div className='flex flex-col xl:flex-row px-5 py-4'>
          <TableSkeleton />
        </div>
      )
    }

    if (Array.isArray(appointments) || debouncedSearchQuery) {
      const tableClientsData = generateAppointmentSalesTableData(appointments || [], appointmentHeadings)
      return (
        <Table
          headers={formatTableHeadersFilterArray(appointmentHeadings)}
          mobileHeaders={MOBILE_APPOINTMENT_SALES_HEADINGS}
          rows={tableClientsData}
          loading={showInitialLoadingShimmer}
          onClick={openAppointment}
        />
      )
    }
  }

  useEffect(() => {
    if (props.salonBranchName) {
      refetch()
    }
  }, [props.salonBranchName])

  useEffect(() => {
    if (props.refreshData) {
      refetch()
    }
  }, [props.refreshData])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initiateCloseViewAppointmentModal = (action: string) => {
    setAppointment(null);
    closeViewAppointmentModal();
  }

  const initiateNoShowAppointment = () => {
    closeViewAppointmentModal();
    openNoShowAppointmentModal();
  }

  const initiateCancelAppointment = () => {
    closeViewAppointmentModal();
    openCancelAppointmentModal();
  }

  const initiateDeleteAppointment = () => {
    closeViewAppointmentModal();
    openDeleteAppointmentModal();
  }


  const revertAppointmentCancellationAsync = async () => {
    try {
      const payload = {
        appointmentId: appointment?.id
      }
      revertAppointmentCancellation({
        variables: { input: { ...payload } }
      }).then(({ data }) => {
        if (data?.revertAppointmentCancellation?.status === 200) {
          refetch();
          closeViewAppointmentModal();
          addToast({
            message: "Appointment updated successfully",
            variant: 'success',
          })
        }
        if (data?.revertAppointmentCancellation?.errors?.length) {
          const message = data?.revertAppointmentCancellation?.errors[0]?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE;
          addToast && addToast({
            variant: 'error',
            message,
          })
        }
      })
    } catch (error) {
      const message = error?.response?.data?.message || API_ERRORS.APPOINTMENT_FAILED_TO_UPDATE
      addToast({
        message,
        variant: 'error'
      })
    }
  }

  // useEffect(() => {
  //   refetchAppointments();
  // }, [debouncedSearchQuery]);
  return (
    <>
      <div className='flex flex-col xl:flex-row justify-between items-center py-4 px-8 space-x-4'>
        <div className='w-full xl:w-6/12 flex items-center space-x-4'>
          <SearchTerm placeholder='Search' setDebouncedSearchQuery={setDebouncedSearchQuery} />
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex space-x-2 w-full items-center justify-center rounded-md bg-grey-50 border border-grey-20 px-4 py-2 text-b5 font-medium text-grey-300 hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85">
                <SvgFilter width="20px" height="20px" />
                <span className='hidden xl:inline'>Filter</span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[9999]">
                <div className="flex flex-col p-4 space-y-4">
                  {Array.isArray(appointmentHeadings) && appointmentHeadings.length && appointmentHeadings.map((heading, index) => (
                    <Label className='flex space-x-2 items-center cursor-pointer' key={index} htmlFor={heading.value} onClick={() => handleHeadingCheckboxChange(heading.value)}>
                      <Checkbox
                        isChecked={heading.show}
                        borderType='Black'
                        size='Sm'
                      />
                      <span>{heading.label}</span>
                    </Label>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <CalendarPicker {...{ selectedDates, setSelectedDates }} />
      </div>
      {getContent()}
      <ViewAppointmentModal
        isVisible={isViewAppointmentModalVisible}
        closeModal={initiateCloseViewAppointmentModal}
        cancelAppointment={initiateCancelAppointment}
        deleteAppointment={initiateDeleteAppointment}
        salonId={props?.salonId}
        addToast={addToast}
        appointmentId={appointment?.id}
        markAsNoShow={initiateNoShowAppointment}
        refetchCalendarData={refetch}
        revertCancellation={revertAppointmentCancellationAsync}
      />
      <CancelAppointmentModal
        isVisible={isCancelAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        appointmentId={appointment?.id}
        salonId={props?.salonId}
      />
      <NoShowAppointmentModal
        isVisible={isNoShowAppointmentModalVisible}
        closeModal={finalizeAppointmentCancellation}
        appointmentId={appointment?.id}
        salonId={props?.salonId}
      />
      <DeleteAppointmentModal
        isVisible={isDeleteAppointmentModalVisible}
        closeModal={finalizeAppointmentDeletion}
      />
    </>
  )
}

export default AppointmentSales