// storage-keys.ts

export const LOCAL_STORAGE_KEYS = {
  APP_AUTH_INFO: "splice:auth-info",
  APP_AUTH_TOKEN: "token",
  CUSTOMER_AUTH_TOKEN: "customer-token",
  APP_SPLICE_USER: "userData",
  APP_SPLICE_CUSTOMER: "splice:customer",
  APP_SPLICE_SALON: "splice:salon",
  APP_SPLICE_BUSINESS: "splice:business",
  APP_SPLICE_SKIP_SETUP: "splice:skip-setup"
} as const;