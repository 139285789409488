import React from 'react';

const ToggleOnly = ({ isChecked, disabled = false }) => {
  const getBgColor = () => {
    if (disabled) {
      return isChecked ? 'bg-green-300' : 'bg-grey-50';
    }
    return isChecked ? 'bg-green-300' : 'bg-grey-100';
  };

  return (
    <label 
      className={`flex items-center ${
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
      }`}
    >
      <div className="relative" role="switch"
        aria-checked={isChecked}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : 0}>
        <div
          className={`w-10 h-6 rounded-full transition-colors duration-200 ease-in-out ${getBgColor()}`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${isChecked ? 'transform translate-x-4' : ''
            } ${
            disabled ? 'shadow-none' : 'shadow-sm'
          }`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleOnly;