import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { ERRORS } from "constants/errors";
import jwt_decode from "jwt-decode";
import { JsonDecodeProps } from "modules/auth";
import { useManageAuth } from "modules/auth/hooks/useManageAuth";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  // credentials: "include",
});

const errorLink = onError(({ graphQLErrors }) => {
  if (Array.isArray(graphQLErrors)) {
    const { logout } = useManageAuth();
    const notAuthorizedError = graphQLErrors.find(
      ({ message }) => message === ERRORS.NOT_AUTHORIZED
    );

    if (notAuthorizedError) {
      logout();
    }
  }
});

const authLink = setContext((_, { headers: originalHeaders }) => {
  const { getAppAuthToken, getCustomerAuthToken } = useManageAuth();
  const appToken = getAppAuthToken();
  const customerToken = getCustomerAuthToken();

  const token = appToken || customerToken;

  let headers = originalHeaders || {};

  // check if token is still valid 
  if (token) {
    const tokenDecoded: JsonDecodeProps = jwt_decode(token as string);
      const expirationTime = (tokenDecoded.exp * 1000) - 60000
      if (Date.now() >= expirationTime) {
        const { logout, logoutCustomer } = useManageAuth();
        appToken && logout();
        customerToken && logoutCustomer();
      }
  }

  if (token) {
    headers = {
      ...headers,
      authorization: `Bearer ${token}`,
    };
  }

  return {
    headers,
  };
});


const cache = new InMemoryCache();

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache,
});

export default client;