import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAppointmentFrequent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect width={24} height={24} rx={4} fill="#98187C" fillOpacity={0.05} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.923 8.815a4.508 4.508 0 0 0-1.318 3.694.35.35 0 0 1-.696.074A5.208 5.208 0 0 1 8.43 8.316c2.056-2.028 5.373-1.995 7.408.068 2.035 2.064 2.021 5.38-.035 7.408a5.201 5.201 0 0 1-3.568 1.5 5.248 5.248 0 0 1-1.498-.19.35.35 0 0 1 .186-.675c.423.117.86.172 1.3.164a4.494 4.494 0 0 0 3.088-1.298c1.78-1.754 1.794-4.627.028-6.417-1.765-1.79-4.639-1.815-6.417-.061Z"
      fill="#98187C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.516 12.954a.35.35 0 0 1-.494.028l-1.204-1.071a.35.35 0 1 1 .464-.522l1.204 1.07a.35.35 0 0 1 .03.495Z"
      fill="#98187C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.974 12.91a.35.35 0 0 0 .49.07l1.4-1.05a.35.35 0 1 0-.42-.56l-1.4 1.05a.35.35 0 0 0-.07.49ZM12 9.55a.35.35 0 0 1 .35.35v2.45a.35.35 0 1 1-.7 0V9.9a.35.35 0 0 1 .35-.35Z"
      fill="#98187C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.45 12.35a.35.35 0 0 1-.35.35H12a.35.35 0 0 1 0-.7h2.1a.35.35 0 0 1 .35.35Z"
      fill="#98187C"
    />
  </svg>
);
export default SvgAppointmentFrequent;
