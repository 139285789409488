import { Button, FormTextarea, Heading, Input, Modal, Paragraph, Pill, SvgArrowDown, SvgCharmMenuKebab, SvgDeleteRegular, SvgEditRegular, SvgMdiLightImage, SvgPlus, SvgRoundArrowDown, SvgRoundArrowUp, SvgRoundDownload, SvgRoundEye, SvgRoundTrash } from "ui"

import * as ReactMenu from '@szhsin/react-menu'
import { IClientNoteToggleProps, ViewNotesProps } from "../types"
import { useSalonCache } from "hooks"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { ClientNote, CreateClientNoteInput } from "core/generated"
import { Controller, useForm } from "react-hook-form"
import { useCreateClientNote, useCreateClientNoteAttachment, useDeleteClientNote, useDeleteClientNoteAttachment, useGetClient } from "api/useClient"
import { getHelperTextForReactHookFormErrors, getHelpTextForCharacterLeft } from "components/utils/form"
import { getNumberMonthAndYearFromDate, limitString } from "components/utils/misc"
import { COLORS } from "constants/colors"
import saveAs from "file-saver"
import { PhotoProvider, PhotoView } from "react-photo-view"
import { useUploadFile } from "api/useAccount"
import { error } from "console"

const ViewNotesModal = ({
  isVisible,
  closeModal,
  actions,
  addToast,
  clientId
}: ViewNotesProps<'editNote' | 'deleteNote'>) => {
  const [notes, setNotes] = useState<IClientNoteToggleProps[]>([])

  const [sortBy, setSortBy] = useState<string>('recent')
  const [selectedNote, setSelectedNote] = useState<ClientNote | null>(null)
  const [editNoteScreen, setEditNoteScreen] = useState<boolean>(false)
  const [attachments, setAttachments] = useState<string[]>([])
  const { getSalonData } = useSalonCache();
  const salon = getSalonData()
  const salonId = salon?.id
  const { handleSubmit, control, setValue } = useForm<CreateClientNoteInput>()
  const sortArray = (sortType: string) => {
    if (sortBy !== sortType) {
      const sortedNotes = notes.reverse()
      setSortBy(sortType)
      setNotes(sortedNotes)
    }
  }
  const {
    refetch: refetchClient,
    data: clientData
  } = useGetClient({
    clientId,
    salonId
  })

  useMemo(() => {
    if (clientData?.salonClient?.clientNotes) {
      const updatedNotes = clientData?.salonClient?.clientNotes.map((note) => ({
        ...note,
        show: false
      }))
      setNotes(updatedNotes)
    }
  }, [clientData])

  const {
    loading: saving,
    createClientNote
  } = useCreateClientNote()

  const {
    loading: savingAttachment,
    createClientNoteAttachment
  } = useCreateClientNoteAttachment()

  const {
    deleteClientNoteAttachment
  } = useDeleteClientNoteAttachment()

  const {
    deleteClientNote
  } = useDeleteClientNote()

  const _closeModal = () => {
    setEditNoteScreen(false)
    setSelectedNote(null)
    setAttachments([])
    setNotes([])
    closeModal()
  }

  const editNote = (note: ClientNote) => {
    toggleShow(note?.id)
    setEditNoteScreen(true)
    setSelectedNote(note)
    setAttachments(note?.clientNoteAttachments.map((x) => x?.imageUrl))
  }

  useEffect(() => {
    if (selectedNote) {
      setValue('note', selectedNote?.note)
      setValue('title', selectedNote?.title)
    }
  }, [selectedNote, setValue])

  const toggleShow = (noteId: string) => {
    if (editNoteScreen) setEditNoteScreen(false)
    const updatedNotes = notes.map((note: IClientNoteToggleProps) => ({
      ...note,
      show: note.id === noteId ? !note.show : false // Toggle show for the clicked note, set others to false
    }))
    // fetch the current selected note from updateNotes
    const selectedNote = updatedNotes.find((note) => note.id === noteId)

    if (selectedNote && selectedNote.show) {
      setSelectedNote(selectedNote)
      setAttachments(selectedNote.clientNoteAttachments.map((x) => x.imageUrl))
    } else {
      setSelectedNote(null)
      setAttachments([])
    }
    // Update the notes state with the new array
    setNotes(updatedNotes)
  }

  const closeNoteEditForm = () => {
    setEditNoteScreen(false)
    setSelectedNote(null)
    setAttachments([])
  }

  const saveNote = async (input: CreateClientNoteInput) => {
    createClientNote({
      variables: {
        input: {
          id: selectedNote.id,
          title: input.title,
          note: input.note,
          clientId: selectedNote.clientId
        }
      }
    }).then(({ data }) => {
      const { createClientNote } = data;
      if (createClientNote.status === 200) {
        addToast({
          message: 'Client note saved successfully',
          variant: 'success'
        })
        closeNoteEditForm()
        refetchClient()
      } else {
        addToast({
          message: createClientNote.errors[0].message,
          variant: 'error'
        })
      }
    })
  }

  const {
    loading: uploadIsLoading,
    uploadFile
  } = useUploadFile();

  const createNoteAttachmentAsync = (url: string, note: ClientNote) => {
    createClientNoteAttachment({
      variables: {
        input: {
          imageUrl: url,
          clientNoteId: note.id
        }
      }
    }).then(({ data }) => {
      const { createClientNoteAttachment } = data;
      if (createClientNoteAttachment.status === 200) {
        addToast({
          message: 'Attachment uploaded successfully',
          variant: 'success'
        })
        refetchClient()
      } else {
        addToast({
          message: createClientNoteAttachment.errors[0].message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  }

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    note: ClientNote
  ) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = async (event) => {
      const base64DataUri = event.target.result
      uploadFile({
        variables: { input: { data: base64DataUri } }
      }).then(({ data }) => {
        const { uploadFile } = data;
        if (uploadFile?.status === 200) {
          createNoteAttachmentAsync(uploadFile?.url, note);
        }

        if (uploadFile?.errors?.length) {
          addToast({
            message: uploadFile?.errors[0].message,
            variant: 'error'
          })
        }
      }).catch((error) => {
        addToast({
          message: error?.message,
          variant: 'error'
        })
      })
    }

    reader.readAsDataURL(file)
  }

  const downloadFileAttachment = (fileName: string, fileUrl: string) => {
    saveAs(fileUrl, fileName) // Put your image URL here.
  }

  const removeFileAttachment = async (imageUrl: string) => {
    deleteClientNoteAttachment({
      variables: { input: { imageUrl } }
    }).then(({ data }) => {
      const { deleteClientNoteAttachment } = data;
      if (deleteClientNoteAttachment.status === 200) {
        addToast({
          message: 'Client note attachment deleted successfully',
          variant: 'success'
        })

        const updatedAttachments = attachments.filter(
          (imgUrl) => imgUrl !== imageUrl
        )

        // Update the state with the new array of attachments
        setAttachments(updatedAttachments)
      } else {
        addToast({
          message: deleteClientNoteAttachment.errors[0].message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
    // modifyAttachment("removed", updatedAttachments);
  }

  const deleteClientNoteAsync = (noteId: string) => {
    deleteClientNote({
      variables: { input: { id: noteId } }
    }).then(({ data }) => {
      const { deleteClientNote } = data;
      if (deleteClientNote.status === 200) {
        addToast({
          message: 'Client note deleted successfully',
          variant: 'success'
        })
        _closeModal()
        refetchClient()
      } else {
        addToast({
          message: deleteClientNote.errors[0].message,
          variant: 'error'
        })
      }
    }).catch((error) => {
      addToast({
        message: error?.message,
        variant: 'error'
      })
    })
  }

  const getNotesContent = () => {
    if (Array.isArray(notes) && notes.length > 0) {
      return notes.map((note: IClientNoteToggleProps, index) => {
        if (note?.note) {
          return (
            <form
              onSubmit={handleSubmit(saveNote)}
              key={index}
              className='flex flex-col space-y-4 border border-grey-20 rounded-md p-5'
            >
              <div className='flex flex-col space-y-2'>
                <div className='w-full flex justify-between'>
                  <div className='flex space-x-4 items-center'>
                    <Paragraph size='b5' color={COLORS.GREY[300]}>
                      {getNumberMonthAndYearFromDate(
                        note?.createdAt
                      )}
                    </Paragraph>
                    {note?.addedBy === 'appointment' ? (
                      <Pill variant={'light'}>Appointment note</Pill>
                    ) : !editNoteScreen ? (
                      <ReactMenu.Menu
                        menuButton={
                          <ReactMenu.MenuButton className=''>
                            <SvgCharmMenuKebab width='20px' height='20px' />
                          </ReactMenu.MenuButton>
                        }
                        key='bottom'
                        direction='bottom'
                        transition
                      >
                        {actions?.editNote ? (
                          <ReactMenu.MenuItem>
                            <span
                              className='text-b4 cursor-pointer flex items-center space-x-2'
                              onClick={() => {
                                editNote(note)
                              }}
                            >
                              <SvgEditRegular width='20px' height='20px' />{' '}
                              <span>Edit</span>
                            </span>
                          </ReactMenu.MenuItem>
                        ) : null}
                        {actions?.deleteNote ? (
                          <ReactMenu.MenuItem>
                            <span
                              className='text-b4 cursor-pointer flex items-center space-x-2'
                              onClick={() => deleteClientNoteAsync(note.id)}
                            >
                              <SvgDeleteRegular width='20px' height='20px' />{' '}
                              <span>Delete</span>
                            </span>
                          </ReactMenu.MenuItem>
                        ) : null}
                      </ReactMenu.Menu>
                    ) : null}
                  </div>
                  <>
                    {note?.show ? (
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => toggleShow(note.id)}
                      >
                        <SvgRoundArrowUp width='28px' height='28px' />
                      </Button>
                    ) : (
                      <Button
                        variant='icon'
                        size='none'
                        type='button'
                        className='border-0'
                        onClick={() => toggleShow(note.id)}
                      >
                        <SvgRoundArrowDown width='28px' height='28px' />
                      </Button>
                    )}
                  </>
                </div>
                {editNoteScreen &&
                  selectedNote &&
                  selectedNote?.id === note?.id ? (
                  <>
                    <Input
                      name='title'
                      label='Title'
                      id='title'
                      type='text'
                      placeholder='Write a title'
                      control={control}
                    // error={errors.title}
                    />
                    <Controller
                      control={control}
                      name='note'
                      render={({
                        field: { onChange, onBlur, name, ref, value },
                        formState: { errors }
                      }) => {
                        const maxLength = 1000
                        const formErrorsHelpTexts =
                          getHelperTextForReactHookFormErrors(
                            errors?.note?.message as string
                          )
                        const helperTexts = []

                        helperTexts.push(
                          getHelpTextForCharacterLeft(maxLength, value)
                        )
                        if (formErrorsHelpTexts)
                          helperTexts.push(formErrorsHelpTexts)

                        return (
                          <FormTextarea
                            type='text'
                            id='note'
                            label='Modify note'
                            placeholder='Add a note'
                            {...{
                              ref,
                              name,
                              value,
                              onChange,
                              onBlur,
                              maxLength
                            }}
                            helperText={helperTexts}
                          />
                        )
                      }}
                    />
                    <div className='flex flex-row space-x-2'>
                      <Button
                        variant='primary'
                        size='sm'
                        type='submit'
                        rounded='md'
                        disabled={saving || uploadIsLoading}
                        loading={saving || uploadIsLoading}
                      >
                        Save
                      </Button>
                      <Button
                        variant='secondary'
                        size='sm'
                        type='button'
                        rounded='md'
                        fontWeight="semiBold"
                        className="w-fit text-red-500"
                        onClick={closeNoteEditForm}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : !note?.id ? (
                  note?.title ? (
                    <>
                      <Paragraph size='b4' color={COLORS.BLACK}>
                        {note?.title}
                      </Paragraph>
                    </>
                  ) : (
                    <Paragraph size='b4' color={COLORS.BLACK}>
                      {note?.note}
                    </Paragraph>
                  )
                ) : (
                  <>
                    <Paragraph size='b4' weight='semiBold' color={COLORS.BLACK}>
                      {note?.title}
                    </Paragraph>

                    <Paragraph size='b4' color={COLORS.BLACK}>
                      {note?.note}
                    </Paragraph>
                  </>
                )}
              </div>
              {note?.id ? (
                <div className='flex flex-col space-y-6'>
                  <div className='flex justify-between items-center'>
                    <div
                      className='cursor-pointer'
                      onClick={() => toggleShow(note?.id)}
                    >
                      <Paragraph
                        size='b4'
                        weight='normal'
                        color={COLORS.GREY[300]}
                      >
                        Attachments ({note?.clientNoteAttachments?.length || 0})
                      </Paragraph>
                    </div>
                    <div className='relative cursor-pointer'>
                      <Button
                        key={note.id}
                        variant='light'
                        size='xs'
                        type='button'
                        rounded='sm'
                        fontSize='b4'
                        className='text-grey-300'
                        disabled={savingAttachment}
                        loading={savingAttachment}
                      >
                        <SvgPlus width='24px' height='24px' />
                        Add new
                      </Button>
                      <input
                        className='cursor-pointer absolute block opacity-0 top-0 w-full h-[40px]'
                        type='file'
                        accept='*'
                        onChange={(e) => handleFileUpload(e, note)}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col space-y-4'>
                    {Array.isArray(attachments) &&
                      attachments.length > 0 &&
                      note.id === selectedNote.id &&
                      attachments.map((attachment, index) => (
                        <div
                          className='flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:justify-between items-center py-2 px-4 border border-grey-20 rounded-md'
                          key={index}
                        >
                          <div className='flex space-x-2 items-center'>
                            <SvgMdiLightImage width='2rem' height='2rem' />
                            <Paragraph size='b5'>
                              {limitString(attachment, 25)}
                            </Paragraph>
                          </div>
                          <div className='flex space-x-2'>
                            <PhotoProvider>
                              <PhotoView src={attachment}>
                                <Button
                                  variant='icon'
                                  size='none'
                                  type='button'
                                  className='border-0'
                                >
                                  <SvgRoundEye width='28px' height='28px' />
                                </Button>
                              </PhotoView>
                            </PhotoProvider>
                            <Button
                              variant='icon'
                              size='none'
                              type='button'
                              className='border-0'
                              onClick={() =>
                                downloadFileAttachment(attachment, attachment)
                              }
                            >
                              <SvgRoundDownload width='28px' height='28px' />
                            </Button>
                            <Button
                              variant='icon'
                              size='none'
                              type='button'
                              className='border-0'
                              onClick={() => removeFileAttachment(attachment)}
                            >
                              <SvgRoundTrash width='28px' height='28px' />
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className='border flex space-x-1 py-2 px-3 border-grey-300 w-fit bg-grey-75 rounded-md'>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    {note?.clientNoteAttachments?.length}
                  </Paragraph>
                  <Paragraph size='b5' color={COLORS.GREY[300]}>
                    attachments
                  </Paragraph>
                </div>
              )}
            </form>
          )
        }
      })
    }
  }

  return (
    <Modal show={isVisible} closeModal={_closeModal}>
      <div className='flex justify-between'>
        <div className='w-full flex flex-col py-4 space-y-6'>
          <Heading variant='h1' size='h9' weight='semiBold'>
            Notes
          </Heading>
          <Paragraph size='b4'>Notes about client</Paragraph>
        </div>
        <div className='w-1/2 flex justify-end items-center'>
          <ReactMenu.Menu
            menuButton={
              <ReactMenu.MenuButton
                style={{ padding: '0.5rem 1rem 0.5rem 1rem' }}
                className='flex items-center space-x-2 border border-grey-100 bg-grey-20 px-4 py-2 rounded-sm'
              >
                <span>Sort by: {sortBy}</span>{' '}
                <SvgArrowDown width='10px' height='10px' />
              </ReactMenu.MenuButton>
            }
            key='bottom'
            direction='bottom'
            transition
          >
            <ReactMenu.MenuItem>
              <span
                className='text-grey-300 text-b4 cursor-pointer'
                onClick={() => sortArray('recent')}
              >
                Recent
              </span>
            </ReactMenu.MenuItem>
            <ReactMenu.MenuItem>
              <span
                className='text-grey-300 text-b4 cursor-pointer'
                onClick={() => sortArray('oldest')}
              >
                Oldest
              </span>
            </ReactMenu.MenuItem>
          </ReactMenu.Menu>
        </div>
      </div>
      <div className='flex flex-col space-y-6 my-12'>{getNotesContent()}</div>
      <Button
        variant='primary'
        className=''
        size='lg'
        rounded='lg'
        onClick={_closeModal}
      >
        Close
      </Button>
    </Modal>
  )
}

export default ViewNotesModal