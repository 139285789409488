import { gql, useMutation, useQuery } from "@apollo/client";
import { Bank, Expense, ExpenseConnection, ExpenseSummaryReport, Payment, PayoutAccount, PosDevice, TransferAccount, VirtualAccount, WalletTransaction } from "core/generated";

const BANKS = gql`
  query Banks {
    banks {
      code
      id
      name
    }
  }
`;

export const useGetBanks = () => {
  return useQuery<{ banks: Bank[] }>(BANKS);
}

const POS_DEVICES = gql`
  query PosDevices($salonId: ID!) {
    posDevices(salonId: $salonId) {
      addedById
      bankCode
      bankName
      createdAt
      currency
      deletedAt
      id
      name
      salonId
      updatedAt
    }
  }
`;

export const useGetPosDevices = (salonId: string) => {
  return useQuery<{ posDevices: PosDevice[] }>(POS_DEVICES, { variables: { salonId }, skip: !salonId });
}

const ADD_POS_DEVICE = gql`
  mutation CreatePosDevice($input: CreatePosDeviceInput!) {
    createPosDevice(input: $input) {
      clientMutationId
      errors {
        message
        property
      }
      status
    }
  }
`;

export const useAddPosDevice = () => {
  const [addPosDevice, options] = useMutation<{
    createPosDevice: { status: number; errors: { message: string; property: string }[] }
  }>(ADD_POS_DEVICE)

  return {
    addPosDevice,
    ...options,
  };
}

const UPDATE_POS_DEVICE = gql`
  mutation UpdatePosDevice($input: UpdatePosDeviceInput!) {
    updatePosDevice(input: $input) {
      clientMutationId
      errors {
        message
        property
      }
      status
    }
  }
`;

export const useUpdatePosDevice = () => {
  const [updatePosDevice, options] = useMutation<{
    updatePosDevice: { status: number; errors: { message: string; property: string }[] }
  }>(UPDATE_POS_DEVICE)

  return {
    updatePosDevice,
    ...options,
  };
}

const DELETE_POS = gql`
  mutation DeletePosDevice($input: DeletePosDeviceInput!) {
    deletePosDevice(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeletePosDevice = () => {
  const [deletePosDevice, options] = useMutation<{
    deletePosDevice: { status: number; errors: { message: string; property: string }[] }
  }>(DELETE_POS)

  return {
    deletePosDevice,
    ...options,
  };
}

const TRANSFER_ACCOUNTS = gql`
  query TransferAccounts($salonId: ID!) {
    transferAccounts(salonId: $salonId) {
      accountName
      accountNumber
      addedById
      bankCode
      bankName
      createdAt
      currency
      deletedAt
      id
      salonId
      updatedAt
    }
  }
`;

export const useGetTransferAccounts = (salonId: string) => {
  return useQuery<{ transferAccounts: TransferAccount[] }>(TRANSFER_ACCOUNTS, { variables: { salonId }, skip: !salonId });
}

const ADD_TRANSFER_ACCOUNT = gql`
  mutation CreateTransferAccount($input: CreateTransferAccountInput!) {
    createTransferAccount(input: $input) {
      clientMutationId
      errors {
        message
        property
      }
      status
    }
  }
`;

export const useAddTransferAccount = () => {
  const [addTransferAccount, options] = useMutation<{
    createTransferAccount: { status: number; errors: { message: string; property: string }[] }
  }>(ADD_TRANSFER_ACCOUNT)

  return {
    addTransferAccount,
    ...options,
  };
}

const UPDATE_TRANSFER_ACCOUNT = gql`
  mutation UpdateTransferAccount($input: UpdateTransferAccountInput!) {
    updateTransferAccount(input: $input) {
      clientMutationId
      isDuplicateAccount
      status
      accountDetails {
        accountName
        accountNumber
        bankId
        bankName
      }
      errors {
        message
        property
      }
    }
  }
`;

export const useUpdateTransferAccount = () => {
  const [updateTransferAccount, options] = useMutation<{
    updateTransferAccount: { status: number; isDuplicateAccount: boolean; errors: { message: string; property: string }[] }
  }>(UPDATE_TRANSFER_ACCOUNT)

  return {
    updateTransferAccount,
    ...options,
  };
}

const DELETE_TRANSFER_ACCOUNT = gql`
  mutation DeleteTransferAccount($input: DeleteTransferAccountInput!) {
    deleteTransferAccount(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteTransferAccount = () => {
  const [deleteTransferAccount, options] = useMutation<{
    deleteTransferAccount: { status: number; errors: { message: string; property: string }[] }
  }>(DELETE_TRANSFER_ACCOUNT)

  return {
    deleteTransferAccount,
    ...options,
  };
}

const CREATE_EXPENSE = gql`
  mutation CreateExpense($input: CreateExpenseInput!) {
    createExpense(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useAddExpense = () => {
  const [createExpense, options] = useMutation<{
    createExpense: { status: number; errors: { message: string; property: string }[] }
  }>(CREATE_EXPENSE)

  return {
    createExpense,
    ...options,
  };
}

const DELETE_EXPENSE = gql`
  mutation DeleteExpense($input: DeleteExpenseInput!) {
    deleteExpense(input: $input) {
      status
      errors {
        message
        property
      }
    }
  }
`;

export const useDeleteExpense = () => {
  const [deleteExpense, options] = useMutation<{
    deleteExpense: { status: number; errors: { message: string; property: string }[] }
  }>(DELETE_EXPENSE)

  return {
    deleteExpense,
    ...options,
  };
}

const PAYMENT_LIST = gql`
  query Payments($salonId: ID!, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $q: String) {
    payments(salonId: $salonId, startDate: $startDate, endDate: $endDate, q: $q) {
      id
      client {
        firstName
        lastName
      }
      description
      mode
      amount
      createdAt
      paymentReference
      staff
    }
  }
`;

export const useGetPayments = ({ salonId, startDate, endDate, q }: {
  salonId: string, startDate?: string, endDate?: string, q?: string
}) => {
  return useQuery<{ payments: Payment[] }>(PAYMENT_LIST, { variables: { salonId, startDate, endDate, q }, skip: !salonId });
}

const WALLET_TRASACTIONS = gql`
  query WalletTransactions($salonId: ID!) {
    walletTransactions(salonId: $salonId) {
      id
      title
      description
      transactionType
      paymentMode
      purposeType
      createdAt
      amount
      status
    }
  }
`;

export const useGetWalletTransactions = (salonId: string) => {
  return useQuery<{ walletTransactions: WalletTransaction[] }>(WALLET_TRASACTIONS, { variables: { salonId }, skip: !salonId });
}

const EXPENSES = gql`
  query Expenses(
    $salonId: ID!
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    expenses(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        id
        recipient
        description
        isRecurring
        interval
        expenseCategory {
          id
          name
        }
        date
        amount
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const useGetExpenses = ({ salonId, startDate, endDate, after, before, first, last }: {
  salonId: string, startDate?: string, endDate?: string, after?: string, before?: string, first?: number, last?: number
}) => {
  return useQuery<{ expenses: ExpenseConnection }>(EXPENSES, { variables: { salonId, startDate, endDate, after, before, first, last }, skip: !salonId });
}

const EXPENSE_SUMMARY = gql`
  query ExpenseSummaryReport(
    $salonId: ID!
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
  ) {
    expenseSummaryReport(
      salonId: $salonId
      startDate: $startDate
      endDate: $endDate
    ) {
      categoryWithMostExpenses
      totalExpense
      expenseTrend
      expensesGraphData {
        label
        value
      }
      expensesTrendGraphData {
        category
        label
        value
      }
    }
  }
`;

export const useGetExpenseSummary = ({ salonId, startDate, endDate }: {
  salonId: string, startDate?: string, endDate?: string
}) => {
  return useQuery<{
    expenseSummaryReport: ExpenseSummaryReport
  }>(EXPENSE_SUMMARY, { variables: { salonId, startDate, endDate }, skip: !salonId });
}

const CREATE_PAYOUT_ACCOUNT = gql`
  mutation CreatePayoutAccount($input: CreatePayoutAccountInput!) {
    createPayoutAccount(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      payoutAccount {
        accountName
        accountNumber
        bankCode
        bankName
        businessId
        createdAt
        currency
        deletedAt
        id
        updatedAt
      }
    }
  }
`;

export const useCreatePayoutAccount = () => {
  const [createPayoutAccount, options] = useMutation<{
    createPayoutAccount: { status: number; payoutAccount: PayoutAccount, message: string; errors: { message: string; property: string }[] }
  }>(CREATE_PAYOUT_ACCOUNT)

  return {
    createPayoutAccount,
    ...options,
  };
}

const CREATE_PAYOUT = gql`
  mutation CreatePayout($input: CreatePayoutInput!) {
    createPayout(input: $input) {
      status
      message
      showOtpForm
      errors {
        message
        property
      }
    }
  }
`

export const useCreatePayout = () => {
  const [createPayout, options] = useMutation<{
    createPayout: { status: number; message: string; showOtpForm: boolean; errors: { message: string; property: string }[] }
  }>(CREATE_PAYOUT)

  return {
    createPayout,
    ...options,
  };
}

const CREATE_VIRTUAL_ACCOUNT = gql`
  mutation CreateVirtualAccount($input: CreateVirtualAccountInput!) {
    createVirtualAccount(input: $input) {
      clientMutationId
      status
      errors {
        message
        property
      }
      virtualAccount {
        accountName
        accountNumber
        bankName
        businessId
        createdAt
        id
        subaccountId
        updatedAt
      }
    }
  }
`;

export const useCreateVirtualAccount = () => {
  const [createVirtualAccount, options] = useMutation<{
    createVirtualAccount: { status: number; virtualAccount: VirtualAccount, message: string; errors: { message: string; property: string }[] }
  }>(CREATE_VIRTUAL_ACCOUNT)

  return {
    createVirtualAccount,
    ...options,
  };
}

const COMPLETE_BVN_VERIFICATION = gql`
  mutation CompleteBvnVerification($input: CompleteBvnVerificationInput!) {
    completeBvnVerification(input: $input) {
      clientMutationId
      message
      status
      errors {
        message
        property
      }
    }
  }
`

export const useCompleteBvnVerification = () => {
  const [completeBvnVerification, options] = useMutation<{
    completeBvnVerification: { status: number; message: string; errors: { message: string; property: string }[] }
  }>(COMPLETE_BVN_VERIFICATION)

  return {
    completeBvnVerification,
    ...options,
  };
}