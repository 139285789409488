import * as React from "react";
import { TableBodyProps } from "./types";
import { Menu, Transition } from "@headlessui/react";
import { SvgCarbonView, SvgCharmMenuKebab, SvgDeleteRegular, SvgEditRegular, SvgIonCopyOutline, SvgTime, SvgUserBlock, SvgUserCheck, SvgVoid } from "ui";
import { TableCell, TableCellContent, TableRow } from "../..";
import { formatTableOptions, isStatusActive } from "./utils";

const TableBody = ({ rows, mobileRows, onClick, tableOptions, hasBorder, hasFooterBg }: TableBodyProps) => {
  const renderIcon = (option: string) => {
    switch (option) {
      case 'view':
        return <SvgCarbonView width="24px" height="24px" />;
      case 'edit':
        return <SvgEditRegular width="24px" height="24px" />;
      case 'duplicate':
        return <SvgIonCopyOutline width="24px" height="24px" />;
      case 'delete':
        return <SvgDeleteRegular width="24px" height="24px" />;
      case 'activate':
        return <SvgUserCheck width="24px" height="24px" />;
      case 'deactivate':
        return <SvgUserBlock width="24px" height="24px" />;
      case 'add_time_off':
        return <SvgTime width="24px" height="24px" />;
      case 'void_sale':
        return <SvgVoid width="24px" height="24px" />;
      default:
        return null; // Default to null if the option doesn't match
    }
  };

  return (
    <>
      <tbody className="hidden md:table-row-group">
        {Array.isArray(rows) &&
          rows.map((row, rowIndex) => {
            let isActive = false;
            if (row?.status && tableOptions?.deactivate && tableOptions?.activate) {
              isActive = isStatusActive(row?.status);
            }
            return (
              <TableRow
                key={rowIndex}
                hasBorder={hasBorder}
                hasFooterBg={hasFooterBg && rowIndex === rows?.length - 1}
              // onClick={onClick ? () => onClick(rowIndex) : onClick}
              >
                {/* convert object row to an array */}
                {Object.keys(row).map((cell, index) => {
                  return (
                    <TableCell key={index}>
                      <div className="flex items-center justify-between">
                        <div className="cursor-pointer" onClick={onClick ? () => onClick(rowIndex) : undefined}>
                          <TableCellContent content={row[cell]} weight={hasFooterBg && rowIndex === rows?.length - 1 ? 'semiBold' : 'normal'} />
                        </div>
                        {(!hasFooterBg || hasFooterBg && rowIndex !== rows?.length - 1) && index === Object.keys(row).length - 1 && tableOptions && onClick ? (
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="flex space-x-2 w-full items-center justify-center px-4 py-2 text-b5 font-medium text-white hover:bg-opacity-90 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-85 z-0">
                                <SvgCharmMenuKebab width="24px" height="24px" />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={React.Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                <div className="flex flex-col p-4 space-y-4">
                                  {tableOptions && formatTableOptions(tableOptions, isActive) &&
                                    Object.entries(formatTableOptions(tableOptions, isActive) || {}).map(([option, value]) => (
                                      value && (
                                        <Menu.Item key={option}>
                                          <span className="text-b4 cursor-pointer flex items-center space-x-2" onClick={() => onClick(rowIndex, option)}>
                                            {/* Your menu item content */}
                                            {renderIcon(option)}
                                            <span>{option.charAt(0).toUpperCase() + option.slice(1).replaceAll("_", " ")}</span>
                                          </span>
                                        </Menu.Item>
                                      )
                                    ))
                                  }
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        ) : null}
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
      </tbody>
      {mobileRows && Array.isArray(mobileRows) && (
        <tbody className="table-row-group md:hidden">
          {Array.isArray(rows) &&
            rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={onClick ? () => onClick(rowIndex) : undefined}
                hasBorder={hasBorder}
                hasFooterBg={hasFooterBg && rowIndex === rows?.length - 1}
              >
                {/* loop through mobileRows, then get the data in the row via row */}
                {mobileRows?.map((header, index) => (
                  <TableCell key={index} isBoldText={hasFooterBg && rowIndex === rows?.length - 1}>
                    <TableCellContent content={row[header.key]} weight={hasFooterBg && rowIndex === rows?.length - 1 ? 'semiBold' : 'normal'} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </tbody>
      )}
    </>
  );
};

export default TableBody;