import * as React from "react";
import { LogoProps } from './types'
import SvgLogo from "assets/icons/SvgLogo";

const Base = (props: LogoProps) => {
  return (
    <div className="w-[150px] 3xl:w-[200px]">
      <SvgLogo width="100%" height="100%" className={`
        ${props.className || ""}
      `} />
    </div>
  )
}

export default Base