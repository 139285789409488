import { COLORS } from 'constants/colors'
import { ClientKeyPreference } from 'core/generated'
import { useModal } from 'hooks'
import React from 'react'
import { Button, Heading, Modal, Paragraph, ToastProps } from 'ui'
import DeleteClientKeyPreference from './DeleteKeyPreferenceModal'
import { useDeleteClientKeyPreference } from 'api/useClient'

const ClientPreferenceModal = ({
  keyPreference,
  isOpen,
  onClose,
  addToast,
}: {
  keyPreference: ClientKeyPreference,
  isOpen: boolean,
  onClose: () => void,
  addToast: (toast: ToastProps) => void
}) => {
  const {
    loading,
    deleteKeyPreference,
  } = useDeleteClientKeyPreference()

  const {
    isVisible: isDeleteClientPreferenceModalIsVisible,
    openModal: openDeleteClientPreferenceModal,
    closeModal: closeDeleteClientPreferenceModal
  } = useModal()

  const deleteKeyPreferenceAsyc = () => {
    deleteKeyPreference({
      variables: {
        input: {
          keyPreferenceId: keyPreference?.id
        }
      }
    }).then(({ data }) => {
      const { deleteKeyPreference } = data;

      if (deleteKeyPreference?.status === 200) {
        addToast({
          message: 'Key preference deleted successfully',
          variant: 'success'
        })
        closeDeleteClientPreferenceModal()
        onClose()
      }
    }).catch((error) => {
      addToast({
        message: error.message,
        variant: 'error'
      })
    })
  }

  const _closeModal = (action?: string) => {
    if (action === 'delete') {
      deleteKeyPreferenceAsyc()
    }
    closeDeleteClientPreferenceModal()
  }
  return (
    <>
      <Modal
        show={isOpen}
        closeModal={onClose}
      >
        <div className="w-full xl:border-none bg-white rounded-lg shadow-medium mx-auto py-12 max-w-[400px] xl:max-w-[500px]">
          <div className="w-full flex flex-col items-start space-y-6">
            <div className='w-full flex items-center space-x-2'>
              <img
                src={keyPreference?.keyPreferenceCategory?.iconSvg}
                alt="key icon"
                style={{ width: '24px', height: '24px', marginRight: '8px' }}
              />
              <Heading variant='h1' size='h9' weight='bold' color={COLORS.GREY[300]}>
                {keyPreference?.keyPreferenceCategory?.label}
              </Heading>
            </div>
            <Paragraph size="b4" weight='semiBold' className='w-full max-w-[400px] py-2' color={COLORS.GREY[400]}>
              {keyPreference?.details}
            </Paragraph>
            <Button
              variant='primary'
              className=''
              size='lg'
              rounded='lg'
              onClick={() => onClose()}
            >
              Close
            </Button>
            <Button
              variant='text'
              className='mx-auto text-red-500'
              size='lg'
              rounded='lg'
              fontSize='b5'
              fontWeight='semiBold'
              onClick={() => openDeleteClientPreferenceModal()}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <DeleteClientKeyPreference
        isVisible={isDeleteClientPreferenceModalIsVisible}
        closeModal={_closeModal}
        isLoading={loading}
      />
    </>
  )
}

export default ClientPreferenceModal
