/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { Business, Client, User } from "core/generated";
import { LOCAL_STORAGE_KEYS } from "constants/information";

export const useUserCache = () => {

  const setUserData = useCallback((user: any) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER_DATA, JSON.stringify(user));
  }, []);

  const setCustomerUserData = useCallback((client: Client) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_SPLICE_CUSTOMER, JSON.stringify(client));
  }, []);

  const setBusinessData = useCallback((business: Business) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_SPLICE_BUSINESS, JSON.stringify(business));
  }, []);

  const getUserData = useCallback((): User | null => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER_DATA) as string);
  }, []);

  const getCustomerData = useCallback(() : Client | null => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_CUSTOMER) as string);
  }, []);

  const checkBusinessSetUp = useCallback(() => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SKIP_SETUP) as string);
  }, []);
  
  const skipSetUp = useCallback((skipSetup: {
    skip: boolean
  }) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SKIP_SETUP, JSON.stringify(skipSetup));
  }, []);

  const getBusinessData = useCallback(() => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_BUSINESS) as string);
  }, []);

  return {
    setUserData,
    setBusinessData,
    getUserData,
    getBusinessData,
    skipSetUp,
    checkBusinessSetUp,
    setCustomerUserData,
    getCustomerData,
  };
};
