export const APPOINTMENT_PAYMENT_METHOD = {
  unpaid: "unpaid",
  card: "card",
  pos: "pos",
  transfer: "transfer"
}

export const APPOINTMENT_PAYMENT_METHODS = [
  {
    label: "Pay now using card or bank transfer ",
    value: APPOINTMENT_PAYMENT_METHOD.card
  },
  {
    label: "Pay later at store",
    value: APPOINTMENT_PAYMENT_METHOD.unpaid
  }
];

export const APPOINTMENT_PAYMENT_METHODS_OFFLINE = [
  {
    label: "Pay later at store",
    value: APPOINTMENT_PAYMENT_METHOD.unpaid
  }
];

export const BOOKING_PAGE_TAB_NAME = {
  SERVICES: 'Services',
  VOUCHERS: 'Vouchers',
}

export const BOOKING_PAGE_TABS = [
  {
    title: BOOKING_PAGE_TAB_NAME.SERVICES,
    key: BOOKING_PAGE_TAB_NAME.SERVICES
  }, {
    title: BOOKING_PAGE_TAB_NAME.VOUCHERS,
    key: BOOKING_PAGE_TAB_NAME.VOUCHERS
  }
]

export const MYSELF_AND_SOMEONE_ELSE_BOOKING_INPUT = [
  {
    label: "Myself",
    value: "myself"
  }, {
    label: "Someone Else",
    value: "someoneElse"
  }
]

export const PACKAGE_ONLY_IN_CART_ERROR = 'Please remove service before adding package';
export const SERVICE_ONLY_IN_CART_ERROR = 'Please remove package before adding service';

export const INITIAL_STEP = 1;
export const PURCHASE_SUCCESSFUL_STEP = 5;
export const USER_DETAILS_STEP = 3;
export const PHONE_FORM_STEP = 2;
export const TIME_AND_DATE = 4;
export const STAFF_SELECTION = 7;
export const VOUCHER_SERVICE_SELECTION = 6;

export const ITEM_PURCHASED_OR_ACTION = {
  VOUCHER: 'voucher',
  SERVICE: 'service',
  REDEEMED: 'redeemed'
}

export const APPOINTMENT_TYPES = [
  {
    "label": "Online",
    "value": "online"
  },
  {
    "label": "Walk-in",
    "value": "walk_in"
  },
  {
    "label": "Phone",
    "value": "phone"
  },
]

export const ADD_APPOINTMENT_TYPES = [
  {
    "label": "Walk-in",
    "value": "walk_in"
  },
  {
    "label": "Phone",
    "value": "phone"
  },
]

export const SALES_PAYMENT_METHODS = [
  {
    label: 'Cash',
    value: 'cash'
  },
  {
    label: 'POS',
    value: 'pos'
  },
  {
    label: 'Bank transfer',
    value: 'bank_transfer'
  },
  {
    label: 'Gift Card',
    value: 'gift_card'
  }
]

export const PAYMENT_TYPE_NAME_AND_VALUE = {
  NOT_PAID: {
    label: 'Not paid',
    value: 'unpaid'
  },
  CASH: {
    label: 'Cash',
    value: 'cash'
  },
  POS: {
    label: 'POS',
    value: 'pos'
  },
  WALLET: {
    label: 'Wallet',
    value: 'client_wallet'
  },
  BANK_TRANSFER: {
    label: 'Bank transfer',
    value: 'bank_transfer'
  },
  VOUCHER: {
    label: 'Voucher',
    value: 'voucher'
  },
  GIFT_CARD: {
    label: 'Gift Card',
    value: 'gift_card'
  }
}

export const PAYMENT_METHODS = [
  PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID,
  PAYMENT_TYPE_NAME_AND_VALUE.CASH,
  PAYMENT_TYPE_NAME_AND_VALUE.POS,
  PAYMENT_TYPE_NAME_AND_VALUE.BANK_TRANSFER,
  PAYMENT_TYPE_NAME_AND_VALUE.VOUCHER,
  PAYMENT_TYPE_NAME_AND_VALUE.GIFT_CARD,
  PAYMENT_TYPE_NAME_AND_VALUE.WALLET
];

export const PAYMENT_METHODS_OFFLINE_ONLY = [
  PAYMENT_TYPE_NAME_AND_VALUE.NOT_PAID,
  PAYMENT_TYPE_NAME_AND_VALUE.CASH,
]

export const APPOINTMENT_STATUS = {
  pending: "pending",
  confirmed: "confirmed",
  cancelled: "cancelled",
  completed: "completed",
  no_show: "no_show",
  rescheduled: "rescheduled",
  rejected: "rejected",
  checked_in: "checked_in",
  deposit_pending: "deposit_pending",
  deposit_abandoned: "deposit_abandoned",
} as const;
