// useAuth.ts

import { apolloClient } from "core/graphql";
import { SetAppAuthTokenProps, TimestampProps } from "../types";
import { LOCAL_STORAGE_KEYS } from "../constants";

export const useManageAuth = () => {
  const setAppAuthToken = ({ token }: SetAppAuthTokenProps) => {
    console.log({
      token
    })
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN,
      token
    );
  };

  const getAppAuthToken = () => {
    try {
      // get token from local storage
      const token = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN);
      return token;
    } catch (error) {
      return undefined;
    }
  };

  const getCustomerAuthToken = () => {
    try {
      // get token from local storage
      const token = localStorage.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_AUTH_TOKEN);
      return token;
    } catch (error) {
      return undefined;
    }
  };

  const logoutCustomer = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_CUSTOMER);
    // clear cache
    try {
      apolloClient.resetStore()
      window.location.href = '/customer-dashboard'
    } catch (error) {
      console.log(error);
    }
  }

  const logout = () => {
    // clear cache
    try {
      apolloClient.resetStore()
      localStorage.clear();
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_INFO);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SALON);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_BUSINESS);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SKIP_SETUP);
      window.location.href = '/login'
    } catch (error) {
      console.log(error);
    }
  };

  return {
    setAppAuthToken,
    getAppAuthToken,
    logoutCustomer,
    logout,
    getCustomerAuthToken,
  };
};