import { useCallback } from 'react'
import { LOCAL_STORAGE_KEYS } from 'constants/information'
import { apolloClient } from 'core/graphql'
import { Salon } from 'core/generated'

export const useSalonCache = () => {
  const setSalonData = useCallback((salon: Salon) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.APP_SPLICE_SALON,
      JSON.stringify(salon)
    )
  }, [])

  const setSalons = useCallback((salons: Salon[]) => {
    localStorage.setItem('salons', JSON.stringify(salons))
  }, [])

  const getSalons = useCallback(() => {
    const salons = localStorage.getItem('salons')
    return JSON.parse(salons) || null
  }, [])

  const getSalonData = useCallback(() => {
    const salon = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SALON) as string

    if (salon === undefined || salon === 'undefined') {
      logout()
    }
    if (salon) return JSON.parse(salon)
  }, [])

  const getSalonFieldValue = useCallback(
    (field: keyof Salon) => {
      return getSalonData()?.[field]
    },
    [getSalonData]
  )


  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_INFO);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_USER);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SALON);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_BUSINESS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.APP_SPLICE_SKIP_SETUP);

    // clear cache
    try {
      apolloClient.resetStore()
    } catch (error) {
      console.log(error);
    }
  };

  return {
    setSalonData,
    getSalonData,
    getSalonFieldValue,
    getSalons,
    setSalons
  }
}
